
import { mapGetters } from 'vuex';

import { MY_PAGE_MENUS } from '@/constants/my-page';

import CustomNuxtLink from '@/components/CustomNuxtLink.vue';

import myPageMixin from '@/mixins/myPageMixin';
import routeMixin from '@/mixins/routeMixin';

export default {
	name: 'MyPageMobileMenu',
	mixins: [myPageMixin, routeMixin],
	props: {
		myPageMenus: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			showDrawer: false,
			boxHeight: 0,
		};
	},
	computed: {
		...mapGetters(['isMentor']),
	},
	watch: {
		showDrawer() {
			const iconRefs = this.$refs.myPageMobileDrawerArrowIcon;
			if (!this.showDrawer) {
				this.boxHeight = 0;
				iconRefs.$el.classList.add('click-passive');
				iconRefs.$el.classList.remove('click-active');
			} else {
				const refs = this.$refs.myPageExtendedMenus;
				this.boxHeight = refs.clientHeight;
				iconRefs.$el.classList.add('click-active');
				iconRefs.$el.classList.remove('click-passive');
			}
		},
	},
	methods: {
		goMenuUrl(url) {
			this.$_routeMixin_go_page(url);
		},
		popoverProps(menu) {
			return menu.popover
				? {
						placement: 'right',
						open: true,
						'auto-hide': false,
						triggers: [],
						trigger: '',
				  }
				: {};
		},
		badgeProps(menu) {
			return menu.badge
				? {
						text: menu.badge,
						color: 'secondary',
						size: 'small',
						type: 'inline',
				  }
				: {};
		},
		extendMenus() {
			return (this.showDrawer = !this.showDrawer);
		},
		getIsShowBottomBorder(name) {
			return this.isMentor
				? [MY_PAGE_MENUS.CREATE_CLASS, MY_PAGE_MENUS.THANKS, MY_PAGE_MENUS.COMMUNITY].includes(name)
				: name === MY_PAGE_MENUS.COMMUNITY;
		},
		applySubMenuStyle(menu) {
			if (this.isMentor) {
				return menu.isSubMenu;
			}
			return menu.isDrawer;
		},
	},
	components: {
		CustomNuxtLink,
	},
};
