import { render, staticRenderFns } from "./MyPageNuxt.vue?vue&type=template&id=52ea96ea&scoped=true&"
import script from "./MyPageNuxt.vue?vue&type=script&lang=js&"
export * from "./MyPageNuxt.vue?vue&type=script&lang=js&"
import style0 from "./MyPageNuxt.vue?vue&type=style&index=0&id=52ea96ea&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52ea96ea",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MyPageAsideMenu: require('/app/components/my-page/layout/MyPageAsideMenu.vue').default,MyPageMobileProfile: require('/app/components/my-page/layout/MyPageMobileProfile.vue').default,MyPageNavSection: require('/app/components/my-page/layout/MyPageNavSection.vue').default,MyPageInfoBoard: require('/app/components/my-page/layout/MyPageInfoBoard.vue').default,MyPageMobileMenu: require('/app/components/my-page/layout/MyPageMobileMenu.vue').default})
