import { eduFilterTopCategoryOrderArray } from '@/constants/edu/index';

import { orderArrayByIndex } from '@/utils/edu/category';

import {
	GET_KEYWORD,
	EDU_LIST_FILTER_TOP_CATEGORIES,
	SET_EDU_LIST_CAMPS,
	SET_EDU_LIST_FILTER_PAGE,
	SET_EDU_LIST_FILTER_TOP_CATEGORIES,
	SET_EDU_LIST_FILTER_KEYWORD,
	mapMutationsOfEduList,
	mapGettersOfEduList,
} from '@/store/edu/list';

import gtmTriggerMixin from '@/mixins/gtmTriggerMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

const URL_SEPARATOR = '+';

const eduFilterMixin = {
	mixins: [gtmTriggerMixin, userInfoMixin],
	computed: {
		...mapGettersOfEduList([GET_KEYWORD, EDU_LIST_FILTER_TOP_CATEGORIES]),
		$_isSearch() {
			return this[GET_KEYWORD] !== '';
		},
		$_eduFilterMixin_currentCategories() {
			return this.$route.query.category ? this.$route.query.category.split(URL_SEPARATOR) : [];
		},
		$_eduFilterMixin_currentDays() {
			return this.$route.query.day ? this.$route.query.day.split(URL_SEPARATOR) : [];
		},
		$_eduFilterMixin_hasEtcFilter() {
			return this.$route.query.day || this.$route.query.endDate;
		},
		$_eduFilterMixin_endDate() {
			return this.$route.query.endDate;
		},
		$_eduFilterMixin_hasFilter() {
			return this.$_eduFilterMixin_currentCategories.length > 0 || this.$_eduFilterMixin_hasEtcFilter;
		},
	},
	methods: {
		...mapMutationsOfEduList([
			SET_EDU_LIST_CAMPS,
			SET_EDU_LIST_FILTER_PAGE,
			SET_EDU_LIST_FILTER_KEYWORD,
			SET_EDU_LIST_FILTER_TOP_CATEGORIES,
		]),
		$_init() {
			this.$_resetEduListFilterPage();
			this[SET_EDU_LIST_CAMPS](null);
		},
		$_resetEduListFilterPage() {
			this.SET_EDU_LIST_FILTER_PAGE(1);
		},
		$_triggerSelectCampCategory(category) {
			this.$_selectCampCategory({ ...this.$_userInfoForGtm, where: 'EduCamp', camp_category: category });
		},
		$_handleFilterText(data) {
			const dataCount = Array.isArray(data) ? data.length : 0;
			const isMoreData = dataCount > 1;
			if (isMoreData) {
				return `${data[0]} 외 ${dataCount - 1}개`;
			} else {
				return `${data}`;
			}
		},
		async $_loadEduTopCategories() {
			try {
				const response = await this.$api.camp.getEduTopCategory();
				this[SET_EDU_LIST_FILTER_TOP_CATEGORIES](
					orderArrayByIndex(response.data, eduFilterTopCategoryOrderArray),
				);
			} catch (error) {
				console.error(error);
			}
		},
		$_triggerBeginApplyFilterGtm() {
			this.$_beginApplyFilter({
				user_no: this.userNo,
				user_type: this.userType,
				email: this.userId,
				where: 'EduCamp',
			});
		},
		$_eduFilterMixin_triggerSelectFilterOptionGtm(category) {
			this.$_selectFilterOption({
				user_no: this.userNo,
				user_type: this.userType,
				email: this.userId,
				where: 'EduCamp',
				category,
			});
		},
		$_eduFilterMixin_triggerClickApplyFilterButtonGtm(categories) {
			this.$_clickApplyFilterButton({
				user_no: this.userNo,
				user_type: this.userType,
				email: this.userId,
				where: 'EduCamp',
				category: categories,
			});
		},
	},
};

export default eduFilterMixin;
