
import { mapActions, mapGetters } from 'vuex';

import { CHECK_CLASS_OPEN_REJECTION, mapActionsOfClassPtOpen } from '@/store/class/pt/open';
import { CHECK_KDC_PROGRESSING_EXIST, mapActionsOfKdc } from '@/store/kdc';
import { LOAD_PASS_INFO, mapActionsOfMyPage } from '@/store/myPage';

import GridLayout from '@/components/common/atoms/GridLayout.vue';
import MyPageAsideMenu from '@/components/my-page/layout/MyPageAsideMenu.vue';
import MyPageInfoBoard from '@/components/my-page/layout/MyPageInfoBoard.vue';
import MyPageMobileMenu from '@/components/my-page/layout/MyPageMobileMenu.vue';
import MyPageMobileProfile from '@/components/my-page/layout/MyPageMobileProfile.vue';
import MyPageNavSection from '@/components/my-page/layout/MyPageNavSection.vue';

import layoutMixin from '@/mixins/layoutMixin';
import myPageMixin from '@/mixins/myPageMixin';
import routeMixin from '@/mixins/routeMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

export default {
	name: 'MyPageNuxt',
	mixins: [myPageMixin, layoutMixin, routeMixin, userInfoMixin],
	async fetch() {
		try {
			await this.getNotificationData();
		} catch (error) {
			console.error(error);
		}
	},
	computed: {
		...mapGetters(['isUser', 'isMentor', 'userType']),
		isShowMobileProfileMenu() {
			return this.IS_MOBILE && this.$route.name === 'mypage';
		},
		isShowTopBarDivider() {
			return this.IS_MOBILE && !this.$_hasNavs;
		},
		getGridColor() {
			if (!this.IS_MOBILE) {
				return 'inherit';
			}
			if (this.isShowMobileProfileMenu || this.$route.name === 'mypage-setting') {
				return 'gray100';
			}

			return 'white';
		},
		computedMyMenus() {
			if (this.isMentor) {
				return {
					menus: this.$_myPageMenus,
					drawers: [],
				};
			}
			return this.$_myPageMenus.reduce(
				(acc, cur) => {
					if (cur.isDrawer) {
						return {
							menus: [...acc.menus],
							drawers: [...acc.drawers, cur],
						};
					} else {
						return {
							menus: [...acc.menus, cur],
							drawers: [...acc.drawers],
						};
					}
				},
				{ menus: [], drawers: [] },
			);
		},
	},
	watch: {
		$_currentMenu() {
			this.getNotificationData();
		},
	},
	created() {
		if (this.isUser) {
			this.getUserProfileImage();
			this[LOAD_PASS_INFO]();
		}
	},
	methods: {
		...mapActions(['getUserProfileImage']),
		...mapActionsOfMyPage([LOAD_PASS_INFO]),
		...mapActionsOfClassPtOpen([CHECK_CLASS_OPEN_REJECTION]),
		...mapActionsOfKdc([CHECK_KDC_PROGRESSING_EXIST]),
		async getNotificationData() {
			await this[CHECK_CLASS_OPEN_REJECTION]();
			await this[CHECK_KDC_PROGRESSING_EXIST]();
		},
	},
	components: {
		MyPageInfoBoard,
		MyPageAsideMenu,
		GridLayout,
		MyPageNavSection,
		MyPageMobileProfile,
		MyPageMobileMenu,
	},
};
