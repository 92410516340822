import { isProduction } from '@/plugins/constants';

const styles = ['border: 1px solid #3E0E02', 'color: red'].join(';');

window.console.customLogger = function (consoleFunction) {
	if (!isProduction) {
		console.debug('%c***************************', styles);
		console.group();
		consoleFunction();
		console.groupEnd();
		console.debug('%c***************************', styles);
	}
};
