
import CommonLogin from '@/components/common/molecules/login/CommonLogin.vue';

export default {
	name: 'CommonLoginModal',
	props: {
		show: {
			type: Boolean,
		},
		redirectUrl: {
			type: String,
			default: null,
		},
		showCloseButton: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		showModal: {
			get() {
				return this.show;
			},

			set() {
				this.$emit('update:show');
			},
		},
	},
	components: {
		CommonLogin,
	},
};
