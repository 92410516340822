import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'camp/path';
export { NAMESPACE as NAMESPACE_CAMP_PATH };

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfCampPath,
	mapMutations as mapMutationsOfCampPath,
	mapActions as mapActionsOfCampPath,
};

// states
export const PATH_CONTRACT_NO = 'PATH_CONTRACT_NO';
export const PATH_CONTRACT_INFO = 'PATH_CONTRACT_INFO';
export const IS_CHECK_CAREER_AUTH = 'IS_CHECK_CAREER_AUTH';
export const IS_APPLY_CAREER_CAMP = 'IS_APPLY_CAREER_CAMP';
export const APPLY_CAREER_CAMP_LIST = 'APPLY_CAREER_CAMP_LIST';
export const EDU_PATH_LIST = 'EDU_PATH_LIST';
export const SELECTED_PATH_CATEGORY = 'SELECTED_PATH_CATEGORY';
export const EDU_PATH_APPLY_STEP = 'EDU_PATH_APPLY_STEP';
export const SHOW_EDU_PATH_APPLY_MODAL = 'SHOW_EDU_PATH_APPLY_MODAL';

// mutations

export const SET_PATH_CONTRACT_NO = 'SET_PATH_CONTRACT_NO';
export const SET_PATH_CONTRACT_INFO = 'SET_PATH_CONTRACT_INFO';
export const SET_IS_CHECK_CAREER_AUTH = 'SET_IS_CHECK_CAREER_AUTH';
export const SET_IS_APPLY_CAREER_CAMP = 'SET_IS_APPLY_CAREER_CAMP';
export const SET_APPLY_CAREER_CAMP_LIST = 'SET_APPLY_CAREER_CAMP_LIST';
export const SET_EDU_PATH_LIST = 'SET_EDU_PATH_LIST';
export const SET_SELECTED_PATH_CATEGORY = 'SET_SELECTED_PATH_CATEGORY';
export const SET_EDU_PATH_APPLY_STEP = 'SET_EDU_PATH_APPLY_STEP';
export const SET_SHOW_EDU_PATH_APPLY_MODAL = 'SET_SHOW_EDU_PATH_APPLY_MODAL';

// actions
export const LOAD_PATH_CAMP_AUTH = 'LOAD_PATH_CAMP_AUTH';
export const LOAD_PATH_CONTRACT_INFO = 'LOAD_PATH_CONTRACT_INFO';

export const state = () => ({
	[PATH_CONTRACT_NO]: null,
	[PATH_CONTRACT_INFO]: null,
	[IS_CHECK_CAREER_AUTH]: false,
	[IS_APPLY_CAREER_CAMP]: false,
	[APPLY_CAREER_CAMP_LIST]: [],
	[EDU_PATH_LIST]: [],
	[SELECTED_PATH_CATEGORY]: [],
	[EDU_PATH_APPLY_STEP]: 1,
	[SHOW_EDU_PATH_APPLY_MODAL]: false,
});

export const getters = {
	[PATH_CONTRACT_NO]: state => state[PATH_CONTRACT_NO],
	[PATH_CONTRACT_INFO]: state => state[PATH_CONTRACT_INFO],
	[IS_CHECK_CAREER_AUTH]: state => state[IS_CHECK_CAREER_AUTH],
	[IS_APPLY_CAREER_CAMP]: state => state[IS_APPLY_CAREER_CAMP],
	[APPLY_CAREER_CAMP_LIST]: state => state[APPLY_CAREER_CAMP_LIST],
	[EDU_PATH_LIST]: state => state[EDU_PATH_LIST],
	[SELECTED_PATH_CATEGORY]: state => state[SELECTED_PATH_CATEGORY],
	[EDU_PATH_APPLY_STEP]: state => state[EDU_PATH_APPLY_STEP],
	[SHOW_EDU_PATH_APPLY_MODAL]: state => state[SHOW_EDU_PATH_APPLY_MODAL],
};

export const mutations = {
	[SET_PATH_CONTRACT_NO]: (state, payload) => {
		state[PATH_CONTRACT_NO] = payload;
	},
	[SET_PATH_CONTRACT_INFO]: (state, payload) => {
		state[PATH_CONTRACT_INFO] = payload;
	},
	[SET_IS_CHECK_CAREER_AUTH]: (state, payload) => {
		state[IS_CHECK_CAREER_AUTH] = payload;
	},
	[SET_IS_APPLY_CAREER_CAMP]: (state, payload) => {
		state[IS_APPLY_CAREER_CAMP] = payload;
	},
	[SET_APPLY_CAREER_CAMP_LIST]: (state, payload) => {
		state[APPLY_CAREER_CAMP_LIST] = payload;
	},
	[SET_EDU_PATH_LIST]: (state, payload) => {
		state[EDU_PATH_LIST] = payload;
	},
	[SET_SELECTED_PATH_CATEGORY]: (state, payload) => {
		const copiedList = [...state[SELECTED_PATH_CATEGORY]];
		const foundedIndex = copiedList.indexOf(payload);
		if (foundedIndex === -1) {
			copiedList.push(payload);
		} else {
			copiedList.splice(foundedIndex, 1);
		}
		state[SELECTED_PATH_CATEGORY] = copiedList;
	},
	[SET_EDU_PATH_APPLY_STEP]: (state, payload) => {
		state[EDU_PATH_APPLY_STEP] = payload;
	},
	[SET_SHOW_EDU_PATH_APPLY_MODAL]: (state, payload) => {
		state[SHOW_EDU_PATH_APPLY_MODAL] = payload;
	},
};

export const actions = {
	async [LOAD_PATH_CAMP_AUTH]({ commit }) {
		commit(SET_IS_APPLY_CAREER_CAMP, false);
		/* getEduPathAuth
		 * 200
		 * 403 - 비회원
		 * 404 - 진로캠 미가입 유저
		 * */
		const {
			data: { code },
		} = await this.$api.camp.getEduPathAuth();
		if (code === 200) {
			commit(SET_IS_CHECK_CAREER_AUTH, true);
			const {
				data: { data, contract_no },
			} = await this.$api.camp.getEduPathCart();

			commit(SET_APPLY_CAREER_CAMP_LIST, data);
			commit(SET_PATH_CONTRACT_NO, contract_no);
		} else {
			commit(SET_APPLY_CAREER_CAMP_LIST, []);
			commit(SET_PATH_CONTRACT_NO, null);
		}
		return { code };
	},
	async [LOAD_PATH_CONTRACT_INFO]({ commit, getters }) {
		try {
			const {
				data: { data },
			} = await this.$api.myPage.totalEduPathInfo({ contract_no: getters.PATH_CONTRACT_NO });
			commit(SET_PATH_CONTRACT_INFO, data);
		} catch (error) {
			console.error(error);
		}
	},
};
