import { createNamespacedHelpers } from 'vuex';
const NAMESPACE = 'pullToRefresh';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfPullToRefresh,
	mapMutations as mapMutationsOfPullToRefresh,
	mapActions as mapActionsOfPullToRefresh,
};

export const SPINNER_OFFSET_Y = 'SPINNER_OFFSET_Y';

export const GET_SPINNER_OFFSET_Y = 'GET_SPINNER_OFFSET_Y';

export const SET_SPINNER_OFFSET_Y = 'SET_SPINNER_OFFSET_Y';

export const state = () => ({
	[SPINNER_OFFSET_Y]: 0,
});

export const getters = {
	[GET_SPINNER_OFFSET_Y]: state => {
		return state[SPINNER_OFFSET_Y];
	},
};

export const mutations = {
	SET_SPINNER_OFFSET_Y: (state, payload) => {
		state[SPINNER_OFFSET_Y] = payload;
	},
};
