import Vue from 'vue';
import { mapGetters } from 'vuex';

import {
	mapGettersOfLayout,
	IS_SHOW_HEADER,
	IS_TRANSPARENT_TYPE,
	BACKWARD_BUTTON_MARGIN_BOTTOM,
	IS_LOGO_TOP_BAR,
	IS_SHOW_SUB_HEADER,
	IS_SHOW_FOOTER,
} from '@/store/layout';

// 이 mixin은 app에서 호출됩니다.
const appCommandReceiveMixin = {
	computed: {
		...mapGetters(['metaTitle', 'metaIsDetail', 'metaBgColor']),
		...mapGettersOfLayout([
			IS_SHOW_HEADER,
			IS_TRANSPARENT_TYPE,
			BACKWARD_BUTTON_MARGIN_BOTTOM,
			IS_LOGO_TOP_BAR,
			IS_SHOW_SUB_HEADER,
			IS_SHOW_FOOTER,
		]),
	},
	methods: {
		/**
		 * 현재 meta 값을 반환하는 메서드
		 * 페이지 전환 시 vue lifecycle이 trigger되지 않을 때 앱에서 호출
		 */
		$_requestCurrentMeta() {
			try {
				return this.$_processData({
					path: this.$route.fullPath,
					isDetail: this.metaIsDetail,
					bgColor: this.metaBgColor,
					title: this.metaTitle,
					backwardButtonMarginBottom: this.BACKWARD_BUTTON_MARGIN_BOTTOM,
					isLogoTopBar: this.IS_LOGO_TOP_BAR,
					// 기존 meta isShowSubHeader를 다음 조건식으로 변경
					isShowSubHeader: !(this.metaIsDetail || this.IS_LOGO_TOP_BAR),
					isShowFooter: this.IS_SHOW_FOOTER,
					isTransparentType: this.IS_TRANSPARENT_TYPE,
					isShowHeader: this.IS_SHOW_HEADER,
				});
			} catch (e) {
				console.error(e);
				return null;
			}
		},
		$_processData(data) {
			if (data === null || data === undefined) {
				throw new Error('값이 없습니다.');
			}
			return JSON.stringify(data);
		},
	},
};

export default appCommandReceiveMixin;

if (!Vue.__app_command_receive_mixin__) {
	Vue.__app_command_receive_mixin__ = true;
	Vue.mixin(appCommandReceiveMixin);
}
