import get from 'lodash/get';
import { createNamespacedHelpers } from 'vuex';
const NAMESPACE = 'community/insight';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfCommunityInsight,
	mapMutations as mapMutationsOfCommunityInsight,
	mapActions as mapActionsOfCommunityInsight,
};

// states
export const POSTS = 'POSTS';
export const POSTS_TOTAL_COUNT = 'POSTS_TOTAL_COUNT';
export const POSTS_PARAMS = 'POSTS_PARAMS';
export const POST_ID = 'POST_ID';
export const SORTING = 'SORTING';
export const SORTING_OPTIONS = 'SORTING_OPTIONS';
export const POSTS_LAST_PAGE = 'POSTS_LAST_PAGE';
export const INFINITE_ID = 'INFINITE_ID';
// 댓글 대댓글 공용
export const CREATED_COMMENT_ID = 'CREATED_COMMENT_ID';

// getters
export const GET_POSTS = 'GET_POSTS';
export const GET_POSTS_TOTAL_COUNT = 'GET_POSTS_TOTAL_COUNT';
export const GET_POSTS_PARAMS = 'GET_POSTS_PARAMS';
export const GET_POST_ID = 'GET_POST_ID';
export const GET_CREATED_COMMENT_ID = 'GET_CREATED_COMMENT_ID';
export const GET_IS_LAST_PAGE = 'GET_IS_LAST_PAGE';
export const GET_POSTS_LAST_PAGE = 'GET_POSTS_LAST_PAGE';
export const GET_POSTS_PAGE = 'GET_POSTS_PAGE';
export const GET_INFINITE_ID = 'GET_INFINITE_ID';

// mutations
export const SET_POSTS_PARAMS = 'SET_POSTS_PARAMS';
export const SET_POSTS = 'SET_POSTS';
export const SET_POSTS_TOTAL_COUNT = 'SET_POSTS_TOTAL_COUNT';
export const SET_SORTING = 'SET_SORTING';
export const SET_CREATED_COMMENT_ID = 'SET_CREATED_COMMENT_ID';
export const SET_POST_ID = 'SET_POST_ID';
export const SET_POSTS_LAST_PAGE = 'SET_POSTS_LAST_PAGE';
export const SET_INFINITE_ID = 'SET_INFINITE_ID';

// actions
export const FETCH_POSTS = 'FETCH_POSTS';

export const state = () => ({
	[POSTS]: null,
	[POSTS_TOTAL_COUNT]: 0,
	[POSTS_PARAMS]: {
		page: 1,
		limit: 12,
	},
	[POST_ID]: null,
	[SORTING_OPTIONS]: [
		{ label: '최신순', value: 'id' },
		{ label: '조회수순', value: 'view' },
		{ label: '좋아요순', value: 'like' },
	],
	[SORTING]: 'id',
	[CREATED_COMMENT_ID]: null,
	[POSTS_LAST_PAGE]: 1,
	[INFINITE_ID]: +new Date(),
});

export const getters = {
	[GET_POSTS]: state => state[POSTS],
	[GET_POSTS_TOTAL_COUNT]: state => state[POSTS_TOTAL_COUNT],
	[GET_POSTS_PARAMS]: state => state[POSTS_PARAMS],
	[GET_POST_ID]: state => state[POST_ID],
	[SORTING_OPTIONS]: state => state[SORTING_OPTIONS],
	[SORTING]: state => state[SORTING],
	[GET_CREATED_COMMENT_ID]: state => state[CREATED_COMMENT_ID],
	[GET_POSTS_LAST_PAGE]: state => state[POSTS_LAST_PAGE],
	[GET_IS_LAST_PAGE]: (_state, getters) => getters[GET_POSTS_LAST_PAGE] <= getters[GET_POSTS_PAGE],
	[GET_POSTS_PAGE]: (_state, getters) => get(getters, [GET_POSTS_PARAMS, 'page'], '1'),
	[GET_INFINITE_ID]: state => state[INFINITE_ID],
};

export const mutations = {
	[SET_POSTS]: (state, payload) => {
		state[POSTS] = payload;
	},
	[SET_POSTS_TOTAL_COUNT]: (state, payload) => {
		state[POSTS_TOTAL_COUNT] = payload;
	},
	[SET_POSTS_PARAMS]: (state, payload) => {
		state[POSTS_PARAMS] = { ...state[POSTS_PARAMS], ...payload };
	},
	[SET_POST_ID]: (state, payload) => {
		state[POST_ID] = payload;
	},
	[SET_SORTING]: (state, payload) => {
		state[SORTING] = payload;
	},
	[SET_CREATED_COMMENT_ID]: (state, payload) => {
		state[CREATED_COMMENT_ID] = payload;
	},
	[SET_POSTS_LAST_PAGE]: (state, payload) => {
		state[POSTS_LAST_PAGE] = payload;
	},
	[SET_INFINITE_ID]: (state, payload) => {
		state[INFINITE_ID] = payload;
	},
};

export const actions = {
	[FETCH_POSTS](_, params) {
		return new Promise((resolve, reject) => {
			const fetchPosts = this.$api.insight.getCommunityInsightPosts(params);
			fetchPosts
				.then(response => {
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
};
