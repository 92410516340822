export const CLASS_OPENING_STATUS = {
	IN_PROGRESS: '작성중',
	IN_REVIEW: '검수중',
	REJECTED: '반려',
	REVIEW_COMPLETE: '검수완료',
};

export const CLASS_OPENING_STATUS_TO_STRING_MAP = {
	12: CLASS_OPENING_STATUS.IN_PROGRESS,
	13: CLASS_OPENING_STATUS.IN_REVIEW,
	14: CLASS_OPENING_STATUS.REJECTED,
	15: CLASS_OPENING_STATUS.REVIEW_COMPLETE,
};

export const CLASS_OPENING_STATUS_TO_NUMBER_MAP = {
	[CLASS_OPENING_STATUS.IN_PROGRESS]: 12,
	[CLASS_OPENING_STATUS.IN_REVIEW]: 13,
	[CLASS_OPENING_STATUS.REJECTED]: 14,
	[CLASS_OPENING_STATUS.REVIEW_COMPLETE]: 15,
};

export const CLASS_OPENING_STATUS_TO_COLOR_MAP = {
	[CLASS_OPENING_STATUS.IN_PROGRESS]: 'primary',
	[CLASS_OPENING_STATUS.IN_REVIEW]: 'gray700',
	[CLASS_OPENING_STATUS.REVIEW_COMPLETE]: 'success',
	[CLASS_OPENING_STATUS.REJECTED]: 'orange600',
};

export const STEP_STATUS = {
	NOT_STARTED: 'notStarted',
	IN_PROGRESS: 'inProgress',
	COMPLETED: 'completed',
	REJECTED: 'rejected',
};
