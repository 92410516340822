import { ALUMNI_PREFIX_URL } from '@/api/after-class';
// alumni api에서 validation 미들웨어를 통과하기 위한 Map
const ALUMNI_VALIDATION_MAP = {
	type: 'community_notice',
	header_id: 1,
	category_name: '공지사항',
};

export default axios => ({
	/**
	 * 공지사항 글 가져오기
	 * @param {Object} params
	 * @param {number} params.page
	 * @param {number} params.limit
	 * @param {string} params.orderby: 정렬(id - 기본값, view, like)
	 * @param {string} params.keyword: 검색어
	 * @param {boolean} params.is_notice: 공지글만 보기
	 * @param {number} params.notice_off: 공지글 제외
	 * @param {string} params.type: community_notice
	 * @param {string} params.category_name
	 * @param {number} params.header_id
	 */
	getNoticePosts(params) {
		return axios.get(`${ALUMNI_PREFIX_URL}`, { params: { type: ALUMNI_VALIDATION_MAP.type, ...params } });
	},
	/**
	 * 공지사항 글 detail 가져오기
	 **/
	getNoticePost(postId) {
		return axios.get(`${ALUMNI_PREFIX_URL}/view/${postId}?type=${ALUMNI_VALIDATION_MAP.type}`);
	},
	/**
	 * 공지사항 공지 작성 권한 체크
	 */
	getNoticeCheckWrite() {
		return axios.get(`${ALUMNI_PREFIX_URL}/check/write`);
	},
	/**
	 * 공지사항 글 작성하기
	 * @apiParam is_notice: 공지글 여부
	 * @apiParam header_id
	 * @apiParam category_name
	 * @apiParam title
	 * @apiParam content
	 * @apiParam images
	 */
	createNoticePost(data) {
		return axios.post(`${ALUMNI_PREFIX_URL}/write`, { ...data, ...ALUMNI_VALIDATION_MAP });
	},
	/**
	 * 공지사항 글 수정하기
	 **/
	updateNoticePost(data, postId) {
		return axios.post(`${ALUMNI_PREFIX_URL}/update/${postId}`, { ...data, ...ALUMNI_VALIDATION_MAP });
	},
	/**
	 * 공지사항 글 삭제하기
	 **/
	removeNoticePost(postId) {
		return axios.delete(`${ALUMNI_PREFIX_URL}/update/${postId}?type=${ALUMNI_VALIDATION_MAP.type}`);
	},
	/**
	 * 공지사항 글 조회수 증가
	 **/
	increaseNoticeViewCount(postId) {
		return axios.post(`${ALUMNI_PREFIX_URL}/view/${postId}/count`, ALUMNI_VALIDATION_MAP);
	},
	/**
	 * 공지사항 글 좋아요 했는지 여부
	 **/
	getIsPostLike(postId) {
		return axios.get(`${ALUMNI_PREFIX_URL}/view/${postId}/like?type=${ALUMNI_VALIDATION_MAP.type}`);
	},
	/**
	 * 공지사항 글 신고
	 * @data message
	 **/
	reportNoticePost(data, postId) {
		return axios.post(`${ALUMNI_PREFIX_URL}/report/article/${postId}`, { ...data, ...ALUMNI_VALIDATION_MAP });
	},
	/**
	 * 공지사항 글 좋아요 토글
	 * 좋아요 했으면 취소, 안했으면 좋아요
	 **/
	togglePostLike(postId) {
		return axios.post(`${ALUMNI_PREFIX_URL}/view/${postId}/like`, ALUMNI_VALIDATION_MAP);
	},
	/**
	 * 공지사항 글 댓글 작성
	 **/
	createPostComment(data, postId) {
		return axios.post(`${ALUMNI_PREFIX_URL}/comment/${postId}`, { ...data, ...ALUMNI_VALIDATION_MAP });
	},
	/**
	 * 공지사항 글 댓글 수정
	 * @apiParam comment_id
	 * @apiParam comment
	 **/
	updatePostComment(data, postId) {
		return axios.patch(`${ALUMNI_PREFIX_URL}/comment/${postId}`, { ...data, ...ALUMNI_VALIDATION_MAP });
	},
	/**
	 * 공지사항 글 댓글 삭제
	 * @apiParam comment_id
	 **/
	removePostComment(data, postId) {
		return axios.delete(`${ALUMNI_PREFIX_URL}/comment/${postId}?type=${ALUMNI_VALIDATION_MAP.type}`, { data });
	},
	/**
	 * 공지사항 글 대댓글 작성(댓글의 답글, 대댓글의 답글)
	 * @apiParam comment: 답글 내용
	 **/
	createPostCoComment(data, postId, commentOrReplyId) {
		return axios.post(`${ALUMNI_PREFIX_URL}/comment/${postId}/${commentOrReplyId}`, {
			...data,
			...ALUMNI_VALIDATION_MAP,
		});
	},
	/**
	 * 공지사항 글 대댓글 수정
	 * @apiParam cocomment_id
	 * @apiParam comment
	 **/
	updatePostCoComment(data, postId, commentId) {
		return axios.patch(`${ALUMNI_PREFIX_URL}/comment/${postId}/${commentId}`, {
			...data,
			...ALUMNI_VALIDATION_MAP,
		});
	},
	/**
	 * 공지사항 글 대댓글 삭제
	 **/
	removePostCoComment(data, postId, commentId) {
		return axios.delete(`${ALUMNI_PREFIX_URL}/comment/${postId}/${commentId}?type=${ALUMNI_VALIDATION_MAP.type}`, {
			data,
		});
	},
	/**
	 * 공지사항 글 댓글, 대댓글 좋아요 토글
	 **/
	togglePostCommentLike(postId, commentId) {
		return axios.post(`${ALUMNI_PREFIX_URL}/comment/${postId}/${commentId}/like`, ALUMNI_VALIDATION_MAP);
	},
	/**
	 * 공지사항 댓글, 대댓글 좋아요 했는지 여부
	 **/
	getIsCommentLikedByMe(postId, commentId) {
		return axios.get(`${ALUMNI_PREFIX_URL}/comment/${postId}/${commentId}/like?type=${ALUMNI_VALIDATION_MAP.type}`);
	},
	/**
	 * 댓글 or 대댓글 신고
	 * 둘다 같은 table에 id로 접근가능해서 한번에 처리가능
	 * @data message
	 **/
	reportNoticeComment(data, commentId) {
		return axios.post(`${ALUMNI_PREFIX_URL}/report/comment/${commentId}`, data);
	},
	/**
	 * 공지 배너 정보 가져오기
	 * @param {Object} params
	 * @param {string} params.filter: pc | mobile | app
	 */
	fetchNoticeAlert(params) {
		return axios.get(`/api/pull/banner`, { params });
	},
});
