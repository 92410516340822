import { useBookmarkRefs } from '@/stores/useBookmarkStore';

export default function ({ $axios, pinia }) {
	try {
		const { bookmarkedIds } = useBookmarkRefs(pinia);
		$axios.get('/api/user/bookmark/pts/getIds?type=pts').then(({ data }) => (bookmarkedIds.value.pts = data));
	} catch (error) {
		console.error(error);
	}
}
