import { createNamespacedHelpers } from 'vuex';

import dayjs, { getTimeInAsia } from '@/plugins/dayjs';

import { isEarlybird } from '@/utils/eduUtil';

const NAMESPACE = 'edu/list';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export { mapGetters as mapGettersOfEduList, mapMutations as mapMutationsOfEduList, mapActions as mapActionsOfEduList };

// states
export const EDU_LIST_CAMPS = 'EDU_LIST_CAMPS';
export const EDU_LIST_FILTER = 'EDU_LIST_FILTER';
export const SHOULD_RESET_KEYWORD = 'SHOULD_RESET_KEYWORD';
export const EDU_RECRUITING_LIST_CAMPS = 'EDU_RECRUITING_LIST_CAMPS';
export const TOTAL_EDU_CAMPS_COUNT = 'TOTAL_EDU_CAMPS_COUNT';
export const EDU_LIST_FILTER_TOP_CATEGORIES = 'EDU_LIST_FILTER_TOP_CATEGORIES';
export const PROMOTION_BANNERS = 'PROMOTION_BANNERS';
export const MATCHED_AFFILIATE_CAMP_LIST = 'MATCHED_AFFILIATE_CAMP_LIST';
export const MATCHED_AFFILIATE_PARTNER = 'MATCHED_AFFILIATE_PARTNER';

// getters
export const GET_EDU_LIST_CAMPS = 'GET_EDU_LIST_CAMPS';
export const GET_EDU_LIST_FILTER = 'GET_EDU_LIST_FILTER';
export const GET_KEYWORD = 'GET_KEYWORD';
export const GET_EDU_LIST_FILTER_PAGE = 'GET_EDU_LIST_FILTER_PAGE';
// 검색 키워드를 초기화해야 하는지의 flag
export const GET_SHOULD_RESET_KEYWORD = 'GET_SHOULD_RESET_KEYWORD';
export const GET_EDU_RECRUITING_LIST_CAMPS = 'GET_EDU_RECRUITING_LIST_CAMPS';
export const GET_MATCHED_AFFILIATE_CAMP_LIST = 'GET_MATCHED_AFFILIATE_CAMP_LIST';
export const GET_IS_MATCHED_CAMP_SECTION_APPEAR = 'GET_IS_MATCHED_CAMP_SECTION_APPEAR';
export const GET_MATCHED_AFFILIATE_PARTNER = 'GET_MATCHED_AFFILIATE_PARTNER';

export const GET_TOTAL_EDU_CAMPS_COUNT = 'GET_TOTAL_EDU_CAMPS_COUNT';
export const GET_TOP_BANNER = 'GET_TOP_BANNER';
export const GET_MIDDLE_BANNER = 'GET_MIDDLE_BANNER';
export const GET_ONLY_BANNER = 'GET_ONLY_BANNER';

// mutations
export const SET_EDU_LIST_CAMPS = 'SET_EDU_LIST_CAMPS';
export const SET_EDU_LIST_FILTER = 'SET_EDU_LIST_FILTER';
export const ADD_EDU_LIST_CAMPS = 'ADD_EDU_LIST_CAMPS';
export const SET_RESET_KEYWORD = 'SET_RESET_KEYWORD';
export const SET_EDU_RECRUITING_LIST_CAMPS = 'SET_EDU_RECRUITING_LIST_CAMPS';
export const SET_EDU_LIST_FILTER_PAGE = 'SET_EDU_LIST_FILTER_PAGE';
export const SET_TOTAL_EDU_CAMPS_COUNT = 'SET_TOTAL_EDU_CAMPS_COUNT';
export const SET_EDU_LIST_FILTER_TOP_CATEGORIES = 'SET_EDU_LIST_FILTER_TOP_CATEGORIES';
export const SET_EDU_LIST_FILTER_KEYWORD = 'SET_EDU_LIST_FILTER_KEYWORD';
export const SET_MATCHED_AFFILIATE_CAMP_LIST = 'SET_MATCHED_AFFILIATE_CAMP_LIST';
export const SET_MATCHED_AFFILIATE_PARTNER = 'SET_MATCHED_AFFILIATE_PARTNER';
// actions
export const RESET_KEYWORD = 'RESET_KEYWORD';
export const LOAD_EDU_LIST_CAMPS = 'LOAD_EDU_LIST_CAMPS';
export const LOAD_MATCHED_AFFILIATE_CAMP_LIST = 'LOAD_MATCHED_AFFILIATE_CAMP_LIST';

export const state = () => ({
	[EDU_LIST_CAMPS]: null,
	[EDU_LIST_FILTER]: {
		keyword: '',
		page: 1,
		limit: 9,
		partner: 'comento' /* partner url */,
		selectedDays: [],
		top_category: [],
		day: null,
		endDate: null,
	},
	[SHOULD_RESET_KEYWORD]: false,
	[EDU_RECRUITING_LIST_CAMPS]: null,
	[TOTAL_EDU_CAMPS_COUNT]: 0,
	[EDU_LIST_FILTER_TOP_CATEGORIES]: [],
	[PROMOTION_BANNERS]: {
		// 배너 추가시 imagePc와 imageMo를 모두 추가할것 -> top & only 호환이 가능해야 함
		// 배너 폰트의 경우, 색 강조를 대응하기 위해 span/p tag에 컬러를 입혀서 사용 할 것
		// gray700: #595F63
		top: [],
		only: [
			{
				from: '2023-04-25',
				to: '2023-04-28',
				mainPc: '김포시 거주자를 위한 특별 지원 혜택',
				mainMobile: '김포시 거주자 특별 혜택',
				mainMobileOnly: '김포시 거주자 특별 혜택',
				subPc: '김포시가 캠프 수강료를 지원합니다',
				subMobile: '김포시가 캠프 수강료 지원합니다',
				button: '지금 혜택 받기',
				nuxt: false,
				imagePc: 'https://cdn.comento.kr/images/edu/list/banner/gimpo-banner-only.png',
				imageMo: 'https://cdn.comento.kr/images/edu/list/banner/gimpo-banner-only-m.png',
				url: '/edu/univ/gimpo',
				color: '#0778AB',
			},
		],
		middle: [
			{
				from: '2023-06-22',
				to: '2023-07-10',
				mainPc: '<span style="color: #0070BD">NCH 코리아 기술영업 정규직 채용</span>',
				mainMobile: '<span style="color: #0070BD">NCH 코리아</span>',
				subPc: '<span style="color: #3C4144">영업 커리어를 쌓고 싶다면, 지금 지원하세요</span>',
				subMobile: '<span style="color: #3C4144">기술영업 정규직 채용</span>',
				nuxt: true,
				multiple: false,
				button: 'NCH 코리아 입사하기',
				emphasized: false,
				emphasizedTitle: '',
				emphasizedImage: '',
				image: 'https://cdn.comento.kr/images/edu/list/banner/nch-banner-middle.png',
				url: '/career/externship/영업고객상담/기술영업-G2781',
				color: '#0070BD',
				filters: [],
			},
			{
				from: '2023-06-22',
				to: '2023-07-12',
				mainPc: '<span style="color: #1B5192">코멘토 그로스 마케터 채용</span>',
				mainMobile: '<span style="color: #1B5192">그로스 마케터</span>',
				subPc: '<span style="color: #3C4144">실무과제로 역량을 평가받고 취업하세요</span>',
				subMobile: '<span style="color: #3C4144">코멘토 익스턴십 정규직 채용</span>',
				nuxt: true,
				multiple: false,
				button: '코멘토 입사하기',
				emphasized: true,
				emphasizedTitle:
					'<span style="color: #3C4144">Edu-Tech 기업 코멘토의 그로스 마케터로 입사하세요.</span>',
				emphasizedImage: 'https://cdn.comento.kr/images/edu/list/banner/comento-emphasized-pc.png',
				image: 'https://cdn.comento.kr/images/edu/list/banner/comento-banner-middle.png',
				url: '/career/externship/마케팅/마케팅-G2828',
				color: '#1B5192',
				filters: [],
			},
		],
	},
	[MATCHED_AFFILIATE_CAMP_LIST]: [],
	[MATCHED_AFFILIATE_PARTNER]: {
		partner: '',
		contractNo: 0,
	},
});

export const getters = {
	[GET_EDU_LIST_CAMPS]: state => state[EDU_LIST_CAMPS],
	[GET_EDU_LIST_FILTER]: state => state[EDU_LIST_FILTER],
	[GET_KEYWORD]: (_state, getters) => {
		return getters[GET_EDU_LIST_FILTER].keyword;
	},
	[GET_SHOULD_RESET_KEYWORD]: state => {
		return state[SHOULD_RESET_KEYWORD];
	},
	[GET_EDU_RECRUITING_LIST_CAMPS]: state => {
		return state[EDU_RECRUITING_LIST_CAMPS];
	},
	[GET_EDU_LIST_FILTER_PAGE]: state => {
		return state[EDU_LIST_FILTER].page;
	},
	[GET_TOTAL_EDU_CAMPS_COUNT]: state => {
		return state[TOTAL_EDU_CAMPS_COUNT];
	},
	[EDU_LIST_FILTER_TOP_CATEGORIES]: state => {
		return state[EDU_LIST_FILTER_TOP_CATEGORIES];
	},
	[PROMOTION_BANNERS]: state => state[PROMOTION_BANNERS],
	[GET_TOP_BANNER]: state => {
		return state[PROMOTION_BANNERS].top.find(banner =>
			dayjs(getTimeInAsia()).isBetween(
				dayjs(banner.from).startOf('day'),
				dayjs(banner.to).endOf('day'),
				'day',
				'[]',
			),
		);
	},
	[GET_MIDDLE_BANNER]: state => {
		const middleBanners = state[PROMOTION_BANNERS].middle.slice();
		if (isEarlybird()) {
			// 타임어택 날에는 상단 배너를 중단 배너로 변경한다.
			middleBanners.unshift({ ...state[PROMOTION_BANNERS].top[0], filters: [] });
		}

		return state[PROMOTION_BANNERS].middle.filter(banner => {
			return dayjs(getTimeInAsia()).isBetween(
				dayjs(banner.from).startOf('day'),
				dayjs(banner.to).endOf('day'),
				'day',
				'[]',
			);
		});
	},
	[GET_ONLY_BANNER]: (state, getters) => {
		const shouldShowBanners = state[PROMOTION_BANNERS].only.find(banner =>
			dayjs(getTimeInAsia()).isBetween(
				dayjs(banner.from).startOf('day'),
				dayjs(banner.to).endOf('day'),
				'day',
				'[]',
			),
		);

		if (isEarlybird()) {
			if (getters[GET_TOP_BANNER]) {
				return getters[GET_TOP_BANNER];
			}
			return shouldShowBanners;
		}

		return shouldShowBanners;
	},
	[GET_MATCHED_AFFILIATE_CAMP_LIST]: state => {
		return state[MATCHED_AFFILIATE_CAMP_LIST];
	},
	[GET_IS_MATCHED_CAMP_SECTION_APPEAR]: state => {
		return !!state[MATCHED_AFFILIATE_CAMP_LIST].length;
	},
	[GET_MATCHED_AFFILIATE_PARTNER]: state => {
		return state[MATCHED_AFFILIATE_PARTNER];
	},
};
export const mutations = {
	[SET_EDU_LIST_CAMPS]: (state, payload) => {
		state[EDU_LIST_CAMPS] = payload;
	},
	[ADD_EDU_LIST_CAMPS]: (state, payload) => {
		state[EDU_LIST_CAMPS] = [...state[EDU_LIST_CAMPS], ...payload];
	},
	[SET_EDU_LIST_FILTER]: (state, payload) => {
		state[EDU_LIST_FILTER] = { ...state[EDU_LIST_FILTER], ...payload };
	},
	[SET_RESET_KEYWORD]: (state, payload) => {
		state[SHOULD_RESET_KEYWORD] = payload;
	},
	[SET_EDU_RECRUITING_LIST_CAMPS]: (state, payload) => {
		state[EDU_RECRUITING_LIST_CAMPS] = payload;
	},
	[SET_EDU_LIST_FILTER_PAGE]: (state, payload) => {
		state[EDU_LIST_FILTER].page = payload;
	},
	[SET_EDU_LIST_FILTER_KEYWORD]: (state, payload) => {
		state[EDU_LIST_FILTER].keyword = payload;
	},
	[SET_TOTAL_EDU_CAMPS_COUNT]: (state, payload) => {
		state[TOTAL_EDU_CAMPS_COUNT] = payload;
	},
	[SET_EDU_LIST_FILTER_TOP_CATEGORIES]: (state, payload) => {
		state[EDU_LIST_FILTER_TOP_CATEGORIES] = payload;
	},
	[SET_MATCHED_AFFILIATE_CAMP_LIST]: (state, payload) => {
		state[MATCHED_AFFILIATE_CAMP_LIST] = payload;
	},
	[SET_MATCHED_AFFILIATE_PARTNER]: (state, payload) => {
		state[MATCHED_AFFILIATE_PARTNER] = payload;
	},
};
export const actions = {
	[RESET_KEYWORD]({ commit }) {
		try {
			commit(SET_RESET_KEYWORD, true);
			setTimeout(() => commit(SET_RESET_KEYWORD, false), 1000);
		} catch (error) {
			console.error(error);
		}
	},
	async [LOAD_MATCHED_AFFILIATE_CAMP_LIST]({ commit }) {
		const params = {
			page: 1,
			limit: 10,
		};
		try {
			const {
				data: { list, partner, contractNo },
			} = await this.$api.camp.getUnivMatchedEduCamp(params);
			commit(SET_MATCHED_AFFILIATE_PARTNER, { partner, contractNo });
			commit(SET_MATCHED_AFFILIATE_CAMP_LIST, list);
		} catch (error) {
			console.error(error);
		}
	},
};
