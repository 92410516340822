import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'answer';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export { mapGetters as mapGettersOfAnswer, mapMutations as mapMutationsOfAnswer, mapActions as mapActionsOfAnswer };

// states
export const ANSWER_LIST = 'ANSWER_LIST';
export const ANSWER_LIST_TOTAL_COUNT = 'ANSWER_LIST_TOTAL_COUNT';
export const ANSWER_LIST_DONE = 'ANSWER_LIST_DONE';
export const FIRST_ANSWER_LIST = 'FIRST_ANSWER_LIST';
export const ANSWER_LIST_NONE_RELATION_INDEX = 'ANSWER_LIST_NONE_RELATION_INDEX';
export const ANSWER_LIST_INFO = 'ANSWER_LIST_INFO';
export const ANSWER_PAGE = 'ANSWER_PAGE';
export const ANSWER_KEYWORD = 'ANSWER_KEYWORD';
export const ANSWER_FILTER_COUNCIL = 'ANSWER_FILTER_COUNCIL';
export const ANSWER_FILTER_ESSAY = 'ANSWER_FILTER_ESSAY';
export const ANSWER_FILTER_INTERVIEW = 'ANSWER_FILTER_INTERVIEW';
export const IS_FETCHING_ANSWER_LIST = 'IS_FETCHING_ANSWER_LIST';
export const ANSWER_USER_CREDIT = 'ANSWER_USER_CREDIT';
export const ANSWER_USER_MENTEE_COUNT = 'ANSWER_USER_MENTEE_COUNT';
export const ANSWER_USER_MENTORING_COUNT = 'ANSWER_USER_MENTORING_COUNT';
export const ANSWER_USER_RELATION_TAGS = 'ANSWER_USER_RELATION_TAGS';
export const ANSWER_USER_RELATION_TAGS_OPEN = 'ANSWER_USER_RELATION_TAGS_OPEN';
export const ANSWER_USER_RELATION_USER = 'ANSWER_USER_RELATION_USER';
export const ANSWER_MISSION_INFO = 'ANSWER_MISSION_INFO';
export const ANSWER_MENTOR_GRADE_INFO = 'ANSWER_MENTOR_GRADE_INFO';
export const IS_SHOW_MENTOR_AUTH_MODAL = 'IS_SHOW_MENTOR_AUTH_MODAL';
export const IS_SHOW_MENTORING_KEYWORD_MODAL = 'IS_SHOW_MENTORING_KEYWORD_MODAL';
export const IS_ANSWER_REMOVE_MODAL = 'IS_ANSWER_REMOVE_MODAL';
export const ANSWER_REMOVE_INDEX = 'ANSWER_REMOVE_INDEX';
export const ANSWER_REMOVE_CARD_INFO = 'ANSWER_REMOVE_CARD_INFO';
export const IS_ANSWER_TUTORIAL_OVER = 'IS_ANSWER_TUTORIAL_OVER';
export const ANSWER_LIST_CACHE = 'ANSWER_LIST_CACHE';

// getters
export const GET_ANSWER_LIST = 'GET_ANSWER_LIST';
export const GET_ANSWER_LIST_TOTAL_COUNT = 'GET_ANSWER_LIST_TOTAL_COUNT';
export const GET_ANSWER_LIST_DONE = 'GET_ANSWER_LIST_DONE';
export const GET_FIRST_ANSWER_LIST = 'GET_FIRST_ANSWER_LIST';
export const GET_ANSWER_LIST_NONE_RELATION_INDEX = 'GET_ANSWER_LIST_NONE_RELATION_INDEX';
export const GET_ANSWER_LIST_INFO = 'GET_ANSWER_LIST_INFO';
export const GET_ANSWER_PAGE = 'GET_ANSWER_PAGE';
export const GET_ANSWER_KEYWORD = 'GET_ANSWER_KEYWORD';
export const GET_ANSWER_FILTER_COUNCIL = 'GET_ANSWER_FILTER_COUNCIL';
export const GET_ANSWER_FILTER_ESSAY = 'GET_ANSWER_FILTER_ESSAY';
export const GET_ANSWER_FILTER_INTERVIEW = 'GET_ANSWER_FILTER_INTERVIEW';
export const GET_IS_FETCHING_ANSWER_LIST = 'GET_IS_FETCHING_ANSWER_LIST';
export const GET_ANSWER_USER_CREDIT = 'GET_ANSWER_USER_CREDIT';
export const GET_ANSWER_USER_MENTEE_COUNT = 'GET_ANSWER_USER_MENTEE_COUNT';
export const GET_ANSWER_USER_MENTORING_COUNT = 'GET_ANSWER_USER_MENTORING_COUNT';
export const GET_ANSWER_USER_RELATION_TAGS = 'GET_ANSWER_USER_RELATION_TAGS';
export const GET_ANSWER_USER_RELATION_TAGS_OPEN = 'GET_ANSWER_USER_RELATION_TAGS_OPEN';
export const GET_ANSWER_USER_RELATION_USER = 'GET_ANSWER_USER_RELATION_USER';
export const GET_ANSWER_MISSION_INFO = 'GET_ANSWER_MISSION_INFO';
export const GET_ANSWER_MENTOR_GRADE_INFO = 'GET_ANSWER_MENTOR_GRADE_INFO';
export const GET_IS_SHOW_MENTOR_AUTH_MODAL = 'GET_IS_SHOW_MENTOR_AUTH_MODAL';
export const GET_IS_SHOW_MENTORING_KEYWORD_MODAL = 'GET_IS_SHOW_MENTORING_KEYWORD_MODAL';
export const GET_IS_ANSWER_REMOVE_MODAL = 'GET_IS_ANSWER_REMOVE_MODAL';
export const GET_ANSWER_REMOVE_INDEX = 'GET_ANSWER_REMOVE_INDEX';
export const GET_ANSWER_REMOVE_CARD_INFO = 'GET_ANSWER_REMOVE_CARD_INFO';
export const GET_IS_ANSWER_TUTORIAL_OVER = 'GET_IS_ANSWER_TUTORIAL_OVER';
export const GET_ANSWER_LIST_CACHE = 'GET_ANSWER_LIST_CACHE';

// mutations
export const SET_ANSWER_LIST = 'SET_ANSWER_LIST';
export const SET_ANSWER_LIST_TOTAL_COUNT = 'SET_ANSWER_LIST_TOTAL_COUNT';
export const SET_ANSWER_LIST_DONE = 'SET_ANSWER_LIST_DONE';
export const SET_FIRST_ANSWER_LIST = 'SET_FIRST_ANSWER_LIST';
export const SET_ANSWER_LIST_NONE_RELATION_INDEX = 'SET_ANSWER_LIST_NONE_RELATION_INDEX';
export const SET_ANSWER_LIST_INFO = 'SET_ANSWER_LIST_INFO';
export const SET_ANSWER_PAGE = 'SET_ANSWER_PAGE';
export const SET_ANSWER_KEYWORD = 'SET_ANSWER_KEYWORD';
export const SET_ANSWER_FILTER_COUNCIL = 'SET_ANSWER_FILTER_COUNCIL';
export const SET_ANSWER_FILTER_ESSAY = 'SET_ANSWER_FILTER_ESSAY';
export const SET_ANSWER_FILTER_INTERVIEW = 'SET_ANSWER_FILTER_INTERVIEW';
export const SET_IS_FETCHING_ANSWER_LIST = 'SET_IS_FETCHING_ANSWER_LIST';
export const SET_ANSWER_USER_CREDIT = 'SET_ANSWER_USER_CREDIT';
export const SET_ANSWER_USER_MENTEE_COUNT = 'SET_ANSWER_USER_MENTEE_COUNT';
export const SET_ANSWER_USER_MENTORING_COUNT = 'SET_ANSWER_USER_MENTORING_COUNT';
export const SET_ANSWER_USER_RELATION_TAGS = 'SET_ANSWER_USER_RELATION_TAGS';
export const SET_ANSWER_USER_RELATION_TAGS_OPEN = 'SET_ANSWER_USER_RELATION_TAGS_OPEN';
export const SET_ANSWER_USER_RELATION_USER = 'SET_ANSWER_USER_RELATION_USER';
export const SET_ANSWER_MISSION_INFO = 'SET_ANSWER_MISSION_INFO';
export const SET_ANSWER_MENTOR_GRADE_INFO = 'SET_ANSWER_MENTOR_GRADE_INFO';
export const SET_IS_SHOW_MENTOR_AUTH_MODAL = 'SET_IS_SHOW_MENTOR_AUTH_MODAL';
export const SET_IS_SHOW_MENTORING_KEYWORD_MODAL = 'SET_IS_SHOW_MENTORING_KEYWORD_MODAL';
export const SET_IS_ANSWER_REMOVE_MODAL = 'SET_ISET_IS_ANSWER_REMOVE_MODALS_ANSWER_REMOVE_MODAL';
export const SET_ANSWER_REMOVE_INDEX = 'SET_ANSWER_REMOVE_INDEX';
export const SET_ANSWER_REMOVE_CARD_INFO = 'SET_ANSWER_REMOVE_CARD_INFO';
export const SET_IS_ANSWER_TUTORIAL_OVER = 'SET_IS_ANSWER_TUTORIAL_OVER';
export const SET_ANSWER_LIST_CACHE = 'SET_ANSWER_LIST_CACHE';

// actions
export const LOAD_ANSWER_LIST_INFO = 'LOAD_ANSWER_LIST_INFO';
export const LOAD_ANSWER_FIRST_LIST = 'LOAD_ANSWER_FIRST_LIST';
export const LOAD_ANSWER_LIST = 'LOAD_ANSWER_LIST';
export const LOAD_ANSWER_USER_INFO = 'LOAD_ANSWER_USER_INFO';
export const REQUEST_ANSWER_LIST = 'REQUEST_ANSWER_LIST';
export const REQUEST_SEARCH_ANSWER_LIST = 'REQUEST_SEARCH_ANSWER_LIST';
export const state = () => ({
	[ANSWER_LIST]: [],
	[ANSWER_LIST_TOTAL_COUNT]: 0,
	[ANSWER_LIST_DONE]: false,
	[FIRST_ANSWER_LIST]: [],
	[ANSWER_LIST_NONE_RELATION_INDEX]: 0,
	[IS_FETCHING_ANSWER_LIST]: false,
	[ANSWER_LIST_INFO]: {
		page: 1,
		filter_request_council: 1,
		filter_request_essay: 1,
		filter_request_interview: 1,
		keyword: '',
	},
	[ANSWER_PAGE]: 1,
	[ANSWER_KEYWORD]: null,
	[ANSWER_FILTER_COUNCIL]: 1,
	[ANSWER_FILTER_ESSAY]: 1,
	[ANSWER_FILTER_INTERVIEW]: 1,
	[ANSWER_USER_CREDIT]: 0,
	[ANSWER_USER_MENTEE_COUNT]: 0,
	[ANSWER_USER_MENTORING_COUNT]: 0,
	[ANSWER_USER_RELATION_TAGS]: [],
	[ANSWER_USER_RELATION_TAGS_OPEN]: false,
	[ANSWER_USER_RELATION_USER]: {},
	[ANSWER_MISSION_INFO]: null,
	[ANSWER_MENTOR_GRADE_INFO]: {},
	[IS_SHOW_MENTOR_AUTH_MODAL]: false,
	[IS_SHOW_MENTORING_KEYWORD_MODAL]: false,
	[IS_ANSWER_REMOVE_MODAL]: false,
	[ANSWER_REMOVE_INDEX]: -1,
	[ANSWER_REMOVE_CARD_INFO]: null,
	[IS_ANSWER_TUTORIAL_OVER]: false,
	[ANSWER_LIST_CACHE]: true,
});
export const getters = {
	[GET_ANSWER_LIST]: state => state[ANSWER_LIST],
	[GET_ANSWER_LIST_TOTAL_COUNT]: state => state[ANSWER_LIST_TOTAL_COUNT],
	[GET_ANSWER_LIST_DONE]: state => state[ANSWER_LIST_DONE],
	[GET_FIRST_ANSWER_LIST]: state => state[FIRST_ANSWER_LIST],
	[GET_ANSWER_LIST_NONE_RELATION_INDEX]: state => state[ANSWER_LIST_NONE_RELATION_INDEX],
	[GET_IS_FETCHING_ANSWER_LIST]: state => state[IS_FETCHING_ANSWER_LIST],
	[GET_ANSWER_LIST_INFO]: state => state[ANSWER_LIST_INFO],
	[GET_ANSWER_PAGE]: state => state[ANSWER_PAGE],
	[GET_ANSWER_KEYWORD]: state => state[ANSWER_KEYWORD],
	[GET_ANSWER_FILTER_COUNCIL]: state => state[ANSWER_FILTER_COUNCIL],
	[GET_ANSWER_FILTER_ESSAY]: state => state[ANSWER_FILTER_ESSAY],
	[GET_ANSWER_FILTER_INTERVIEW]: state => state[ANSWER_FILTER_INTERVIEW],
	[GET_ANSWER_USER_CREDIT]: state => state[ANSWER_USER_CREDIT],
	[GET_ANSWER_USER_MENTEE_COUNT]: state => state[ANSWER_USER_MENTEE_COUNT],
	[GET_ANSWER_USER_MENTORING_COUNT]: state => state[ANSWER_USER_MENTORING_COUNT],
	[GET_ANSWER_USER_RELATION_TAGS]: state => state[ANSWER_USER_RELATION_TAGS],
	[GET_ANSWER_USER_RELATION_TAGS_OPEN]: state => state[ANSWER_USER_RELATION_TAGS_OPEN],
	[GET_ANSWER_USER_RELATION_USER]: state => state[ANSWER_USER_RELATION_USER],
	[GET_ANSWER_MISSION_INFO]: state => state[ANSWER_MISSION_INFO],
	[GET_ANSWER_MENTOR_GRADE_INFO]: state => state[ANSWER_MENTOR_GRADE_INFO],
	[GET_IS_SHOW_MENTOR_AUTH_MODAL]: state => state[IS_SHOW_MENTOR_AUTH_MODAL],
	[GET_IS_SHOW_MENTORING_KEYWORD_MODAL]: state => state[IS_SHOW_MENTORING_KEYWORD_MODAL],
	[GET_IS_ANSWER_REMOVE_MODAL]: state => state[IS_ANSWER_REMOVE_MODAL],
	[GET_ANSWER_REMOVE_INDEX]: state => state[ANSWER_REMOVE_INDEX],
	[GET_ANSWER_REMOVE_CARD_INFO]: state => state[ANSWER_REMOVE_CARD_INFO],
	[GET_IS_ANSWER_TUTORIAL_OVER]: state => state[IS_ANSWER_TUTORIAL_OVER],
	[GET_ANSWER_LIST_CACHE]: state => state[ANSWER_LIST_CACHE],
};

export const mutations = {
	[SET_ANSWER_LIST]: (state, payload) => {
		state[ANSWER_LIST] = payload;
	},
	[SET_ANSWER_LIST_TOTAL_COUNT]: (state, payload) => {
		state[ANSWER_LIST_TOTAL_COUNT] = payload;
	},
	[SET_ANSWER_LIST_DONE]: (state, payload) => {
		state[ANSWER_LIST_DONE] = payload;
	},
	[SET_FIRST_ANSWER_LIST]: (state, payload) => {
		state[FIRST_ANSWER_LIST] = payload;
	},
	[SET_ANSWER_LIST_NONE_RELATION_INDEX]: (state, payload) => {
		state[ANSWER_LIST_NONE_RELATION_INDEX] = payload;
	},
	[SET_ANSWER_LIST_INFO]: (state, payload) => {
		state[ANSWER_LIST_INFO] = payload;
	},
	[SET_ANSWER_KEYWORD]: (state, payload) => {
		state[ANSWER_KEYWORD] = payload;
	},
	[SET_ANSWER_PAGE]: (state, payload) => {
		state[ANSWER_PAGE] = payload;
	},
	[SET_IS_FETCHING_ANSWER_LIST]: (state, payload) => {
		state[IS_FETCHING_ANSWER_LIST] = payload;
	},
	[SET_ANSWER_FILTER_COUNCIL]: (state, payload) => {
		state[ANSWER_FILTER_COUNCIL] = payload;
	},
	[SET_ANSWER_FILTER_ESSAY]: (state, payload) => {
		state[ANSWER_FILTER_ESSAY] = payload;
	},
	[SET_ANSWER_FILTER_INTERVIEW]: (state, payload) => {
		state[ANSWER_FILTER_INTERVIEW] = payload;
	},
	[SET_ANSWER_USER_CREDIT]: (state, payload) => {
		state[ANSWER_USER_CREDIT] = payload;
	},
	[SET_ANSWER_USER_MENTEE_COUNT]: (state, payload) => {
		state[ANSWER_USER_MENTEE_COUNT] = payload;
	},
	[SET_ANSWER_USER_MENTORING_COUNT]: (state, payload) => {
		state[ANSWER_USER_MENTORING_COUNT] = payload;
	},
	[SET_ANSWER_USER_RELATION_TAGS]: (state, payload) => {
		state[ANSWER_USER_RELATION_TAGS] = payload;
	},
	[SET_ANSWER_USER_RELATION_TAGS_OPEN]: (state, payload) => {
		state[ANSWER_USER_RELATION_TAGS_OPEN] = payload;
	},
	[SET_ANSWER_USER_RELATION_USER]: (state, payload) => {
		state[ANSWER_USER_RELATION_USER] = payload;
	},
	[SET_ANSWER_MISSION_INFO]: (state, payload) => {
		state[ANSWER_MISSION_INFO] = payload;
	},
	[SET_ANSWER_MENTOR_GRADE_INFO]: (state, payload) => {
		state[ANSWER_MENTOR_GRADE_INFO] = payload;
	},
	[SET_IS_SHOW_MENTOR_AUTH_MODAL]: (state, payload) => {
		state[IS_SHOW_MENTOR_AUTH_MODAL] = payload;
	},
	[SET_IS_SHOW_MENTORING_KEYWORD_MODAL]: (state, payload) => {
		state[IS_SHOW_MENTORING_KEYWORD_MODAL] = payload;
	},
	[SET_IS_ANSWER_REMOVE_MODAL]: (state, payload) => {
		state[IS_ANSWER_REMOVE_MODAL] = payload;
	},
	[SET_ANSWER_REMOVE_INDEX]: (state, payload) => {
		state[ANSWER_REMOVE_INDEX] = payload;
	},
	[SET_ANSWER_REMOVE_CARD_INFO]: (state, payload) => {
		state[ANSWER_REMOVE_CARD_INFO] = payload;
	},
	[SET_IS_ANSWER_TUTORIAL_OVER]: (state, payload) => {
		state[IS_ANSWER_TUTORIAL_OVER] = payload;
	},
	[SET_ANSWER_LIST_CACHE]: (state, payload) => {
		state[ANSWER_LIST_CACHE] = payload;
	},
};

export const actions = {
	[LOAD_ANSWER_LIST_INFO]({ getters, commit }) {
		commit(SET_ANSWER_LIST_INFO, {
			page: getters.GET_ANSWER_PAGE,
			filter_request_council: getters.GET_ANSWER_FILTER_COUNCIL,
			filter_request_essay: getters.GET_ANSWER_FILTER_ESSAY,
			filter_request_interview: getters.GET_ANSWER_FILTER_INTERVIEW,
			keyword: getters.GET_ANSWER_KEYWORD,
		});
	},
	async [LOAD_ANSWER_FIRST_LIST]({ getters, commit }) {
		const {
			data: { result },
		} = await this.$api.answerList.getMentoringForFirstTime({
			filter_request_council: getters.GET_ANSWER_FILTER_COUNCIL,
		});

		if (result) {
			commit(SET_FIRST_ANSWER_LIST, result);
			commit(SET_ANSWER_LIST, [...getters.GET_FIRST_ANSWER_LIST, ...getters.GET_ANSWER_LIST]);
		}
	},
	async [LOAD_ANSWER_LIST]({ getters, commit, dispatch, rootGetters }, params = {}) {
		dispatch(LOAD_ANSWER_LIST_INFO);

		if (rootGetters.isUser && getters.GET_ANSWER_FILTER_COUNCIL && getters.GET_ANSWER_PAGE === 1) {
			// GET_ANSWER_FILTER_COUNCIL 값이 true이면 신규질문셋 로직을 돌림
			await dispatch(LOAD_ANSWER_FIRST_LIST);
		}
		try {
			const { data, next_page_url, total } = params.isSearch
				? await dispatch(REQUEST_SEARCH_ANSWER_LIST, params.sort)
				: await dispatch(REQUEST_ANSWER_LIST);
			commit(SET_IS_FETCHING_ANSWER_LIST, true);
			commit(SET_ANSWER_LIST_DONE, !next_page_url);
			commit(SET_ANSWER_LIST_TOTAL_COUNT, total);
			commit(SET_ANSWER_LIST, [...getters.GET_ANSWER_LIST, ...data]);
			commit(SET_ANSWER_PAGE, getters.GET_ANSWER_PAGE + 1);

			// 다음에 부를 데이터가 없는 경우
			if (!next_page_url) {
				return false;
			}

			/*
			 * 관련성 없는 질문 index 구하기
			 * 신규 멘토 질문이 있는경우, 신규질문 길이 만큼 추가
			 * */
			const etcAnswer = element => element.relation_info?.length === 0;
			const etcAnswerIndex = getters.GET_ANSWER_LIST.findIndex(etcAnswer);
			commit(SET_ANSWER_LIST_NONE_RELATION_INDEX, etcAnswerIndex - 1);

			return true;
		} catch (error) {
			console.error('LOAD_ANSWER_LIST error: ', error);
		}
	},
	async [REQUEST_ANSWER_LIST]({ getters }) {
		try {
			const {
				data: { data, total, next_page_url },
			} = await this.$api.answerList.getAnswerList(
				{ ...getters[GET_ANSWER_LIST_INFO] },
				getters[GET_ANSWER_LIST_CACHE],
			);
			return {
				data,
				total,
				next_page_url: Boolean(next_page_url),
			};
		} catch (error) {
			console.error(error);
		}
	},
	async [REQUEST_SEARCH_ANSWER_LIST]({ getters }, sort) {
		try {
			const getAnswerListParams = {
				page: getters[GET_ANSWER_LIST_INFO].page,
				question: getters[GET_ANSWER_LIST_INFO].filter_request_council,
				essay: getters[GET_ANSWER_LIST_INFO].filter_request_essay,
				interview: getters[GET_ANSWER_LIST_INFO].filter_request_interview,
				keyword: getters[GET_ANSWER_LIST_INFO].keyword,
				sort,
			};
			const {
				data: {
					data: { data, total, next_page_url },
				},
			} = await this.$api.answerList.getAnswerSearchList({ ...getAnswerListParams });
			return {
				data,
				total,
				next_page_url,
			};
		} catch (error) {
			console.error(error);
		}
	},
	async [LOAD_ANSWER_USER_INFO]({ commit }) {
		try {
			const {
				data: { credit, mentee_count, mentoring_count, relation_tags, relation_tags_open, relation_user },
			} = await this.$api.answerList.getUserInfo();

			commit(SET_ANSWER_USER_CREDIT, credit.credit);
			commit(SET_ANSWER_USER_MENTEE_COUNT, mentee_count);
			commit(SET_ANSWER_USER_MENTORING_COUNT, mentoring_count);
			commit(SET_ANSWER_USER_RELATION_TAGS, relation_tags);
			commit(SET_ANSWER_USER_RELATION_TAGS_OPEN, relation_tags_open);
			commit(SET_ANSWER_USER_RELATION_USER, relation_user);
		} catch (error) {
			console.error('LOAD_ANSWER_USER_INFO error: ', error);
		}
	},
};
