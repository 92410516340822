
import { MY_PAGE_CREDIT_LIST_PATH } from '@/constants/paths/my-page';

import { mapGettersOfMyPage, GET_TOTAL_CREDIT, GET_EXTINCTION_CREDIT } from '@/store/myPage';

import layoutMixin from '@/mixins/layoutMixin';
import routeMixin from '@/mixins/routeMixin';

import Skeleton from '@/src/components/Other/Skeleton.vue';

export default {
	name: 'MyPageCreditInfoBoard',
	mixins: [layoutMixin, routeMixin],
	props: {
		isCreditShop: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGettersOfMyPage([GET_TOTAL_CREDIT, GET_EXTINCTION_CREDIT]),
		myPageCreditListInfoBoard() {
			return this.IS_MOBILE ? [24, 16, 24, 16] : [0, 0, 32, 0];
		},
		myPageCreditListInfoBoardBoxPadding() {
			return [18, 16, 18, 16];
		},
		titleTypography() {
			return this.IS_MOBILE ? 'body2' : 'headline5';
		},
		totalCreditSkeletonWidth() {
			return this.IS_MOBILE ? '72px' : '130px';
		},
		totalCreditSkeletonHeight() {
			return this.IS_MOBILE ? '27px' : '47px';
		},
		extinctionCreditSkeletonWidth() {
			return this.IS_MOBILE ? '72px' : '40px';
		},
		extinctionCreditSkeletonHeight() {
			return this.IS_MOBILE ? '27px' : '20px';
		},
		priceFontWeight() {
			return this.IS_MOBILE ? 'semi-bold' : 'regular';
		},
	},
	methods: {
		goCredit() {
			if (!this.isCreditShop) {
				return;
			}
			this.$_routeMixin_go_page(MY_PAGE_CREDIT_LIST_PATH);
		},
	},
	components: { Skeleton },
};
