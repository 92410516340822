import get from 'lodash/get';
import { createNamespacedHelpers } from 'vuex';

// TODO : edu directory 아래로 옮길 것
const NAMESPACE = 'class/on-air';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfClassOnAir,
	mapMutations as mapMutationsOfClassOnAir,
	mapActions as mapActionsOfClassOnAir,
};

// states
export const CLASS_ON_AIR_INFO = 'CLASS_ON_AIR_INFO';
export const CLASS_ON_AIR_DETAIL_SELECT_TAB = 'CLASS_ON_AIR_DETAIL_SELECT_TAB';
export const CLASS_ON_AIR_JOB_CLASS_LIST = 'CLASS_ON_AIR_JOB_CLASS_LIST';
export const CLASS_ON_AIR_NORMAL_CLASS_LIST = 'CLASS_ON_AIR_NORMAL_CLASS_LIST';
export const CLASS_ON_AIR_JOB_SHOW_MORE_BUTTON = 'CLASS_ON_AIR_JOB_SHOW_MORE_BUTTON';
export const CLASS_ON_AIR_NORMAL_SHOW_MORE_BUTTON = 'CLASS_ON_AIR_NORMAL_SHOW_MORE_BUTTON';
// getters
export const GET_CLASS_ON_AIR_INFO = 'GET_CLASS_ON_AIR_INFO';
export const GET_CLASS_ON_AIR_TITLE = 'GET_CLASS_ON_AIR_TITLE';
export const GET_CLASS_ON_AIR_TITLE_IMAGE = 'GET_CLASS_ON_AIR_TITLE_IMAGE';
export const GET_CLASS_ON_AIR_TOP_CATEGORY = 'GET_CLASS_ON_AIR_TOP_CATEGORY';
export const GET_CLASS_ON_AIR_MID_CATEGORY = 'GET_CLASS_ON_AIR_MID_CATEGORY';
export const GET_CLASS_ON_AIR_NO = 'GET_CLASS_ON_AIR_NO';
export const GET_CLASS_ON_AIR_PERSON_LIMIT = 'GET_CLASS_ON_AIR_PERSON_LIMIT';
export const GET_CLASS_ON_AIR_PERIOD = 'GET_CLASS_ON_AIR_PERIOD';
export const GET_CLASS_ON_AIR_PRICE = 'GET_CLASS_ON_AIR_PRICE';
export const GET_CLASS_ON_AIR_CONTENT = 'GET_CLASS_ON_AIR_CONTENT';
export const GET_CLASS_ON_AIR_DETAIL_SELECT_TAB = 'GET_CLASS_ON_AIR_DETAIL_SELECT_TAB';
export const GET_CLASS_ON_AIR_JOB_CLASS_LIST = 'GET_CLASS_ON_AIR_JOB_CLASS_LIST';
export const GET_CLASS_ON_AIR_NORMAL_CLASS_LIST = 'GET_CLASS_ON_AIR_NORMAL_CLASS_LIST';
export const GET_CLASS_ON_AIR_JOB_SHOW_MORE_BUTTON = 'GET_CLASS_ON_AIR_JOB_SHOW_MORE_BUTTON';
export const GET_CLASS_ON_AIR_NORMAL_SHOW_MORE_BUTTON = 'GET_CLASS_ON_AIR_NORMAL_SHOW_MORE_BUTTON';

// mutations
export const SET_CLASS_ON_AIR_INFO = 'SET_CLASS_ON_AIR_INFO';
export const SET_CLASS_ON_AIR_DETAIL_SELECT_TAB = 'SET_CLASS_ON_AIR_DETAIL_SELECT_TAB';
export const SET_CLASS_ON_AIR_JOB_CLASS_LIST = 'SET_CLASS_ON_AIR_JOB_CLASS_LIST';
export const SET_CLASS_ON_AIR_NORMAL_CLASS_LIST = 'SET_CLASS_ON_AIR_NORMAL_CLASS_LIST';
export const SET_CLASS_ON_AIR_JOB_SHOW_MORE_BUTTON = 'SET_CLASS_ON_AIR_JOB_SHOW_MORE_BUTTON';
export const SET_CLASS_ON_AIR_NORMAL_SHOW_MORE_BUTTON = 'SET_CLASS_ON_AIR_NORMAL_SHOW_MORE_BUTTON';

// actions
export const FETCH_CLASS_ON_AIR_JOB_CLASS_LIST = 'FETCH_CLASS_ON_AIR_JOB_CLASS_LIST';
export const FETCH_CLASS_ON_AIR_NORMAL_CLASS_LIST = 'FETCH_CLASS_ON_AIR_NORMAL_CLASS_LIST';

export const state = () => ({
	[CLASS_ON_AIR_INFO]: {},
	[CLASS_ON_AIR_DETAIL_SELECT_TAB]: 0,
	[CLASS_ON_AIR_JOB_CLASS_LIST]: null,
	[CLASS_ON_AIR_NORMAL_CLASS_LIST]: null,
	[CLASS_ON_AIR_JOB_SHOW_MORE_BUTTON]: true,
	[CLASS_ON_AIR_NORMAL_SHOW_MORE_BUTTON]: true,
});

export const getters = {
	GET_CLASS_ON_AIR_INFO: state => get(state, [CLASS_ON_AIR_INFO]),
	GET_CLASS_ON_AIR_TITLE_IMAGE: (_state, getters) => {
		const media = get(getters, [GET_CLASS_ON_AIR_INFO, 'media']);
		if (media && media.length !== 0) {
			return `${media[0].media}`;
		} else {
			return null;
		}
	},
	GET_CLASS_ON_AIR_TITLE: (_state, getters) => get(getters, [GET_CLASS_ON_AIR_INFO, 'title'], '클래스 제목'),
	GET_CLASS_ON_AIR_TOP_CATEGORY: (_state, getters) =>
		get(getters, [GET_CLASS_ON_AIR_INFO, 'job_top_category'], '직무 카테고리'),
	GET_CLASS_ON_AIR_MID_CATEGORY: (_state, getters) =>
		get(getters, [GET_CLASS_ON_AIR_INFO, 'job_mid_category'], '직무 카테고리'),
	GET_CLASS_ON_AIR_NO: (_state, getters) => get(getters, [GET_CLASS_ON_AIR_INFO, 'id'], '클래스 번호'),
	GET_CLASS_ON_AIR_PERSON_LIMIT: (_state, getters) => get(getters, [GET_CLASS_ON_AIR_INFO, 'person_limit'], 0),
	GET_CLASS_ON_AIR_PERIOD: (_state, getters) => get(getters, [GET_CLASS_ON_AIR_INFO, 'period'], 1),
	GET_CLASS_ON_AIR_PRICE: (_state, getters) => get(getters, [GET_CLASS_ON_AIR_INFO, 'price'], 0),
	GET_CLASS_ON_AIR_CONTENT: (_state, getters) => {
		const contents = get(getters, [GET_CLASS_ON_AIR_INFO, 'contents']);
		if (contents && contents.length !== 0) {
			return contents[0].content;
		} else {
			return null;
		}
	},
	GET_CLASS_ON_AIR_DETAIL_SELECT_TAB: state => get(state, [CLASS_ON_AIR_DETAIL_SELECT_TAB], 0),
	GET_CLASS_ON_AIR_JOB_CLASS_LIST: state => get(state, [CLASS_ON_AIR_JOB_CLASS_LIST], null),
	GET_CLASS_ON_AIR_NORMAL_CLASS_LIST: state => get(state, [CLASS_ON_AIR_NORMAL_CLASS_LIST], null),
	GET_CLASS_ON_AIR_JOB_SHOW_MORE_BUTTON: state => get(state, [CLASS_ON_AIR_JOB_SHOW_MORE_BUTTON]),
	GET_CLASS_ON_AIR_NORMAL_SHOW_MORE_BUTTON: state => get(state, [CLASS_ON_AIR_NORMAL_SHOW_MORE_BUTTON]),
};

export const mutations = {
	[SET_CLASS_ON_AIR_INFO]: (state, payload) => {
		state[CLASS_ON_AIR_INFO] = payload;
	},
	[SET_CLASS_ON_AIR_DETAIL_SELECT_TAB]: (state, payload) => {
		state[CLASS_ON_AIR_DETAIL_SELECT_TAB] = payload;
	},
	[SET_CLASS_ON_AIR_JOB_CLASS_LIST]: (state, payload) => {
		state[CLASS_ON_AIR_JOB_CLASS_LIST] = payload;
	},
	[SET_CLASS_ON_AIR_NORMAL_CLASS_LIST]: (state, payload) => {
		state[CLASS_ON_AIR_NORMAL_CLASS_LIST] = payload;
	},
	[SET_CLASS_ON_AIR_JOB_SHOW_MORE_BUTTON]: (state, payload) => {
		state[CLASS_ON_AIR_JOB_SHOW_MORE_BUTTON] = payload;
	},
	[SET_CLASS_ON_AIR_NORMAL_SHOW_MORE_BUTTON]: (state, payload) => {
		state[CLASS_ON_AIR_NORMAL_SHOW_MORE_BUTTON] = payload;
	},
};

export const actions = {
	async [FETCH_CLASS_ON_AIR_JOB_CLASS_LIST]({ commit }) {
		try {
			const response = await this.$api.onAir.getOnAirClassList('job');
			commit(SET_CLASS_ON_AIR_JOB_CLASS_LIST, [...response.data]);
		} catch (error) {
			console.error('FETCH_CLASS_ON_AIR_JOB_CLASS_LIST error: ', error);
		}
	},
	async [FETCH_CLASS_ON_AIR_NORMAL_CLASS_LIST]({ commit }) {
		try {
			const response = await this.$api.onAir.getOnAirClassList('normal');
			commit(SET_CLASS_ON_AIR_NORMAL_CLASS_LIST, [...response.data]);
		} catch (error) {
			console.error('FETCH_CLASS_ON_AIR_NORMAL_CLASS_LIST error: ', error);
		}
	},
};
