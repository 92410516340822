import { createNamespacedHelpers } from 'vuex';

export const NAMESPACE = 'search/list';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfSearchList,
	mapMutations as mapMutationsOfSearchList,
	mapActions as mapActionsOfSearchList,
};

const defaultSearchListParams = {
	keyword: '',
	sort: '',
	page: 1,
	limit: 12,
};

const searchList = 'searchList';
const searchListParams = 'searchListParams';
const searchCount = 'searchCount';
const haveSearchListNextPage = 'haveSearchListNextPage';
const communitySubHeader = 'communitySubHeader';
const searchIndex = 'searchIndex';

// getters
export const SEARCH_LIST = 'SEARCH_LIST';
export const SEARCH_COUNT = 'SEARCH_COUNT';
export const IS_EMPTY_SEARCH_LIST = 'IS_EMPTY_SEARCH_LIST';
export const SEARCH_LIST_PARAMS = 'SEARCH_LIST_PARAMS';
export const HAVE_SEARCH_LIST_NEXT_PAGE = 'HAVE_SEARCH_LIST_NEXT_PAGE';
export const COMMUNITY_SUB_HEADER = 'COMMUNITY_SUB_HEADER';
export const IS_SEARCH_INDEX_EMPTY = 'IS_SEARCH_INDEX_EMPTY';
export const IS_SEARCH_INDEX_FETCHING = 'IS_SEARCH_INDEX_FETCHING';
export const IS_SEARCH_INDEX_FETCHED = 'IS_SEARCH_INDEX_FETCHED';
export const SEARCH_KEYWORD = 'SEARCH_KEYWORD';
export const SEARCH_LIST_PAGE = 'SEARCH_LIST_PAGE';
export const SEARCH_LIST_FOR_LECTURE_VOD = 'SEARCH_LIST_FOR_LECTURE_VOD';
export const SEARCH_LIST_FOR_VOD = 'SEARCH_LIST_FOR_VOD';

// mutations
export const SET_SEARCH_COUNT = 'SET_SEARCH_COUNT';
export const SET_SEARCH_LIST = 'SET_SEARCH_LIST';
export const ADD_SEARCH_LIST = 'ADD_SEARCH_LIST';
export const SET_SEARCH_LIST_PARAMS = 'SET_SEARCH_LIST_PARAMS';
export const SET_HAVE_SEARCH_LIST_NEXT_PAGE = 'SET_HAVE_SEARCH_LIST_NEXT_PAGE';
export const SET_COMMUNITY_SUB_HEADER = 'SET_COMMUNITY_SUB_HEADER';
export const SET_SEARCH_INDEX = 'SET_SEARCH_INDEX';

// actions
export const LOAD_SEARCH_LIST_FOR_COMMUNITY = 'LOAD_SEARCH_LIST_FOR_COMMUNITY';
export const LOAD_COMMUNITY_SUB_HEADER = 'LOAD_COMMUNITY_SUB_HEADER';
export const LOAD_SEARCH_LIST_FOR_CLASS_PT = 'LOAD_SEARCH_LIST_FOR_CLASS_PT';
export const LOAD_SEARCH_LIST_FOR_EDU = 'LOAD_SEARCH_LIST_FOR_EDU';
export const LOAD_SEARCH_LIST_FOR_VOD = 'LOAD_SEARCH_LIST_FOR_VOD';
export const RESET_SEARCH_LIST = 'RESET_SEARCH_LIST';
export const START_SEARCH_INDEX_FETCHING = 'START_SEARCH_INDEX_FETCHING';
export const END_SEARCH_INDEX_FETCHING = 'END_SEARCH_INDEX_FETCHING';
export const SAVE_POPULAR_KEYWORD = 'SAVE_POPULAR_KEYWORD';

export const state = () => ({
	[searchList]: null,
	[searchListParams]: defaultSearchListParams,
	[searchCount]: 0,
	[haveSearchListNextPage]: true,
	[communitySubHeader]: null,
	[searchIndex]: {
		empty: [false, false, false, false, false],
		fetching: 0,
	},
});

export const getters = {
	[SEARCH_LIST]: state => state[searchList],
	[SEARCH_LIST_PARAMS]: state => state[searchListParams],
	[SEARCH_COUNT]: state => state[searchCount],
	[IS_EMPTY_SEARCH_LIST]: state => state[searchList] && state[searchCount] === 0,
	[HAVE_SEARCH_LIST_NEXT_PAGE]: state => state[haveSearchListNextPage],
	[COMMUNITY_SUB_HEADER]: state => state[communitySubHeader],
	[IS_SEARCH_INDEX_EMPTY]: state => state[searchIndex].empty.every(isEmpty => isEmpty === true),
	[IS_SEARCH_INDEX_FETCHING]: state => state[searchIndex].fetching > 0,
	[IS_SEARCH_INDEX_FETCHED]: state => state[searchIndex].fetching === 0,
	[SEARCH_KEYWORD]: state => state[searchListParams].keyword,
	[SEARCH_LIST_PAGE]: state => state[searchListParams].page,
	[SEARCH_LIST_FOR_LECTURE_VOD]: state => state[searchList] && state[searchList].filter(item => item.isLecture),
	[SEARCH_LIST_FOR_VOD]: state => state[searchList] && state[searchList].filter(item => !item.isLecture),
};

export const mutations = {
	[SET_SEARCH_LIST]: (state, payload) => {
		state[searchList] = payload;
	},
	[ADD_SEARCH_LIST]: (state, payload) => {
		state[searchList] = [...state[searchList], ...payload];
	},
	[SET_SEARCH_LIST_PARAMS]: (state, payload) => {
		state[searchListParams] = { ...state[searchListParams], ...payload };
	},
	[SET_SEARCH_COUNT]: (state, payload) => {
		state[searchCount] = payload;
	},
	[SET_HAVE_SEARCH_LIST_NEXT_PAGE]: (state, payload) => {
		state[haveSearchListNextPage] = payload;
	},
	[SET_COMMUNITY_SUB_HEADER]: (state, payload) => {
		state[communitySubHeader] = payload;
	},
	[SET_SEARCH_INDEX]: (state, payload) => {
		state[searchIndex] = payload;
	},
};

export const actions = {
	async [LOAD_COMMUNITY_SUB_HEADER]({ commit }) {
		try {
			const { data } = await this.$api.case.getCaseSubHeader();
			commit(SET_COMMUNITY_SUB_HEADER, data);
		} catch (error) {
			console.error(error);
		}
	},
	async [LOAD_SEARCH_LIST_FOR_COMMUNITY]({ getters, commit }) {
		try {
			const {
				data: { data },
			} = await this.$api.searchList.getSearchListForCommunity({
				...getters[SEARCH_LIST_PARAMS],
				category_group_id: getters[SEARCH_LIST_PARAMS].category,
			});
			const total = data.list.total;

			await commit(getters[SEARCH_LIST] ? ADD_SEARCH_LIST : SET_SEARCH_LIST, data.list.data);
			commit(SET_SEARCH_COUNT, total);
			commit(SET_HAVE_SEARCH_LIST_NEXT_PAGE, getters[SEARCH_LIST].length < total);
		} catch (error) {
			console.error(error);
		}
	},
	async [LOAD_SEARCH_LIST_FOR_CLASS_PT]({ getters, commit }) {
		try {
			const {
				data: { data },
			} = await this.$api.searchList.getSearchListForClassPt({
				...getters[SEARCH_LIST_PARAMS],
			});
			const total = data.total;

			await commit(getters[SEARCH_LIST] ? ADD_SEARCH_LIST : SET_SEARCH_LIST, data.list);
			commit(SET_SEARCH_COUNT, total);
			commit(SET_HAVE_SEARCH_LIST_NEXT_PAGE, getters[SEARCH_LIST].length < total);
		} catch (error) {
			console.error(error);
		}
	},
	async [LOAD_SEARCH_LIST_FOR_EDU]({ getters, commit }) {
		try {
			const {
				data: { data },
			} = await this.$api.searchList.getSearchListForEdu({
				...getters[SEARCH_LIST_PARAMS],
			});
			const total = data.total;

			await commit(getters[SEARCH_LIST] ? ADD_SEARCH_LIST : SET_SEARCH_LIST, data.list.data);
			commit(SET_SEARCH_COUNT, total);
			commit(SET_HAVE_SEARCH_LIST_NEXT_PAGE, getters[SEARCH_LIST].length < total);
		} catch (error) {
			console.error(error);
		}
	},
	async [LOAD_SEARCH_LIST_FOR_VOD]({ getters, commit }) {
		try {
			const params = { ...getters[SEARCH_LIST_PARAMS] };
			const fetchVod = () => this.$api.searchList.getSearchListForVod({ ...params, category_group_id: 1 });

			// 직무VOD
			const {
				data: { data },
			} = await fetchVod();
			await commit(getters[SEARCH_LIST] ? ADD_SEARCH_LIST : SET_SEARCH_LIST, data.list.data);
			commit(SET_SEARCH_COUNT, getters[SEARCH_COUNT] + data.total);
			commit(SET_HAVE_SEARCH_LIST_NEXT_PAGE, getters[SEARCH_LIST_FOR_VOD].length < data.total);
		} catch (error) {
			console.error(error);
		}
	},
	[RESET_SEARCH_LIST]({ commit }) {
		commit(SET_SEARCH_LIST_PARAMS, defaultSearchListParams);
		commit(SET_SEARCH_LIST, null);
		commit(SET_SEARCH_COUNT, 0);
		commit(SET_HAVE_SEARCH_LIST_NEXT_PAGE, true);
	},
	[START_SEARCH_INDEX_FETCHING]({ commit, state }, index) {
		const newEmptyArray = [...state[searchIndex].empty];
		newEmptyArray[index] = false;
		commit(SET_SEARCH_INDEX, { empty: newEmptyArray, fetching: state[searchIndex].fetching + 1 });
	},
	[END_SEARCH_INDEX_FETCHING]({ commit, state }, { index, total }) {
		const newEmptyArray = [...state[searchIndex].empty];
		newEmptyArray[index] = total === 0;

		commit(SET_SEARCH_INDEX, {
			empty: newEmptyArray,
			fetching: state[searchIndex].fetching - 1,
		});
	},
	[SAVE_POPULAR_KEYWORD]({ getters }, type) {
		try {
			const params = {
				keyword: getters[SEARCH_LIST_PARAMS].keyword,
				type,
			};
			this.$api.searchList.postPopularKeyword(params);
		} catch (error) {
			console.error(error);
		}
	},
};
