
import { mapGetters } from 'vuex';

import { MY_PAGE_SETTING_PATH } from '@/constants/paths/my-page';

import { cdnImageBaseUrl } from '@/plugins/constants';

import { isEmptyData } from '@/utils/utils';

import { IS_UNIV_AFFILIATE_AVAILABLE, mapGettersOfCase, LOAD_USER_PARTNER_INFO, mapActionsOfCase } from '@/store/case';
import { mapGettersOfMyPage, GET_PASS_INFO, GET_IS_LOADED_PASS_INFO } from '@/store/myPage';
import { GET_USER_META_CAREER, mapGettersOfUserMeta } from '@/store/userMeta';

import ImageUploadWrapper from '@/components/common/atoms/ImageUploadWrapper.vue';
import ProfileAvatar from '@/components/common/atoms/ProfileAvatar.vue';
import SocialIcon from '@/components/common/atoms/SocialIcon.vue';
import MyPageToAffiliateBanner from '@/components/my-page/layout/MyPageToAffiliateBanner.vue';

import { profileImageMixin } from '@/mixins/profileImageMixin';
import routeMixin from '@/mixins/routeMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

import Skeleton from '@/src/components/Other/Skeleton.vue';

export default {
	name: 'MyPageMobileProfile',
	mixins: [userInfoMixin, routeMixin, profileImageMixin],
	props: {
		isChangeTopMargin: {
			type: Boolean,
			default: false,
		},
	},
	async fetch() {
		await this[LOAD_USER_PARTNER_INFO]();
	},
	computed: {
		...mapGetters(['isUser', 'isMentee', 'userInfo', 'userProfileImage']),
		...mapGettersOfMyPage([GET_PASS_INFO, GET_IS_LOADED_PASS_INFO]),
		...mapGettersOfCase([IS_UNIV_AFFILIATE_AVAILABLE]),
		...mapGettersOfUserMeta([GET_USER_META_CAREER]),
		hasProfileImage() {
			return !isEmptyData(this.userProfileImage);
		},
		passUserInfo() {
			return this[GET_PASS_INFO]?.userInfo;
		},
		nickname() {
			return this.passUserInfo?.nickname ? this.passUserInfo.nickname : this.defaultNickname;
		},
		defaultNickname() {
			return `${this.userType === 1 ? '멘티' : '멘토'}${this.userNo}`;
		},
		isUserLoaded() {
			return !this.isUser || this[GET_IS_LOADED_PASS_INFO];
		},
		userSocialType() {
			return this.userInfo?.social_type;
		},
		showMyPageToAffiliateBanner() {
			return this[GET_USER_META_CAREER] === 1 && this.isMentee && this[IS_UNIV_AFFILIATE_AVAILABLE];
		},
		showMentorCreditBanner() {
			return this[GET_USER_META_CAREER] === 2 && this.isMentee;
		},
		creditIllustPath() {
			return `${cdnImageBaseUrl}/illust/illust-credit.svg`;
		},
	},
	methods: {
		...mapActionsOfCase([LOAD_USER_PARTNER_INFO]),
		goProfileDetail() {
			this.$_routeMixin_go_page(MY_PAGE_SETTING_PATH);
		},
	},
	components: {
		MyPageToAffiliateBanner,
		Skeleton,
		SocialIcon,
		ProfileAvatar,
		ImageUploadWrapper,
	},
};
