export const EDU_CAMP_TYPE = {
	B2C_DEFAULT: 'b2c_default',
	B2B_DEFAULT: 'b2b_default',
	FREE_SCHEDULE: 'free_schedule',
	EDU_PATH: 'edu_path',
	EDU_PATH_2: 'path',
	EXTERNSHIP: 'externship',
	VIRTUAL_COMPANY: 'virtualCompany',
	JUMP_UP: 'jump-up',
};

export const EDU_CAMP_NAME = {
	EDU: '직무부트캠프',
	PT: '실무PT',
	VOD: 'VOD',
	VOD_LECTURE: '실무 VOD',
	PATH: '진로부트캠프',
	EXTERNSHIP: '익스턴십',
	VIRTUAL_COMPANY: '가상회사',
	JUMP_UP: '재학생 맞춤 고용서비스',
};
