import { EDU_ALUMNI_PATH } from '@/constants/paths';

import { SET_IS_APPLICANT_COMPLETED, SET_SHOW_ALUMNI_MODAL } from '@/store/alumni';

export default function ({ $api, app, store }) {
	app.router.beforeResolve(async (to, _, next) => {
		await alumniAuthCheck(to, $api, store.commit, next);
	});
}

export async function alumniAuthCheck(to, $api, commit, next, redirect) {
	// 하나라도 포함된다면
	const restrictedPaths = [`${EDU_ALUMNI_PATH}/view`, `${EDU_ALUMNI_PATH}/write`];
	const toAlumni = restrictedPaths.some(path => to.path.includes(path));
	if (toAlumni) {
		const response = await $api.afterClass.getAlumniMyInfo();
		if (response.data.user.is_applicant_completed) {
			commit(`alumni/${SET_IS_APPLICANT_COMPLETED}`, true);
			return next ? next() : null;
		} else {
			commit(`alumni/${SET_IS_APPLICANT_COMPLETED}`, false);
			commit(`alumni/${SET_SHOW_ALUMNI_MODAL}`, true);
			return redirectToPath(next, redirect, EDU_ALUMNI_PATH);
		}
	} else {
		return next ? next() : null;
	}
}

function redirectToPath(next, redirect, path) {
	return next ? next(path) : redirect(path);
}
