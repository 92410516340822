import Vue from 'vue';
import VueInfiniteLoading from 'vue-infinite-loading';

import LoadingSpinner from '@/components/common/atoms/LoadingSpinner.vue';

const config = {
	props: {
		spinner: 'waveDots',
	},
	slots: {
		spinner: LoadingSpinner,
		noResults: '',
		noMore: '',
	},
};
Vue.use(VueInfiniteLoading, config);
