export const ALUMNI_PREFIX_URL = `/api/edu/alumni`;

export default axios => ({
	/**
	 * 수료생 공간 포스트 카테고리 가져오기
	 **/
	getAfterClassPostCategories() {
		return axios.get(`${ALUMNI_PREFIX_URL}/header/list`);
	},
	/**
	 * 수료생 공간 포스트 가져오기
	 * @param page
	 * @param limit
	 * @param header_id
	 **/
	getAfterClassPosts(params) {
		return axios.get(`${ALUMNI_PREFIX_URL}`, { params });
	},
	/**
	 * 수료생 공간 포스트 작성하기
	 **/
	createAfterClassPost(data) {
		return axios.post(`${ALUMNI_PREFIX_URL}/write`, data);
	},
	/**
	 * 수료생 공간 포스트 수정하기
	 **/
	updateAfterClassPost(data, postId) {
		return axios.post(`${ALUMNI_PREFIX_URL}/update/${postId}`, data);
	},
	/**
	 * 수료생 공간 포스트 삭제하기
	 **/
	removeAfterClassPost(postId) {
		return axios.delete(`${ALUMNI_PREFIX_URL}/update/${postId}`);
	},
	/**
	 * 수료생 공간 포스트 detail 가져오기
	 **/
	getAfterClassDetail(postId) {
		return axios.get(`${ALUMNI_PREFIX_URL}/view/${postId}`);
	},
	/**
	 * 수료생 공간 포스트 조회수 증가
	 **/
	increaseAfterClassViewCount(postId) {
		return axios.post(`${ALUMNI_PREFIX_URL}/view/${postId}/count`);
	},
	/**
	 * 수료생 공간 포스트 댓글 작성
	 **/
	createPostComment(data, postId) {
		return axios.post(`${ALUMNI_PREFIX_URL}/comment/${postId}`, data);
	},
	/**
	 * 수료생 공간 포스트 댓글 수정
	 * @data comment_id
	 * @data comment
	 **/
	updatePostComment(data, postId) {
		return axios.patch(`${ALUMNI_PREFIX_URL}/comment/${postId}`, data);
	},
	/**
	 * 수료생 공간 포스트 댓글 삭제
	 * @data comment_id
	 **/
	removePostComment(data, postId) {
		return axios.delete(`${ALUMNI_PREFIX_URL}/comment/${postId}`, { data });
	},
	/**
	 * 수료생 공간 포스트 대댓글 작성
	 **/
	createPostCoComment(data, postId, commentId) {
		return axios.post(`${ALUMNI_PREFIX_URL}/comment/${postId}/${commentId}`, data);
	},
	/**
	 * 수료생 공간 포스트 대댓글 수정
	 * @data cocomment_id
	 * @data comment
	 **/
	updatePostCoComment(data, postId, commentId) {
		return axios.patch(`${ALUMNI_PREFIX_URL}/comment/${postId}/${commentId}`, data);
	},
	/**
	 * 수료생 공간 포스트 대댓글 삭제
	 **/
	removePostCoComment(data, postId, commentId) {
		return axios.delete(`${ALUMNI_PREFIX_URL}/comment/${postId}/${commentId}`, { data });
	},
	/**
	 * 수료생 공간 포스트 좋아요 토글
	 * 좋아요 했으면 취소, 안했으면 좋아요
	 **/
	togglePostLike(postId) {
		return axios.post(`${ALUMNI_PREFIX_URL}/view/${postId}/like`);
	},
	/**
	 * 수료생 공간 포스트 좋아요 했는지 여부
	 **/
	getIsPostLike(postId) {
		return axios.get(`${ALUMNI_PREFIX_URL}/view/${postId}/like`);
	},
	/**
	 * 수료여부
	 **/
	getAlumniMyInfo() {
		return axios.get(`${ALUMNI_PREFIX_URL}/my_info`);
	},
	/**
	 * 글 신고
	 * @data message
	 **/
	reportAlumniCommunityPost(data, postId) {
		return axios.post(`${ALUMNI_PREFIX_URL}/report/article/${postId}`, data);
	},
	/**
	 * 댓글 or 대댓글 신고
	 * 둘다 같은 table에 id로 접근가능해서 한번에 처리가능
	 * @data message
	 **/
	reportAlumniCommunityComment(data, commentId) {
		return axios.post(`${ALUMNI_PREFIX_URL}/report/comment/${commentId}`, data);
	},
});
