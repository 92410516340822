import { LOUNGE_PATH } from '@/constants/paths';
import { SEARCH_PATH } from '@/constants/paths/search';
import { ROUTE_NAMES } from '@/constants/route-name';
import { SEARCH_PRODUCT_TYPES, SEARCH_SORT_TYPES } from '@/constants/search/search-products';

import { encodeKeyword } from '@/utils/utils';

import { mapActionsOfCase, RESET_CASE_LIST } from '@/store/case';
import { GET_SHOULD_RESET_KEYWORD, mapGettersOfEduList } from '@/store/edu/list';

import gtmSearchMixin from '@/mixins/gtm/gtmSearchMixin';
import layoutMixin from '@/mixins/layoutMixin';
import localStorageIdMixin from '@/mixins/localStorageIdMixin';
import navigationMixin from '@/mixins/navigationMixin';
import routeMixin from '@/mixins/routeMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

const searchMixin = {
	mixins: [routeMixin, userInfoMixin, localStorageIdMixin, layoutMixin, navigationMixin, gtmSearchMixin],
	data: () => ({
		baseUrl: process.env.baseUrl,
		searchKeyword: '',
		popularKeywordList: [],
	}),
	watch: {
		[GET_SHOULD_RESET_KEYWORD](newVal) {
			if (newVal) {
				this.searchKeyword = '';
			}
		},
	},
	computed: {
		...mapGettersOfEduList([GET_SHOULD_RESET_KEYWORD]),
		keyword() {
			const { params, query, name } = this.$route;
			if (name === ROUTE_NAMES.SEARCH_LIST) {
				return params.keyword || params.product;
			}
			return query.query || params.keyword;
		},
	},
	methods: {
		...mapActionsOfCase([RESET_CASE_LIST]),
		async $_getPopularKeywordList() {
			return await this.$axios
				.get('/api/log/popular')
				.then(({ data }) => {
					const popularKeywordList = data;
					return popularKeywordList.map(obj => {
						return {
							keyword: obj.keyword,
						};
					});
				})
				.catch(error => {
					console.error(error);
					return [];
				});
		},
		async $_getPopularCampKeywordList() {
			try {
				const { data } = await this.$axios.get('/api/edu/keywords');
				return data.keyword.map(keyword => ({ keyword }));
			} catch (error) {
				console.error(error);
				return [];
			}
		},
		/**
		 * 검색 관련 이벤트들
		 * @param keyword
		 * @param searchType: typing, popular, recent
		 */
		async $_triggerSearch(keyword, searchType) {
			this.searchKeyword = keyword;
			this.$_handleGTM(keyword, searchType);
			this.$_handleDongjakSearch(keyword);
			await this.$_setSearchLog(keyword, searchType);
		},
		$_handleGTM(keyword, searchType) {
			// GTM - searchContent
			this.$_gtmSearchMixin_searchContent({
				search_keyword: keyword,
				type: searchType,
			});
		},
		/**
		 * 동작구청 입력시, 동작구청 신청페이지로 이동하기
		 * @param keyword
		 */
		$_handleDongjakSearch(keyword) {
			const dongjakKeywords = ['동작구청', '동작구', 'ehdwkrrncjd', 'ehdwkrrn'];
			if (dongjakKeywords.includes(keyword)) {
				window.open(`/edu/univ/dongjak`);
			}
		},
		/**
		 * 검색어 로그 쌓기
		 * @param keyword
		 * @param searchType
		 */
		async $_setSearchLog(keyword, searchType) {
			const data = {
				keyword: decodeURIComponent(keyword),
				search_type: searchType,
			};
			await this.$axios.post('/api/log/search', data).then().catch();
		},
		$_getSearchParams(product) {
			const getQueryParams = product => {
				if (this.$_isAnswer || product === SEARCH_PRODUCT_TYPES.ANSWER) {
					return {
						where: SEARCH_PRODUCT_TYPES.INDEX,
						sort: SEARCH_SORT_TYPES.RELEVANCE,
						category: 0,
					};
				}
				if (this.$_isCommunityMenu || product === SEARCH_PRODUCT_TYPES.COMMUNITY) {
					return {
						where: SEARCH_PRODUCT_TYPES.COMMUNITY,
						sort: SEARCH_SORT_TYPES.RELEVANCE,
						category: 0,
					};
				}
				if (
					this.$_isEduMenu ||
					this.$_isPathMenu ||
					this.$_isClassHome ||
					product === SEARCH_PRODUCT_TYPES.EDU
				) {
					// 진로캠, 클래스홈도 통함검색 직부캠으로 보여주기
					return {
						where: SEARCH_PRODUCT_TYPES.EDU,
						sort: SEARCH_SORT_TYPES.RELEVANCE,
					};
				}
				if (this.$_isVodMenu || product === SEARCH_PRODUCT_TYPES.VOD) {
					return {
						where: SEARCH_PRODUCT_TYPES.VOD,
						sort: SEARCH_SORT_TYPES.RELEVANCE,
					};
				}
				if (this.$_isPt || product === SEARCH_PRODUCT_TYPES.CLASS_PT) {
					return {
						where: SEARCH_PRODUCT_TYPES.CLASS_PT,
						sort: SEARCH_SORT_TYPES.RELEVANCE,
						origin: this.$route.query.origin,
					};
				}
				return {};
			};
			const { query, name, path } = this.$route;
			const isNotSearch = name !== ROUTE_NAMES.SEARCH_LIST;
			const isAppendFrom = this.IS_MOBILE && isNotSearch;
			const searchParams = {};

			if (isAppendFrom) {
				searchParams.from = path;
			} else if (Object.prototype.hasOwnProperty.call(query, 'from')) {
				searchParams.from = query.from;
			}

			if (isNotSearch && Object.prototype.hasOwnProperty.call(getQueryParams(product), 'where')) {
				searchParams.where = getQueryParams(product).where;
			} else if (Object.prototype.hasOwnProperty.call(getQueryParams(query), 'where')) {
				searchParams.where = query.where;
			}

			if (Object.prototype.hasOwnProperty.call(getQueryParams(product), 'sort')) {
				searchParams.sort = getQueryParams(product)?.sort;
			} else {
				delete searchParams.sort;
			}

			if (Object.prototype.hasOwnProperty.call(getQueryParams(product), 'category')) {
				searchParams.category = getQueryParams(product)?.category;
			} else if (Object.prototype.hasOwnProperty.call(query, 'category')) {
				delete searchParams.category;
			}

			if (Object.prototype.hasOwnProperty.call(getQueryParams(product), 'origin')) {
				searchParams.origin = getQueryParams(product)?.origin;
			} else if (Object.prototype.hasOwnProperty.call(query, 'origin')) {
				delete searchParams.origin;
			}

			return searchParams;
		},
		/**
		 * 통합검색 페이지 path + queryParams
		 * @param encodedKeyword
		 * @param product: index, answer...
		 * @returns {string}
		 */
		$_getSearchUrl(encodedKeyword, product) {
			return (
				`${SEARCH_PATH}/${product}/${encodedKeyword}?` +
				new URLSearchParams(this.$_getSearchParams(product)).toString()
			);
		},
		/**
		 * 검색 페이지로 보내기(예외: 자유게시판)
		 * 모바일 검색일 때는 from 붙이기
		 * @param keyword
		 * @returns {Promise<Route>|void}
		 */
		$_goSearchPage(keyword) {
			const encodedKeyword = encodeKeyword(keyword);
			const { name, params } = this.$route;
			if (name.startsWith(ROUTE_NAMES.COMMUNITY_LOUNGE)) {
				// 자유게시판
				return this.$router.push(`${LOUNGE_PATH}?query=${encodedKeyword}`);
			}

			const product = () => {
				/**
				 * 답변하기, 채용은 바로 해당 검색페이지로 보내기
				 * 재검색은 해당 검색페이지로 보내기
				 */
				const isAnswer =
					name.startsWith(ROUTE_NAMES.ANSWER) ||
					(name === ROUTE_NAMES.SEARCH_LIST && params.product === SEARCH_PRODUCT_TYPES.ANSWER);
				const isCommunitySearch =
					name === ROUTE_NAMES.SEARCH_LIST && params.product === SEARCH_PRODUCT_TYPES.COMMUNITY;
				const isEduSearch = name === ROUTE_NAMES.SEARCH_LIST && params.product === SEARCH_PRODUCT_TYPES.EDU;
				const isPtSearch = name === ROUTE_NAMES.SEARCH_LIST && params.product === SEARCH_PRODUCT_TYPES.CLASS_PT;
				const isVodSearch = name === ROUTE_NAMES.SEARCH_LIST && params.product === SEARCH_PRODUCT_TYPES.VOD;
				if (isAnswer) {
					return SEARCH_PRODUCT_TYPES.ANSWER;
				}
				if (isCommunitySearch) {
					return SEARCH_PRODUCT_TYPES.COMMUNITY;
				}
				if (isEduSearch) {
					return SEARCH_PRODUCT_TYPES.EDU;
				}
				if (isPtSearch) {
					return SEARCH_PRODUCT_TYPES.CLASS_PT;
				}
				if (isVodSearch) {
					return SEARCH_PRODUCT_TYPES.VOD;
				}
				return SEARCH_PRODUCT_TYPES.INDEX;
			};

			const searchProduct = product();
			let searchUrl = this.$_getSearchUrl(encodedKeyword, searchProduct);
			if (this.$_isPt) {
				searchUrl = `/search/pt/${encodedKeyword}?sort=relevance&origin=pt`;
			}

			// 답변하기는 아직 Nuxt라 router.push
			if (searchProduct === SEARCH_PRODUCT_TYPES.ANSWER) {
				this.$router.push(searchUrl);
				return;
			}

			// 나머지 검색은 Next라 window.location.href
			window.location.href = searchUrl;
		},
		/**
		 * 검색어 연동
		 */
		$_setInitialSearchKeyword() {
			if (this.keyword) {
				this.searchKeyword = this.keyword;
			}
		},
	},
};

export default searchMixin;
