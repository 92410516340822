export const CLASS_TYPE = {
	EDU: '직무부트캠프',
	PT: '실무PT',
	VOD: 'VOD',
	VOD_LECTURE: '실무 VOD',
	PATH: '진로부트캠프',
	EXTERNSHIP: '익스턴십',
	VIRTUAL_COMPANY: '가상회사',
};

export const OPENED_CLASS_PROGRESS = {
	ALL: '전체',
	RECRUITING: '모집 중',
	WILL_PROCEED: '진행 예정',
	PROCEEDING: '진행 중',
	FINISHED: '종료',
};

export const OPENED_CLASS_COLUMNS = [
	'상태',
	'카테고리',
	'시작일시',
	'클래스명',
	'인원(명)',
	'클래스룸',
	'후기 및 과제',
];

export const CREATE_CLASS_PROGRESS = {
	ALL: '전체',
	PROGRESS: '진행중',
	COMPLETE: '완료',
};

export const APPLIED_CLASS_PROGRESS = {
	WILL_PROCEED: '수강 예정',
	PROCEEDING: '수강 중',
	FINISHED: '수강 완료',
	FINISHED_VOD: '종료',
};
export const MENTORING_TYPE = {
	INTERVIEW: 'in',
	ENGLISH: 'en',
	ESSAY: 'ce',
	QUESTION: 'qa',
};

export const MY_PAGE_MENUS = {
	HOME: '홈',
	COMMUNITY: '커뮤니티 활동',
	CREATE_CLASS: '클래스 만들기',
	THANKS: '합격한 멘티 보기',
	CREDIT_CASH: '크레딧 관리',
	CLASSROOM_OPENED: '개설한 클래스',
	CLASSROOM_APPLIED: '구매한 클래스',
	CLASSROOM: '내 클래스룸',
	PURCHASE_HISTORY: '구매 내역',
	SETTING: '개인 정보 설정',
	CS: '고객센터',
	NOTICE: '공지사항',
	HISTORY: '이력서 관리',
	PROFILE: '프로필',
};

export const EXTERNSHIP_STATUS = {
	APPLY: '서류 미제출',
	SUBMITTED: '서류 제출',
	FAILED: '서류 불합격',
	READY: '참여 예정',
	ONGOING: '참여 중',
	COMPLETE: '참여 완료',
	FINISHED: '종료',
};
