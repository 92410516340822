
import layoutMixin from '@/mixins/layoutMixin';

export default {
	name: 'NotiReadAllButton',
	mixins: [layoutMixin],
	methods: {
		emitReadAll() {
			this.$emit('read-all');
		},
	},
};
