
export default {
	name: 'FloatingActionButton',
	props: {
		iconName: {
			type: String,
			default: 'IconWriting2XLargeLine',
		},
	},
};
