import get from 'lodash/get';
import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'vod/player/lecture';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfVodPlayerLecture,
	mapMutations as mapMutationsOfVodPlayerLecture,
	mapActions as mapActionsOfVodPlayerLecture,
};

export const SIDE_SECTION = Object.freeze({
	CHAPTER_LIST: '목차',
	COMMUNITY: 'Q&A',
	LESSON_NOTE: '학습노트',
	FILES: '자료실',
	ASSIGNMENT: '과제',
	DASHBOARD: '학습현황',
});

export const SIDE_MODAL_TYPE = Object.freeze({
	PERMISSION: 'permission',
	ALERT: 'alert',
});

export const PURCHASE_MODAL_TEXT = Object.freeze({
	title: {
		VOD_PLAYER: '끝까지 보시겠어요?',
		DEFAULT: 'VOD 수강하기',
	},
	content: {
		VOD_PLAYER: '이 강의는 수강신청 이후에 학습할 수 있습니다.\n강의를 신청하고 수업을 완주해보세요!',
		COMMUNITY: '강의에서 궁금한 점을 결제 후 멘토님에게 직접 질문해보세요!',
		NOTE: '학습노트는 수강 신청 이후에 이용할 수 있어요!',
		FILE: '자료실은 수강 신청 이후에 이용할 수 있어요!',
	},
});

export const NO_ACCESS_MODAL_STATE_TYPES = Object.freeze({
	HIDE: 'hide',
	ASSIGNMENT_NOT_COMPLETED: 'assignment_not_completed',
	LECTURE_NOT_VIEWED: 'lecture_not_viewed',
	CONCURRENT_ACCESS_DETECTED: 'concurrent_access_detected',
	DAILY_LIMIT_REACHED: 'daily_limit_reached',
	AUTO_LOGOUT_INACTIVITY: 'auto_logout_inactivity',
});

export const NO_ACCESS_MODAL_TEXT = Object.freeze({
	[NO_ACCESS_MODAL_STATE_TYPES.ASSIGNMENT_NOT_COMPLETED]: {
		title: '깜짝 미션 등장!',
		content: `<div>과제 탭에서 <strong>프로젝트과제</strong>를 제출해주세요.</div><div>과제 제출 후, Pass를 받아야만 다음 강의를 수강할 수 있어요.</div>`,
	},
	[NO_ACCESS_MODAL_STATE_TYPES.LECTURE_NOT_VIEWED]: {
		title: '진도 제한 안내',
		content: `<div>강의 넘겨뛰기는 불가해요.</div><div><strong>이전 영상을</strong> 시청해주세요!</div>`,
	},
	[NO_ACCESS_MODAL_STATE_TYPES.CONCURRENT_ACCESS_DETECTED]: {
		title: '동시 접속 안내',
		content: `<div>동일 아이디로 다른 기기에서 이용 중입니다.</div><div>보안을 위해 로그아웃 되오니 최근 로그인한 기기에서 이용해주세요.</div>`,
	},
	[NO_ACCESS_MODAL_STATE_TYPES.DAILY_LIMIT_REACHED]: {
		title: '진도 제한 안내',
		content: '강의는 하루에 최대 16개까지만 들을 수 있어요! 내일 다시 이어서 수강해주세요.',
	},
	[NO_ACCESS_MODAL_STATE_TYPES.AUTO_LOGOUT_INACTIVITY]: {
		title: '자동 로그아웃 안내',
		content: `<div>일정 시간 동안 활동이 없어 자동으로 로그아웃되었습니다.</div><div>확인을 누르시면 로그인 페이지로 이동합니다.</div>`,
	},
});

// getters
export const CURRENT_SIDE_SECTION = 'CURRENT_SIDE_SECTION';

export const CURRENT_LESSON_ID = 'CURRENT_LESSON_ID';
export const CURRENT_LESSON = 'CURRENT_LESSON';
export const CURRENT_LESSON_PLAYTIME = 'CURRENT_LESSON_PLAYTIME';
export const CURRENT_LESSON_DURATION = 'CURRENT_LESSON_DURATION';
export const CURRENT_LESSON_TIME = 'CURRENT_LESSON_TIME';
export const CURRENT_LESSON_SRC = 'CURRENT_LESSON_SRC';

export const LECTURE_TITLE = 'LECTURE_TITLE';
export const LECTURE_DETAIL_URL = 'LECTURE_DETAIL_URL';
export const LECTURE_CURRICULUMS = 'LECTURE_CURRICULUMS';
export const LECTURE_LESSONS = 'LECTURE_LESSONS';
export const GET_LESSON = 'GET_LESSON';

export const SIDE_SECTION_HEIGHT = 'SIDE_SECTION_HEIGHT';
export const CURRENT_SCROLL_TOP = 'CURRENT_SCROLL_TOP';

export const IS_SIDE_LOADING = 'IS_SIDE_LOADING';
export const SIDE_MODAL = 'SIDE_MODAL';
export const PURCHASE_MODAL = 'PURCHASE_MODAL';

export const IS_USER_PAID = 'IS_USER_PAID';
export const IS_KDC_USER = 'IS_KDC_USER';
export const IS_KDC_LECTURE = 'IS_KDC_LECTURE';

export const ASSIGNMENT_SUBMIT_COUNT = 'ASSIGNMENT_SUBMIT_COUNT';
export const VOD_SUBMITTED_FILE_LIST = 'VOD_SUBMITTED_FILE_LIST';
export const VOD_ASSIGNMENT_FILE_LIST = 'VOD_ASSIGNMENT_FILE_LIST';
export const SHOW_ATTACH_FAIL_MODAL = 'SHOW_ATTACH_FAIL_MODAL';
export const ATTACH_FAIL_MODAL_DATA = 'ATTACH_FAIL_MODAL_DATA';
export const VOD_ASSIGNMENT_LIST = 'VOD_ASSIGNMENT_LIST';
export const SHOW_ASSIGNMENT_FILE_DELETE_MODAL = 'SHOW_ASSIGNMENT_FILE_DELETE_MODAL';
export const ASSIGNMENT_FILE_TO_DELETE = 'ASSIGNMENT_FILE_TO_DELETE';
export const NO_ACCESS_MODAL_STATE = 'NO_ACCESS_MODAL_STATE';

export const VOD_COMPLETION = 'VOD_COMPLETION';

// mutations
export const SET_CURRENT_SIDE_SECTION = 'SET_CURRENT_SIDE_SECTION';

export const SET_CURRENT_LESSON_ID = 'SET_CURRENT_LESSON_ID';
export const SET_CURRENT_LESSON = 'SET_CURRENT_LESSON';
export const SET_CURRENT_LESSON_PLAYTIME = 'SET_CURRENT_LESSON_PLAYTIME';
export const SET_CURRENT_LESSON_DURATION = 'SET_CURRENT_LESSON_DURATION';
export const SET_CURRENT_LESSON_TIME = 'SET_CURRENT_LESSON_TIME';
export const SET_CURRENT_LESSON_SRC = 'SET_CURRENT_LESSON_SRC';

export const SET_LECTURE_TITLE = 'SET_LECTURE_TITLE';
export const SET_LECTURE_DETAIL_URL = 'SET_LECTURE_DETAIL_URL';
export const SET_LECTURE_CURRICULUMS = 'SET_LECTURE_CURRICULUMS';
export const SET_LECTURE_LESSON = 'SET_LECTURE_LESSON';

export const SET_BUTTON_VISIBLE = 'SET_BUTTON_VISIBLE';
export const SET_SIDE_SECTION_HEIGHT = 'SET_SIDE_SECTION_HEIGHT';
export const SET_CURRENT_SCROLL_TOP = 'SET_CURRENT_SCROLL_TOP';

export const SET_SIDE_LOADING = 'SET_SIDE_LOADING';
export const SET_SIDE_MODAL = 'SET_SIDE_MODAL';
export const SET_PURCHASE_MODAL = 'SET_PURCHASE_MODAL';

export const SET_IS_USER_PAID = 'SET_IS_USER_PAID';
export const SET_IS_KDC_USER = 'SET_IS_KDC_USER';
export const SET_IS_KDC_LECTURE = 'SET_IS_KDC_LECTURE';

export const SET_ASSIGNMENT_SUBMIT_COUNT = 'SET_ASSIGNMENT_SUBMIT_COUNT';
export const SET_VOD_SUBMITTED_FILE_LIST = 'SET_VOD_SUBMITTED_FILE_LIST';
export const SET_VOD_ASSIGNMENT_FILE_LIST = 'SET_VOD_ASSIGNMENT_FILE_LIST';
export const SET_SHOW_ATTACH_FAIL_MODAL = 'SET_SHOW_ATTACH_FAIL_MODAL';
export const SET_ATTACH_FAIL_MODAL_DATA = 'SET_ATTACH_FAIL_MODAL_DATA';
export const SET_VOD_ASSIGNMENT_LIST = 'SET_VOD_ASSIGNMENT_LIST';
export const SET_SHOW_ASSIGNMENT_FILE_DELETE_MODAL = 'SET_SHOW_ASSIGNMENT_FILE_DELETE_MODAL';
export const SET_ASSIGNMENT_FILE_TO_DELETE = 'SET_ASSIGNMENT_FILE_TO_DELETE';
export const SET_NO_ACCESS_MODAL_STATE = 'SET_NO_ACCESS_MODAL_STATE';

export const SET_VOD_COMPLETION = 'SET_VOD_COMPLETION';

// actions
export const UPDATE_LECTURE_LESSON = 'UPDATE_LECTURE_LESSON';
export const TOGGLE_SIDE_MODAL = 'TOGGLE_SIDE_MODAL';
export const REMOVE_ATTACHMENT_FILE = 'REMOVE_ATTACHMENT_FILE';
export const SUBMIT_ATTACHED_FILE = 'SUBMIT_ATTACHED_FILE';
export const FETCH_KDC_ASSIGNMENT = 'FETCH_KDC_ASSIGNMENT';
export const FETCH_VOD_ASSIGNMENT_LIST = 'FETCH_VOD_ASSIGNMENT_LIST';
export const DELETE_ASSIGNMENT = 'DELETE_ASSIGNMENT';
export const FETCH_VOD_LECTURE_LIST = 'FETCH_VOD_LECTURE_LIST';

export const state = () => ({
	[CURRENT_SIDE_SECTION]: SIDE_SECTION.CHAPTER_LIST,
	[CURRENT_LESSON]: {
		id: -1,
		src: '',
		title: '',
		order: '0-0',
		totalPlayTime: 0,
		duration: Infinity,
		time: 0,
		complete: false,
	},
	[CURRENT_LESSON_ID]: -1,
	[LECTURE_TITLE]: '',
	[LECTURE_DETAIL_URL]: '',
	[LECTURE_CURRICULUMS]: [],
	[SIDE_SECTION_HEIGHT]: 0,
	[IS_SIDE_LOADING]: false,
	[CURRENT_SCROLL_TOP]: 0,
	[SIDE_MODAL]: {
		show: false,
		type: SIDE_MODAL_TYPE.PERMISSION,
		title: '',
		content: '',
		confirm: null,
		cancel: null,
	},
	[IS_USER_PAID]: false,
	[IS_KDC_USER]: false,
	[PURCHASE_MODAL]: {
		show: false,
		title: 'VOD 수강하기',
		content: '',
	},
	[ASSIGNMENT_SUBMIT_COUNT]: 0,
	[VOD_SUBMITTED_FILE_LIST]: [],
	[VOD_ASSIGNMENT_FILE_LIST]: [],
	[SHOW_ATTACH_FAIL_MODAL]: false,
	[ATTACH_FAIL_MODAL_DATA]: '',
	[IS_KDC_LECTURE]: false,
	[VOD_ASSIGNMENT_LIST]: [],
	[SHOW_ASSIGNMENT_FILE_DELETE_MODAL]: false,
	[ASSIGNMENT_FILE_TO_DELETE]: null,
	[NO_ACCESS_MODAL_STATE]: 'hide', // hide | assignment_not_completed | lecture_not_viewed
	[VOD_COMPLETION]: 0,
});

export const getters = {
	[CURRENT_SIDE_SECTION]: state => state[CURRENT_SIDE_SECTION],
	[CURRENT_LESSON]: state => state[CURRENT_LESSON],
	[CURRENT_LESSON_ID]: state => state[CURRENT_LESSON_ID],
	[CURRENT_LESSON_PLAYTIME]: state => state[CURRENT_LESSON].totalPlayTime,
	[CURRENT_LESSON_DURATION]: state => state[CURRENT_LESSON].duration,
	[CURRENT_LESSON_SRC]: state => state[CURRENT_LESSON].src,
	[CURRENT_LESSON_TIME]: state => state[CURRENT_LESSON].time,
	[LECTURE_TITLE]: state => state[LECTURE_TITLE],
	[LECTURE_DETAIL_URL]: state => state[LECTURE_DETAIL_URL],
	[LECTURE_CURRICULUMS]: state => state[LECTURE_CURRICULUMS],
	[LECTURE_LESSONS]: state => {
		return state[LECTURE_CURRICULUMS].reduce((list, curriculum) => {
			list.push(...curriculum.process);
			return list;
		}, []);
	},
	[SIDE_SECTION_HEIGHT]: state => state[SIDE_SECTION_HEIGHT],
	[IS_SIDE_LOADING]: state => state[IS_SIDE_LOADING],
	[CURRENT_SCROLL_TOP]: state => state[CURRENT_SCROLL_TOP],
	[SIDE_MODAL]: state => state[SIDE_MODAL],
	[IS_USER_PAID]: state => state[IS_USER_PAID],
	[IS_KDC_USER]: state => state[IS_KDC_USER],
	[GET_LESSON]: (_, getters) => id => getters[LECTURE_LESSONS].find(lesson => lesson.id === id),
	[PURCHASE_MODAL]: state => state[PURCHASE_MODAL],
	[IS_KDC_LECTURE]: state => state[IS_KDC_LECTURE],
	[ASSIGNMENT_SUBMIT_COUNT]: state => state[ASSIGNMENT_SUBMIT_COUNT],
	[VOD_SUBMITTED_FILE_LIST]: state => state[VOD_SUBMITTED_FILE_LIST],
	[VOD_ASSIGNMENT_FILE_LIST]: state => state[VOD_ASSIGNMENT_FILE_LIST],
	[SHOW_ATTACH_FAIL_MODAL]: state => state[SHOW_ATTACH_FAIL_MODAL],
	[ATTACH_FAIL_MODAL_DATA]: state => state[ATTACH_FAIL_MODAL_DATA],
	[VOD_ASSIGNMENT_LIST]: state => state[VOD_ASSIGNMENT_LIST],
	[SHOW_ASSIGNMENT_FILE_DELETE_MODAL]: state => state[SHOW_ASSIGNMENT_FILE_DELETE_MODAL],
	[ASSIGNMENT_FILE_TO_DELETE]: state => state[ASSIGNMENT_FILE_TO_DELETE],
	[NO_ACCESS_MODAL_STATE]: state => state[NO_ACCESS_MODAL_STATE],
	[VOD_COMPLETION]: state => state[VOD_COMPLETION],
};

export const mutations = {
	[SET_CURRENT_SIDE_SECTION]: (state, payload) => (state[CURRENT_SIDE_SECTION] = payload),
	[SET_CURRENT_LESSON]: (state, payload) => (state[CURRENT_LESSON] = { ...state[CURRENT_LESSON], ...payload }),
	[SET_CURRENT_LESSON_ID]: (state, payload) => (state[CURRENT_LESSON_ID] = payload),
	[SET_CURRENT_LESSON_PLAYTIME]: (state, payload) => (state[CURRENT_LESSON].totalPlayTime = payload),
	[SET_CURRENT_LESSON_DURATION]: (state, payload) => (state[CURRENT_LESSON].duration = payload),
	[SET_CURRENT_LESSON_SRC]: (state, payload) => (state[CURRENT_LESSON].src = payload),
	[SET_CURRENT_LESSON_TIME]: (state, payload) => (state[CURRENT_LESSON].time = payload),
	[SET_LECTURE_TITLE]: (state, payload) => (state[LECTURE_TITLE] = payload),
	[SET_LECTURE_DETAIL_URL]: (state, payload) => (state[LECTURE_DETAIL_URL] = payload),
	[SET_LECTURE_CURRICULUMS]: (state, payload) => (state[LECTURE_CURRICULUMS] = payload),
	[SET_LECTURE_LESSON]: (_, { lessonToUpdate, lesson }) => Object.assign(lessonToUpdate, lesson),
	[SET_SIDE_SECTION_HEIGHT]: (state, payload) => (state[SIDE_SECTION_HEIGHT] = payload),
	[SET_SIDE_LOADING]: (state, payload) => (state[IS_SIDE_LOADING] = payload),
	[SET_CURRENT_SCROLL_TOP]: (state, payload) => (state[CURRENT_SCROLL_TOP] = payload),
	[SET_SIDE_MODAL]: (state, payload) => (state[SIDE_MODAL] = { ...state[SIDE_MODAL], ...payload }),
	[SET_IS_USER_PAID]: (state, payload) => (state[IS_USER_PAID] = payload),
	[SET_IS_KDC_USER]: (state, payload) => (state[IS_KDC_USER] = payload),
	[SET_PURCHASE_MODAL]: (state, payload) => (state[PURCHASE_MODAL] = { ...state[PURCHASE_MODAL], ...payload }),
	[SET_IS_KDC_LECTURE]: (state, payload) => (state[IS_KDC_LECTURE] = payload),
	[SET_ASSIGNMENT_SUBMIT_COUNT]: (state, payload) => (state[ASSIGNMENT_SUBMIT_COUNT] = payload),
	[SET_VOD_SUBMITTED_FILE_LIST]: (state, payload) => (state[VOD_SUBMITTED_FILE_LIST] = payload),
	[SET_VOD_ASSIGNMENT_FILE_LIST]: (state, payload) => (state[VOD_ASSIGNMENT_FILE_LIST] = payload),
	[SET_SHOW_ATTACH_FAIL_MODAL]: (state, payload) => (state[SHOW_ATTACH_FAIL_MODAL] = payload),
	[SET_ATTACH_FAIL_MODAL_DATA]: (state, payload) => (state[ATTACH_FAIL_MODAL_DATA] = payload),
	[SET_VOD_ASSIGNMENT_LIST]: (state, payload) => (state[VOD_ASSIGNMENT_LIST] = payload),
	[SET_SHOW_ASSIGNMENT_FILE_DELETE_MODAL]: (state, payload) => (state[SHOW_ASSIGNMENT_FILE_DELETE_MODAL] = payload),
	[SET_ASSIGNMENT_FILE_TO_DELETE]: (state, payload) => (state[ASSIGNMENT_FILE_TO_DELETE] = payload),
	[SET_NO_ACCESS_MODAL_STATE]: (state, payload) => {
		if (!payload) {
			payload = NO_ACCESS_MODAL_STATE_TYPES.HIDE;
		}

		state[NO_ACCESS_MODAL_STATE] = payload;
	},
	[SET_VOD_COMPLETION]: (state, payload) => (state[VOD_COMPLETION] = payload),
};

export const actions = {
	[UPDATE_LECTURE_LESSON]({ commit, getters }, { id, lesson }) {
		try {
			const lessonToUpdate = getters[LECTURE_LESSONS].find(lesson => lesson.id === id);
			commit(SET_LECTURE_LESSON, { lessonToUpdate, lesson });
		} catch (error) {
			console.error(error);
		}
	},
	[TOGGLE_SIDE_MODAL]({ commit, state }) {
		commit(SET_SIDE_MODAL, { show: !state[SIDE_MODAL].show });
	},
	async [FETCH_KDC_ASSIGNMENT]({ getters, commit }) {
		try {
			const lectureId = get(getters[CURRENT_LESSON], 'lectureId', 0);
			const {
				data: { lists, count },
			} = await this.$api.vodLecture.getKdcAssignmentList({
				lectureId,
			});
			commit(SET_VOD_SUBMITTED_FILE_LIST, lists ?? []);
			commit(SET_ASSIGNMENT_SUBMIT_COUNT, count);
		} catch (error) {
			console.error('FETCH_KDC_ASSIGNMENT error: ', error);
		} finally {
			commit(SET_SIDE_LOADING, false);
		}
	},
	[REMOVE_ATTACHMENT_FILE]: ({ getters, commit }, no) => {
		const fileList = getters[VOD_ASSIGNMENT_FILE_LIST];
		const removedFileList = fileList.filter((_, index) => no !== index);
		commit(SET_VOD_ASSIGNMENT_FILE_LIST, removedFileList);
	},
	async [SUBMIT_ATTACHED_FILE]({ getters }, { applicantId, file, assignmentId }) {
		const lectureId = get(getters[CURRENT_LESSON], 'lectureId', 0);
		const formData = new FormData();
		formData.append('lectureId', lectureId);
		formData.append('applicantId', applicantId);
		formData.append('file', file);
		formData.append('lessonId', assignmentId);

		const response = await this.$api.vodLecture.postSubmitAttachedFile(formData);
		return response.status;
	},
	async [FETCH_VOD_ASSIGNMENT_LIST]({ commit }, lectureId) {
		const { data } = await this.$api.vodLecture.getVodAssignments(lectureId);
		commit(SET_VOD_ASSIGNMENT_LIST, data);
	},
	[DELETE_ASSIGNMENT]({ getters, commit }, assignmentId) {
		commit(
			SET_VOD_SUBMITTED_FILE_LIST,
			getters[VOD_SUBMITTED_FILE_LIST].filter(assignment => assignment.id !== assignmentId),
		);
		this.$api.vodLecture.deleteAssignment(assignmentId);
	},
	async [FETCH_VOD_LECTURE_LIST](_, lectureId) {
		const {
			data: { lectureTitle, curriculums, vodCompletion },
		} = await this.$api.vodLecture.getLectureList({ lectureId });

		for (const [i, curriculum] of curriculums.entries()) {
			for (const [j, lecture] of curriculum.process.entries()) {
				lecture.order = `${i + 1}-${j + 1}`;
			}
		}

		return { lectureTitle, curriculums, vodCompletion };
	},
};
