
import { IS_MOBILE, SET_IS_MOBILE, mapGettersOfLayout, mapMutationsOfLayout } from '@/store/layout';
import {
	ALERT_SHOW_MODAL,
	ALERT_BUTTON_COLOR,
	ALERT_SUCCESS_MESSAGE,
	ALERT_PERSISTENT,
	ALERT_CONTENT,
	ALERT_CLOSE_MODAL,
	mapGettersOfAlertModal,
	mapMutationsOfAlertModal,
} from '@/store/modal/alert';
import {
	VOD_ID,
	LAYOUT_OVERFLOW,
	SET_LAYOUT_OVERFLOW,
	mapGettersOfVodPlayer,
	mapMutationsOfVodPlayer,
} from '@/store/vod/player';

import VodHeader from '@/components/vod/player/atoms/VodHeader.vue';

import gtmTriggerMixin from '@/mixins/gtmTriggerMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

export default {
	name: 'VodLayout',
	mixins: [gtmTriggerMixin, userInfoMixin],
	data() {
		return {
			isFirstLoad: true,
		};
	},
	computed: {
		...mapGettersOfLayout([IS_MOBILE]),
		...mapGettersOfVodPlayer([VOD_ID, LAYOUT_OVERFLOW]),
		...mapGettersOfAlertModal([
			ALERT_SHOW_MODAL,
			ALERT_BUTTON_COLOR,
			ALERT_SUCCESS_MESSAGE,
			ALERT_PERSISTENT,
			ALERT_CONTENT,
		]),
		computedStyle() {
			return {
				'--layout-overflow': this[LAYOUT_OVERFLOW],
			};
		},
	},
	watch: {
		[VOD_ID]: {
			handler(newValue) {
				this.loadLMS(newValue);
			},
			immediate: true,
		},
	},
	mounted() {
		this.handleResize();
		window.addEventListener('resize', this.handleResize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
		document.documentElement.style.overflowY = '';
	},
	methods: {
		...mapMutationsOfLayout([SET_IS_MOBILE]),
		...mapMutationsOfVodPlayer([SET_LAYOUT_OVERFLOW]),
		...mapMutationsOfAlertModal([ALERT_CLOSE_MODAL]),
		handleResize() {
			this[SET_IS_MOBILE](window.innerWidth <= 992);
			document.documentElement.style.overflowY = 'hidden';
			const vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		},
		loadLMS(id) {
			this.$_loadLMS({
				...this.$_userInfoForGtm,
				group_id: id,
			});
		},
	},
	head: {
		meta: [{ name: 'viewport', content: 'width=device-width, height=device-height, initial-scale=1' }],
	},
	components: {
		VodHeader,
	},
};
