export default axios => ({
	/**
	 * params
	 * camp_no
	 **/
	getCampDetail(params) {
		return axios.get('/api/edu/camp/detail', { params });
	},
	/**
	 * params
	 * camp_no
	 **/
	getCampHolidayDelayed(params) {
		return axios.get('/api/edu/holiday', { params });
	},
	/**
	 * params
	 * group_no
	 **/
	getCampBasicInfo(params) {
		return axios.get('/api/edu/detail/basicInfo', { params });
	},
	/**
	 * params
	 * group_no
	 **/
	getEduDetailRecruitingInfo(params) {
		return axios.get('/api/edu-recruiting/detail/info', { params });
	},
	/**
	 * params
	 * camp_no
	 **/
	getEduApplyRecruitingInfo(params) {
		return axios.get('/api/edu-recruiting/apply/info', { params });
	},
	/**
	 * params
	 * group_no
	 **/
	getCampScheduleInfo(params) {
		return axios.get('/api/edu/detail/preInfo', { params });
	},
	/**
	 * params
	 * group_no
	 **/
	getFirstCampNo(params) {
		return axios.get('/api/edu/detail/firstCamp', { params });
	},
	/**
	 * params
	 * group_no : 캠프의 group number
	 **/
	getRequestList(params) {
		return axios.get('/api/edu/request/list', { params });
	},
	/**
	 * params
	 * group_no : 캠프의 group number
	 **/
	getCampGroupInfo(params) {
		return axios.get('/api/edu/detail/preInfo', { params });
	},
	/**
	 * 파트너 신청여부 판별
	 * data
	 * camp_no : 캠프번호
	 **/
	getCheckApplyForPartner(data) {
		return axios.post('/api/edu/biz/checkApply', data);
	},
	/**
	 * 신청전 체크
	 * params
	 * camp_no : 캠프번호
	 **/
	getCheckBeforeApply(params) {
		return axios.get('/api/edu/biz/beforeApply', { params });
	},
	/**
	 * 직부캠 결제에 필요한 토큰 발급
	 * @data campNo
	 * @data applicantNo
	 **/
	createEduPayToken(data) {
		return axios.post('/api/edu/pay/token', data);
	},
	/**
	 * 지환님의 피땀눈물
	 * 추천 캠프 불러오기
	 * limit을 줘야 running이 넘어옴
	 **/
	getRecommendedCampList(params) {
		return axios.get(`/api/edu/personalize`, { params });
	},
	/**
	 * camp view log
	 * @data behavior: 처음 로드된 캠프인지, 클릭한 캠프인지 (initial | click)
	 * @data camp_no
	 **/
	postCampViewLog(data) {
		return axios.post('/api/edu/camp/log', data);
	},
	/**
	 * outbound 정보 전송
	 * @data group_no: 그룹넘버
	 * @data title: 캠프제목
	 * @data mobile: 휴대폰번호
	 **/
	postEduOutbound(data) {
		return axios.post('/api/edu/outbound', data);
	},
	/**
	 * dominant color 불러오기
	 * @params images: img 주소 array (cdn 주소 포함)
	 **/
	getDominantColor(params) {
		return axios.get('/api/edu/dominant', { params });
	},
	/**
	 * dominant color 한번에 불러오기
	 * @params title_img: 캠프 이미지
	 **/
	getDominantColors(data) {
		return axios.post('/api/edu/dominants', data);
	},
	/**
	 * 리뷰 리스트
	 * @params group_no: 그룹넘버
	 * @params page: 현재 페이지
	 * @params per_page: 페이지당 아이템 개수
	 * @params top_category: 대분류
	 **/
	getEduSurvey(params) {
		return axios.get('/api/edu/survey', { params });
	},
	/**
	 * 리뷰 별점
	 * @params group_no: 그룹넘버
	 * @params top_category: 대분류
	 **/
	getEduSurveyScore(params) {
		return axios.get('/api/edu/survey/score', { params });
	},
	/**
	 * utm variation 파라미터에 따른 eduLanding main_text, sub_text 불러오기
	 * @params utmVariation: 기본값 default
	 **/
	getEduUtmVariation({ variation }) {
		return axios.get(`/api/edu/utm/variation/${encodeURI(variation)}`);
	},
	/**
	 * 직부캠 총 수강생
	 **/
	getEduMenteeCount() {
		return axios.get('/api/edu/current_count');
	},
	/**
	 * 직부캠 대분류
	 * 목록 가져오기
	 **/
	getEduTopCategory() {
		return axios.get('/api/edu/survey/topCategory');
	},
	/**
	 * 직부캠 블로그 후기 목록 가져오기
	 * @apiParam {String} [mid_category] 캠프 소분류(option) - 안주면 all
	 * @apiParam {Number} page 페이지 번호
	 * @apiParam {Number} per_page 페이지 당 갯수
	 **/
	getEduEarnedReviews(params) {
		return axios.get('/api/edu/survey/earnedReview', { params });
	},
	/**
	 * b2b 모집중인 캠프수 가져오기
	 * @apiParam {String} [partner_id] 파트너 넘버
	 **/
	getB2BCampCount(params) {
		return axios.get('/api/b2b/edu/camp/count', { params });
	},
	/**
	 * 캠프 그룹 검색
	 * @apiParam {String} [keyword] 검색어
	 * @apiParam {Number} [page] 페이지
	 * @apiParam {Number} [limit] 개수
	 * @apiParam {String} [partner] partnerUrl (ex. comento)
	 **/
	searchCampGroups(params) {
		return axios.get('/api/edu/search', { params });
	},
	/**
	 * 직부캠 얼리버드 리스트
	 **/
	getEarlybirdList() {
		return axios.get('/api/edu/list/earlybird');
	},
	/**
	 * 캠프 검색
	 * @apiParam {String} [keyword] 검색어
	 * @apiParam {Number} [page] 페이지
	 * @apiParam {Number} [limit] 개수
	 * @apiParam {String} [partner] partnerUrl (ex. comento)
	 * @apiParam {String} [type] eduCamp의 type (ex. promotion_2022_1h)
	 **/
	searchCamps(params) {
		return axios.get('/api/edu/campSearch', { params });
	},
	/**
	 * 추천캠프 불러오기
	 **/
	getRecommendCamp() {
		return axios.get('/api/edu/recommend/camp');
	},
	/**
	 * 수료생 공간 vod 목록 가져오기
	 **/
	getEduAfterClassVodList(params) {
		return axios.get('/api/edu/after-class/list', { params });
	},
	/**
	 * 직부캠 구매설문
	 * @apiParam {String} token (설문 고유 토큰 required)
	 * @apiParam {Array} purchase_reason (required)
	 * @apiParam {Array} purchase_route (required)
	 * @apiParam {String} career_type (required)
	 * @apiParam {String} expect_graduate (required)
	 * @apiParam {String} career_period (required)
	 * @apiParam {String} age (optional)
	 * @apiParam {String} gender (optional)
	 * @apiParam {String} location (optional)
	 **/
	putEduSurveyPurchase(data) {
		return axios.put('/api/edu/survey/purchase', data);
	},
	/**
	 * b2b 직부캠 결제 여부 확인
	 * @apiParam {Number} camp_no
	 * status 200
	 * 비회원 data.code === 401
	 * 결제 안했으면 data.code === 404
	 * 결제 했으면 data.code === 200
	 * status 204
	 * 결제는 했는데 이력서 등록안함 data.code === 204
	 * 이력서 등록했으면 files 넘겨받음
	 **/
	checkBizPaid(data) {
		return axios.post('/api/edu/biz/externship/check-paid', data);
	},
	/* 직부캠 광고 가져오기 */
	getEduCharm(productType) {
		return axios.post('/api/banner/case/list/mentor-charm', productType);
	},
	/* updateProfile 마지막에 나오는 직부캠 리스트 */
	getEduCampRandomList() {
		return axios.get(`/api/edu/camp/list/random`);
	},
	/**
	 * 취업연계형 edu list가져오기
	 **/
	getJobAssignedEduList(params) {
		return axios.get('/api/edu/job_assigned', { params });
	},
	/**
	 * 인기 캠프 가져오기
	 **/
	getPopularCamps() {
		return axios.get('/api/edu/popularList');
	},
	/**
	 * 캠프 북마크 수 가져오기
	 * @apiParam {eduCampGroupNo} 캠프 그룹 번호
	 **/
	getBookmarkCount(data) {
		return axios.post('/api/edu/bookmark/count', data);
	},
	/**
	 * 캠프 북마크 토글
	 * @apiParam {eduCampGroupNo} 캠프 그룹 번호
	 **/
	toggleBookmark(data) {
		return axios.post('/api/user/bookmark', data);
	},
	/**
	 * 유저가 한 북마크 리스트
	 **/
	getUserBookmarkList() {
		return axios.get('/api/user/bookmark/list');
	},
	/**
	 * 유저가 북마크 한 캠프 리스트
	 **/
	getUserBookmarkCampList(params) {
		return axios.get('/api/user/bookmark/camp', { params });
	},
	/**
	 * 유저가 북마크 한 캠프 리스트
	 **/
	getUserBookmarkCount() {
		return axios.get('/api/user/bookmark/count');
	},
	/**
	 * 자율일정캠프만 조회
	 * @apiParam {String} [keyword] 검색어
	 * @apiParam {Number} [page] 페이지
	 * @apiParam {Number} [limit] 개수
	 * @apiParam {String} [partner] partnerUrl (ex. comento)
	 */
	getFreeScheduleCamps(params) {
		return axios.get('/api/edu/free-schedule/search', { params });
	},

	/**
	 * 스파르타 코딩캠프 이벤트에 해당하는 캠프를 조회
	 * @apiParam {String} mid_category 분류
	 */
	getSpartaEventCamps(mid_category) {
		return axios.get('/api/edu/sparta/search', {
			params: {
				mid_category,
			},
		});
	},

	/**
	 * 해당 캠프가 스파르타 코딩캠프 이벤트 대상 캠프인지 확인
	 * @apiParam {Number} group_no 캠프 그룹 번호
	 */
	checkCampIsSparta(group_no) {
		return axios.get('/api/edu/sparta/is-exist', {
			params: {
				group_no,
			},
		});
	},

	/**
	 * VED7 쿠폰 발급
	 */
	issueVed7Coupon() {
		return axios.post('/api/coupon/ved7');
	},

	/**
	 * 토큰으로 캠프 정보 조회
	 * @param {String} token
	 */
	getCampByToken(token) {
		return axios.get('/api/edu/camp/kakao/info', { params: { token } });
	},

	/**
	 * postKakaoLink(data)
	 *
	 * @param {String} token
	 * @param {String} url
	 * @param {String} pw
	 */
	postKakaoLink(token, url, pw) {
		return axios.post('/api/edu/camp/kakao/set', { token, url, pw });
	},

	/**
	 * 캠프에 맞춰 블로그 후기 가져오기
	 * data
	 * group_no : 그룹넘버
	 */
	postCampBlogReview(data) {
		return axios.post('/api/edu/camp/blog', data);
	},
	/**
	 * 캠프를 수강한 멘티의 회사 노출시키기
	 * group_no : 그룹넘버
	 */
	getMenteeCompany(group_no) {
		return axios.get('/api/edu/detail/menteeCompany', {
			params: {
				group_no,
			},
		});
	},
	/**
	 * 유저가 직부캠 기 수료생인지 판단
	 */
	getEduUserCompletion() {
		return axios.get('/api/edu/user/completion');
	},
	/**
	 * 유저가 B2B, B2C 직부캠 구매를 했는지 확인
	 * pay - B2C 구매 여부
	 * partner_pay - B2B 구매 여부
	 * completion - 수료여부
	 *
	 */
	getEduPayInformation() {
		return axios.get('/api/edu/user/information');
	},
	/**
	 *  진로부트캠프 권한 체크
	 */
	getEduPathAuth() {
		return axios.get('/api/edu/path/check-auth');
	},
	/**
	 *  cart_id
	 */
	checkEduPathStudentInfo(data) {
		return axios.post('/api/edu/path/check-student', data);
	},
	/**
	 *  진로부트캠프 쿠폰 등록시 멘티 정보 입력
	 *  name, mobile, email
	 */
	addEduPathStudentInfo(data) {
		return axios.post('/api/edu/path/student', data);
	},
	/**
	 *  진로부트캠프 담아둔 전체 목록 확인
	 */
	getEduPathCart() {
		return axios.get('/api/edu/path/cart');
	},
	/**
	 *  진로부트캠프 담기 기능
	 *  camp_no
	 */
	addEduPathCart(data) {
		return axios.post('/api/edu/path/cart', data);
	},
	/**
	 *  진로부트캠프 담은 캠프 신청하기
	 *  cart_id
	 */
	postApplyEduPath(data) {
		return axios.post('/api/edu/path/add', data);
	},
	/**
	 *  유저가 진로부트캠프 신청했는지 여부 판단
	 */
	getIsEduPathApply() {
		return axios.get('/api/edu/path/is-apply');
	},
	/**
	 * 진로캠프 리스트 호출
	 * limit:20(default)
	 * page:1
	 */
	getEduPathCampList(params) {
		return axios.get('/api/edu/path', { params });
	},
	/**
	 * 진로캠프 선택한 캠프 정보 가져오기
	 * camp_no
	 * contract_no
	 */
	getEduPathCampInfo(params) {
		return axios.get('/api/edu/path/info', { params });
	},
	/**
	 * 진로캠프 직무 확신도 체크여부 확인
	 * camp_no
	 */
	getCheckJobConfidence(params) {
		return axios.get('/api/edu/path/check-job-confidence', { params });
	},
	/**
	 * 진로캠프 직무 확신도 체크하기 (캠프마다 진행) 2024.11.07 계약 이전로 사용
	 * camp_no
	 * code
	 * value
	 */
	handleCheckJobConfidence(data) {
		return axios.post('/api/edu/path/check-job-confidence', data);
	},
	/**
	 * 진로캠프 직무 확신도 체크하기 (캠프마다 진행) 2024.11.07 계약 이후로 사용
	 * camp_no
	 * code
	 * value
	 * reason
	 */
	setCheckJobConfidence(data) {
		return axios.post('/api/edu/path/update-job-confidence', data);
	},
	/**
	 * 진로 확신도 체크여부 확인
	 * contract_no
	 */
	getCheckCareerConfidence(params) {
		return axios.get('/api/edu/path/check-career-confidence', { params });
	},
	/**
	 * 진로부트캠프 과제 만족도 조사했는지 여부
	 * token
	 */
	checkIsEduPathAssignmentSurveyDone(params) {
		return axios.get('/api/edu/path/survey', { params });
	},
	/**
	 * 진로부트캠프 과제 만족도 조사 제출
	 * token
	 */
	submitEduPathAssignmentSurvey(data) {
		return axios.post('/api/edu/path/survey', data);
	},
	/**
	 * 진로 확신도 체크하기(진로캠프 수료 후 진행)
	 * contract_no
	 * code
	 * value
	 * job
	 */
	handelCheckCareerConfidence(data) {
		return axios.post('/api/edu/path/check-career-confidence', data);
	},
	/**
	 * 진로 확신도 체크하기(진로캠프 과제 신청시)
	 * camp_no
	 * project - 주요 업무내용
	 * ability - 역량내용
	 */
	handelSetJobConfidence(data) {
		return axios.post('/api/edu/path/set-job-confidence', data);
	},
	/**
	 * 현재 로그인 중인 사용자의 univ와 대응하는 캠프 리스트를 호출
	 * @param {object} params
	 * @param {number} params.limit:10
	 * @param {number} params.page:1
	 */
	getUnivMatchedEduCamp(params) {
		return axios.get('/api/edu/list/affiliate', { params });
	},
	/**
	 * 직부캠 일정 변경 요청 페이지 Load
	 * @param campNo
	 */
	getEduScheduleRequests(campNo) {
		return axios.get(`/api/edu/schedule-request/${campNo}`);
	},
	/**
	 * 직부캠 일정 변경 요청
	 * @param {number} campNo
	 * @param {object} data
	 */
	postEduScheduleRequest(campNo, data) {
		return axios.post(`/api/edu/schedule-request/${campNo}`, data);
	},
	/**
	 * 근태 신청서 관련 정보보기
	 * @param {number} edu_camp_no
	 * @param {number} week
	 */
	getHrReportInfo(params) {
		return axios.get(`/api/edu/hr-report`, { params });
	},
	/**
	 * 멘티 근태 신청서 요청
	 * @param {number} campNo
	 * @param {object} data
	 * @param {number} status 0=결근, 1=출근, 2=지각
	 * @param {String} hr_reason
	 *
	 */
	postMenteeHrReport(data) {
		return axios.post(`/api/edu/hr-report`, data);
	},
	/**
	 * 근태 신청 내역 확인
	 * @param {number} edu_camp_no
	 * @param {number} week
	 */
	getHrReportCheck(params) {
		return axios.get(`/api/edu/hr-report/check`, { params });
	},
});
