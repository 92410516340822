// plugins/filters/formatNumber.js

import Vue from 'vue';

Vue.filter('formatNumber', (value = '') => {
	value = '' + value;

	// 소수점은 제외
	if (value.includes('.')) {
		value = value.split('.')[0];
	}

	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
});
