import get from 'lodash/get';
import { createNamespacedHelpers } from 'vuex';

import { EDU_CAMP_TYPE } from '@/constants/edu/camp-type';
import { DONGJAK, SBA } from '@/constants/partner';

import { isFreeSchedule, isLiveSchedule } from '~/plugins/constants';

const NAMESPACE = 'camp';
export { NAMESPACE as NAMESPACE_CAMP };
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export { mapGetters as mapGettersOfCamp, mapMutations as mapMutationsOfCamp, mapActions as mapActionsOfCamp };

// states
export const CAMP_NO = 'CAMP_NO';
export const CAMP_SCHEDULE_INFO = 'CAMP_SCHEDULE_INFO';
export const CAMP_INFO = 'CAMP_INFO';
export const CAMP_RECRUITING_INFO = 'CAMP_RECRUITING_INFO';
export const GROUP_INFO = 'GROUP_INFO';
export const ENCORE_ROUND = 'ENCORE_ROUND';
export const SELECT_TAB = 'SELECT_TAB';
export const HAVE_CAMP_REQUESTED = 'HAVE_CAMP_REQUESTED';
export const GROUP_NO = 'GROUP_NO';
export const PARTNER_INFO = 'PARTNER_INFO';
export const PARTNER_URL = 'PARTNER_URL';
export const PARTNER_NAME = 'PARTNER_NAME';
export const PARTNER_NO = 'PARTNER_NO';
export const IS_APPLY = 'IS_APPLY';
export const IS_PARTNER = 'IS_PARTNER';
export const IS_ONLINE = 'IS_ONLINE';
export const APPLY_INFO = 'APPLY_INFO';
export const IS_CAMP_DETAIL_LOADED = 'IS_CAMP_DETAIL_LOADED';
export const IS_FREE_SCHEDULE = 'IS_FREE_SCHEDULE';
export const HAS_FREE_SCHEDULE = 'HAS_FREE_SCHEDULE';
export const HAS_LIVE_SCHEDULE = 'HAS_LIVE_SCHEDULE';
export const HAS_PROMOTION = 'HAS_PROMOTION';
export const PROMOTION_INFO = 'PROMOTION_INFO';
export const IS_USER_REAL_NAME = 'IS_USER_REAL_NAME';
export const IS_PARTNER_APPLIED = 'IS_PARTNER_APPLIED';
export const CAMP_CHARM_ITEM = 'CAMP_CHARM_ITEM';
export const CAMP_BOOKMARK_LIST = 'CAMP_BOOKMARK_LIST';
export const IS_DELAYED_BY_HOLIDAY = 'IS_DELAYED_BY_HOLIDAY';
export const CAMP_BOOKMARK_CAMP_LIST = 'CAMP_BOOKMARK_CAMP_LIST';
export const CAMP_USER_BOOKMARK_COUNT = 'CAMP_USER_BOOKMARK_COUNT';
export const IS_SPARTA_CAMP = 'IS_SPARTA_CAMP';
export const BOOKMARK_LIST_INFO = 'BOOKMARK_LIST_INFO';
export const IS_CHATABLE = 'IS_CHATABLE';
export const EDU_USER_MATCH_INFO = 'EDU_USER_MATCH_INFO';
export const EDU_USER_COMPLETION = 'EDU_USER_COMPLETION';
export const IS_TAB_FIXED = 'IS_TAB_FIXED';
export const IS_CAREER_CAMP = 'IS_CAREER_CAMP'; // 진로부트캠프 판단
export const IS_EDU_JUMP_UP = 'IS_EDU_JUMP_UP'; // 직무부트캠프 재맞고 판단
export const CAMP_TYPE = 'CAMP_TYPE';
export const IS_B2B_WEEK3_CAMP = 'IS_B2B_WEEK3_CAMP';

/* partner */
export const IS_ROOTIMPACT = 'IS_ROOTIMPACT';
export const IS_ROOTIMPACT_APPLIED = 'IS_ROOTIMPACT_APPLIED';
export const IS_GREEN_NEW_DEAL = 'IS_GREEN_NEW_DEAL';
export const IS_GREEN_NEW_DEAL_APPLIED = 'IS_GREEN_NEW_DEAL_APPLIED';
export const IS_JOB_ASSIGNED = 'IS_JOB_ASSIGNED'; // 채용연계과정 판단
export const BIZ_STATE = 'BIZ_STATE';
export const BIZ_STATE_LOADED = 'BIZ_STATE_LOADED';

// getters
export const GET_IS_DONGJAK_APPLIED = 'GET_IS_DONGJAK_APPLIED';
export const CAMP_DAYS = 'CAMP_DAYS';
export const CAMP_SESSION_CONTENT = 'CAMP_SESSION_CONTENT';
export const GET_CAMP_BOOKMARK_LIST = 'GET_CAMP_BOOKMARK_LIST';
export const GET_GROUP_TRACK = 'GET_GROUP_TRACK';
export const GET_CAMP_SCHEDULE_TYPE = 'GET_CAMP_SCHEDULE_TYPE';
export const GET_IS_BOOKMARKED = 'GET_IS_BOOKMARKED';
export const GET_CAMP_BOOKMARK_CAMP_LIST = 'GET_CAMP_BOOKMARK_CAMP_LIST';
export const GET_CAMP_USER_BOOKMARK_COUNT = 'GET_CAMP_USER_BOOKMARK_COUNT';
export const GET_CAMP_BOOKMARK_ACTIVE_CAMP_LIST = 'GET_CAMP_BOOKMARK_ACTIVE_CAMP_LIST';

// SBA(서울산업진흥원)
export const GET_IS_SBA = 'GET_IS_SBA';
// 동작구청
export const GET_IS_DONGJAK = 'GET_IS_DONGJAK';
export const GET_BIZ_STATE = 'GET_BIZ_STATE';
// 휴넷
export const GET_IS_HUNET = 'GET_IS_HUNET';
// hfr
export const GET_IS_HFR = 'GET_IS_HFR';

/* */
// sideBox나 schedule selectBox에서 schedule을 변경한 횟수
export const SCHEDULE_CLICK_COUNT = 'SCHEDULE_CLICK_COUNT';
export const RECOMMENDED_CAMP_LIST = 'RECOMMENDED_CAMP_LIST';
export const TOP_CATEGORY = 'TOP_CATEGORY';
export const MID_CATEGORY = 'MID_CATEGORY';
export const CAMP_INDUSTRY = 'CAMP_INDUSTRY';
export const CAMP_PRICE = 'CAMP_PRICE';
export const CAMP_TITLE = 'CAMP_TITLE';
export const CAMP_CERTIFICATION_TITLE = 'CAMP_CERTIFICATION_TITLE';
export const CAMP_WEEK_INFO = 'CAMP_WEEK_INFO';
export const CAMP_WEEK_COUNT = 'CAMP_WEEK_COUNT';
export const CAMP_IMAGE = 'CAMP_IMAGE';
export const CAMP_START_DATE = 'CAMP_START_DATE';
export const CAMP_END_DATE = 'CAMP_END_DATE';
export const CAMP_HAS_VOD = 'CAMP_HAS_VOD';
export const REVIEWPAGE = 'REVIEWPAGE';

// mutations
export const SET_CAMP_NO = 'SET_CAMP_NO';
export const SET_CAMP_SCHEDULE_INFO = 'SET_CAMP_SCHEDULE_INFO';
export const SET_CAMP_INFO = 'SET_CAMP_INFO';
export const SET_CAMP_RECRUITING_INFO = 'SET_IS_RECRUITING_CAMP';
export const SET_ENCORE_ROUND = 'SET_ENCORE_ROUND';
export const SET_SELECT_TAB = 'SET_SELECT_TAB';
export const SET_HAVE_CAMP_REQUESTED = 'SET_HAVE_CAMP_REQUESTED';
export const SET_GROUP_NO = 'SET_GROUP_NO';
export const SET_IS_ROOTIMPACT_APPLIED = 'SET_IS_ROOTIMPACT_APPLIED';
export const SET_IS_GREEN_NEW_DEAL_APPLIED = 'SET_IS_GREEN_NEW_DEAL_APPLIED';
export const SET_SCHEDULE_CLICK_COUNT = 'SET_SCHEDULE_CLICK_COUNT';
export const SET_RECOMMENDED_CAMP_LIST = 'SET_RECOMMENDED_CAMP_LIST';
export const SET_REVIEWPAGE_PAGE = 'SET_REVIEWPAGE_PAGE';
export const SET_IS_CAMP_DETAIL_LOADED = 'SET_IS_CAMP_DETAIL_LOADED';
export const SET_IS_JOB_ASSIGNED = 'SET_IS_JOB_ASSIGNED';
export const SET_IS_USER_REAL_NAME = 'SET_IS_USER_REAL_NAME';
export const SET_BIZ_STATE = 'SET_BIZ_STATE';
export const SET_CAMP_CHARM_ITEM = 'SET_CAMP_CHARM_ITEM';
export const SET_CAMP_BOOKMARK_LIST = 'SET_CAMP_BOOKMARK_LIST';
export const SET_IS_DELAYED_BY_HOLIDAY = 'SET_IS_DELAYED_BY_HOLIDAY';
export const SET_CAMP_BOOKMARK_CAMP_LIST = 'SET_CAMP_BOOKMARK_CAMP_LIST';
export const SET_CAMP_USER_BOOKMARK_CAMP_COUNT = 'SET_CAMP_USER_BOOKMARK_CAMP_COUNT';
export const SET_SPARTA_CAMP = 'SET_SPARTA_CAMP';
export const SET_BOOKMARK_LIST_INFO = 'SET_BOOKMARK_LIST_INFO';
export const SET_HAS_PROMOTION = 'SET_HAS_PROMOTION';
export const SET_PROMOTION_INFO = 'SET_PROMOTION_INFO';
export const SET_EDU_USER_MATCH_INFO = 'SET_EDU_USER_MATCH_INFO';
export const SET_EDU_USER_COMPLETION = 'SET_EDU_USER_COMPLETION';
export const SET_TAB_FIXED = 'SET_TAB_FIXED';
export const SET_IS_CAREER_CAMP = 'SET_IS_CAREER_CAMP';
export const SET_CAMP_TYPE = 'SET_CAMP_TYPE';

// actions
export const LOAD_CAMP_SCHEDULE_INFO = 'LOAD_CAMP_SCHEDULE_INFO';
export const LOAD_CHECK_APPLY_FOR_PARTNER = 'LOAD_CHECK_APPLY_FOR_PARTNER';
export const LOAD_RECOMMENDED_CAMP_LIST = 'LOAD_RECOMMENDED_CAMP_LIST';
export const LOAD_CAMP_BOOKMARK_LIST = 'LOAD_CAMP_BOOKMARK_LIST';
export const LOAD_CAMP_BOOKMARK_CAMP_LIST = 'LOAD_CAMP_BOOKMARK_CAMP_LIST';
export const LOAD_CAMP_USER_BOOKMARK_CAMP_COUNT = 'LOAD_CAMP_USER_BOOKMARK_CAMP_COUNT';
export const LOAD_MORE_CAMP_USER_BOOKMARK_CAMP_LIST = 'LOAD_MORE_CAMP_USER_BOOKMARK_CAMP_LIST';
export const RESET_CAMP_INFO = 'RESET_CAMP_INFO';

export const LOADED = 'LOADED';
export const APPLIED = 'APPLIED';
export const PAID = 'PAID';
export const RESUME_SUBMITTED = 'RESUME_SUBMITTED';

export const state = () => ({
	[CAMP_NO]: null,
	[CAMP_SCHEDULE_INFO]: null,
	[CAMP_INFO]: null,
	[CAMP_RECRUITING_INFO]: null,
	[ENCORE_ROUND]: null,
	[SELECT_TAB]: 0,
	[HAVE_CAMP_REQUESTED]: false,
	[GROUP_NO]: false,
	[IS_ROOTIMPACT_APPLIED]: false,
	[SCHEDULE_CLICK_COUNT]: 0,
	[RECOMMENDED_CAMP_LIST]: [],
	[REVIEWPAGE]: {
		totalCount: 0,
		page: 1,
		perPage: 10,
	},
	[IS_CAREER_CAMP]: false,
	[IS_CAMP_DETAIL_LOADED]: false,
	[IS_JOB_ASSIGNED]: false,
	[IS_USER_REAL_NAME]: false,
	[IS_PARTNER_APPLIED]: {
		DONGJAK: false,
	},
	[HAS_PROMOTION]: false,
	[PROMOTION_INFO]: null,
	// 채용연계형에서 이력서를 제출했는지 여부
	[BIZ_STATE]: {
		// api를 불러왔는지 체크 (채용연계형에서 CTA button loading 조건에 사용)
		[LOADED]: false,
		[APPLIED]: false,
		[PAID]: false,
		[RESUME_SUBMITTED]: false,
	},
	[CAMP_CHARM_ITEM]: null,
	[CAMP_BOOKMARK_LIST]: null,
	[IS_DELAYED_BY_HOLIDAY]: false,
	[CAMP_BOOKMARK_CAMP_LIST]: null,
	[CAMP_USER_BOOKMARK_COUNT]: 0,
	[IS_SPARTA_CAMP]: false,
	[BOOKMARK_LIST_INFO]: {
		page: 1,
		limit: 12,
		totalCount: -1,
	},
	[EDU_USER_MATCH_INFO]: null,
	[EDU_USER_COMPLETION]: null,
	[IS_TAB_FIXED]: false,
	[CAMP_TYPE]: 'edu',
	[IS_EDU_JUMP_UP]: false,
	[IS_B2B_WEEK3_CAMP]: false,
});
export const getters = {
	[CAMP_NO]: state => state[CAMP_NO],
	[CAMP_SCHEDULE_INFO]: state => state[CAMP_SCHEDULE_INFO],
	[CAMP_INFO]: state => state[CAMP_INFO] || {},
	[CAMP_RECRUITING_INFO]: state => state[CAMP_RECRUITING_INFO],
	[GROUP_INFO]: state => get(state, [CAMP_INFO, 'edu_camp_group'], {}),
	[ENCORE_ROUND]: state => state[ENCORE_ROUND],
	[SELECT_TAB]: state => state[SELECT_TAB],
	[HAVE_CAMP_REQUESTED]: state => state[HAVE_CAMP_REQUESTED],
	[GROUP_NO]: state => state[GROUP_NO],
	[PARTNER_INFO]: state => get(state, [CAMP_INFO, 'edu_partner']),
	[PARTNER_URL]: (_state, getters) => get(getters, [PARTNER_INFO, 'url'], 'comento'),
	[PARTNER_NAME]: (_state, getters) => get(getters, [PARTNER_INFO, 'name'], ''),
	[PARTNER_NO]: (_state, getters) => get(getters, [PARTNER_INFO, 'no'], 0),
	[IS_APPLY]: state => parseInt(get(state, [CAMP_INFO, 'isApply'], 0), 10),
	[IS_PARTNER]: (_state, getters) => get(getters, PARTNER_URL) !== 'comento',
	[IS_ROOTIMPACT]: (_state, getters) => get(getters, PARTNER_URL) === 'impactcampus',
	[IS_ROOTIMPACT_APPLIED]: state => state[IS_ROOTIMPACT_APPLIED],
	[IS_GREEN_NEW_DEAL]: (_state, getters) => get(getters, PARTNER_URL) === 'green',
	[IS_GREEN_NEW_DEAL_APPLIED]: state => state[IS_GREEN_NEW_DEAL_APPLIED],
	[GET_IS_SBA]: (_state, getters) => get(getters, PARTNER_URL) === SBA,
	[GET_IS_DONGJAK]: (_state, getters) => get(getters, PARTNER_URL) === DONGJAK,
	[GET_IS_DONGJAK_APPLIED]: state => get(state, [IS_PARTNER_APPLIED, DONGJAK]),
	[APPLY_INFO]: (state, getters) => {
		const campScheduleInfo = get(getters, [CAMP_SCHEDULE_INFO]);
		if (Array.isArray(campScheduleInfo) && campScheduleInfo.length > 0) {
			const applyInfo = campScheduleInfo.find(schedule => schedule.no === state[CAMP_NO])?.edu_applicant;
			if (Array.isArray(applyInfo) && applyInfo.length > 0) {
				return applyInfo[0];
			} else {
				return null;
			}
		} else {
			return null;
		}
	},
	[SCHEDULE_CLICK_COUNT]: state => state[SCHEDULE_CLICK_COUNT],
	[RECOMMENDED_CAMP_LIST]: state => state[RECOMMENDED_CAMP_LIST],
	[IS_ONLINE]: state => state[CAMP_INFO] && state[CAMP_INFO].channel === 'online',
	[TOP_CATEGORY]: state => get(state, [CAMP_INFO, 'edu_camp_group', 'top_category']),
	[MID_CATEGORY]: state => get(state, [CAMP_INFO, 'edu_camp_group', 'mid_category']),
	[CAMP_INDUSTRY]: state => get(state, [CAMP_INFO, 'edu_camp_group', 'industry']),
	[CAMP_PRICE]: state => {
		const target =
			state[CAMP_SCHEDULE_INFO] &&
			state[CAMP_SCHEDULE_INFO].find(info => {
				return info.no === state[CAMP_NO];
			});
		return get(target, ['price'], 0);
	},
	[CAMP_WEEK_INFO]: (_state, getters) => {
		const campScheduleInfo = get(getters, [CAMP_SCHEDULE_INFO]);
		const campNo = get(getters, [CAMP_NO]);
		if (campScheduleInfo !== null) {
			return campScheduleInfo.find(weekInfo => weekInfo.no === campNo);
		}
	},
	[CAMP_TITLE]: (_state, getters) => get(getters, [GROUP_INFO, 'title'], '직무부트캠프'),
	[CAMP_CERTIFICATION_TITLE]: (_state, getters) => get(getters, [GROUP_INFO, 'trimmed_title'], '직무부트캠프'),
	[CAMP_IMAGE]: (_state, getters) => get(getters, [GROUP_INFO, 'title_img']),
	[IS_ONLINE]: state => state[CAMP_INFO] && state[CAMP_INFO].edu_camp_group === 'online',
	[CAMP_WEEK_COUNT]: state => {
		const target = get(state, [CAMP_INFO, 'week_info'], {});
		return Object.values(target).length;
	},
	[CAMP_DAYS]: (_state, getters) => {
		if (get(getters, [IS_FREE_SCHEDULE], false)) {
			return '90일';
		}
		return `${get(getters, [CAMP_WEEK_COUNT])}주`;
	},
	[CAMP_SESSION_CONTENT]: (_state, getters) => {
		if (get(getters, [IS_FREE_SCHEDULE], false)) {
			return '4회의 피드백';
		}
		return '3회의 세션';
	},
	[CAMP_START_DATE]: (_state, getters) => {
		return get(getters, [CAMP_WEEK_INFO, 'start_date']);
	},
	[CAMP_END_DATE]: (_state, getters) => {
		return get(getters, [CAMP_WEEK_INFO, 'end_date']);
	},
	[REVIEWPAGE]: state => state[REVIEWPAGE],
	[CAMP_HAS_VOD]: (_state, getters) => {
		return get(getters, [GROUP_INFO, 'vod']);
	},
	[IS_CAMP_DETAIL_LOADED]: state => state[IS_CAMP_DETAIL_LOADED],
	[IS_FREE_SCHEDULE]: state => state[CAMP_INFO] && isFreeSchedule(state[CAMP_INFO].type),
	[HAS_FREE_SCHEDULE]: state => {
		return (
			state[CAMP_SCHEDULE_INFO] &&
			state[CAMP_SCHEDULE_INFO].some(info => {
				return isFreeSchedule(info.type);
			})
		);
	},
	[HAS_LIVE_SCHEDULE]: state => {
		return (
			state[CAMP_SCHEDULE_INFO] &&
			state[CAMP_SCHEDULE_INFO].some(info => {
				return isLiveSchedule(info.type);
			})
		);
	},
	[IS_CAREER_CAMP]: state => state[IS_CAREER_CAMP],
	[HAS_PROMOTION]: state => state[HAS_PROMOTION],
	[PROMOTION_INFO]: state => state[PROMOTION_INFO],
	[IS_JOB_ASSIGNED]: state => state[IS_JOB_ASSIGNED],
	[IS_USER_REAL_NAME]: state => state[IS_USER_REAL_NAME],
	[GET_BIZ_STATE]: state => state[BIZ_STATE],
	[CAMP_CHARM_ITEM]: state => state[CAMP_CHARM_ITEM],
	[GET_IS_HUNET]: (_state, getters) => getters[GROUP_NO] === 1549,
	[GET_IS_HFR]: (_state, getters) => getters[GROUP_NO] === 1437,
	[GET_CAMP_BOOKMARK_LIST]: state => get(state, [CAMP_BOOKMARK_LIST], []),
	[GET_GROUP_TRACK]: (_state, getters) => get(getters, [GROUP_INFO, 'track']),
	[GET_CAMP_SCHEDULE_TYPE]: (_state, getters) => get(getters, [CAMP_INFO, 'type']),
	[GET_IS_BOOKMARKED]: (_state, getters) => {
		const campNo = get(getters, [GROUP_NO]);
		// GET_CAMP_BOOKMARK_LIST가 null일때도 동작은 하도록
		const list = get(getters, [GET_CAMP_BOOKMARK_LIST], []);
		return list ? list.includes(campNo) : false;
	},
	[IS_DELAYED_BY_HOLIDAY]: state => state[IS_DELAYED_BY_HOLIDAY],
	[GET_CAMP_BOOKMARK_CAMP_LIST]: state => state[CAMP_BOOKMARK_CAMP_LIST],
	[GET_CAMP_USER_BOOKMARK_COUNT]: state => state[CAMP_USER_BOOKMARK_COUNT],
	[GET_CAMP_BOOKMARK_ACTIVE_CAMP_LIST]: (_state, getters) => {
		const list = get(getters, [GET_CAMP_BOOKMARK_CAMP_LIST], []);
		return list ? list.filter(item => item.running !== 0) : [];
	},
	[IS_SPARTA_CAMP]: state => state[IS_SPARTA_CAMP],
	[BOOKMARK_LIST_INFO]: state => state[BOOKMARK_LIST_INFO],
	[IS_CHATABLE]: (_, getters) => getters[GROUP_INFO].is_chatable,
	[EDU_USER_MATCH_INFO]: state => state[EDU_USER_MATCH_INFO],
	[EDU_USER_COMPLETION]: state => state[EDU_USER_COMPLETION],
	[IS_TAB_FIXED]: state => state[IS_TAB_FIXED],
	[EDU_USER_COMPLETION]: state => state[EDU_USER_COMPLETION],
	[CAMP_TYPE]: state => state[CAMP_TYPE],
	[IS_EDU_JUMP_UP]: state => state[CAMP_INFO]?.camp_type === EDU_CAMP_TYPE.JUMP_UP,
	[IS_B2B_WEEK3_CAMP]: state => {
		return state[CAMP_INFO]?.week_count === 3 && state[CAMP_INFO]?.camp_type.includes('b2b');
	},
};

export const mutations = {
	[SET_CAMP_NO]: (state, payload) => {
		state[CAMP_NO] = payload;
	},
	[SET_CAMP_SCHEDULE_INFO]: (state, payload) => {
		state[CAMP_SCHEDULE_INFO] = payload;
	},
	[SET_CAMP_INFO]: (state, payload) => {
		state[CAMP_INFO] = payload;
	},
	[SET_CAMP_RECRUITING_INFO]: (state, payload) => {
		state[CAMP_RECRUITING_INFO] = payload;
	},
	[SET_ENCORE_ROUND]: (state, payload) => {
		state[ENCORE_ROUND] = payload;
	},
	[SET_SELECT_TAB]: (state, payload) => {
		state[SELECT_TAB] = payload;
	},
	[SET_HAVE_CAMP_REQUESTED]: (state, payload) => {
		state[HAVE_CAMP_REQUESTED] = payload;
	},
	[SET_GROUP_NO]: (state, payload) => {
		state[GROUP_NO] = payload;
	},
	[SET_IS_ROOTIMPACT_APPLIED]: (state, payload) => {
		state[IS_ROOTIMPACT_APPLIED] = payload;
	},
	[SET_IS_GREEN_NEW_DEAL_APPLIED]: (state, payload) => {
		state[IS_GREEN_NEW_DEAL_APPLIED] = payload;
	},
	[SET_SCHEDULE_CLICK_COUNT]: (state, payload) => {
		state[SCHEDULE_CLICK_COUNT] = payload;
	},
	[SET_RECOMMENDED_CAMP_LIST]: (state, payload) => {
		state[RECOMMENDED_CAMP_LIST] = payload;
	},
	[SET_REVIEWPAGE_PAGE]: (state, payload) => {
		state[REVIEWPAGE].page = payload;
	},
	[SET_IS_CAMP_DETAIL_LOADED]: (state, payload) => {
		state[IS_CAMP_DETAIL_LOADED] = payload;
	},
	[SET_IS_JOB_ASSIGNED]: (state, payload) => {
		state[IS_JOB_ASSIGNED] = payload;
	},
	[SET_IS_USER_REAL_NAME]: (state, payload) => {
		state[IS_USER_REAL_NAME] = payload;
	},
	[SET_CAMP_CHARM_ITEM]: (state, payload) => {
		state[CAMP_CHARM_ITEM] = payload;
	},
	[SET_BIZ_STATE]: (state, payload) => {
		state[BIZ_STATE] = { ...state[BIZ_STATE], ...payload };
	},
	[SET_CAMP_BOOKMARK_LIST]: (state, payload) => {
		state[CAMP_BOOKMARK_LIST] = payload;
	},
	[SET_IS_DELAYED_BY_HOLIDAY]: (state, payload) => {
		state[IS_DELAYED_BY_HOLIDAY] = payload;
	},
	[SET_CAMP_BOOKMARK_CAMP_LIST]: (state, payload) => {
		state[CAMP_BOOKMARK_CAMP_LIST] = payload;
	},
	[SET_CAMP_USER_BOOKMARK_CAMP_COUNT]: (state, payload) => {
		state[CAMP_USER_BOOKMARK_COUNT] = payload;
	},
	[SET_SPARTA_CAMP]: (state, payload) => {
		state[IS_SPARTA_CAMP] = payload;
	},
	[SET_BOOKMARK_LIST_INFO]: (state, payload) => {
		state[BOOKMARK_LIST_INFO] = payload;
	},
	[SET_HAS_PROMOTION]: (state, payload) => {
		state[HAS_PROMOTION] = payload;
	},
	[SET_PROMOTION_INFO]: (state, payload) => {
		state[PROMOTION_INFO] = payload;
	},
	[SET_EDU_USER_MATCH_INFO]: (state, payload) => {
		state[EDU_USER_MATCH_INFO] = payload;
	},
	[SET_EDU_USER_COMPLETION]: (state, payload) => {
		state[EDU_USER_COMPLETION] = payload;
	},
	[SET_TAB_FIXED]: (state, payload) => (state[IS_TAB_FIXED] = payload),
	[SET_IS_CAREER_CAMP]: (state, payload) => {
		state[IS_CAREER_CAMP] = payload;
	},
	[SET_CAMP_TYPE]: (state, payload) => (state[CAMP_TYPE] = payload),
};

export const actions = {
	async [LOAD_CAMP_SCHEDULE_INFO]({ commit }, params) {
		try {
			const response = await this.$api.camp.getCampScheduleInfo(params);
			commit(SET_CAMP_SCHEDULE_INFO, response.data.info.group);

			const promotionIndex = response.data.info.group.findIndex(camp => camp.type === 'promotion_2022_1h');

			if (promotionIndex >= 0) {
				const promotionInfo = {
					index: promotionIndex,
					...response.data.info.group[promotionIndex],
				};
				commit(SET_HAS_PROMOTION, true);
				commit(SET_PROMOTION_INFO, promotionInfo);
			}
		} catch (error) {
			console.error(error);
		}
	},
	async [LOAD_CHECK_APPLY_FOR_PARTNER]({ commit }, params) {
		try {
			const {
				data: { code },
			} = await this.$api.camp.getCheckApplyForPartner(params);
			// ROOTIMPACT : 209
			// GREEN_NEW_DEAL : ?
			// 지금 제대로 동작안하는 로직. 그린뉴딜은 이거 안써도 됨.
			if (code) {
				// TODO: 몽고디비에는 동작구청 데이터밖에 없는데 루트임팩트는 뭘 체크하는걸까요? (@백지환)
				if (code === 209) {
					commit(SET_IS_ROOTIMPACT_APPLIED, true);
				}
			}
		} catch (error) {
			console.error(error);
		}
	},
	async [LOAD_RECOMMENDED_CAMP_LIST]({ commit }, params) {
		try {
			const {
				data: { list },
			} = await this.$api.camp.getRecommendedCampList(params);
			commit(SET_RECOMMENDED_CAMP_LIST, list);
		} catch (error) {
			console.error(error);
		}
	},
	async [LOAD_CAMP_BOOKMARK_LIST]({ commit }) {
		try {
			const { data } = await this.$api.camp.getUserBookmarkList();
			commit(SET_CAMP_BOOKMARK_LIST, data);
		} catch (error) {
			console.error(error);
		}
	},
	async [LOAD_CAMP_BOOKMARK_CAMP_LIST]({ commit, state }) {
		try {
			commit(SET_BOOKMARK_LIST_INFO, {
				...state[BOOKMARK_LIST_INFO],
				page: 1,
				totalCount: -1,
			});

			const { page, limit } = state[BOOKMARK_LIST_INFO];

			const {
				data: { list, count },
			} = await this.$api.camp.getUserBookmarkCampList({ page, limit });
			commit(SET_CAMP_BOOKMARK_CAMP_LIST, list);
			commit(SET_BOOKMARK_LIST_INFO, {
				...state[BOOKMARK_LIST_INFO],
				page: page + 1,
				totalCount: count,
			});
		} catch (error) {
			console.error(error);
		}
	},
	async [LOAD_MORE_CAMP_USER_BOOKMARK_CAMP_LIST]({ commit, state }) {
		try {
			const { page, limit, totalCount } = state[BOOKMARK_LIST_INFO];
			if ((page - 1) * limit > totalCount && totalCount >= 0) {
				return;
			}

			const {
				data: { list, count },
			} = await this.$api.camp.getUserBookmarkCampList({ page, limit });

			const existingList = state[CAMP_BOOKMARK_CAMP_LIST] || [];
			commit(SET_CAMP_BOOKMARK_CAMP_LIST, [...existingList, ...list]);
			commit(SET_BOOKMARK_LIST_INFO, {
				...state[BOOKMARK_LIST_INFO],
				page: page + 1,
				totalCount: count,
			});
		} catch (error) {
			console.error(error);
		}
	},
	async [LOAD_CAMP_USER_BOOKMARK_CAMP_COUNT]({ commit }) {
		try {
			const {
				data: { count },
			} = await this.$api.camp.getUserBookmarkCount();
			commit(SET_CAMP_USER_BOOKMARK_CAMP_COUNT, count);
		} catch (error) {
			console.error(error);
		}
	},
	[RESET_CAMP_INFO]({ commit }) {
		commit(SET_CAMP_INFO, null);
		commit(SET_CAMP_NO, null);
	},
};
