
import { CLASS_PT } from '@/constants/class/pt/class-types';
import { COUPON_BOOK_PATH } from '@/constants/paths';

import { getCdnPath } from '@/utils/cdn';
import { moveToLaravelPage } from '@/utils/utils';

import layoutMixin from '@/mixins/layoutMixin';

export default {
	name: 'PtFirstAnniversaryAlert',
	mixins: [layoutMixin],
	data() {
		return {
			CLASS_PT,
		};
	},
	computed: {
		imgSrc() {
			return getCdnPath(`/images/banner/pt-first-anniversary-alert${this.IS_MOBILE ? '-mo.svg' : '.jpg'}`);
		},
	},
	methods: {
		goCouponBookPage() {
			moveToLaravelPage(COUPON_BOOK_PATH);
		},
		emitClose() {
			this.$emit('close');
		},
	},
};
