import communityGtmTriggerMixin from '@/mixins/gtm/communityGtmTriggerMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

const gtmCommunityAdMixin = (gtmParams = {}) => {
	return {
		mixins: [communityGtmTriggerMixin, userInfoMixin],
		props: {
			gtmParamsProps: {
				type: Object,
				default: () => {},
			},
		},
		mounted() {
			this.$_gtmCommunityAdMixin_triggerGTMImpression();
		},
		computed: {
			gtmCommonParameters() {
				return {
					...this.$_userInfoForGtm,
					...gtmParams,
					...this.gtmParamsProps,
				};
			},
		},
		methods: {
			$_gtmCommunityAdMixin_triggerGTMImpression() {
				// GTM - impression
				this.$_impression({
					...this.gtmCommonParameters,
				});
			},
			$_gtmCommunityAdMixin_triggerGTMClickAdBanner() {
				// GTM - clickAdBanner
				this.$_clickAdBanner({
					...this.gtmCommonParameters,
				});
			},
		},
	};
};

export default gtmCommunityAdMixin;
