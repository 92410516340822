import { isAppNativePage } from '@/plugins/app-route';

import {
	BACKWARD_BUTTON_MARGIN_BOTTOM,
	IS_LOGO_TOP_BAR,
	IS_SHOW_FOOTER,
	IS_TRANSPARENT_TYPE,
	IS_SHOW_HEADER,
	IS_SHOW_BOTTOM_NAV,
} from '@/store/layout';

import appCommandMixin from '@/mixins/appCommandMixin';
import layoutMixin from '@/mixins/layoutMixin';

export default ({ route, store }) => {
	if (layoutMixin.computed.IS_APP() && !isAppNativePage(route.fullPath)) {
		appCommandMixin.methods.$_app_setMeta({
			path: route.fullPath,
			isDetail: store.getters.metaIsDetail,
			bgColor: store.getters.metaBgColor,
			title: store.getters.metaTitle,
			backwardButtonMarginBottom: store.getters[`layout/${BACKWARD_BUTTON_MARGIN_BOTTOM}`],
			isLogoTopBar: store.getters[`layout/${IS_LOGO_TOP_BAR}`],
			// 기존 meta isShowSubHeader를 다음 조건식으로 변경
			isShowSubHeader: !(store.getters.metaIsDetail || store.getters[`layout/${IS_LOGO_TOP_BAR}`]),
			isShowFooter: store.getters[`layout/${IS_SHOW_FOOTER}`],
			isTransparentType: store.getters[`layout/${IS_TRANSPARENT_TYPE}`],
			isShowHeader: store.getters[`layout/${IS_SHOW_HEADER}`],
			isShowBottomNav: store.getters[`layout/${IS_SHOW_BOTTOM_NAV}`],
		});
	}
};
