
import { cdnBaseUrl, KAKAO, EMAIL } from '@/plugins/constants';
export default {
	name: 'SocialIcon',
	props: {
		social: {
			type: String,
			default: KAKAO,
		},
		radius: {
			type: Number,
			default: 7,
		},
		iconWidth: {
			type: Number,
			default: 8,
		},
		iconHeight: {
			type: Number,
			default: 8,
		},
	},
	computed: {
		socialName() {
			if (this.social === 'comento') {
				return EMAIL.toLowerCase();
			}
			return this.social.toLowerCase();
		},
		isEmail() {
			return this.socialName === 'email';
		},
		computedStyle() {
			return { height: this.computedRadius, minWidth: this.computedRadius };
		},
		computedRadius() {
			return (this.radius * 2).toString() + 'px';
		},
		iconSrc() {
			return `${cdnBaseUrl}/icon/icon-${this.socialName}-medium.svg`;
		},
	},
};
