import get from 'lodash/get';
import { createNamespacedHelpers } from 'vuex';

import { CLASS_TYPE } from '@/constants/class/pt/class-types';
import { CLASSROOM_TAB_ITEMS_ENUM } from '@/constants/classroom';
export const NAMESPACE = 'classroom/pt';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfClassroomPt,
	mapMutations as mapMutationsOfClassroomPt,
	mapActions as mapActionsOfClassroomPt,
};

// states
export const PT_NO = 'PT_NO';
export const IS_PT_MENTOR = 'IS_PT_MENTOR';
export const IS_PT_MENTEE = 'IS_PT_MENTEE';
export const PT_INFO = 'PT_INFO';
export const PT_MENTOR_INFO = 'PT_MENTOR_INFO';
export const PT_CURRICULUMS = 'PT_CURRICULUMS';
export const IS_PT_CLASSROOM = 'IS_PT_CLASSROOM';
export const PT_ASSIGNMENT = 'PT_ASSIGNMENT';
export const PT_ASSIGNMENT_COMMENT = 'PT_ASSIGNMENT_COMMENT';
export const PT_ALL_ASSIGNMENT_LIST = 'PT_ALL_ASSIGNMENT_LIST';
export const PT_MY_ASSIGNMENT_LIST = 'PT_MY_ASSIGNMENT_LIST';
export const PT_WEEK_ASSIGNMENT_LIST = 'PT_WEEK_ASSIGNMENT_LIST';
export const IS_ON_AIR = 'IS_ON_AIR';
export const PT_REPLY_STREAM = 'PT_REPLY_STREAM';
export const PT_FEEDBACK = 'PT_FEEDBACK';
export const PT_REPLY_STREAM_COUNT = 'PT_REPLY_STREAM_COUNT';
export const PT_ASSIGNMENT_UNREAD = 'PT_ASSIGNMENT_UNREAD';
export const PT_ATTENDANCE_MINE = 'PT_ATTENDANCE_MINE';
export const PT_LIVE_CLASS_STEP = 'PT_LIVE_CLASS_STEP';
export const PT_REVISE_VOD = 'PT_REVISE_VOD';
export const SHOW_CLASSROOM_OPTION_MODAL = 'SHOW_CLASSROOM_OPTION_MODAL';

// getters
export const GET_PT_NO = 'GET_PT_NO';
export const GET_PT_INFO = 'GET_PT_INFO';
export const GET_PT_MENTOR_INFO = 'GET_PT_MENTOR_INFO';
export const GET_PT_CURRICULUMS = 'PT_CURRICULUMS';
export const GET_IS_PT_MENTOR = 'GET_IS_PT_MENTOR';
export const GET_IS_PT_MENTEE = 'GET_IS_PT_MENTEE';
export const GET_IS_PT_CLASSROOM = 'GET_IS_PT_CLASSROOM';
export const GET_PT_ASSIGNMENT = 'GET_PT_ASSIGNMENT';
export const GET_PT_ASSIGNMENT_COMMENT = 'GET_PT_ASSIGNMENT_COMMENT';
export const GET_PT_ALL_ASSIGNMENT_LIST = 'GET_PT_ALL_ASSIGNMENT_LIST';
export const GET_PT_MY_ASSIGNMENT_LIST = 'GET_PT_MY_ASSIGNMENT_LIST';
export const GET_PT_WEEK_ASSIGNMENT_LIST = 'GET_PT_WEEK_ASSIGNMENT_LIST';
export const GET_IS_ON_AIR = 'GET_IS_ON_AIR';
export const GET_PT_REPLY_STREAM = 'GET_PT_REPLY_STREAM';
export const GET_PT_FEEDBACK = 'GET_PT_FEEDBACK';
export const GET_PT_REPLY_STREAM_COUNT = 'GET_PT_REPLY_STREAM_COUNT';
export const GET_PT_ASSIGNMENT_UNREAD = 'GET_PT_ASSIGNMENT_UNREAD';
export const GET_PT_ATTENDANCE_MINE = 'GET_PT_ATTENDANCE_MINE';
export const GET_PT_LIVE_CLASS_STEP = 'GET_PT_LIVE_CLASS_STEP';
export const GET_PT_BOTTOM_CATEGORY = 'GET_PT_BOTTOM_CATEGORY';
export const GET_PT_HAS_VOD = 'GET_PT_HAS_VOD';
export const GET_PT_REVISE_VOD = 'GET_PT_REVISE_VOD';
export const GET_PT_CLASS_TYPE = 'GET_PT_CLASS_TYPE';
export const GET_PT_HAS_REVISE_VOD = 'GET_PT_HAS_REVISE_VOD';
export const GET_PT_REVISE_VOD_MENU_NAME = 'GET_PT_REVISE_VOD_MENU_NAME';
export const GET_SHOW_CLASSROOM_OPTION_MODAL = 'GET_SHOW_CLASSROOM_OPTION_MODAL';

// mutations
export const SET_PT_NO = 'SET_PT_NO';
export const SET_IS_PT_MENTOR = 'SET_IS_PT_MENTOR';
export const SET_IS_PT_MENTEE = 'SET_IS_PT_MENTEE';
export const SET_PT_INFO = 'SET_PT_INFO';
export const SET_PT_MENTOR_INFO = 'SET_PT_MENTOR_INFO';
export const SET_PT_CURRICULUMS = 'SET_PT_CURRICULUMS';
export const SET_IS_PT_CLASSROOM = 'SET_IS_PT_CLASSROOM';
export const SET_PT_ASSIGNMENT = 'SET_PT_ASSIGNMENT';
export const SET_PT_ASSIGNMENT_COMMENT = 'SET_PT_ASSIGNMENT_COMMENT';
export const SET_PT_ALL_ASSIGNMENT_LIST = 'SET_PT_ALL_ASSIGNMENT_LIST';
export const SET_PT_MY_ASSIGNMENT_LIST = 'SET_PT_MY_ASSIGNMENT_LIST';
export const SET_PT_WEEK_ASSIGNMENT_LIST = 'SET_PT_WEEK_ASSIGNMENT_LIST';
export const SET_IS_ON_AIR = 'SET_IS_ON_AIR';
export const SET_PT_REPLY_STREAM = 'SET_PT_REPLY_STREAM';
export const SET_PT_FEEDBACK = 'SET_PT_FEEDBACK';
export const SET_PT_REPLY_STREAM_COUNT = 'SET_PT_REPLY_STREAM_COUNT';
export const SET_PT_ASSIGNMENT_UNREAD = 'SET_PT_ASSIGNMENT_UNREAD';
export const SET_PT_ATTENDANCE_MINE = 'SET_PT_ATTENDANCE_MINE';
export const SET_PT_LIVE_CLASS_STEP = 'SET_PT_LIVE_CLASS_STEP';
export const SET_PT_REVISE_VOD = 'SET_PT_REVISE_VOD';
export const SET_SHOW_CLASSROOM_OPTION_MODAL = 'SET_SHOW_CLASSROOM_OPTION_MODAL';

// actions
export const FETCH_CHECK_PT_MENTOR = 'FETCH_CHECK_PT_MENTOR';
export const FETCH_MY_ASSIGNMENT = 'FETCH_MY_ASSIGNMENT';
export const FETCH_CURRICULUM = 'FETCH_CURRICULUM';
export const FETCH_MY_ATTENDANCE = 'FETCH_MY_ATTENDANCE';
export const FETCH_CLASSROOM_PT_INFO = 'FETCH_CLASSROOM_PT_INFO';
export const FETCH_PT_REVISE_VOD = 'FETCH_PT_REVISE_VOD';

export const state = () => ({
	PT_NO: null,
	IS_PT_MENTOR: false,
	IS_PT_MENTEE: false,
	PT_INFO: null,
	PT_MENTOR_INFO: null,
	PT_CURRICULUMS: null,
	IS_PT_CLASSROOM: false,
	PT_ASSIGNMENT: null,
	PT_ASSIGNMENT_COMMENT: null,
	PT_MY_ASSIGNMENT_LIST: {
		list: null,
		perPage: 10,
		totalCount: 0,
	},
	PT_WEEK_ASSIGNMENT_LIST: {
		list: null,
		perPage: 10,
		totalCount: 0,
	},
	PT_ALL_ASSIGNMENT_LIST: {
		list: null,
		perPage: 10,
		totalCount: 0,
	},
	IS_ON_AIR: false,
	PT_REPLY_STREAM_COUNT: 0,
	PT_ASSIGNMENT_UNREAD: 0,
	PT_REPLY_STREAM: {
		list: null,
		perPage: 10,
		totalCount: 0,
	},
	PT_FEEDBACK: {
		fileList: [],
		content: '',
	},
	PT_ATTENDANCE_MINE: null,
	PT_LIVE_CLASS_STEP: 1,
	PT_REVISE_VOD: null,
	SHOW_CLASSROOM_OPTION_MODAL: false,
});

export const getters = {
	[GET_PT_NO]: state => state[PT_NO],
	[GET_IS_PT_MENTOR]: state => state[IS_PT_MENTOR],
	[GET_IS_PT_MENTEE]: state => state[IS_PT_MENTEE],
	[GET_PT_INFO]: state => state[PT_INFO],
	[GET_PT_MENTOR_INFO]: state => state[PT_MENTOR_INFO],
	[GET_PT_CURRICULUMS]: state => state[PT_CURRICULUMS],
	[GET_IS_PT_CLASSROOM]: state => state[IS_PT_CLASSROOM],
	[GET_PT_ASSIGNMENT]: state => state[PT_ASSIGNMENT],
	[GET_PT_ASSIGNMENT_COMMENT]: state => state[PT_ASSIGNMENT_COMMENT],
	[GET_PT_ALL_ASSIGNMENT_LIST]: state => state[PT_ALL_ASSIGNMENT_LIST],
	[GET_PT_MY_ASSIGNMENT_LIST]: state => state[PT_MY_ASSIGNMENT_LIST],
	[GET_PT_WEEK_ASSIGNMENT_LIST]: state => state[PT_WEEK_ASSIGNMENT_LIST],
	[GET_IS_ON_AIR]: state => state[IS_ON_AIR],
	[GET_PT_REPLY_STREAM]: state => state[PT_REPLY_STREAM],
	[GET_PT_REPLY_STREAM_COUNT]: state => state[PT_REPLY_STREAM_COUNT],
	[GET_PT_ASSIGNMENT_UNREAD]: state => state[PT_ASSIGNMENT_UNREAD],
	[GET_PT_FEEDBACK]: state => state[PT_FEEDBACK],
	[GET_PT_ATTENDANCE_MINE]: state => state[PT_ATTENDANCE_MINE],
	[GET_PT_LIVE_CLASS_STEP]: state => state[PT_LIVE_CLASS_STEP],
	[GET_PT_BOTTOM_CATEGORY]: (_, getters) => get(getters[GET_PT_INFO], 'bottom_category', ''),
	[GET_PT_HAS_VOD]: (_, getters) => Boolean(get(getters[GET_PT_INFO], 'isVod', 0)),
	[GET_PT_CLASS_TYPE]: (_, getters) => {
		const isVod = get(getters[GET_PT_INFO], 'isVod', 0);
		return [CLASS_TYPE.LIVE, CLASS_TYPE.VOD, CLASS_TYPE.VOD_ONLY][isVod];
	},
	[GET_PT_REVISE_VOD]: state => state[PT_REVISE_VOD],
	[GET_PT_HAS_REVISE_VOD]: state => state[PT_REVISE_VOD]?.some(({ url }) => url),
	[GET_PT_REVISE_VOD_MENU_NAME]: (_, getters) => {
		// 일반클래스면 복습 VOD, 플립러닝이면 실무 VOD로 보여주기
		const { CLASSROOM_PT_REVISE_VOD, CLASSROOM_PT_REVISE_VOD_FOR_HAS_VOD } = CLASSROOM_TAB_ITEMS_ENUM;
		return getters[GET_PT_HAS_VOD] ? CLASSROOM_PT_REVISE_VOD_FOR_HAS_VOD : CLASSROOM_PT_REVISE_VOD;
	},
	[GET_SHOW_CLASSROOM_OPTION_MODAL]: state => state[SHOW_CLASSROOM_OPTION_MODAL],
};

export const mutations = {
	[SET_PT_NO]: (state, payload) => (state[PT_NO] = payload),
	[SET_IS_PT_MENTOR]: (state, payload) => (state[IS_PT_MENTOR] = payload),
	[SET_IS_PT_MENTEE]: (state, payload) => (state[IS_PT_MENTEE] = payload),
	[SET_PT_INFO]: (state, payload) => (state[PT_INFO] = payload),
	[SET_PT_MENTOR_INFO]: (state, payload) => (state[PT_MENTOR_INFO] = payload),
	[SET_PT_CURRICULUMS]: (state, payload) => (state[PT_CURRICULUMS] = payload),
	[SET_IS_PT_CLASSROOM]: (state, payload) => (state[IS_PT_CLASSROOM] = payload),
	[SET_PT_ASSIGNMENT]: (state, payload) => (state[PT_ASSIGNMENT] = payload),
	[SET_PT_ASSIGNMENT_COMMENT]: (state, payload) => (state[PT_ASSIGNMENT_COMMENT] = payload),
	[SET_PT_ALL_ASSIGNMENT_LIST]: (state, payload) => (state[PT_ALL_ASSIGNMENT_LIST] = payload),
	[SET_PT_MY_ASSIGNMENT_LIST]: (state, payload) => (state[PT_MY_ASSIGNMENT_LIST] = payload),
	[SET_PT_WEEK_ASSIGNMENT_LIST]: (state, payload) => (state[PT_WEEK_ASSIGNMENT_LIST] = payload),
	[SET_IS_ON_AIR]: (state, payload) => (state[IS_ON_AIR] = payload),
	[SET_PT_REPLY_STREAM]: (state, payload) => (state[PT_REPLY_STREAM] = payload),
	[SET_PT_REPLY_STREAM_COUNT]: (state, payload) => (state[PT_REPLY_STREAM_COUNT] = payload),
	[SET_PT_ASSIGNMENT_UNREAD]: (state, payload) => (state[PT_ASSIGNMENT_UNREAD] = payload),
	[SET_PT_FEEDBACK]: (state, payload) => (state[PT_FEEDBACK] = payload),
	[SET_PT_ATTENDANCE_MINE]: (state, payload) => (state[PT_ATTENDANCE_MINE] = payload),
	[SET_PT_LIVE_CLASS_STEP]: (state, payload) => (state[PT_LIVE_CLASS_STEP] = payload),
	[SET_PT_REVISE_VOD]: (state, payload) => {
		const ptReviseVodList = state[PT_CURRICULUMS].map(({ mainTitle, week }) => {
			return { mainTitle, week };
		});
		payload.map(({ id, week, url, date }) => {
			return (ptReviseVodList[week - 1] = { ...ptReviseVodList[week - 1], id, url, date });
		});
		state[PT_REVISE_VOD] = ptReviseVodList;
	},
	[SET_SHOW_CLASSROOM_OPTION_MODAL]: (state, payload) => {
		state[SHOW_CLASSROOM_OPTION_MODAL] = payload;
	},
};
export const actions = {
	async [FETCH_CHECK_PT_MENTOR]({ commit }, ptNo) {
		try {
			const {
				data: { check },
			} = await this.$api.classroomPt.checkPtMentor({ class_no: ptNo });
			commit(SET_IS_PT_MENTOR, check);
			commit(SET_IS_PT_MENTEE, !check);
		} catch (error) {
			console.error('FETCH_CHECK_PT_MENTOR error: ', error);
		}
	},
	async [FETCH_MY_ASSIGNMENT]({ commit }, ptNo) {
		try {
			const {
				data: {
					list: { data, per_page, total },
				},
			} = await this.$api.classroomPt.getPtClassroomAssignmentList(ptNo, 'mine', 1);
			const assignmentData = {
				list: data,
				perPage: per_page,
				totalCount: total,
			};
			commit(SET_PT_MY_ASSIGNMENT_LIST, assignmentData);
		} catch (error) {
			console.error('FETCH_MY_ASSIGNMENT error: ', error);
		}
	},
	async [FETCH_CURRICULUM]({ commit }, ptNo) {
		try {
			const {
				data: { curriculums },
			} = await this.$api.classroomPt.getPtClassroomSchedule(ptNo);
			commit(SET_PT_CURRICULUMS, curriculums);
		} catch (error) {
			console.error('FETCH_CURRICULUM error: ', error);
		}
	},
	async [FETCH_MY_ATTENDANCE]({ commit }, ptNo) {
		try {
			const { data: attendancesMine } = await this.$api.classroomPt.fetchMyAttendances({
				ptNo,
			});
			commit(SET_PT_ATTENDANCE_MINE, attendancesMine.list);
		} catch (error) {
			console.error('FETCH_MY_ATTENDANCE error: ', error);
		}
	},
	async [FETCH_CLASSROOM_PT_INFO]({ commit }, ptNo) {
		try {
			const {
				data: { classInformation },
			} = await this.$api.classroomPt.getPtClassroomInfo(ptNo);
			commit(SET_PT_INFO, classInformation);
		} catch (error) {
			console.error('FETCH_CLASSROOM_PT_INFO error: ', error);
		}
	},
	async [FETCH_PT_REVISE_VOD]({ commit }, ptNo) {
		try {
			const { data } = await this.$api.classroomPt.fetchPtReviseVod(ptNo);
			commit(SET_PT_REVISE_VOD, data);
		} catch (error) {
			console.error('FETCH_PT_REVISE_VOD error: ', error);
		}
	},
};
