export const isShortenedGoogleFormUrl = url => {
	const shortenedGoogleFormUrl = 'https://forms.gle';
	return url.startsWith(shortenedGoogleFormUrl);
};

export const validateAppUrl = url => {
	if (typeof url !== 'string') {
		throw new TypeError(`Invalid URL: ${url} is not a string`);
	}
	if (!isValidUrl(url)) {
		throw new Error(`Invalid URL: ${url} is not a url`);
	}
	if (isShortenedGoogleFormUrl(url)) {
		throw new Error(`Invalid URL: ${url} is a shortened google form url`);
	}
	return true;
};

export const isValidUrl = url => {
	try {
		(() => {
			return new URL(url);
		})();
		return true;
	} catch (error) {
		return false;
	}
};
