import { compareVersion } from '@/utils/utils';

import { ROUTE_NAME_FOR_COMMUNITY_CASE_DETAIL } from '@/store/loading';
import { SELECTED_CASE_QUESTION, SET_SELECTED_CASE_QUESTION } from '@/store/stateForApp';

import appCommandMixin, { APP_NATIVE_PAGE } from '@/mixins/appCommandMixin';
import layoutMixin from '@/mixins/layoutMixin';

export default context => {
	context.app.router.beforeEach((to, _, next) => {
		if (!isAppNativePage(to)) {
			return next();
		}
		if (to.name === ROUTE_NAME_FOR_COMMUNITY_CASE_DETAIL) {
			openReadCaseNativePage(to.fullPath, context);
		}
		next(false);
	});
};

export function isAppNativePage(to) {
	if (!layoutMixin.computed.IS_APP() || !compareVersion('1.6.0', layoutMixin.computed.APP_VERSION())) {
		return false;
	}
	return to.name === ROUTE_NAME_FOR_COMMUNITY_CASE_DETAIL;
}

function openReadCaseNativePage(path, context) {
	appCommandMixin.methods.$_app_openNativePage({
		path,
		page: APP_NATIVE_PAGE.READ_CASE,
		args: context.store.getters[`stateForApp/${SELECTED_CASE_QUESTION}`],
	});
	// 초기화
	context.store.commit(`stateForApp/${SET_SELECTED_CASE_QUESTION}`, null);
}
