
import { cdnBaseUrl, EMAIL, KAKAO, FACEBOOK, SOCIAL_LOGIN_MAP } from '@/plugins/constants';

export default {
	name: 'RegisterButtons',
	props: {
		recentLogin: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			cdnBaseUrl,
			SOCIAL_LOGIN_MAP,
		};
	},
	computed: {
		loginType() {
			return this.type.toString().toLowerCase();
		},
		buttonText() {
			const socialName = SOCIAL_LOGIN_MAP[this.type];
			const actionText = this.type === EMAIL ? '로그인하기' : '시작하기';
			return `${socialName}${this.type === FACEBOOK ? '으' : ''}로
			${this.type === KAKAO ? '1초만에 ' : ''}${actionText}`;
		},
	},
};
