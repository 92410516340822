export default function ({ store, route }) {
	const { name } = route;
	if (name !== 'apply-domain-subDomain-productId') {
		return;
	}

	const { domain, subDomain } = route.params;
	const metaTitle = {
		vod: {
			lecture: '실무스킬 배우기',
		},
	};

	store.commit('setMetaTitle', metaTitle[domain][subDomain] ?? '지원하기');
}
