import { createNamespacedHelpers } from 'vuex';
const NAMESPACE = 'notice';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export { mapGetters as mapGettersOfNotice, mapMutations as mapMutationsOfNotice, mapActions as mapActionsOfNotice };

// states
export const POSTS = 'POSTS';
export const POSTS_TOTAL_COUNT = 'POSTS_TOTAL_COUNT';
export const POSTS_PARAMS = 'POSTS_PARAMS';
export const POST_ID = 'POST_ID';
// 댓글 대댓글 공용
export const CREATED_COMMENT_ID = 'CREATED_COMMENT_ID';
export const IS_FETCHING = 'IS_FETCHING';
// getters
export const GET_POSTS = 'GET_POSTS';
export const GET_POSTS_TOTAL_COUNT = 'GET_POSTS_TOTAL_COUNT';
export const GET_POSTS_PARAMS = 'GET_POSTS_PARAMS';
export const GET_POST_ID = 'GET_POST_ID';
export const GET_CREATED_COMMENT_ID = 'GET_CREATED_COMMENT_ID';
export const GET_IS_FETCHING = 'GET_IS_FETCHING';

// mutations
export const SET_POSTS_PARAMS = 'SET_POSTS_PARAMS';
export const SET_POSTS = 'SET_POSTS';
export const SET_POSTS_TOTAL_COUNT = 'SET_POSTS_TOTAL_COUNT';
export const SET_CREATED_COMMENT_ID = 'SET_CREATED_COMMENT_ID';
export const SET_IS_FETCHING = 'SET_IS_FETCHING';
export const SET_POST_ID = 'SET_POST_ID';

// actions
export const FETCH_POSTS = 'FETCH_POSTS';

export const state = () => ({
	[POSTS]: null,
	[POSTS_TOTAL_COUNT]: 0,
	[POSTS_PARAMS]: {
		page: 1,
		limit: 15,
	},
	[POST_ID]: null,
	[CREATED_COMMENT_ID]: null,
	[IS_FETCHING]: false,
});

export const getters = {
	[GET_POSTS]: state => state[POSTS],
	[GET_POSTS_TOTAL_COUNT]: state => state[POSTS_TOTAL_COUNT],
	[GET_POSTS_PARAMS]: state => state[POSTS_PARAMS],
	// [GET_POSTS_PAGE]: (state, getters) => get(getters, [GET_POSTS_PARAMS, 'page'], '1'),
	[GET_POST_ID]: state => state[POST_ID],
	[GET_CREATED_COMMENT_ID]: state => state[CREATED_COMMENT_ID],
	[GET_IS_FETCHING]: state => state[IS_FETCHING],
};

export const mutations = {
	[SET_POSTS]: (state, payload) => {
		state[POSTS] = payload;
	},
	[SET_POSTS_TOTAL_COUNT]: (state, payload) => {
		state[POSTS_TOTAL_COUNT] = payload;
	},
	[SET_POSTS_PARAMS]: (state, payload) => {
		state[POSTS_PARAMS] = { ...state[POSTS_PARAMS], ...payload };
	},
	[SET_POST_ID]: (state, payload) => {
		state[POST_ID] = payload;
	},
	[SET_CREATED_COMMENT_ID]: (state, payload) => {
		state[CREATED_COMMENT_ID] = payload;
	},
	[SET_IS_FETCHING]: (state, payload) => {
		state[IS_FETCHING] = payload;
	},
};

export const actions = {
	async [FETCH_POSTS]({ getters, commit }) {
		try {
			commit(SET_IS_FETCHING, true);
			const { data } = await this.$api.notice.getNoticePosts(getters[GET_POSTS_PARAMS]);
			commit(SET_POSTS_TOTAL_COUNT, data.total);
			commit(SET_POSTS, data.data);
			commit(SET_IS_FETCHING, false);
		} catch (error) {
			console.error('FETCH_POSTS error: ', error);
		}
	},
};
