import { render, staticRenderFns } from "./CommunityListNuxt.vue?vue&type=template&id=7194b65e&scoped=true&"
import script from "./CommunityListNuxt.vue?vue&type=script&lang=js&"
export * from "./CommunityListNuxt.vue?vue&type=script&lang=js&"
import style0 from "./CommunityListNuxt.vue?vue&type=style&index=0&id=7194b65e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7194b65e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AnswerSideBox: require('/app/components/answer/organisms/AnswerSideBox.vue').default,CaseListSideBox: require('/app/components/case/list/main/organisms/CaseListSideBox.vue').default,CommonLoginModal: require('/app/components/common/molecules/login/CommonLoginModal.vue').default})
