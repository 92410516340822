const AUTOCOMPLETE_PREFIX_URL_V2 = 'api/auto-complete';

export default axios => ({
	/**
	 * 자동완성 - 회사
	 * @keyword 검색어
	 */
	getAutocompleteCompany(params) {
		return axios.get(`${AUTOCOMPLETE_PREFIX_URL_V2}/company`, { params });
	},
	/**
	 * 자동완성 - 직무
	 * @keyword 검색어
	 */
	getAutocompleteJob(params) {
		return axios.get(`${AUTOCOMPLETE_PREFIX_URL_V2}/job`, { params });
	},
	/**
	 * 자동완성 - 대학
	 * @keyword 검색어
	 */
	getAutocompleteUniv(params) {
		return axios.get(`${AUTOCOMPLETE_PREFIX_URL_V2}/school`, { params });
	},
	/**
	 * 자동완성 - 전공
	 * @keyword 검색어
	 */
	getAutocompleteMajor(params) {
		return axios.get(`${AUTOCOMPLETE_PREFIX_URL_V2}/major`, { params });
	},
});
