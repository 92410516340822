
import { EDU_USER_COMPLETION, EDU_USER_MATCH_INFO, mapGettersOfCamp } from '@/store/camp';

import NotiAdBanner from '@/components/common/molecules/noti/NotiAdBanner.vue';
import NotiContent from '@/components/common/molecules/noti/NotiContent.vue';
import NotiFullscreenModal from '@/components/common/molecules/noti/NotiFullscreenModal.vue';
import NotiSideDrawer from '@/components/common/molecules/noti/NotiSideDrawer.vue';

import eduCampMixin from '@/mixins/eduCampMixin';
import gtmTriggerMixin from '@/mixins/gtmTriggerMixin';
import layoutMixin from '@/mixins/layoutMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

export default {
	name: 'NotiWrapper',
	mixins: [userInfoMixin, layoutMixin, gtmTriggerMixin, eduCampMixin],
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		notiCount: {
			type: Number,
			default: 0,
		},
		eduApplicantNo: {
			type: Number,
			default: 0,
		},
		eduCompletion: {
			type: Boolean,
			default: false,
		},
		userMatchInfo: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			notiList: [],
			notiPage: 1,
			infiniteId: +new Date(),
			infiniteErrorMessage: '',
			isErrorInfiniteLoading: false,
			baseUrl: process.env.baseUrl,
		};
	},

	computed: {
		...mapGettersOfCamp([EDU_USER_MATCH_INFO, EDU_USER_COMPLETION]),
	},
	methods: {
		emitClose() {
			this.$emit('close');
		},
		resetNotiCount() {
			this.$emit('reset-noti-count');
		},
		async readAll() {
			try {
				const {
					data: { code },
				} = await this.$api.noti.patchNotiReadAll();

				if (code === 200) {
					this.notiList.forEach(item => {
						if (item.confirm_style !== '') {
							item.confirm_style = '';
						}
					});

					if (this.notiCount > 0) {
						this.resetNotiCount();
					}
					this.$forceUpdate();
				}
			} catch (error) {
				console.error(error);
			}
		},
		init() {
			this.notiPage = 1;
			this.infiniteId += 1;
			this.notiList = [];
		},
		async infiniteHandler($state) {
			try {
				const {
					data: { code, count, list },
				} = await this.$api.noti.getNotiList({ page: this.notiPage });

				if (code === 600) {
					// eslint-disable-next-line no-throw-literal
					throw '로그인이 되지 않았습니다.';
				}

				if (count > 0) {
					this.notiList.push(...list);
					this.notiPage++;
					$state.loaded();
				} else {
					$state.complete();
				}
			} catch (error) {
				console.error(error);
				this.isErrorInfiniteLoading = true;
				this.infiniteErrorMessage = '에러가 발생했습니다. :(';
				$state.error();
			}
		},
		async goNotiDetail({ noti_no, tp, url, confirm_style, index, alarm_url }) {
			if (confirm_style !== '' && confirm_style !== 'alarm-edu') {
				// 읽음 표시
				await this.changeConfirmStyle(noti_no, index);
			}

			switch (tp) {
				case 'lo': // 라운지
					this.clickNotiList();
					return (location.href = `${this.baseUrl}/lounge/read/${url}`);
				case 'ap': // 합격자 감사인사
					this.clickNotiList();
					return (location.href = `${this.baseUrl}/mypage/thanks`);
				case 'conect': // 맞춤공고
					this.clickNotiList();
					return (location.href = url);
				case 'qa_feedback':
					this.clickNotiList();
					// eslint-disable-next-line no-case-declarations
					const $form = $(document.createElement('form'))
						.css({ display: 'none' })
						.attr('method', 'POST')
						.attr('action', '/answer/question');
					// eslint-disable-next-line no-case-declarations
					const $input = $(document.createElement('input')).attr('name', 'request_no').val(url);
					$form.append($input);
					$('body').append($form);
					$form.submit();
					break;
				case 'snooze':
					this.clickNotiList();
					return (location.href = `${this.baseUrl}/mypage/apply`);
				case 'credit':
					this.clickNotiList();
					return (location.href = `${this.baseUrl}/credit/list`);
				case '99':
					this.clickNotiList();
					return (location.href = url);
				case 'qa_history':
					this.clickNotiList();
					return (location.href = url);
				case 'ce':
					this.clickNotiList();
					return (location.href = `${this.baseUrl}/mine/essay?request_no=${url}`);
				case 'qa':
					this.clickNotiList();
					if (alarm_url === '0' || !alarm_url) {
						return (location.href = `${this.baseUrl}/mine/question?request_no=${url}`);
					}
					return (location.href = url);
				case 'in':
					this.clickNotiList();
					return (location.href = `${this.baseUrl}/mine/interview?request_no=${url}`);
				case 'no':
					this.clickNotiList();
					return (location.href = `${this.baseUrl}/mypage/community`);
				case 'pm_message':
					this.clickNotiList();
					return (location.href = url);
				case 'edu':
					this.clickNotiList();
					return (location.href = url);
				case 'fixed-match-alarm':
					return window.open(url, '_blank');
				case 'edu_new_camp':
					this.clickNotiList('newcamp');
					return (location.href = url);
				case 'edu_detail': // 지난 주에 고민하신 00캠프 마감 임박!
					this.clickNotiList('remind');
					return (location.href = url);
				case 'questionToMyCompany': // Drip
					this.clickNotiList();
					return (location.href = `${this.baseUrl}/answer?keyword=${url}`);
				case 'return':
					this.clickNotiList();
					return (location.href = `${this.baseUrl}/credit/cash`);
			}
		},
		async changeConfirmStyle(noti_no, index) {
			try {
				const {
					data: { code },
				} = await this.$api.noti.patchNotiItem(noti_no);

				if (code === 200) {
					this.notiList[index].confirm_style = '';
				}
			} catch (error) {
				console.error(error);
			}
		},
		clickNotiList(where) {
			// GTM - clickNotiList
			const args = {
				...this.$_userInfoForGtm,
				event: 'clickNotiList',
			};
			if (where) {
				args.where = where;
			}
			this.$_gtm(args);
		},
	},
	components: { NotiAdBanner, NotiContent, NotiFullscreenModal, NotiSideDrawer },
};
