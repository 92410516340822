import get from 'lodash/get';
import { createNamespacedHelpers } from 'vuex';

import { CLASS_TYPE } from '@/constants/class/pt/class-types';

import { getPopularity } from '@/utils/classPtUtils';
import { mergeDefinedValue } from '@/utils/object';
import { getUrlWithoutHost } from '@/utils/utils';
export const NAMESPACE = 'class/pt/detail';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfClassPtDetail,
	mapMutations as mapMutationsOfClassPtDetail,
	mapActions as mapActionsOfClassPtDetail,
};
// states
const classPtDetailId = 'classPtDetailId';
const classPtDetailScheduleList = 'classPtDetailScheduleList';
export const CLASS_PT_DETAIL_INFO = 'CLASS_PT_DETAIL_INFO';
export const CLASS_PT_DETAIL_CONTENT = 'CLASS_PT_DETAIL_CONTENT';
export const CLASS_PT_RELATION_LIST = 'CLASS_PT_RELATION_LIST';
export const CLASS_PT_MY_APPLICANT_INFO = 'CLASS_PT_MY_APPLICANT_INFO';
const isChangingClassSchedule = 'isChangingClassSchedule';
const classPtSyllabusList = 'classPtSyllabusList';

// getters
export const CLASS_PT_DETAIL_ID = 'CLASS_PT_DETAIL_ID';
export const CLASS_PT_DETAIL_IS_DUMMY = 'CLASS_PT_DETAIL_IS_DUMMY';
export const CLASS_PT_DETAIL_IS_CLOSED = 'CLASS_PT_DETAIL_IS_CLOSED';
export const CLASS_PT_DETAIL_CATEGORY = 'CLASS_PT_DETAIL_CATEGORY';
export const CLASS_PT_DETAIL_TOP_CATEGORY = 'CLASS_PT_DETAIL_TOP_CATEGORY';
export const CLASS_PT_DETAIL_MID_CATEGORY = 'CLASS_PT_DETAIL_MID_CATEGORY';
export const CLASS_PT_DETAIL_BOTTOM_CATEGORY = 'CLASS_PT_DETAIL_BOTTOM_CATEGORY';
export const CLASS_PT_DETAIL_POPULARITY = 'CLASS_PT_DETAIL_POPULARITY';
export const CLASS_PT_DETAIL_PRICING = 'CLASS_PT_DETAIL_PRICING';
export const CLASS_PT_DETAIL_SALE_INFO = 'CLASS_PT_DETAIL_SALE_INFO';
export const CLASS_PT_DETAIL_DOMINANT_COLOR = 'CLASS_PT_DETAIL_DOMINANT_COLOR';
export const CLASS_PT_DETAIL_PAIN_POINT = 'CLASS_PT_DETAIL_PAIN_POINT';
export const CLASS_PT_DETAIL_AFTER_FIGURE = 'CLASS_PT_DETAIL_AFTER_FIGURE';
export const CLASS_PT_DETAIL_USER_TARGET = 'CLASS_PT_DETAIL_USER_TARGET';
export const CLASS_PT_DETAIL_CONFIGURATION = 'CLASS_PT_DETAIL_CONFIGURATION';
export const CLASS_PT_DETAIL_CURRICULUMS = 'CLASS_PT_DETAIL_CURRICULUMS';
export const CLASS_PT_DETAIL_FAQ = 'CLASS_PT_DETAIL_FAQ';
export const CLASS_PT_DETAIL_MENTOR = 'CLASS_PT_DETAIL_MENTOR';
export const CLASS_PT_DETAIL_VOD_OFFER = 'CLASS_PT_DETAIL_VOD_OFFER';
export const GET_CLASS_PT_MY_APPLICANT_INFO = 'GET_CLASS_PT_MY_APPLICANT_INFO';
export const GET_CLASS_PT_PAID = 'GET_CLASS_PT_PAID';
export const CLASS_PT_CTA_BUTTON_NAME = 'CLASS_PT_CTA_BUTTON_NAME';
export const CLASS_PT_TOTAL_PRICE = 'CLASS_PT_TOTAL_PRICE';
export const SELECTED_CLASS_TYPE = 'SELECTED_CLASS_TYPE';
export const IS_SELECTED_VOD_TYPE = 'IS_SELECTED_VOD_TYPE';
export const CLASS_PT_DETAIL_WEEK = 'CLASS_PT_DETAIL_WEEK';
export const PT_DETAIL_REFERENCE_ID = 'PT_DETAIL_REFERENCE_ID';
export const IS_AKA_MENTOR_CLASS = 'IS_AKA_MENTOR_CLASS';
export const IS_SHOW_PT_DETAIL_DIFFICULTY_CALLOUT = 'IS_SHOW_PT_DETAIL_DIFFICULTY_CALLOUT';
export const RECRUIT_END_DATE = 'RECRUIT_END_DATE';
export const IS_CLICKABLE_APPLY_BUTTON = 'IS_CLICKABLE_APPLY_BUTTON';
export const IS_B2B_CLASS = 'IS_B2B_CLASS';
export const CLASS_PT_DETAIL_SCHEDULE_LIST = 'CLASS_PT_DETAIL_SCHEDULE_LIST';
export const CLASS_PT_DETAIL_SCHEDULE_LIST_FOR_LIVE = 'CLASS_PT_DETAIL_SCHEDULE_LIST_FOR_LIVE';
export const CLASS_PT_DETAIL_SCHEDULE_LIST_FOR_VOD = 'CLASS_PT_DETAIL_SCHEDULE_LIST_FOR_VOD';
export const CLASS_PT_DETAIL_SCHEDULE_LIST_FOR_VOD_ONLY = 'CLASS_PT_DETAIL_SCHEDULE_LIST_FOR_VOD_ONLY';
export const HAS_VARIOUS_CLASS_TYPE = 'HAS_VARIOUS_CLASS_TYPE';
export const IS_CHANGING_CLASS_SCHEDULE = 'IS_CHANGING_CLASS_SCHEDULE';
export const CLASS_PT_SYLLABUS_LIST = 'CLASS_PT_SYLLABUS_LIST';
export const IS_VOD_LECTURE_CONNECTED = 'IS_VOD_LECTURE_CONNECTED';
export const IS_VOD_LECTURE_SELECTED = 'IS_VOD_LECTURE_SELECTED';
export const CLASS_PT_DETAIL_CURRENT_MENU = 'CLASS_PT_DETAIL_CURRENT_MENU';
export const IS_VOD_ONLY_CLASS = 'IS_VOD_ONLY_CLASS';

// mutations
export const SET_CLASS_PT_DETAIL_ID = 'SET_CLASS_PT_DETAIL_ID';
export const SET_CLASS_PT_DETAIL_INFO = 'SET_CLASS_PT_DETAIL_INFO';
export const SET_CLASS_PT_DETAIL_SCHEDULE_LIST = 'SET_CLASS_PT_DETAIL_SCHEDULE_LIST';
export const SET_CLASS_PT_DETAIL_CONTENT = 'SET_CLASS_PT_DETAIL_CONTENT';
export const SET_CLASS_PT_DETAIL_SALE_INFO = 'SET_CLASS_PT_DETAIL_SALE_INFO';
export const SET_CLASS_PT_RELATION_LIST = 'SET_CLASS_PT_RELATION_LIST';
export const SET_CLASS_PT_MY_APPLICANT_INFO = 'SET_CLASS_PT_MY_APPLICANT_INFO';
export const SET_CHANGING_CLASS_SCHEDULE = 'SET_CHANGING_CLASS_SCHEDULE';
export const SET_CLASS_PT_SYLLABUS_LIST = 'SET_CLASS_PT_SYLLABUS_LIST';
export const SET_CLASS_PT_DETAIL_CURRENT_MENU = 'SET_CLASS_PT_DETAIL_CURRENT_MENU';

// actions
export const UPDATE_CLASS_PT_DETAIL = 'UPDATE_CLASS_PT_DETAIL';
export const FETCH_CLASS_PAID_INFO = 'FETCH_CLASS_PAID_INFO';
export const FETCH_CLASS_PT_DETAIL_INFO = 'FETCH_CLASS_PT_DETAIL_INFO';
export const UPDATE_CURRENT_MENU = 'UPDATE_CURRENT_MENU';

export const state = () => ({
	[classPtDetailId]: null,
	[classPtDetailScheduleList]: [],
	[CLASS_PT_DETAIL_INFO]: {},
	[CLASS_PT_DETAIL_CONTENT]: {},
	[CLASS_PT_RELATION_LIST]: null,
	[CLASS_PT_MY_APPLICANT_INFO]: { paid: false },
	[CLASS_PT_DETAIL_SALE_INFO]: null,
	[isChangingClassSchedule]: false,
	[classPtSyllabusList]: null,
	classPtDetailCurrentMenu: {
		index: -1,
		name: null,
	},
});

export const getters = {
	[CLASS_PT_DETAIL_ID]: state => state[classPtDetailId],
	[CLASS_PT_DETAIL_INFO]: state => state[CLASS_PT_DETAIL_INFO],
	[CLASS_PT_DETAIL_CONTENT]: state => state[CLASS_PT_DETAIL_CONTENT],
	[CLASS_PT_DETAIL_IS_DUMMY]: state => state[CLASS_PT_DETAIL_INFO].isDummy,
	[CLASS_PT_DETAIL_IS_CLOSED]: state => state[CLASS_PT_DETAIL_INFO].progress !== '모집중',
	[CLASS_PT_DETAIL_CATEGORY]: state => {
		return {
			industry: state[CLASS_PT_DETAIL_INFO].industry,
			bottomCategory: state[CLASS_PT_DETAIL_INFO].bottomCategory,
			midCategory: state[CLASS_PT_DETAIL_INFO].midCategory,
			topCategory: state[CLASS_PT_DETAIL_INFO].topCategory,
		};
	},
	[CLASS_PT_DETAIL_TOP_CATEGORY]: (_, getters) => {
		return get(getters[CLASS_PT_DETAIL_CATEGORY], 'topCategory', '');
	},
	[CLASS_PT_DETAIL_MID_CATEGORY]: (_, getters) => {
		return get(getters[CLASS_PT_DETAIL_CATEGORY], 'midCategory', '');
	},
	[CLASS_PT_DETAIL_BOTTOM_CATEGORY]: (_, getters) => {
		return get(getters[CLASS_PT_DETAIL_CATEGORY], 'bottomCategory', '');
	},
	[CLASS_PT_DETAIL_POPULARITY]: (_, getters) => {
		return getPopularity(getters[CLASS_PT_DETAIL_INFO]);
	},
	[CLASS_PT_CTA_BUTTON_NAME]: state => state[CLASS_PT_DETAIL_INFO].ctaButtonName,
	[CLASS_PT_DETAIL_PRICING]: state => state[CLASS_PT_DETAIL_INFO].pricing,
	[CLASS_PT_DETAIL_DOMINANT_COLOR]: state => state[CLASS_PT_DETAIL_INFO].dominantColor,
	[CLASS_PT_DETAIL_MENTOR]: state => {
		return {
			mentorData: state[CLASS_PT_DETAIL_INFO].mentorData,
			mentor: state[CLASS_PT_DETAIL_INFO].mentor,
		};
	},
	[CLASS_PT_DETAIL_PAIN_POINT]: state => state[CLASS_PT_DETAIL_CONTENT].painPoint || {},
	[CLASS_PT_DETAIL_AFTER_FIGURE]: state => state[CLASS_PT_DETAIL_CONTENT].afterFigure || {},
	[CLASS_PT_DETAIL_USER_TARGET]: state => state[CLASS_PT_DETAIL_CONTENT].userTarget || {},
	[CLASS_PT_DETAIL_CONFIGURATION]: state => state[CLASS_PT_DETAIL_CONTENT].configuration || {},
	[CLASS_PT_DETAIL_CURRICULUMS]: state => state[CLASS_PT_DETAIL_CONTENT].curriculums || {},
	[CLASS_PT_DETAIL_FAQ]: state => state[CLASS_PT_DETAIL_CONTENT].faqData || {},
	[CLASS_PT_DETAIL_SALE_INFO]: state => state[CLASS_PT_DETAIL_SALE_INFO],
	[CLASS_PT_DETAIL_VOD_OFFER]: state => {
		const isVodOffer = state[CLASS_PT_DETAIL_INFO].classChips.find(chip => chip.title === '복습VOD');
		return Boolean(isVodOffer);
	},
	[CLASS_PT_RELATION_LIST]: state => state[CLASS_PT_RELATION_LIST],
	[GET_CLASS_PT_MY_APPLICANT_INFO]: state => state[CLASS_PT_MY_APPLICANT_INFO],
	[GET_CLASS_PT_PAID]: (_, getters) => {
		return getters[GET_CLASS_PT_MY_APPLICANT_INFO].paid;
	},
	[CLASS_PT_TOTAL_PRICE]: state => {
		return state[CLASS_PT_DETAIL_INFO].calculatePrice.price;
	},
	[SELECTED_CLASS_TYPE]: (_, getters) => {
		const isVod = get(getters[CLASS_PT_DETAIL_INFO], 'isVod', 0);
		return [CLASS_TYPE.LIVE, CLASS_TYPE.VOD, CLASS_TYPE.VOD_ONLY][isVod];
	},
	[IS_SELECTED_VOD_TYPE]: (_, getters) => getters[SELECTED_CLASS_TYPE] === CLASS_TYPE.VOD,
	[CLASS_PT_DETAIL_WEEK]: (_, getters) => get(getters[CLASS_PT_DETAIL_INFO], 'period', 6),
	[PT_DETAIL_REFERENCE_ID]: (_, getters) => get(getters[CLASS_PT_DETAIL_INFO], 'referenceId', 0),
	[IS_AKA_MENTOR_CLASS]: (_, getters) => getters[CLASS_PT_DETAIL_ID] === 368,
	[IS_SHOW_PT_DETAIL_DIFFICULTY_CALLOUT]: state => {
		return (
			state[CLASS_PT_DETAIL_INFO].difficulty &&
			state[CLASS_PT_DETAIL_INFO].difficulty.applicant &&
			state[CLASS_PT_DETAIL_INFO].difficulty.class
		);
	},
	[RECRUIT_END_DATE]: (_, getters) => getters[CLASS_PT_DETAIL_INFO]?.recruitEndDate,
	[IS_CLICKABLE_APPLY_BUTTON]: (_, getters) => !(getters[CLASS_PT_DETAIL_IS_CLOSED] || getters[GET_CLASS_PT_PAID]),
	[IS_B2B_CLASS]: (_, getters) => getters[CLASS_PT_DETAIL_INFO].type === 'B',
	[CLASS_PT_DETAIL_SCHEDULE_LIST]: state => state[classPtDetailScheduleList],
	[CLASS_PT_DETAIL_SCHEDULE_LIST_FOR_LIVE]: (_, getters) =>
		getters[CLASS_PT_DETAIL_SCHEDULE_LIST].filter(item => item.isVod === 0),
	[CLASS_PT_DETAIL_SCHEDULE_LIST_FOR_VOD]: (_, getters) =>
		getters[CLASS_PT_DETAIL_SCHEDULE_LIST].filter(item => item.isVod === 1),
	[CLASS_PT_DETAIL_SCHEDULE_LIST_FOR_VOD_ONLY]: (_, getters) =>
		getters[CLASS_PT_DETAIL_SCHEDULE_LIST].filter(item => item.isVod === 2),
	[HAS_VARIOUS_CLASS_TYPE]: (_, getters) =>
		getters[CLASS_PT_DETAIL_SCHEDULE_LIST_FOR_LIVE]?.length &&
		getters[CLASS_PT_DETAIL_SCHEDULE_LIST_FOR_VOD]?.length,
	[IS_CHANGING_CLASS_SCHEDULE]: state => state[isChangingClassSchedule],
	[CLASS_PT_SYLLABUS_LIST]: state => state[classPtSyllabusList],
	[IS_VOD_LECTURE_CONNECTED]: (_, getters) => {
		// isVod === 2면서 vodLecture 주소값을 가지고 있으면 실무VOD와 연결
		const vodClass = getters[CLASS_PT_DETAIL_SCHEDULE_LIST_FOR_VOD_ONLY][0];
		return !!vodClass?.vodLecture;
	},
	[IS_VOD_LECTURE_SELECTED]: state =>
		!!state[CLASS_PT_DETAIL_INFO].vodLecture && state[CLASS_PT_DETAIL_INFO].isVod === 2,
	[CLASS_PT_DETAIL_CURRENT_MENU]: state => state.classPtDetailCurrentMenu,
	[IS_VOD_ONLY_CLASS]: (_, getters) => getters[SELECTED_CLASS_TYPE] === CLASS_TYPE.VOD_ONLY,
};

export const mutations = {
	SET_CLASS_PT_DETAIL_ID: (state, payload) => {
		state[classPtDetailId] = payload;
	},
	SET_CLASS_PT_DETAIL_SCHEDULE_LIST: (state, payload) => {
		state[classPtDetailScheduleList] = payload;
	},
	SET_CLASS_PT_DETAIL_INFO: (state, payload) => {
		state[CLASS_PT_DETAIL_INFO] = {
			...payload,
			vodLecture: getUrlWithoutHost(payload.vodLecture),
		};
	},
	SET_CLASS_PT_DETAIL_CONTENT: (state, payload) => {
		state[CLASS_PT_DETAIL_CONTENT] = payload;
	},
	SET_CLASS_PT_DETAIL_SALE_INFO: (state, payload) => {
		state[CLASS_PT_DETAIL_SALE_INFO] = payload;
	},
	SET_CLASS_PT_RELATION_LIST: (state, payload) => {
		state[CLASS_PT_RELATION_LIST] = payload;
	},
	[SET_CLASS_PT_MY_APPLICANT_INFO]: (state, payload) => {
		state[CLASS_PT_MY_APPLICANT_INFO] = payload;
	},
	[SET_CHANGING_CLASS_SCHEDULE]: (state, payload) => {
		state[isChangingClassSchedule] = payload;
	},
	[SET_CLASS_PT_SYLLABUS_LIST]: (state, payload) => {
		state[classPtSyllabusList] = payload;
	},
	[SET_CLASS_PT_DETAIL_CURRENT_MENU]: (state, payload) => (state.classPtDetailCurrentMenu = payload),
};

export const actions = {
	async [UPDATE_CLASS_PT_DETAIL](
		{ commit, getters, dispatch },
		{ class_id, pricing, recruitEndDate, calculatePrice, personLimit, vacancy, isVod, vodLecture, progress },
	) {
		commit(SET_CHANGING_CLASS_SCHEDULE, true);
		await Promise.all([
			dispatch(FETCH_CLASS_PT_DETAIL_INFO, { class_id, type: isVod === 2 ? 'vod' : 'pt' }),
			dispatch(FETCH_CLASS_PAID_INFO, class_id),
		]);
		commit(
			SET_CLASS_PT_DETAIL_INFO,
			mergeDefinedValue(getters[CLASS_PT_DETAIL_INFO], {
				pricing,
				recruitEndDate,
				calculatePrice,
				personLimit,
				vacancy,
				isVod,
				vodLecture: getUrlWithoutHost(vodLecture),
				progress,
			}),
		);
		commit(SET_CLASS_PT_DETAIL_ID, class_id);
		commit(SET_CHANGING_CLASS_SCHEDULE, false);
	},
	/**
	 *
	 * @param {*} param0
	 * @param {Object} payload payload
	 * @param {string} payload.title 클래스 제목
	 * @param {string} payload.class_id 클래스 ID
	 */
	async [FETCH_CLASS_PAID_INFO]({ commit, rootGetters }, classId) {
		try {
			if (!rootGetters.isUser) {
				return;
			}

			const { data } = await this.$api.classPt.getUserPurchaseInfo(classId);
			commit(SET_CLASS_PT_MY_APPLICANT_INFO, data);
		} catch (error) {
			console.error('FETCH_CLASS_PAID_INFO error: ', error);
			commit(SET_CLASS_PT_MY_APPLICANT_INFO, {});
		}
	},
	async [FETCH_CLASS_PT_DETAIL_INFO]({ commit }, { class_id, type }) {
		try {
			const ptDetailContentParams = {
				class_id,
				type,
			};
			const { data } = await this.$api.classPt.postClassPtDetailContent(ptDetailContentParams);
			commit(SET_CLASS_PT_DETAIL_CONTENT, data);
		} catch (error) {
			console.error('FETCH_CLASS_PT_DETAIL_INFO error: ', error);
		}
	},
	[UPDATE_CURRENT_MENU]({ commit }, currentMenu) {
		commit(SET_CLASS_PT_DETAIL_CURRENT_MENU, currentMenu);
	},
};
