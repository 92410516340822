import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'vod/player/lecture/dashboard';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfVodPlayerLectureDashboard,
	mapMutations as mapMutationsOfVodPlayerLectureDashboard,
	mapActions as mapActionsOfVodPlayerLectureDashboard,
};

export const LECTURE_VOD_DASHBOARD = 'LECTURE_VOD_DASHBOARD';
export const COURSE_COMPLETION_RANKING = 'COURSE_COMPLETION_RANKING';
export const LECTURE_VOD_EVALUATIONS = 'LECTURE_VOD_EVALUATIONS';
export const REIMBURSEMENT_MISSIONS = 'REIMBURSEMENT_MISSIONS';
export const HAS_LECTURE_VOD_EVALUATIONS = 'HAS_LECTURE_VOD_EVALUATIONS';

export const SET_LECTURE_VOD_DASHBOARD = 'SET_LECTURE_VOD_DASHBOARD';

export const LOAD_LECTURE_VOD_DASHBOARD = 'LOAD_LECTURE_VOD_DASHBOARD';

export const state = () => ({
	[LECTURE_VOD_DASHBOARD]: null,
});

export const getters = {
	[LECTURE_VOD_DASHBOARD]: state => state[LECTURE_VOD_DASHBOARD],
	[COURSE_COMPLETION_RANKING]: state => state[LECTURE_VOD_DASHBOARD]?.courseCompletionRanking || 0,
	[LECTURE_VOD_EVALUATIONS]: state => state[LECTURE_VOD_DASHBOARD]?.evaluations || {},
	[REIMBURSEMENT_MISSIONS]: state => state[LECTURE_VOD_DASHBOARD]?.reimbursementMissions || {},
	[HAS_LECTURE_VOD_EVALUATIONS]: (_state, getters) => Object.keys(getters[LECTURE_VOD_EVALUATIONS]).length > 0,
};

export const mutations = {
	[SET_LECTURE_VOD_DASHBOARD]: (state, payload) => (state[LECTURE_VOD_DASHBOARD] = payload),
};

export const actions = {
	async [LOAD_LECTURE_VOD_DASHBOARD]({ _state, commit }, lectureId) {
		try {
			const { data } = await this.$api.vodLecture.getLectureVodDashboard(lectureId);
			commit(SET_LECTURE_VOD_DASHBOARD, data);
		} catch (error) {
			console.error('LOAD_LECTURE_VOD_DASHBOARD error: ', error);
		}
	},
};
