import { createNamespacedHelpers } from 'vuex';
const NAMESPACE = 'class/pt/dashboard';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfClassPtDashboard,
	mapMutations as mapMutationsOfClassPtDashboard,
	mapActions as mapActionsOfClassPtDashboard,
};

export const CLASS = 'CLASS';
export const CLASSES = 'CLASSES';
export const CLASS_OPTIONS = 'CLASS_OPTIONS';
export const APPLICANTS = 'APPLICANTS';
export const APPLICANTS_FOR_CLASS = 'APPLICANTS_FOR_CLASS';

export const SET_CLASS = 'SET_CLASS';
export const SET_CLASSES = 'SET_CLASSES';
export const SET_APPLICANTS = 'SET_APPLICANTS';
export const SET_APPLICANTS_FOR_CLASS = 'SET_APPLICANTS_FOR_CLASS';

export const LOAD_APPLICANTS_FOR_CLASS = 'LOAD_APPLICANTS_FOR_CLASS';

export const state = () => ({
	[CLASS]: null,
	[CLASSES]: null,
	[APPLICANTS]: null,
	[APPLICANTS_FOR_CLASS]: null,
});

export const getters = {
	[CLASS]: state => state[CLASS],
	[CLASSES]: state => state[CLASSES],
	[APPLICANTS]: state => state[APPLICANTS],
	[CLASS_OPTIONS]: state =>
		state[CLASSES].map(({ title, id }) => {
			return { label: title, value: id };
		}),
	[APPLICANTS_FOR_CLASS]: state => state[APPLICANTS_FOR_CLASS],
};

export const mutations = {
	[SET_CLASS]: (state, payload) => {
		state[CLASS] = payload;
	},
	[SET_CLASSES]: (state, payload) => {
		state[CLASSES] = payload;
	},
	[SET_APPLICANTS]: (state, payload) => {
		state[APPLICANTS] = payload;
	},
	[SET_APPLICANTS_FOR_CLASS]: (state, payload) => {
		state[APPLICANTS_FOR_CLASS] = payload;
	},
};

export const actions = {
	[LOAD_APPLICANTS_FOR_CLASS]({ commit, getters }, params) {
		const applicantsForClass = getters[APPLICANTS].filter(({ id }) => id === params.id);
		commit(SET_APPLICANTS_FOR_CLASS, applicantsForClass);
	},
};
