import _uniqBy from 'lodash/uniqBy';
import { createNamespacedHelpers } from 'vuex';

import {
	CLASS_ON_AIR_LIST_PATH,
	CASE_PATH,
	ANSWER_PATH,
	EDU_ALUMNI_PATH,
	CAREER_EXTERNSHIP_PATH,
} from '@/constants/paths';

import { TIMELINE, CASE_CATEGORY_WORKING_LEVEL_SKILL } from '@/plugins/constants';
import dayjs from '@/plugins/dayjs';

const NAMESPACE = 'layout';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export { mapGetters as mapGettersOfLayout, mapMutations as mapMutationsOfLayout, mapActions as mapActionsOfLayout };

// state
export const IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT = 'IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT';
export const TOP_BAR_BUTTONS = 'TOP_BAR_BUTTONS';

// getters
export const BACKWARD_BUTTON_MARGIN_BOTTOM = 'BACKWARD_BUTTON_MARGIN_BOTTOM';
export const IS_SHOW_HEADER = 'IS_SHOW_HEADER';
export const IS_SHOW_SUB_HEADER = 'IS_SHOW_SUB_HEADER';
export const IS_SHOW_FOOTER = 'IS_SHOW_FOOTER';
export const HAS_FOOTER_MARGIN = 'HAS_FOOTER_MARGIN';
export const IS_SHOW_BOTTOM_NAV = 'IS_SHOW_BOTTOM_NAV';
export const IS_MOBILE = 'IS_MOBILE';
export const IS_PC = 'IS_PC';
export const USER_AGENT = 'USER_AGENT';
export const IS_LOGO_TOP_BAR = 'IS_LOGO_TOP_BAR';
export const SUB_HEADER_EDU_COUNT = 'SUB_HEADER_EDU_COUNT';
export const SUB_HEADER_INDEX = 'SUB_HEADER_INDEX';
export const SET_SUB_HEADER_LIST = 'SET_SUB_HEADER_LIST';
export const SUB_HEADER_LIST = 'SUB_HEADER_LIST';
export const IS_LOADED_SUB_HEADER_LIST = 'IS_LOADED_SUB_HEADER_LIST';
export const IS_SHOW_ALERT_LAYOUT = 'IS_SHOW_ALERT_LAYOUT';
export const IS_SHOW_GLOBAL_NOTICE_ALERT = 'IS_SHOW_GLOBAL_NOTICE_ALERT';
export const GET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT = 'GET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT';
export const GLOBAL_NOTICE_INFO = 'GLOBAL_NOTICE_INFO';
export const IS_SHOW_KPC_HEADER = 'IS_SHOW_KPC_HEADER';
export const IS_OPEN_NOTI_DRAWER = 'IS_OPEN_NOTI_DRAWER'; // CaseListSideBox 말풍선 z-index 조정때문에 필요함
export const IS_SHOW_SEARCH_DROPDOWN = 'IS_SHOW_SEARCH_DROPDOWN'; // CaseListSideBox 말풍선 z-index 조정때문에 필요함
// 현재 헤더부분이 투명인지
export const IS_TRANSPARENT_TYPE = 'IS_TRANSPARENT_TYPE';
// 랜딩했을 때 헤더부분이 원래 투명이어야 하는지
export const IS_TRANSPARENT_TYPE_PAGE = 'IS_TRANSPARENT_TYPE_PAGE';
export const IS_STICKY_ENABLED = 'IS_STICKY_ENABLED';
export const IS_FOOTER_SLIM = 'IS_FOOTER_SLIM';

// mutations
export const SET_BACKWARD_BUTTON_MARGIN_BOTTOM = 'SET_BACKWARD_BUTTON_MARGIN_BOTTOM';
export const SET_IS_SHOW_HEADER = 'SET_IS_SHOW_HEADER';
export const SET_IS_SHOW_SUB_HEADER = 'SET_IS_SHOW_SUB_HEADER';
export const SET_IS_SHOW_FOOTER = 'SET_IS_SHOW_FOOTER';
export const SET_HAS_FOOTER_MARGIN = 'SET_HAS_FOOTER_MARGIN';
export const SET_IS_SHOW_BOTTOM_NAV = 'SET_IS_SHOW_BOTTOM_NAV';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_USER_AGENT = 'SET_USER_AGENT';
export const SET_IS_LOGO_TOP_BAR = 'SET_IS_LOGO_TOP_BAR';
export const SET_SUB_HEADER_EDU_COUNT = 'SET_SUB_HEADER_EDU_COUNT';
export const SET_SUB_HEADER_INDEX = 'SET_SUB_HEADER_INDEX';
export const SET_IS_LOADED_SUB_HEADER_LIST = 'SET_IS_LOADED_SUB_HEADER_LIST';
export const SET_IS_SHOW_GLOBAL_NOTICE_ALERT = 'SET_IS_SHOW_GLOBAL_NOTICE_ALERT';
export const SET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT = 'SET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT';
export const SET_GLOBAL_NOTICE_INFO = 'SET_GLOBAL_NOTICE_INFO';
export const SET_IS_SHOW_KPC_HEADER = 'SET_IS_SHOW_KPC_HEADER';
export const SET_IS_TRANSPARENT_TYPE = 'SET_IS_TRANSPARENT_TYPE';
export const SET_IS_TRANSPARENT_TYPE_PAGE = 'SET_IS_TRANSPARENT_TYPE_PAGE';
export const SET_IS_OPEN_NOTI_DRAWER = 'SET_IS_OPEN_NOTI_DRAWER';
export const SET_IS_SHOW_SEARCH_DROPDOWN = 'SET_IS_SHOW_SEARCH_DROPDOWN';
export const SET_STICKY_ENABLED = 'SET_STICKY_ENABLED';
export const SET_FOOTER_SLIM = 'SET_FOOTER_SLIM';
export const SET_TOP_BAR_BUTTONS = 'SET_TOP_BAR_BUTTONS';

// actions
export const LOAD_CASE_SUB_HEADER = 'LOAD_CASE_SUB_HEADER';
export const LOAD_SUB_HEADER = 'LOAD_SUB_HEADER';
export const LOAD_EDU_COUNT = 'LOAD_EDU_COUNT';
export const HANDLE_SET_IS_TRANSPARENT = 'HANDLE_SET_IS_TRANSPARENT';

export const state = () => ({
	views: {
		[BACKWARD_BUTTON_MARGIN_BOTTOM]: '16px',
		[IS_SHOW_HEADER]: true,
		[IS_SHOW_SUB_HEADER]: false,
		[IS_SHOW_FOOTER]: true, // footer(pc, mobile)
		[HAS_FOOTER_MARGIN]: true,
		[IS_SHOW_BOTTOM_NAV]: true, // bottomNav(only mobile)
		[IS_LOGO_TOP_BAR]: false,
		[SUB_HEADER_LIST]: [],
		[SUB_HEADER_EDU_COUNT]: 0,
		[IS_SHOW_GLOBAL_NOTICE_ALERT]: false,
		[IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT]: false,
		[SET_GLOBAL_NOTICE_INFO]: null,
		[IS_SHOW_KPC_HEADER]: false,
		[IS_TRANSPARENT_TYPE]: false,
		[IS_TRANSPARENT_TYPE_PAGE]: false,
		[IS_OPEN_NOTI_DRAWER]: false,
		[IS_SHOW_SEARCH_DROPDOWN]: false,
		[IS_STICKY_ENABLED]: false,
		[IS_FOOTER_SLIM]: false,
	},
	[IS_MOBILE]: false,
	[USER_AGENT]: '',
	[SUB_HEADER_INDEX]: 0,
	[IS_LOADED_SUB_HEADER_LIST]: false,
	[TOP_BAR_BUTTONS]: null,
});

export const getters = {
	BACKWARD_BUTTON_MARGIN_BOTTOM: state => {
		return state.views[BACKWARD_BUTTON_MARGIN_BOTTOM];
	},
	IS_SHOW_HEADER: state => {
		return state.views[IS_SHOW_HEADER];
	},
	IS_SHOW_SUB_HEADER: state => {
		return state.views[IS_SHOW_SUB_HEADER];
	},
	IS_SHOW_FOOTER: state => {
		return state.views[IS_SHOW_FOOTER];
	},
	HAS_FOOTER_MARGIN: state => {
		return state.views[HAS_FOOTER_MARGIN];
	},
	IS_SHOW_BOTTOM_NAV: state => {
		return state.views[IS_SHOW_BOTTOM_NAV];
	},
	GET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT: state => {
		return state.views[IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT];
	},
	IS_SHOW_GLOBAL_NOTICE_ALERT: state => {
		return state.views[IS_SHOW_GLOBAL_NOTICE_ALERT];
	},
	GLOBAL_NOTICE_INFO: state => {
		return state.views[GLOBAL_NOTICE_INFO];
	},
	IS_SHOW_KPC_HEADER: state => {
		return state.views[IS_SHOW_KPC_HEADER];
	},
	IS_SHOW_ALERT_LAYOUT: (_state, getters) => {
		return (
			getters[IS_SHOW_GLOBAL_NOTICE_ALERT] ||
			getters[IS_SHOW_KPC_HEADER] ||
			getters[GET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT]
		);
	},
	IS_MOBILE: state => {
		return state[IS_MOBILE];
	},
	IS_PC: state => {
		return !state[IS_MOBILE];
	},
	USER_AGENT: state => {
		return state[USER_AGENT];
	},
	IS_LOGO_TOP_BAR: state => {
		return state[IS_LOGO_TOP_BAR];
	},
	SUB_HEADER_EDU_COUNT: state => {
		return state.views[SUB_HEADER_EDU_COUNT];
	},
	SUB_HEADER_INDEX: state => {
		return state[SUB_HEADER_INDEX];
	},
	SUB_HEADER_LIST: state => {
		return state.views[SUB_HEADER_LIST];
	},
	IS_LOADED_SUB_HEADER_LIST: state => {
		return state[IS_LOADED_SUB_HEADER_LIST];
	},
	IS_TRANSPARENT_TYPE: state => {
		return state.views[IS_TRANSPARENT_TYPE];
	},
	IS_TRANSPARENT_TYPE_PAGE: state => {
		return state.views[IS_TRANSPARENT_TYPE_PAGE];
	},
	IS_OPEN_NOTI_DRAWER: state => {
		return state.views[IS_OPEN_NOTI_DRAWER];
	},
	IS_SHOW_SEARCH_DROPDOWN: state => {
		return state.views[IS_SHOW_SEARCH_DROPDOWN];
	},
	IS_STICKY_ENABLED: state => state.views[IS_STICKY_ENABLED],
	IS_FOOTER_SLIM: state => state[IS_FOOTER_SLIM],
	TOP_BAR_BUTTONS: state => state[TOP_BAR_BUTTONS],
};

export const mutations = {
	SET_BACKWARD_BUTTON_MARGIN_BOTTOM: (state, payload) => {
		state.views[BACKWARD_BUTTON_MARGIN_BOTTOM] = payload;
	},
	SET_IS_SHOW_HEADER: (state, payload) => {
		state.views[IS_SHOW_HEADER] = payload;
	},
	SET_IS_SHOW_SUB_HEADER: (state, payload) => {
		state.views[IS_SHOW_SUB_HEADER] = payload;
	},
	SET_IS_SHOW_FOOTER: (state, payload) => {
		state.views[IS_SHOW_FOOTER] = payload;
	},
	SET_HAS_FOOTER_MARGIN: (state, payload) => {
		state.views[HAS_FOOTER_MARGIN] = payload;
	},
	SET_IS_SHOW_BOTTOM_NAV: (state, payload) => {
		state.views[IS_SHOW_BOTTOM_NAV] = payload;
	},
	SET_IS_SHOW_GLOBAL_NOTICE_ALERT: (state, payload) => {
		state.views[IS_SHOW_GLOBAL_NOTICE_ALERT] = payload;
	},
	SET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT: (state, payload) => {
		state.views[IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT] = payload;
	},
	SET_GLOBAL_NOTICE_INFO: (state, payload) => {
		state.views[GLOBAL_NOTICE_INFO] = payload;
	},
	SET_IS_SHOW_KPC_HEADER: (state, payload) => {
		state.views[IS_SHOW_KPC_HEADER] = payload;
	},
	SET_IS_MOBILE: (state, payload) => {
		state[IS_MOBILE] = payload;
	},
	SET_USER_AGENT: (state, payload) => {
		state[USER_AGENT] = payload;
	},
	SET_IS_LOGO_TOP_BAR: (state, payload) => {
		state[IS_LOGO_TOP_BAR] = payload;
	},
	SET_SUB_HEADER_EDU_COUNT: (state, payload) => {
		state[SUB_HEADER_EDU_COUNT] = payload;
	},
	SET_SUB_HEADER_INDEX: (state, payload) => {
		state[SUB_HEADER_INDEX] = payload;
	},
	SET_SUB_HEADER_LIST: (state, payload) => {
		state.views[SUB_HEADER_LIST] = payload;
	},
	SET_IS_LOADED_SUB_HEADER_LIST: (state, payload) => {
		state[IS_LOADED_SUB_HEADER_LIST] = payload;
	},
	SET_IS_TRANSPARENT_TYPE: (state, payload) => {
		state.views[IS_TRANSPARENT_TYPE] = payload;
	},
	SET_IS_TRANSPARENT_TYPE_PAGE: (state, payload) => {
		state.views[IS_TRANSPARENT_TYPE_PAGE] = payload;
	},
	SET_IS_OPEN_NOTI_DRAWER: (state, payload) => {
		state.views[IS_OPEN_NOTI_DRAWER] = payload;
	},
	SET_IS_SHOW_SEARCH_DROPDOWN: (state, payload) => {
		state.views[IS_SHOW_SEARCH_DROPDOWN] = payload;
	},
	SET_STICKY_ENABLED: (state, payload) => (state.views[IS_STICKY_ENABLED] = payload),
	SET_FOOTER_SLIM: (state, payload) => (state[IS_FOOTER_SLIM] = payload),
	SET_TOP_BAR_BUTTONS: (state, payload) => (state[TOP_BAR_BUTTONS] = payload),
};

export const actions = {
	async [LOAD_CASE_SUB_HEADER]({ commit }) {
		try {
			const { data } = await this.$api.case.getCaseSubHeader();
			const caseMenu = data.map(menu => {
				menu.url = `${CASE_PATH}?category=${menu.name}`;
				return menu;
			});
			const tempCaseSubHeader = _uniqBy(
				[{ name: TIMELINE, url: CASE_PATH }, ...caseMenu, { name: '답변하기', url: ANSWER_PATH, id: 6 }],
				'name',
			);
			tempCaseSubHeader.map(menu => {
				menu.new = menu.name === CASE_CATEGORY_WORKING_LEVEL_SKILL;
				return menu;
			});

			commit(SET_SUB_HEADER_LIST, tempCaseSubHeader);
		} catch (error) {
			console.error('LOAD_CASE_SUB_HEADER error:', error);
		}
	},
	async [LOAD_EDU_COUNT]({ commit }) {
		try {
			const {
				data: { code, count },
			} = await this.$axios.get('/api/edu/current_count');

			if (code === 200) {
				commit(SET_SUB_HEADER_EDU_COUNT, count);
			}
		} catch (error) {
			console.error('LOAD_EDU_COUNT error:', error);
		}
	},
	async [LOAD_SUB_HEADER]({ dispatch, commit, getters }, params) {
		const removeOnAirMenu = dayjs().isAfter(dayjs('2022-02-10')); // 2022.02.10 이후로 onair 삭제
		commit(SET_IS_LOADED_SUB_HEADER_LIST, false);
		switch (params) {
			case 'community':
				await dispatch(LOAD_CASE_SUB_HEADER);
				break;
			case 'edu': {
				const eduSubHeaderList = [
					{ name: '홈', url: '/edu', nuxt: true },
					{ name: getters[IS_MOBILE] ? '모집 캠프' : '모집 중인 캠프', url: '/edu/list', nuxt: true },
					// { name: '실시간 후기', url: '/edu/review', caption: true },
					{ name: '채용연계과정', url: CAREER_EXTERNSHIP_PATH, nuxt: true },
					{ name: '진로부트캠프', url: '/edu/path/list', nuxt: true },
					{ name: '취업준비', url: CLASS_ON_AIR_LIST_PATH, nuxt: true },
					{ name: '수료생 공간', url: EDU_ALUMNI_PATH, nuxt: true },
				];
				if (removeOnAirMenu) {
					// 2022.02.10 이후로 onair(취업준비) 삭제
					commit(
						SET_SUB_HEADER_LIST,
						eduSubHeaderList.filter(menu => menu.name !== '취업준비'),
					);
				} else {
					commit(SET_SUB_HEADER_LIST, eduSubHeaderList);
				}
				// dispatch(LOAD_EDU_COUNT);
				break;
			}
			case 'pt': {
				const ptSubHeaderList = [
					{ name: '홈', url: '/class/pt', nuxt: true },
					{ name: '모든 클래스', url: '/class/pt/list', nuxt: true },
				];
				commit(SET_SUB_HEADER_LIST, ptSubHeaderList);
				break;
			}
			default:
				break;
		}
		commit(SET_IS_LOADED_SUB_HEADER_LIST, true);
	},
	[HANDLE_SET_IS_TRANSPARENT]({ commit }, payload) {
		commit(SET_IS_TRANSPARENT_TYPE, payload);
		commit(SET_IS_TRANSPARENT_TYPE_PAGE, payload);
	},
};
