export const getPopularity = info => {
	switch (info?.popularity) {
		case 0:
			return '미정';
		case 1:
			return '인기';
		case 2:
			return '비인기';
		default:
			return '';
	}
};
