import { freeScheduleMap } from '@/plugins/constants';
import dayjs, { getTimeInAsia } from '@/plugins/dayjs';
import { formatDate } from '@/plugins/filters/formatDate';

export function getEncoreCampTitle(title = '', round = 0) {
	let campTitle = title;
	if (round > 1) {
		campTitle = `[${round}차 앵콜] ${campTitle}`;
	}
	return campTitle;
}

export function getReviewText(score) {
	switch (parseInt(score, 10)) {
		case 5:
			return '매우 추천해요!';
		case 4:
			return '추천해요!';
		case 3:
			return '보통이에요!';
		case 2:
			return '별로에요!';
		case 1:
			return '매우 별로에요!';
	}
}

export function reviewRandomElement(feedback, type) {
	const name = [
		'윤',
		'정',
		'이',
		'김',
		'권',
		'박',
		'황',
		'최',
		'안',
		'유',
		'송',
		'백',
		'채',
		'장',
		'홍',
		'강',
		'배',
		'오',
		'임',
		'천',
		'조',
	];
	const index = (feedback.length / 10).toFixed(1).toString().split('.')[1];
	if (type === 'name') {
		return `${name[index]}**`;
	}
}

export function getAssignmentText(week, isFreeSchedule) {
	if (isFreeSchedule) {
		if (week === 1) {
			// 진로캠프는 자율일정으로 들어가고 주차는 1로 고정, 1번만 과제를 제출
			return `과제`;
		} else {
			const numberToString = {
				1: '첫',
				2: '두',
				3: '세',
				4: '네',
			};

			// 자율일정 과제는 2~5주차라 1씩 뺌
			return `${numberToString[week - 1]} 번째`;
		}
	}
	return `${week}차`;
}

export function filterCamps(camps) {
	return Array.isArray(camps) ? camps.filter(camp => camp.running !== 0) : [];
}

export const checkIsFreeScheduledDate = date => dayjs(date).year() === 9999;

export const formatStartDate = date => {
	if (checkIsFreeScheduledDate(date)) {
		return freeScheduleMap.free_schedule;
	}

	const formattedDate = formatDate(date, 'M월 D일');

	if (formattedDate === date) {
		return date;
	} else {
		return `${formattedDate}부터`;
	}
};

export const isJB096Trigger = () => {
	// const today = getTimeInAsia();
	// const changeDate = dayjs('2024-07-11 04:00');
	// return today.isAfter(changeDate);

	const startDate = dayjs('2024-07-11 04:00');
	const endDate = dayjs('2024-10-18 03:59:59');
	const currentDate = getTimeInAsia();

	return currentDate.isBetween(startDate, endDate, null, '[]');
};

export const eduSalePercent = () => {
	return isJB096Trigger() ? 37 : 41;
};

export const isEarlybird = () => {
	return getTimeInAsia().get('day') === 3;
};

export const isEarlybirdVariation = () => {
	const thisWednesdayAt15 = getTimeInAsia().day(3).hour(15).minute(0).second(0);
	const thisThursdayAt0 = thisWednesdayAt15.add(1, 'day').hour(0).minute(0).second(0);
	// 현재 시간이 수요일 15:00 이후이며 목요일 00:00 이전이라면 true를 반환
	return (
		getTimeInAsia().isSameOrAfter(thisWednesdayAt15, 'hour') &&
		getTimeInAsia().isSameOrBefore(thisThursdayAt0, 'hour')
	);
};

/**
 * 프로덕션이 아니면서 화요일 또는 수요일인지 확인(직부캠 타임어택 배너 확인용)
 * @returns {boolean}
 */
export const isNotProductionAndTuesdayOrWednesday = () => {
	if (process.env.NODE_ENV !== 'production') {
		const dayOfWeek = getTimeInAsia().day();
		return dayOfWeek === 2 || dayOfWeek === 3;
	}
	return false;
};

/**
 * 직부캠 클래스룸 과제제출페이지에서 질문 유도하는 실험
 * @returns {boolean}
 */
export const isExperimentAtEduClassroomSubmitPage = submittedDate => {
	const targetDate = dayjs('2023-09-15');
	if (submittedDate) {
		return dayjs(submittedDate).isSame(targetDate, 'day');
	}
	return getTimeInAsia().isSame(targetDate, 'day');
};
