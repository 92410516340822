import { createNamespacedHelpers } from 'vuex';
const NAMESPACE = 'stateForApp';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfStateForApp,
	mapMutations as mapMutationsOfStateForApp,
	mapActions as mapActionsOfStateForApp,
};

// state
export const _SELECTED_CASE_QUESTION = '_SELECTED_CASE_QUESTION';

// getters
export const SELECTED_CASE_QUESTION = 'SELECTED_CASE_QUESTION';

// mutations
export const SET_SELECTED_CASE_QUESTION = 'SET_SELECTED_CASE_QUESTION';

export const state = () => ({
	[_SELECTED_CASE_QUESTION]: null,
});
export const getters = {
	SELECTED_CASE_QUESTION: state => state[_SELECTED_CASE_QUESTION],
};
export const mutations = {
	SET_SELECTED_CASE_QUESTION: (state, payload) => {
		state[_SELECTED_CASE_QUESTION] = payload;
	},
};
export const actions = {};
