import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'myPage';
export { NAMESPACE as NAMESPACE_MY_PAGE };
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export { mapGetters as mapGettersOfMyPage, mapMutations as mapMutationsOfMyPage, mapActions as mapActionsOfMyPage };

// state
export const PASS_INFO = 'PASS_INFO';
export const IS_LOADED_PASS_INFO = 'IS_LOADED_PASS_INFO';
export const THANKS_MENTEE_COUNT = 'THANKS_MENTEE_COUNT';
export const CREDIT_REFUND_THIS_MONTH = 'CREDIT_REFUND_THIS_MONTH';
export const CREDIT_REFUND_AMOUNT = 'CREDIT_REFUND_AMOUNT';
export const TOTAL_CREDIT = 'TOTAL_CREDIT';
export const EXTINCTION_CREDIT = 'EXTINCTION_CREDIT';

// getters
export const GET_PASS_INFO = 'GET_PASS_INFO';
export const GET_IS_LOADED_PASS_INFO = 'GET_IS_LOADED_PASS_INFO';
export const GET_THANKS_MENTEE_COUNT = 'GET_THANKS_MENTEE_COUNT';
export const GET_CREDIT_REFUND_THIS_MONTH = 'GET_CREDIT_REFUND_THIS_MONTH';
export const GET_CREDIT_REFUND_AMOUNT = 'GET_CREDIT_REFUND_AMOUNT';
export const GET_TOTAL_CREDIT = 'GET_TOTAL_CREDIT';
export const GET_EXTINCTION_CREDIT = 'GET_EXTINCTION_CREDIT';

// mutations
export const SET_PASS_INFO = 'SET_PASS_INFO';
export const SET_IS_LOADED_PASS_INFO = 'SET_IS_LOADED_PASS_INFO';
export const SET_NICKNAME = 'SET_NICKNAME';
export const SET_SCHOOL = 'SET_SCHOOL';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_THANKS_MENTEE_COUNT = 'SET_THANKS_MENTEE_COUNT';
export const SET_CREDIT_REFUND_THIS_MONTH = 'SET_CREDIT_REFUND_THIS_MONTH';
export const SET_CREDIT_REFUND_AMOUNT = 'SET_CREDIT_REFUND_AMOUNT';
export const SET_TOTAL_CREDIT = 'SET_TOTAL_CREDIT';
export const SET_EXTINCTION_CREDIT = 'SET_EXTINCTION_CREDIT';

// actions
export const LOAD_PASS_INFO = 'LOAD_PASS_INFO';

export const state = () => ({
	[PASS_INFO]: null,
	[IS_LOADED_PASS_INFO]: false,
	[THANKS_MENTEE_COUNT]: null,
	[CREDIT_REFUND_THIS_MONTH]: null,
	[CREDIT_REFUND_AMOUNT]: null,
	[THANKS_MENTEE_COUNT]: 0,
	[TOTAL_CREDIT]: null,
	[EXTINCTION_CREDIT]: null,
});

export const getters = {
	[GET_PASS_INFO]: state => state[PASS_INFO],
	[GET_IS_LOADED_PASS_INFO]: state => state[IS_LOADED_PASS_INFO],
	[GET_THANKS_MENTEE_COUNT]: state => state[THANKS_MENTEE_COUNT],
	[GET_CREDIT_REFUND_THIS_MONTH]: state => state[CREDIT_REFUND_THIS_MONTH],
	[GET_CREDIT_REFUND_AMOUNT]: state => state[CREDIT_REFUND_AMOUNT],
	[GET_TOTAL_CREDIT]: state => state[TOTAL_CREDIT],
	[GET_EXTINCTION_CREDIT]: state => state[EXTINCTION_CREDIT],
};

export const mutations = {
	[SET_PASS_INFO]: (state, payload) => {
		state[PASS_INFO] = payload;
	},
	[SET_IS_LOADED_PASS_INFO]: (state, payload) => {
		state[IS_LOADED_PASS_INFO] = payload;
	},
	[SET_NICKNAME]: (state, payload) => {
		state[PASS_INFO].userInfo.nickname = payload;
	},
	[SET_SCHOOL]: (state, payload) => {
		state[PASS_INFO].userInfo.univ = payload.belong;
		state[PASS_INFO].userInfo.major = payload.dep;
	},
	[SET_COMPANY]: (state, payload) => {
		state[PASS_INFO].userInfo.belong = payload.company;
		state[PASS_INFO].userInfo.depart = payload.job;
	},
	[SET_THANKS_MENTEE_COUNT]: (state, payload) => {
		state[THANKS_MENTEE_COUNT] = payload;
	},
	[SET_CREDIT_REFUND_THIS_MONTH]: (state, payload) => {
		state[CREDIT_REFUND_THIS_MONTH] = payload;
	},
	[SET_CREDIT_REFUND_AMOUNT]: (state, payload) => {
		state[CREDIT_REFUND_AMOUNT] = payload;
	},
	[SET_TOTAL_CREDIT]: (state, payload) => {
		state[TOTAL_CREDIT] = payload;
	},
	[SET_EXTINCTION_CREDIT]: (state, payload) => {
		state[EXTINCTION_CREDIT] = payload;
	},
};

export const actions = {
	async [LOAD_PASS_INFO]({ commit }) {
		try {
			const { data } = await this.$api.myPage.getPassInfo();
			commit(SET_PASS_INFO, data);
			commit(SET_IS_LOADED_PASS_INFO, true);
		} catch (error) {
			console.error(error);
			commit(SET_IS_LOADED_PASS_INFO, true);
		}
	},
};
