import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'class/pt/list';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfClassPtList,
	mapMutations as mapMutationsOfClassPtList,
	mapActions as mapActionsOfClassPtList,
};

// states
export const PT_LIST_PTS = 'PT_LIST_PTS';
export const PT_LIST_PARAMS = 'PT_LIST_PARAMS';
export const PT_LIST_PTS_TOTAL_COUNT = 'PT_LIST_PTS_TOTAL_COUNT';
// pagination에서 다음 페이지가 있는지(끝나지 않았는지) flag
export const HAVE_PT_LISTS_PTS_NEXT_PAGE = 'HAVE_PT_LISTS_PTS_NEXT_PAGE';
export const SELECTED_CATEGORY = 'SELECTED_CATEGORY';
export const CLASS_PT_TOP_CATEGORY_LIST = 'CLASS_PT_TOP_CATEGORY_LIST';
// getters
export const GET_PT_LIST_PTS = 'GET_PT_LIST_PTS';
export const GET_PT_LIST_PARAMS = 'GET_PT_LIST_PARAMS';
export const GET_PT_LIST_PTS_TOTAL_COUNT = 'GET_PT_LIST_PTS_TOTAL_COUNT';
export const GET_PT_LIST_PARAMS_PAGE = 'GET_PT_LIST_PARAMS_PAGE';
export const GET_HAVE_PT_LISTS_PTS_NEXT_PAGE = 'GET_HAVE_PT_LISTS_PTS_NEXT_PAGE';
export const GET_IS_PT_LIST_PTS_EMPTY = 'GET_IS_PT_LIST_PTS_EMPTY';
export const GET_SELECTED_CATEGORY = 'GET_SELECTED_CATEGORY';
export const GET_CLASS_PT_TOP_CATEGORY_LIST = 'GET_CLASS_PT_TOP_CATEGORY_LIST';
// mutations
export const SET_PT_LIST_PTS = 'SET_PT_LIST_PTS';
export const SET_PT_LIST_PARAMS = 'SET_PT_LIST_PARAMS';
export const SET_PT_LIST_PTS_TOTAL_COUNT = 'SET_PT_LIST_PTS_TOTAL_COUNT';
export const SET_HAVE_PT_LISTS_PTS_NEXT_PAGE = 'SET_HAVE_PT_LISTS_PTS_NEXT_PAGE';
export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';
export const SET_CLASS_PT_TOP_CATEGORY_LIST = 'SET_CLASS_PT_TOP_CATEGORY_LIST';
// actions
export const LOAD_PT_LISTS_PTS = 'LOAD_PT_LISTS_PTS';
export const LOAD_MORE_PT_LISTS_PTS = 'LOAD_MORE_PT_LISTS_PTS';
export const LOAD_CLASS_PT_TOP_CATEGORY_LIST = 'LOAD_CLASS_PT_TOP_CATEGORY_LIST';

export const state = () => ({
	[PT_LIST_PTS]: null,
	[PT_LIST_PARAMS]: {
		keyword: '',
		page: 1,
		perPage: 16,
		order_type: 'recent',
		top_category: '',
	},
	[PT_LIST_PTS_TOTAL_COUNT]: null,
	[HAVE_PT_LISTS_PTS_NEXT_PAGE]: true,
	[SELECTED_CATEGORY]: { id: 0, name: '전체', keyword: '' },
	[CLASS_PT_TOP_CATEGORY_LIST]: [],
});
export const getters = {
	[GET_PT_LIST_PTS]: state => state[PT_LIST_PTS],
	[GET_PT_LIST_PARAMS]: state => state[PT_LIST_PARAMS],
	[GET_PT_LIST_PARAMS_PAGE]: state => state[PT_LIST_PARAMS].page,
	[GET_HAVE_PT_LISTS_PTS_NEXT_PAGE]: state => state[HAVE_PT_LISTS_PTS_NEXT_PAGE],
	[GET_PT_LIST_PTS_TOTAL_COUNT]: state => state[PT_LIST_PTS_TOTAL_COUNT],
	[GET_IS_PT_LIST_PTS_EMPTY]: (_state, getters) => getters[GET_PT_LIST_PTS_TOTAL_COUNT] === 0,
	[GET_SELECTED_CATEGORY]: state => state[SELECTED_CATEGORY],
	[GET_CLASS_PT_TOP_CATEGORY_LIST]: state => state[CLASS_PT_TOP_CATEGORY_LIST],
};
export const mutations = {
	[SET_PT_LIST_PTS]: (state, payload) => {
		state[PT_LIST_PTS] = payload;
	},
	[SET_PT_LIST_PARAMS]: (state, payload) => {
		state[PT_LIST_PARAMS] = { ...state[PT_LIST_PARAMS], ...payload };
	},
	[SET_HAVE_PT_LISTS_PTS_NEXT_PAGE]: (state, payload) => {
		state[HAVE_PT_LISTS_PTS_NEXT_PAGE] = payload;
	},
	[SET_PT_LIST_PTS_TOTAL_COUNT]: (state, payload) => {
		state[PT_LIST_PTS_TOTAL_COUNT] = payload;
	},
	[SET_SELECTED_CATEGORY]: (state, payload) => {
		state[SELECTED_CATEGORY] = payload;
	},
	[SET_CLASS_PT_TOP_CATEGORY_LIST]: (state, payload) => {
		state[CLASS_PT_TOP_CATEGORY_LIST] = payload;
	},
};
export const actions = {
	async [LOAD_PT_LISTS_PTS]({ getters, commit }) {
		try {
			const {
				data: {
					list: { data, total, to },
				},
			} = await this.$api.classPt.getPtLandingList({
				...getters[GET_PT_LIST_PARAMS],
			});
			commit(SET_HAVE_PT_LISTS_PTS_NEXT_PAGE, !!to);
			commit(SET_PT_LIST_PTS_TOTAL_COUNT, total);
			commit(SET_PT_LIST_PTS, data);
		} catch (error) {
			console.error('LOAD_PT_LISTS_PTS error: ', error);
		}
	},
	async [LOAD_MORE_PT_LISTS_PTS]({ getters, commit }) {
		try {
			const {
				data: {
					list: { data, to },
				},
			} = await this.$api.classPt.getPtLandingList({ ...getters[GET_PT_LIST_PARAMS] });
			// to는 total-현재 부른 총 개수
			// to가 더이상 없으면 다 불러온 것.
			// to는 남은 개수(number) or null(없음)
			const PtList = getters[GET_PT_LIST_PTS] ?? [];
			commit(SET_HAVE_PT_LISTS_PTS_NEXT_PAGE, !!to);
			commit(SET_PT_LIST_PTS, [...PtList, ...data]);
		} catch (error) {
			console.error('LOAD_MORE_PT_LISTS_PTS error: ', error);
		}
	},
	async [LOAD_CLASS_PT_TOP_CATEGORY_LIST]({ commit }) {
		try {
			const {
				data: { data },
			} = await this.$api.classPt.getClassPtTopCategory();
			commit(SET_CLASS_PT_TOP_CATEGORY_LIST, data);
		} catch (error) {
			console.error('LOAD_CLASS_PT_TOP_CATEGORY_LIST error: ', error);
		}
	},
};
