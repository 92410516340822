const TIMELINE_PREFIX = `/api/timeline`;

export default axios => ({
	/**
	 * 타임라인 리스트 가져오기
	 * @param {Object} params
	 * @param {number} params.page
	 * @param {number} params.limit
	 * @returns {*}
	 */
	fetchTimeline(params) {
		return axios.get(`${TIMELINE_PREFIX}/sync`, { params });
	},
	/**
	 * @typedef {Object} EventInfo
	 * @property {string} title
	 * @property {number} camp_no
	 * @property {number} group_no
	 * @property {string} top_category
	 * @property {string} mid_category
	 * @property {string} mentor_nickname
	 * @property {string} display_name
	 * @property {string} img_url
	 * @property {number} applicant 신청자 수
	 * @property {string} user
	 * @property {string} url
	 * @property {string} created_at
	 * @property {string} event_type: 'eduApplicant'
	 */
	/**
	 * 타임라인 직부캠 신청 현황 가져오기
	 * 오늘기준으로 최신(하루동안의) 데이터
	 *
	 * @returns {Promise<EventInfo>}
	 */
	fetchTimelineEduApplicant() {
		return axios.get(`${TIMELINE_PREFIX}/bundleEvent`);
	},
	/**
	 * 타임라인 - 실무PT 라이브세션 시작 로그 가져오기
	 */
	fetchOpenedLiveClassPtLogs() {
		return axios.get(`/api/class/pt/time-line`);
	},
	/**
	 * 필터가 적용된 피드 가져오기
	 * @param {object} params - query params
	 * @param {number} params.page - 페이지 번호
	 * @param {number} params.limit - 페이지당 아이템 수
	 * @param {string} params.jobs - 직무 필터('|'로 구분됨)
	 * @returns {Promise}
	 */
	fetchFilteredFeed(params) {
		return axios.get(`/api/community/case-list/feed/comento`, { params });
	},
});
