
import { mapGetters } from 'vuex';

import CustomNuxtLink from '@/components/CustomNuxtLink.vue';

export default {
	name: 'MyPageAsideMenuButton',
	props: {
		menu: {
			type: Object,
			default: () => {},
		},
		index: {
			type: Number,
			default: 0,
		},
		currentMenuIndex: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			isClickComentoCommunity: false,
		};
	},
	computed: {
		...mapGetters(['isMentor']),
		fontWeight() {
			return this.currentMenuIndex === this.index ? 'semi-bold' : 'regular';
		},
		applySubMenuStyle() {
			if (this.isMentor) {
				return this.menu.isSubMenu;
			}
			return this.menu.isDrawer;
		},
		popoverProps() {
			return this.menu.popover
				? {
						placement: 'right',
						open: true,
						'auto-hide': false,
						triggers: [],
						trigger: '',
						delay: 1500,
				  }
				: {};
		},
		badgeProps() {
			return this.menu.badge
				? {
						text: this.menu.badge,
						color: 'secondary',
						size: 'small',
						type: 'inline',
				  }
				: {};
		},
	},
	created() {
		this.isClickComentoCommunity = !!this.$_getLocalStorage('click_comento_community_menu');
	},
	methods: {
		clickCommunityMenu(name) {
			if (name === '커뮤니티 활동') {
				this.$_setLocalStorage('click_comento_community_menu', true);
				this.isClickComentoCommunity = true;
			}
		},
	},
	components: { CustomNuxtLink },
};
