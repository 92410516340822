// import { EDU_CAMP_TYPE } from '@/constants/edu/camp-type';

import {
	CAMP_INFO,
	SET_CAMP_INFO,
	SET_CAMP_NO,
	SET_CAMP_RECRUITING_INFO,
	SET_CAMP_TYPE,
	SET_ENCORE_ROUND,
	SET_GROUP_NO,
	SET_IS_CAREER_CAMP,
	SET_IS_JOB_ASSIGNED,
	SET_IS_USER_REAL_NAME,
} from '@/store/camp';

export default async function ({ store, redirect, route, $api }) {
	const { name, params } = route;
	if (!['edu-learn-top-camp', 'edu-path-learn-top-camp', 'career-externship-top-camp'].includes(name)) {
		return;
	}
	const groupNo = Number(params.camp.split('-')[1]?.slice(1));
	if (isNaN(groupNo)) {
		redirect('/edu');
	}
	store.commit(`camp/${SET_GROUP_NO}`, groupNo);

	const {
		data: {
			info: { basic },
		},
	} = await $api.camp.getCampBasicInfo({
		group_no: groupNo,
	});

	const {
		edu_camp_group: { round, use_real_name, is_job_assigned },
		edu_camp_group: group,
	} = basic;

	const campType = getCampType(group);
	store.commit(`camp/${SET_CAMP_TYPE}`, campType);

	if (campType === 'externship') {
		const {
			data: {
				recruiting_info,
				info: { employeeId, simpleInfo, jobDetail },
			},
		} = await $api.camp.getEduDetailRecruitingInfo({
			group_no: groupNo,
		});
		store.commit(`camp/${SET_CAMP_RECRUITING_INFO}`, {
			recruiting_info,
			simpleInfo,
			employeeId,
			jobDetail,
		});
	}

	const prevCampInfo = store.getters[`camp/${CAMP_INFO}`];
	// campInfo를 basic으로 갈아끼우는게 아니라 기존 것은 유지하면서 basic 부분만 변경하도록
	store.commit(`camp/${SET_CAMP_INFO}`, {
		...(prevCampInfo && { ...prevCampInfo }),
		...basic,
	});
	store.commit(`camp/${SET_CAMP_NO}`, basic.no);
	store.commit(`camp/${SET_ENCORE_ROUND}`, round - 1);
	store.commit(`camp/${SET_IS_JOB_ASSIGNED}`, is_job_assigned);
	store.commit(`camp/${SET_IS_CAREER_CAMP}`, campType === 'path');
	store.commit(`camp/${SET_IS_USER_REAL_NAME}`, use_real_name);
	store.commit('setMetaTitle', getTitle(campType));
}

function getCampType(group) {
	if (group.type === 'path') {
		return 'path';
	}

	if (group.is_job_assigned) {
		return 'externship';
	}

	return 'camp';
}

function getTitle(type) {
	return {
		camp: '직무부트캠프',
		path: '진로부트캠프',
		externship: '익스턴십',
	}[type];
}
