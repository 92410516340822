import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'secrecy';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export { mapGetters as mapGettersOfSecrecy, mapMutations as mapMutationsOfSecrecy, mapActions as mapActionsOfSecrecy };

export const USER_NAME = 'USER_NAME';
export const MENTOR = 'MENTOR';
export const VERIFIED_AT = 'VERIFIED_AT';
export const NEXT_PAGE = 'NEXT_PAGE';
export const LECTURE_NAME = 'LECTURE_NAME';

const SET_MENTOR = `SET_MENTOR`;
const SET_VERIFIED_AT = 'SET_VERIFIED_AT';
const SET_NEXT_PAGE = 'SET_NEXT_PAGE';
const SET_LECTURE_NAME = 'SET_LECTURE_NAME';

export const CHECK_CODE_IS_VALID = 'CHECK_CODE_IS_VALID';
export const CONFIRM_SECRECY_TERM = 'CONFIRM_SECRECY_TERM';

const validProducts = ['vod-lecture'];

export const state = () => ({
	mentor: {
		name: '',
		image: '',
	},
	verifiedAt: null,
	nextPage: '/',
	lectureName: '',
});

export const getters = {
	[USER_NAME]: (_, __, ___, rootGetters) => rootGetters.userNickname,
	[MENTOR]: state => state.mentor,
	[VERIFIED_AT]: state => state.verifiedAt,
	[NEXT_PAGE]: state => state.nextPage,
	[LECTURE_NAME]: state => state.lectureName,
};

export const mutations = {
	[SET_MENTOR]: (state, mentor) => (state.mentor = mentor),
	[SET_VERIFIED_AT]: (state, verifiedAt) => (state.verifiedAt = verifiedAt),
	[SET_NEXT_PAGE]: (state, nextPage) => (state.nextPage = nextPage),
	[SET_LECTURE_NAME]: (state, lectureName) => (state.lectureName = lectureName),
};

export const actions = {
	async [CHECK_CODE_IS_VALID]({ commit }, { code, product }) {
		if (!validProducts.includes(product)) {
			throw new Error('Invalid product');
		}

		if (product === 'vod-lecture') {
			const response = await this.$api.vodLecture.checkCodeIsValid(code);
			const { error, mentor_name, mentor_profile, verify_at, lecture_id, lesson_id } = response.data;
			if (error) {
				return response;
			}

			commit(SET_MENTOR, {
				name: mentor_name,
				image: mentor_profile,
			});
			commit(SET_VERIFIED_AT, verify_at);
			commit(SET_NEXT_PAGE, `/vod/player/lecture/${lecture_id}/${lesson_id}`);
			return response;
		}
	},
	async [CONFIRM_SECRECY_TERM]({ commit }, { code, product }) {
		if (!validProducts.includes(product)) {
			throw new Error('Invalid product');
		}

		if (product === 'vod-lecture') {
			const response = await this.$api.vodLecture.signSecrecyTerms(code);
			const { error, verify_at, lecture_id, lesson_id, name } = response.data;
			if (error) {
				return response;
			}

			commit(SET_VERIFIED_AT, verify_at);
			commit(SET_LECTURE_NAME, name);
			commit(SET_NEXT_PAGE, `/vod/player/lecture/${lecture_id}/${lesson_id}`);
			return response;
		}
	},
};
