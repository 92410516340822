export default axios => ({
	getInvitationCode() {
		return axios.get('/api/user/invitation/code');
	},
	getInvitationInformation() {
		return axios.get('/api/user/invitation/information');
	},
	postInvitation(data) {
		return axios.post('/api/user/invitation', data);
	},
});
