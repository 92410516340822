import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'recruit';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export { mapGetters as mapGettersOfRecruit, mapMutations as mapMutationsOfRecruit, mapActions as mapActionsOfRecruit };

// states
export const RECRUITMENT_LIST = 'RECRUITMENT_LIST';
export const RECRUITMENT_LIST_LAST_PAGE = 'RECRUITMENT_LIST_LAST_PAGE';
export const RECRUITMENT_LIST_API_PARAMS = 'RECRUITMENT_LIST_API_PARAMS';

// getters
export const RECRUITMENT_LIST_PAGE = 'RECRUITMENT_LIST_PAGE';
export const RECRUITMENT_LIST_PER_PAGE = 'RECRUITMENT_LIST_PER_PAGE';

// setters
export const SET_RECRUITMENT_LIST = 'SET_RECRUITMENT_LIST';
export const SET_RECRUITMENT_LAST_PAGE = 'SET_RECRUITMENT_LAST_PAGE';
export const SET_RECRUITMENT_LIST_API_PARAMS = 'SET_RECRUITMENT_LIST_API_PARAMS';

// actions
export const FETCH_RECRUITMENT_LIST = 'FETCH_RECRUITMENT_LIST';

export const state = () => ({
	[RECRUITMENT_LIST]: [],
	[RECRUITMENT_LIST_LAST_PAGE]: 0,
	[RECRUITMENT_LIST_API_PARAMS]: {
		page: 1,
		perPage: 20,
	},
});

export const getters = {
	[RECRUITMENT_LIST]: state => state[RECRUITMENT_LIST],
	[RECRUITMENT_LIST_LAST_PAGE]: state => state[RECRUITMENT_LIST_LAST_PAGE],
	[RECRUITMENT_LIST_API_PARAMS]: state => state[RECRUITMENT_LIST_API_PARAMS],
	[RECRUITMENT_LIST_PAGE]: state => state[RECRUITMENT_LIST_API_PARAMS].page,
	[RECRUITMENT_LIST_PER_PAGE]: state => state[RECRUITMENT_LIST_API_PARAMS].perPage,
};

export const mutations = {
	[SET_RECRUITMENT_LIST]: (state, payload) => {
		state[RECRUITMENT_LIST] = payload;
	},
	[SET_RECRUITMENT_LAST_PAGE]: (state, payload) => {
		state[RECRUITMENT_LIST_LAST_PAGE] = payload;
	},
	[SET_RECRUITMENT_LIST_API_PARAMS]: (state, payload) => {
		state[RECRUITMENT_LIST_API_PARAMS] = { ...state[RECRUITMENT_LIST_API_PARAMS], ...payload };
	},
};

export const actions = {
	[FETCH_RECRUITMENT_LIST]: async ({ commit, getters }) => {
		try {
			const { data } = await this.$api.recruit.getRecruitments(getters[RECRUITMENT_LIST_API_PARAMS]);
			commit(SET_RECRUITMENT_LIST, data);
		} catch (error) {
			console.error('FETCH_RECRUITMENT_LIST error: ', error);
		}
	},
};
