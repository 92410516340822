
import { getLocalStorage, setLocalStorage } from '@/utils/utils';

import { GET_IS_KDC_PROGRESS_EXIST, mapGettersOfKdc } from '@/store/kdc';

import GridLayout from '@/components/common/atoms/GridLayout.vue';
import CustomNuxtLink from '@/components/CustomNuxtLink.vue';

import layoutMixin from '@/mixins/layoutMixin';
import myPageNavMixin from '@/mixins/myPageNavMixin';

export default {
	name: 'MyPageNav',
	mixins: [myPageNavMixin, layoutMixin],
	computed: {
		...mapGettersOfKdc([GET_IS_KDC_PROGRESS_EXIST]),
		computedGridFluid() {
			return this.IS_MOBILE && this.navsType === 'swiper';
		},
		navsType() {
			return this.IS_MOBILE ? 'swiper' : 'basic';
		},
		showChipValue() {
			return getLocalStorage('eid_120_new_analytics') || false;
		},
	},
	methods: {
		showNavDots(name) {
			const clickAiAnalytics = getLocalStorage('coment_click_ai_analytics') || true;
			const clickEssayTutorial = getLocalStorage('coment_click_essay_tutorial') || true;
			if (name === '국비지원' && this[GET_IS_KDC_PROGRESS_EXIST]) {
				return true;
			} else if (name === 'AI 자기소개서 분석기' && !clickAiAnalytics) {
				return true;
			} else if (name === '자기소개서 튜토리얼' && !clickEssayTutorial) {
				return true;
			} else {
				return false;
			}
		},
		clickNavDots(name) {
			if (name === 'AI 자기소개서 분석기') {
				setLocalStorage('coment_click_ai_analytics', true);
				setLocalStorage('eid_120_new_analytics', false);
			}
			if (name === '자기소개서 튜토리얼') {
				setLocalStorage('coment_click_essay_tutorial', true);
			}
			return false;
		},
		showNewChip(name) {
			return name === 'AI 자기소개서 분석기' && this.showChipValue;
		},
	},
	components: {
		GridLayout,
		CustomNuxtLink,
	},
};
