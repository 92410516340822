
export default {
	name: 'GridLayout',
	props: {
		gridFluid: {
			type: Boolean,
			default: false,
		},
		gridColor: {
			type: String,
			default: '',
		},
		rowGutters: {
			type: Boolean,
			default: false,
		},
	},
};
