import Vue from 'vue';

import { getTimeInAsia } from '@/plugins/dayjs';
import { YMDHMS } from '@/plugins/filters/formatDate';

import layoutMixin from '@/mixins/layoutMixin';

const gtmMixin = {
	mixins: [layoutMixin],
	methods: {
		$_gtm(...args) {
			args[0].is_app = this.IS_APP;
			args[0].date = getTimeInAsia().format(YMDHMS);
			this.$gtm.push(...args);
		},
	},
};

export default gtmMixin;

if (!Vue.__gtm_mixin__) {
	Vue.__gtm_mixin__ = true;
	Vue.mixin(gtmMixin);
}
