
import axios from 'axios';
import { ref } from 'vue';

export default {
	name: 'RemoteSvg',
	props: {
		src: {
			type: String,
			required: true,
		},
		fill: {
			type: String,
			default: 'white',
		},
		width: {
			type: [String, Number],
			default: 'auto',
		},
		height: {
			type: [String, Number],
			default: 'auto',
		},
	},
	setup(props) {
		const svg = ref(null);
		const styles = {
			'--fill': props.fill,
			'--width': `${props.width === 'auto' ? 'auto' : props.width + 'px'}`,
			'--height': `${props.height === 'auto' ? 'auto' : props.height + 'px'}`,
		};

		async function fetchSvg() {
			const { data } = await axios.get('/cdn', { params: { resource: props.src } });
			svg.value = data;
		}

		fetchSvg();

		return {
			svg,
			styles,
		};
	},
};
