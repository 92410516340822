import { createNamespacedHelpers } from 'vuex';
const NAMESPACE = 'historyCustom';

const { mapGetters, mapMutations } = createNamespacedHelpers(NAMESPACE);
export { mapGetters as mapGettersOfHistoryCustom, mapMutations as mapMutationsOfHistoryCustom };

// state
export const HISTORY_CUSTOM_DATA_FOR_READ_CASE = 'HISTORY_CUSTOM_DATA_FOR_READ_CASE';
export const HISTORY_CUSTOM_DATA_FOR_PT_VED = 'HISTORY_CUSTOM_DATA_FOR_PT_VED';

// getters
export const IS_PUSHED_STATE_FOR_READ_CASE = 'IS_PUSHED_STATE_FOR_READ_CASE';
export const IS_CHANGED_HISTORY_FOR_READ_CASE = 'IS_CHANGED_HISTORY_FOR_READ_CASE';
export const IS_PUSHED_STATE_FOR_PT_VED = 'IS_PUSHED_STATE_FOR_PT_VED';
export const IS_CHANGED_HISTORY_FOR_PT_VED = 'IS_CHANGED_HISTORY_FOR_PT_VED';

// mutations
export const SET_HISTORY_CUSTOM_DATA_FOR_READ_CASE = 'SET_HISTORY_CUSTOM_DATA_FOR_READ_CASE';
export const SET_HISTORY_CUSTOM_DATA_FOR_PT_VED = 'SET_HISTORY_CUSTOM_DATA_FOR_PT_VED';

export const state = () => ({
	[HISTORY_CUSTOM_DATA_FOR_READ_CASE]: {
		pushedState: false,
		changedHistory: false,
	},
	[HISTORY_CUSTOM_DATA_FOR_PT_VED]: {
		pushedState: false,
		changedHistory: false,
	},
});

export const getters = {
	[IS_PUSHED_STATE_FOR_READ_CASE]: state => {
		return state[HISTORY_CUSTOM_DATA_FOR_READ_CASE].pushedState;
	},
	[IS_CHANGED_HISTORY_FOR_READ_CASE]: state => {
		return state[HISTORY_CUSTOM_DATA_FOR_READ_CASE].changedHistory;
	},
	[IS_PUSHED_STATE_FOR_PT_VED]: state => {
		return state[HISTORY_CUSTOM_DATA_FOR_PT_VED].pushedState;
	},
	[IS_CHANGED_HISTORY_FOR_PT_VED]: state => {
		return state[HISTORY_CUSTOM_DATA_FOR_PT_VED].changedHistory;
	},
};

export const mutations = {
	[SET_HISTORY_CUSTOM_DATA_FOR_READ_CASE]: (state, payload) => {
		state[HISTORY_CUSTOM_DATA_FOR_READ_CASE] = { ...state[HISTORY_CUSTOM_DATA_FOR_READ_CASE], ...payload };
	},
	[SET_HISTORY_CUSTOM_DATA_FOR_PT_VED]: (state, payload) => {
		state[HISTORY_CUSTOM_DATA_FOR_PT_VED] = { ...state[HISTORY_CUSTOM_DATA_FOR_PT_VED], ...payload };
	},
};
