const FILE_PREFIX = `/api/file`;

export default axios => ({
	/**
	 * @params file: 업로드할 파일
	 * @params folder: s3 폴더 위치
	 * @params prefix: 파일명 prefix
	 */
	uploadFile(data) {
		return axios.post(`${FILE_PREFIX}/upload`, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	},
	/**
	 * 파일 다운로드
	 * @param {string} url 파일 CDN URL
	 * @returns {Blob} 파일
	 */
	downloadFile(url) {
		return axios.get(`${FILE_PREFIX}/downloadFile`, { params: { file: url }, responseType: 'blob' });
	},
});
