import { ROUTE_NAMES } from '@/constants/route-name';
import { SEARCH_PRODUCT_TYPES } from '@/constants/search/search-products';

import gtmTriggerMixin from '@/mixins/gtmTriggerMixin';
import localStorageIdMixin from '@/mixins/localStorageIdMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

const WHERE_NAMES = {
	class: 'classhome',
	community: SEARCH_PRODUCT_TYPES.COMMUNITY,
	edu: 'educamp',
	pt: 'CBC',
	vod: SEARCH_PRODUCT_TYPES.VOD,
	career: SEARCH_PRODUCT_TYPES.CAREER,
	answer: SEARCH_PRODUCT_TYPES.ANSWER,
};

const gtmSearchMixin = {
	mixins: [gtmTriggerMixin, userInfoMixin, localStorageIdMixin],
	methods: {
		/**
		 * GTM where 값 치환하기
		 * @param {string} value: route name 또는 product
		 * @returns {string|null} classhome, community, educamp, CBC, vod, career, answer
		 */
		$_gtmSearchMixin_getWhere(value) {
			if (!value) {
				return null;
			}

			if (value === ROUTE_NAMES.CLASS) {
				return WHERE_NAMES.class;
			}
			if (value === SEARCH_PRODUCT_TYPES.ANSWER || value.startsWith(ROUTE_NAMES.ANSWER)) {
				return WHERE_NAMES.answer;
			}
			if (value === SEARCH_PRODUCT_TYPES.COMMUNITY || value.startsWith(ROUTE_NAMES.JOB_QUESTIONS)) {
				return WHERE_NAMES.community;
			}
			if (value === SEARCH_PRODUCT_TYPES.EDU || value.startsWith(ROUTE_NAMES.EDU)) {
				return WHERE_NAMES.edu;
			}
			if (value === SEARCH_PRODUCT_TYPES.CLASS_PT || value.startsWith(ROUTE_NAMES.CLASS_PT)) {
				return WHERE_NAMES.pt;
			}
			if (value === SEARCH_PRODUCT_TYPES.CAREER || value.startsWith(ROUTE_NAMES.CAREER_EXTERNSHIP)) {
				return WHERE_NAMES.career;
			}
			if (
				value === SEARCH_PRODUCT_TYPES.VOD ||
				value.startsWith(ROUTE_NAMES.VOD) ||
				value.startsWith(ROUTE_NAMES.CLASS_VOD)
			) {
				return WHERE_NAMES.vod;
			}

			return null;
		},
		$_gtmSearchMixin_searchContent({ search_keyword, type }) {
			this.$_searchContent({
				...this.$_userInfoForGtm,
				local_storage_id: this.comentoLocalStorageId,
				search_keyword,
				where: this.$_gtmSearchMixin_getWhere(this.$route.name),
				type,
			});
		},
		/**
		 *
		 * @param {string} search_keyword
		 * @param {string} product
		 * @param {array} order_by_products
		 */
		$_gtmSearchMixin_viewSearchContent({ search_keyword, product, order_by_products }) {
			const getSearchOrder = orderByProducts => {
				if (orderByProducts.length === 0) {
					return [];
				}
				return orderByProducts.map(product => this.$_gtmSearchMixin_getWhere(product));
			};
			// GTM - viewSearchContent
			this.$_viewSearchContent({
				...this.$_userInfoForGtm,
				search_keyword,
				where: this.$_gtmSearchMixin_getWhere(product),
				search_order: getSearchOrder(order_by_products),
			});
		},
		$_gtmSearchMixin_clickSearchCard({ search_keyword, product }) {
			// GTM - clickSearchCard
			this.$_clickSearchCard({
				...this.$_userInfoForGtm,
				search_keyword,
				where: this.$_gtmSearchMixin_getWhere(product),
			});
		},
		$_gtmSearchMixin_clickSearchCard_community({ search_keyword, product, correspondence_company }) {
			// GTM - clickSearchCard
			this.$_clickSearchCard_community({
				...this.$_userInfoForGtm,
				search_keyword,
				where: this.$_gtmSearchMixin_getWhere(product),
				correspondence_company,
			});
		},
		$_gtmSearchMixin_viewSearchProduct({ search_keyword, product, page_depth }) {
			// GTM - viewSearchProduct
			this.$_viewSearchProduct({
				...this.$_userInfoForGtm,
				search_keyword,
				where: this.$_gtmSearchMixin_getWhere(product),
				page_depth,
			});
		},
	},
};

export default gtmSearchMixin;
