const API_PREFIX = '/api/checkplus';

export default axios => ({
	getCheckPlusRequest(params) {
		return axios.get(`${API_PREFIX}/request`, { params });
	},
	getCheckPlusSuccess(params) {
		return axios.get(`${API_PREFIX}/response/success`, { params });
	},
	getCheckPlusFail() {
		return axios.get(`${API_PREFIX}/response/fail`);
	},
});
