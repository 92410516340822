import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'vod/player';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfVodPlayer,
	mapMutations as mapMutationsOfVodPlayer,
	mapActions as mapActionsOfVodPlayer,
};

// getters
export const VOD_TITLE = 'VOD_TITLE';
export const VOD_ORIGIN_URL = 'VOD_ORIGIN_URL';
export const VOD_ID = 'VOD_ID';

export const LAYOUT_OVERFLOW = 'LAYOUT_OVERFLOW';

// mutations
export const SET_VOD_TITLE = 'SET_VOD_TITLE';
export const SET_VOD_ORIGIN_URL = 'SET_VOD_ORIGIN_URL';
export const SET_LAYOUT_OVERFLOW = 'SET_LAYOUT_OVERFLOW';
export const SET_VOD_ID = 'SET_VOD_ID';

// actions

export const state = () => ({
	[VOD_TITLE]: '',
	[VOD_ORIGIN_URL]: '',
	[VOD_ID]: null,
	[LAYOUT_OVERFLOW]: 'hidden',
});

export const getters = {
	[VOD_TITLE]: state => state[VOD_TITLE],
	[VOD_ORIGIN_URL]: state => state[VOD_ORIGIN_URL],
	[VOD_ID]: state => state[VOD_ID],
	[LAYOUT_OVERFLOW]: state => state[LAYOUT_OVERFLOW],
};

export const mutations = {
	[SET_VOD_TITLE]: (state, payload) => (state[VOD_TITLE] = payload),
	[SET_VOD_ORIGIN_URL]: (state, payload) => (state[VOD_ORIGIN_URL] = payload),
	[SET_VOD_ID]: (state, payload) => (state[VOD_ID] = payload),
	[SET_LAYOUT_OVERFLOW]: (state, payload) => (state[LAYOUT_OVERFLOW] = payload),
};
