import { createNamespacedHelpers } from 'vuex';
const NAMESPACE = 'live-class';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfLiveClass,
	mapMutations as mapMutationsOfLiveClass,
	mapActions as mapActionsOfLiveClass,
};

export const CAN_CREATE = 'CAN_CREATE';
export const IS_LIVE = 'IS_LIVE';
export const IS_CREATE_MODAL_OPEN = 'IS_CREATE_MODAL_OPEN';
export const START_URL = 'START_URL';
export const JOIN_URL = 'JOIN_URL';
export const STEP = 'STEP';

export const SET_CREATE = 'SET_CREATE';
export const SET_LIVE = 'SET_LIVE';
export const SET_CREATE_MODAL_OPEN = 'SET_CREATE_MODAL_OPEN';
export const SET_START_URL = 'SET_START_URL';
export const SET_JOIN_URL = 'SET_JOIN_URL';
export const SET_STEP = 'SET_STEP';

export const INIT_LIVE_CLASS = 'INIT_LIVE_CLASS';
export const OPEN_CREATE_MODAL = 'OPEN_CREATE_MODAL';
export const CLOSE_CREATE_MODAL = 'CLOSE_CREATE_MODAL';
export const GO_NEXT_STEP = 'GO_NEXT_STEP';

export const state = () => ({
	canCreate: true,
	isLive: false,
	isCreateModalOpen: false,
	startUrl: '',
	joinUrl: '',
	step: 1,
});

export const getters = {
	[CAN_CREATE]: state => state.canCreate,
	[IS_LIVE]: state => state.isLive,
	[IS_CREATE_MODAL_OPEN]: state => state.isCreateModalOpen,
	[START_URL]: state => state.startUrl,
	[JOIN_URL]: state => state.joinUrl,
	[STEP]: state => state.step,
};

export const mutations = {
	[SET_CREATE]: (state, payload) => (state.canCreate = payload),
	[SET_LIVE]: (state, payload) => (state.isLive = payload),
	[SET_CREATE_MODAL_OPEN]: (state, payload) => (state.isCreateModalOpen = payload),
	[SET_START_URL]: (state, payload) => (state.startUrl = payload),
	[SET_JOIN_URL]: (state, payload) => (state.joinUrl = payload),
	[SET_STEP]: (state, payload) => (state.step = payload),
};

export const actions = {
	[INIT_LIVE_CLASS]: ({ commit }, payload) => {
		const { canCreate, isLive, joinUrl, startUrl } = payload;

		commit(SET_CREATE, canCreate);
		if (!canCreate) {
			commit(SET_STEP, 1);
			return;
		}

		commit(SET_LIVE, isLive);
		commit(SET_JOIN_URL, joinUrl ?? '');
		commit(SET_START_URL, startUrl ?? '');
		commit(SET_STEP, isLive ? 2 : 1);
	},
	[OPEN_CREATE_MODAL]: ({ commit }) => {
		commit(SET_CREATE_MODAL_OPEN, true);
	},
	[CLOSE_CREATE_MODAL]: ({ commit }) => {
		commit(SET_CREATE_MODAL_OPEN, false);
	},
	[GO_NEXT_STEP]: ({ commit, state }) => {
		commit(SET_STEP, state.step + 1);
	},
};
