
import layoutMixin from '@/mixins/layoutMixin';

export default {
	name: 'MinifiedImage',
	mixins: [layoutMixin],
	inheritAttrs: false,
	props: {
		src: {
			type: String,
			required: true,
		},
		alt: {
			type: String,
			default: '',
		},
		width: {
			type: [String, Number, Array],
			required: true,
			validator(value) {
				const type = typeof value;
				if (type === 'number') {
					return Number.isFinite(value);
				}

				if (Array.isArray(value)) {
					return value.every(element => Number.isFinite(element));
				}

				return Number.isFinite(Number.parseFloat(value));
			},
		},
		height: {
			type: [String, Number, Array],
			required: true,
		},
		quality: {
			type: [String, Number],
			default: 75,
			validator(value) {
				if (typeof value === 'number') {
					return value >= 0 && value <= 100;
				}

				const valueToNumber = Number.parseFloat(value);
				return valueToNumber >= 0 && valueToNumber <= 100;
			},
		},
		full: {
			type: Boolean,
			default: false,
		},
		display: {
			type: String,
			default: 'inline-block',
		},
		loading: {
			type: String,
			default: 'eager',
			validator(value) {
				return ['eager', 'lazy'].includes(value);
			},
		},
	},
	computed: {
		computedStyles() {
			const { width, height } = this.resizeDimension;

			return {
				display: this.display,
				'--optimized-image-width': this.full ? '100%' : width + 'px',
				'--optimized-image-aspect-ratio': `${width} / ${height}`,
				'--optimized-image-fallback-ratio': `${(height / width) * 100}%`,
			};
		},
		resizeDimension() {
			const isWidthArray = Array.isArray(this.width);
			const isHeightArray = Array.isArray(this.height);
			const width = isWidthArray ? this.width[Number(this.IS_PC)] : this.width;
			const height = isHeightArray ? this.height[Number(this.IS_PC)] : this.height;

			return {
				width: width > 0 ? Number(width) : 0,
				height: height > 0 ? Number(height) : 0,
			};
		},
		webpSrc() {
			return `${this.src}?s=${this.resizeDimension.width * 2}x${this.resizeDimension.height * 2}&q=${
				this.quality
			}`;
		},
	},
};
