
import { mapGetters } from 'vuex';

import { cdnBaseUrl } from '@/plugins/constants';

import layoutMixin from '@/mixins/layoutMixin';
import routeMixin from '@/mixins/routeMixin';

export default {
	name: 'MyPageThanksInfoBoard',
	mixins: [routeMixin, layoutMixin],
	props: {
		count: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			cdnBaseUrl,
		};
	},
	computed: {
		...mapGetters(['userNickname']),
		boxContainerPaddings() {
			return this.IS_MOBILE ? [24, 16, 24, 16] : [0, 0, 0, 0];
		},
	},
	methods: {
		goToViewRequestList() {
			this.$_routeMixin_go_page('/answer');
		},
	},
};
