import { Pinia, defineStore, storeToRefs } from 'pinia';
import { toRefs } from 'vue';

const useBookmarkStore = defineStore('bookmark', {
	state: () => ({
		page: {
			isFetching: false,
		},
		bookmarkedIds: {
			camp: [],
			pts: [],
		},
	}),
});

export const useBookmarkPageRefs = (pinia?: Pinia) => {
	const { page } = useBookmarkStore(pinia);
	return toRefs(page);
};

export const useBookmarkRefs = (pinia?: Pinia) => {
	return storeToRefs(useBookmarkStore(pinia));
};
