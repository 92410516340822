import { CLASS_PT } from '@/constants/class/pt/class-types';

import {
	CASE_CATEGORY_CAREER_CHANGE,
	CASE_CATEGORY_JOB_SEEKING,
	CASE_CATEGORY_ONLINE_MENTOR_NEW,
	CASE_CATEGORY_UNIVERSITY_STUDENT_WORRY,
	CASE_CATEGORY_WORKING_LEVEL_SKILL_NEW,
} from '@/plugins/constants';

export const SEARCH_PRODUCT_TYPES = {
	INDEX: 'index',
	COMMUNITY: 'community',
	EDU: 'edu',
	CLASS_PT: 'pt',
	VOD: 'vod',
	ANSWER: 'answer',
};

export const SEARCH_PRODUCTS = {
	[SEARCH_PRODUCT_TYPES.INDEX]: '통합',
	[SEARCH_PRODUCT_TYPES.COMMUNITY]: '커뮤니티',
	[SEARCH_PRODUCT_TYPES.EDU]: '직무부트캠프',
	[SEARCH_PRODUCT_TYPES.CLASS_PT]: CLASS_PT,
	[SEARCH_PRODUCT_TYPES.VOD]: 'VOD',
	[SEARCH_PRODUCT_TYPES.ANSWER]: '답변하기',
};

export const SEARCH_SORT_TYPES = {
	RELEVANCE: 'relevance',
	RELEVANCE_RECENT: 'relevance-recent',
	RECENT: 'recent',
	POPULAR: 'popular',
	COMMENTS: 'comments',
	COMMENTS_FEW: 'comments_few',
};

export const SEARCH_SORT_NAMES = {
	[SEARCH_SORT_TYPES.RELEVANCE]: '관련순',
	[SEARCH_SORT_TYPES.RECENT]: '최신순',
	[SEARCH_SORT_TYPES.POPULAR]: '인기순',
	[SEARCH_SORT_TYPES.COMMENTS]: '답변많은순',
	[SEARCH_SORT_TYPES.COMMENTS_FEW]: '답변적은순',
};

export const COMMUNITY_CATEGORY_NAME = {
	0: '전체',
	1: CASE_CATEGORY_JOB_SEEKING,
	2: CASE_CATEGORY_CAREER_CHANGE,
	3: CASE_CATEGORY_ONLINE_MENTOR_NEW,
	4: CASE_CATEGORY_UNIVERSITY_STUDENT_WORRY,
	5: CASE_CATEGORY_WORKING_LEVEL_SKILL_NEW,
};
