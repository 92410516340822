import { CLASS_OPENING_STEPS } from '@/constants/class/pt/opening/opening-steps';

import { getDefaultTheory } from '@/utils/class/pt/open';

export const defaultStep = () => CLASS_OPENING_STEPS.PROFILE;

export const defaultGuideExampleActiveIndex = () => -1;

export const defaultProfile =
	'<p><strong>현) 현재 이력 작성</strong></p><p>- 현재 회사에서의 구체 이력1 (직무 및 성과 등)</p><p>- 현재 회사에서의 구체 이력2</p><p><br></p><p><strong>전) 이전 이력 작성</strong></p><p>- 이전 회사에서의 구체 이력1 (직무 및 성과 등)</p><p>- 이전 회사에서의 구체 이력2</p><p><br></p><p><strong>그 외의 큰 프로젝트 이력1</strong></p><p><strong>그 외의 큰 프로젝트 이력2</strong></p>';

export const defaultWeek = 4;

export const defaultAssignment =
	'<p>[과제] 이곳에 과제 주제에 대해 작성해주세요.</p><p>- 이론 과제에 대한 세부 내용을 작성해주세요.</p>';

export const defaultTheory = index => {
	return getDefaultTheory(index);
};

export const defaultCurriculum = isLastWeek => {
	return {
		subject: null,
		curriculum: new Array(2).fill(null).map((_, index) => getDefaultTheory(index + 1)),
		...(!isLastWeek && { assignment: defaultAssignment }),
	};
};

export const defaultCurricula = week => {
	return [...Array.from({ length: week - 1 }, () => defaultCurriculum()), defaultCurriculum(true)];
};

export const defaultTarget = () => {
	return new Array(4).fill(null);
};

export const defaultGoal = () => {
	return new Array(4).fill(null);
};

export const defaultClassIntroduction = () => {
	return new Array(4).fill(null);
};

export const defaultFAQ = () => {
	return { question: null, content: null };
};

export const defaultStepStatusList = () => {
	const result = {};
	Object.keys(CLASS_OPENING_STEPS).forEach(step => (result[CLASS_OPENING_STEPS[step]] = 'empty'));
	return result;
};

export const defaultHeaderTitle = () => {
	return '새 클래스';
};
