import { cdnBaseUrl } from '@/plugins/constants';

export const CASE_PATH = `/job-questions`;
export const COMMUNITY_PATH = CASE_PATH;
export const LOUNGE_PATH = `/community/lounge`;
export const INSIGHT_PATH = `/community/insight`;
export const ANSWER_PATH = `/answer`;
export const CS_PATH = `/help`;
export const LOGOUT_PATH = `/logout`;

export const EDU_PATH = `/edu`;
export const EDU_LIST_PATH = `/edu/list`;
export const EDU_PATH_LIST_PATH = `/edu/path/list`;
export const EDU_ALUMNI_PATH = `${EDU_PATH}/alumni`;
export const JOB_ASSIGNED_EDU_LIST = `${EDU_PATH}/recruiting/list`;
export const EDU_MENTOR_PATH = `${EDU_PATH}/mentor`;
export const EDU_SCHEDULE_PATH = `${EDU_PATH}/schedule`;

export const CLASS_PT_PATH = `/class/pt`;
export const CLASS_PT_LIST_PATH = `${CLASS_PT_PATH}/list`;
export const CLASS_PT_PATH_DETAIL = `/class/pt/detail/2`;

export const CLASS_VOD_PATH = `/class/vod`;
export const CLASS_VOD_LIST_PATH = `${CLASS_VOD_PATH}/list`;
export const CLASS_VOD_DETAIL_PATH = `${CLASS_VOD_PATH}/detail`;
export const CLASS_VOD_DETAIL_SAMPLE_PATH = `${CLASS_VOD_PATH}/detail/sample`;

export const CLASS_ON_AIR_PATH = `/class/on-air`;
export const CLASS_ON_AIR_LIST_PATH = `${CLASS_ON_AIR_PATH}/list`;
export const CLASS_ON_AIR_LIST_ALL_PATH = `${CLASS_ON_AIR_LIST_PATH}/all`;
export const CLASS_ON_AIR_DETAIL_PATH = `${CLASS_ON_AIR_PATH}/detail`;

export const CAREER_EXTERNSHIP_PATH = '/career/externship';

export const CDN_CLASS_ON_AIR_PATH = `${cdnBaseUrl}/images/class/on-air`;

export const CDN_META_PATH = `${cdnBaseUrl}/images/meta`;

export const EVENT_PATH_PREFIX = '/events';
export const EVENT_SPARTA_PATH = `${EVENT_PATH_PREFIX}/comento_sparta`;
export const EVENT_REFERRAL_PATH = `${EVENT_PATH_PREFIX}/referral`;

export const RESUME_INDEX_PATH = `/resume/begin`;
export const ESSAY_TUTORIAL_PATH = `/essay/tutorial`;
export const AI_ANALYSIS_PATH = `/analytics`;

export const AFFILIATED_UNIVERSITY_PATH = '/affiliated-university';

export const NOTICE_PATH = `/notice`;

export const VOD_PATH = '/vod';

export const ANALYTICS_PATH = '/analytics';

export const REQUEST_ESSAY_PATH = '/request/essay';
export const REQUEST_INTERVIEW_PATH = '/request/interview';

export const BOOKMARK_PATH = '/bookmark';
export const COMMUNITY_BOOKMARK_PATH = `${BOOKMARK_PATH}/community`;

export const CDN_EMOJI_PATH = '/images/emojis';

export const CLASSROOM_PATH = `/classroom`;
export const CLASSROOM_SUBMIT_PATH = `${CLASSROOM_PATH}/submit`;

export const COUPON_BOOK_PATH = `/coupon/book`;

export const KDC_APPLY_PATH = '/kdc/apply';
export const KDC_APPLY_COMPLETE_PATH = `${KDC_APPLY_PATH}/complete`;

export const VOD_APPLY_LECTURE_PATH = '/apply/vod/lecture';

export const CASE_RECOMMEND_PATH = '/job-questions?category=추천피드';
