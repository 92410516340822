
import { mapGetters } from 'vuex';

import { cdnBaseUrl } from '@/plugins/constants';

import CustomNuxtLink from '@/components/CustomNuxtLink.vue';

export default {
	name: 'AnswerSideBoxTrophy',
	data() {
		return {
			cdnBaseUrl,
			menteeCount: 0,
		};
	},
	computed: {
		...mapGetters(['userNickname', 'userNo']),
	},

	mounted() {
		this.fetchThanks();
	},
	methods: {
		async fetchThanks() {
			try {
				const payload = { page: 1, per_page: 10, select: '' };
				const {
					data: { original },
				} = await this.$api.myPage.fetchThanks(payload);
				if (!original) {
					throw new Error('fetchThanks: original is null. User No: ' + this.userNo);
				}

				this.menteeCount = original.mentee.mentee_count;
			} catch (error) {
				console.error(error);
			}
		},
	},
	components: { CustomNuxtLink },
};
