export const EDU_PRODUCT_NAME = '직무부트캠프';

export const saraminUtmLink = (campaign, isMobile) => {
	switch (campaign) {
		case 'community_timeline':
			return isMobile
				? 'https://m.saramin.co.kr/live-job/view?idx=108896?inner_source=comento&inner_medium=banner&inner_campaign=community_timeline&inner_term=comento3'
				: 'https://www.saramin.co.kr/zf_user/help/live/view?idx=108896?inner_source=comento&inner_medium=banner&inner_campaign=community_timeline&inner_term=comento3';
		case 'community_job':
			return isMobile
				? 'https://m.saramin.co.kr/live-job/view?idx=108868?inner_source=comento&inner_medium=banner&inner_campaign=community_job&inner_term=comento2'
				: 'https://www.saramin.co.kr/zf_user/help/live/view?idx=108868?inner_source=comento&inner_medium=banner&inner_campaign=community_job&inner_term=comento2';
		case 'community_univ':
			return isMobile
				? 'https://m.saramin.co.kr/live-job/view?idx=108868?inner_source=comento&inner_medium=banner&inner_campaign=community_univ&inner_term=comento2'
				: 'https://www.saramin.co.kr/zf_user/help/live/view?idx=108868?inner_source=comento&inner_medium=banner&inner_campaign=community_univ&inner_term=comento2';
		case 'main_1':
			return isMobile
				? 'https://m.saramin.co.kr/live-job/view?idx=108896?inner_source=comento&inner_medium=banner&inner_campaign=main_1&inner_term=comento3'
				: 'https://www.saramin.co.kr/zf_user/help/live/view?idx=108896?inner_source=comento&inner_medium=banner&inner_campaign=main_1&inner_term=comento3';
		case 'main_2':
			return isMobile
				? 'https://m.saramin.co.kr/live-job/view?idx=108896?inner_source=comento&inner_medium=banner&inner_campaign=main_2&inner_term=comento3'
				: 'https://www.saramin.co.kr/zf_user/help/live/view?idx=108896?inner_source=comento&inner_medium=banner&inner_campaign=main_2&inner_term=comento3';
		case 'edu_detail':
			return isMobile
				? 'https://m.saramin.co.kr/live-job/view?idx=108896?inner_source=comento&inner_medium=banner&inner_campaign=edu_detail&inner_term=comento3'
				: 'https://www.saramin.co.kr/zf_user/help/live/view?idx=108896?inner_source=comento&inner_medium=banner&inner_campaign=edu_detail&inner_term=comento3';
		default:
			throw new Error('Invalid Campaign');
	}
};

export const CTAButtonText = Object.freeze({
	APPLY_COMPLETE: '신청 완료',
	RECRUIT_PENDING: '모집 예정',
	RECRUIT_COMPLETE: '모집 마감',
	CAMP_OPEN_REQUEST_COMPLETE: '캠프 개설 신청 완료',
	CAMP_OPEN_REQUEST: '캠프 개설 신청하기',
	APPLY_CAMP: '직무 경험 쌓기',
	APPLY_JOB_ASSIGNED_CAMP: '지원하기',
	SUBMIT_FILE: '제출 파일 등록하기',
	PATH_APPLY_COMPLETE: '진로부트캠프 신청 완료',
});

export const EDU_FILTER_DAYS = Object.freeze([
	{ text: '월요일', value: '1' },
	{ text: '화요일', value: '2' },
	{ text: '수요일', value: '3' },
	{ text: '목요일', value: '4' },
	{ text: '금요일', value: '5' },
	{ text: '토요일', value: '6' },
	{ text: '일요일', value: '0' },
]);
