export const CLASS_PT_OPEN_API_PREFIX = `/api/class-pt/make`;

export default axios => ({
	/**
	 * 과정개발 시스템 리스트 (직부캠 포함)
	 * @params type: all, classPt, eduCamp
	 * @params status : 진행중, 완료
	 * @params perPage : 페이지당 갯수
	 * @params page : 페이지
	 */
	getOpenedClassList(params) {
		return axios.get(`${CLASS_PT_OPEN_API_PREFIX}/list`, { params });
	},
	/**
	 * 과정개설 시스템에 반려상태의 목록이 있는지 확인
	 */
	getCheckRejection() {
		return axios.get(`${CLASS_PT_OPEN_API_PREFIX}/check-rejection`);
	},
	/**
	 * 반려 상태 알림을 읽음으로 처리
	 */
	checkClassOpenNotice(id) {
		return axios.get(`${CLASS_PT_OPEN_API_PREFIX}/change-notice/${id}`);
	},
	/**
	 * 과정개발시스템 첫 방문 정보 수집 확인
	 */
	checkFirstProfile() {
		return axios.get(`${CLASS_PT_OPEN_API_PREFIX}/check-first-profile`);
	},
	/**
	 * 과정개발시스템 첫 방문 정보 저장
	 @data name: 이름
	 @data email: 이메일 주소
	 @data telephone: 전화번호 11자리(숫자만)
	 */
	createFirstProfile(data) {
		return axios.post(`${CLASS_PT_OPEN_API_PREFIX}/first-profile`, data);
	},
	/**
	 * 과정개발시스템 임시저장 (최초 생성 시)
	 */
	temporarilyCreateClassOpening(data) {
		// 최초 임시저장(생성)
		return axios.post(`${CLASS_PT_OPEN_API_PREFIX}`, data);
	},
	/**
	 * 과정개발시스템 수정
	 * id가 있어야 함.
	 */
	temporarilyUpdateClassOpening(data) {
		// 수정
		return axios.post(`${CLASS_PT_OPEN_API_PREFIX}/update/${data.id}`, data);
	},
	/**
	 * 과정개발시스템 임시저장
	 * id가 없으면 생성, 있으면 수정
	 */
	temporarilySaveClassOpening(data) {
		// 수정
		if (data.id) {
			return this.temporarilyUpdateClassOpening(data);
		}
		// 최초 임시저장(생성)
		return this.temporarilyCreateClassOpening(data);
	},
	/**
	 * 과정개발시스템 제출
	 * id가 없으면 생성, 있으면 수정
	 */
	submitCreateClassOpening(data) {
		return axios.post(`${CLASS_PT_OPEN_API_PREFIX}`, { ...data, submit: true });
	},
	/**
	 * 과정개발시스템 제출
	 * id가 없으면 생성, 있으면 수정
	 */
	submitUpdateClassOpening(data) {
		return axios.post(`${CLASS_PT_OPEN_API_PREFIX}/update/${data.id}`, { ...data, submit: true });
	},
	/**
	 * 과정개발시스템 제출
	 * id가 없으면 생성, 있으면 수정
	 */
	submitClassOpening(data) {
		// 수정
		if (data.id) {
			return this.submitUpdateClassOpening(data);
		}
		// 최초 제출(생성)
		return this.submitCreateClassOpening(data);
	},
	/**
	 * 과정개발시스템 데이터 불러오기
	 @params classId: 클래스ID
	 */
	getClassOpeningInfo(params) {
		return axios.get(`${CLASS_PT_OPEN_API_PREFIX}/edit/${params.classId}`);
	},
	/**
	 * 과정개발시스템 상태 리스트 불러오기
	 @params id: 클래스ID
	 */
	getClassOpeningStatuses() {
		return axios.get(`${CLASS_PT_OPEN_API_PREFIX}/statuses`);
	},
	/**
	 * 과정개발시스템 파일 S3에 업로드
	 @params formData: file
	 */
	uploadFile(formData) {
		return axios.post(`${CLASS_PT_OPEN_API_PREFIX}/file-upload`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	},
	/**
	 * 과정개발시스템 참고자료(파일, 링크) 삭제
	 @params id: 클래스 id
	 @params referenceId: 참고자료 id
	 */
	removeReference({ id, referenceId }) {
		return axios.post(`${CLASS_PT_OPEN_API_PREFIX}/reference-destroy/${id}/${referenceId}`);
	},
	/**
	 * 과정개발시스템 단계별 진행상황 체크
	 @params classId: 클래스 id
	 */
	checkStep({ classId }) {
		return axios.get(`${CLASS_PT_OPEN_API_PREFIX}/check-step/${classId}`);
	},
	/**
	 * 과정개발시스템 참고자료 파일 다운로드
	 @params referenceId: 참고자료 파일 ID
	 */
	downloadFile({ referenceId }) {
		return axios.get(`${CLASS_PT_OPEN_API_PREFIX}/file-download/${referenceId}`, {
			responseType: 'blob',
		});
	},
	/**
	 * 과정개발시스템 닉네임 중복 체크
	 @params nickname: 체크할 닉네임
	 중복이면 false, 아니면 true
	 */
	checkNicknameDuplication(params) {
		return axios.get(`${CLASS_PT_OPEN_API_PREFIX}/check-nickname`, { params });
	},
	/**
	 * 과정개발시스템 개설 개수 초과 여부
	 */
	checkClassOpeningLimit() {
		return axios.get(`${CLASS_PT_OPEN_API_PREFIX}/check-limit`);
	},
});
