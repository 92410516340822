import Vue from 'vue';

import { baseUrl } from '@/plugins/constants';

import { isKoreanCharacter } from '@/utils/utils';

import { mapGettersOfWhitelist, WHITELIST } from '@/store/whitelist';

const commonMixin = {
	computed: {
		...mapGettersOfWhitelist([WHITELIST]),
	},
	methods: {
		$_nl2br(value) {
			if (!value) {
				return '';
			}
			value = value.toString();
			value = value.replace(/\n/g, '<br>');
			value = value.replace(/\\/gi, '');
			return value;
		},
		/**
		 * 특정 위치로 스크롤되는 애니메이션
		 * @param id: HTML ID명
		 * @param duration: 속도
		 * @param offset: 스크롤 위치 조정 (음수면 위, 양수면 아래)
		 * https://github.com/rigor789/vue-scrollto
		 **/
		$_scrollTo(selector, duration = 500, offset = 0, options = {}) {
			if (selector) {
				this.$nextTick(() => {
					this.$scrollTo(`${selector}`, duration, {
						offset,
						onStart: () => {
							this.$emit('scrollStart');
						},
						onDone: () => {
							this.$emit('scrollDone');
						},
						...options,
					});
				});
			}
		},
		$_getLocalStorage(key) {
			try {
				return JSON.parse(localStorage.getItem(key));
			} catch (e) {
				return null;
			}
		},
		$_setLocalStorage(key, value) {
			try {
				return localStorage.setItem(key, JSON.stringify(value));
			} catch (e) {
				return null;
			}
		},
		$_removeHTMLTag(value) {
			if (value === null) {
				return '';
			}
			return value?.replace(/(<([^>]+)>)/gi, '');
		},
		$_removeLocalStorage(key) {
			try {
				return localStorage.removeItem(key);
			} catch (e) {
				return null;
			}
		},
		/**
		 * 파일 확장자 체크
		 * @param fileName: 파일명
		 * @param exts: 체크할 확장자 배열
		 * @returns {boolean}
		 */
		$_hasExtension(fileName, exts) {
			return new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$').test(fileName.toLowerCase());
		},
		/**
		 * 클래스룸 파일 확장자 체크
		 * @param fileName: 파일명
		 * @returns {boolean}
		 */
		$_validateClassroomFileExtension(fileName) {
			return this.$_hasExtension(fileName, this[WHITELIST]);
		},
		$_isProduction() {
			return process.env.NODE_ENV === 'production';
		},
		/**
		 * 파일 크기 변환
		 * @param mb: mb단위의 파일 사이즈
		 * @returns {number} : byte단위의 파일 사이즈
		 **/
		$_mbToByte(mb) {
			return mb * Math.pow(1024, 2);
		},
		/**
		 * 객체에 키값이 있는지 판별
		 * @param obj: Object
		 * @param propertyName: String
		 * @return Boolean
		 **/
		$_checkOwnProperty(obj, propertyName) {
			return !!(
				obj &&
				(typeof obj === 'object' || typeof obj === 'function') &&
				Object.prototype.hasOwnProperty.call(obj, propertyName)
			);
		},
		/**
		 * 날짜를 반환
		 * @param date: 날짜
		 * @return 2020.07.20
		 **/
		$_dateWithDot(date) {
			const replacedDate = String(date).replace(/-/gi, '.');
			return replacedDate.split('T')[0];
		},
		$_getCurrentURL() {
			// utm이 붙으면 url이 길어지고 트랙킹도 이슈라서 fullpath -> path로 변경
			return `${baseUrl}${this.$route.path}`;
		},
		$_moveIndex(array, from, to) {
			const copiedArray = [...array];
			copiedArray.splice(to, 0, ...copiedArray.splice(from, 1));
			return copiedArray;
		},
		$_hexToRGB(hex) {
			return {
				red: parseInt(hex[1] + hex[2], 16),
				green: parseInt(hex[3] + hex[4], 16),
				blue: parseInt(hex[5] + hex[6], 16),
			};
		},
		$_rgbToRGBA({ red, green, blue }, alpha = 1) {
			return `rgba(${red},${green},${blue},${alpha})`;
		},
		$_hexToRGBA(hex, alpha) {
			return this.$_rgbToRGBA(this.$_hexToRGB(hex), alpha);
		},
		/**
		 * 한글 10자, 영문∙숫자∙특수문자 20자
		 * @param nickname
		 */
		$_isValidNickname(nickname) {
			let nickLength = 0;

			for (let i = 0; i < nickname.length; i++) {
				// 한글은 2, 영문은 1로 치환
				const char = nickname.charAt(i);
				nickLength += isKoreanCharacter(char) ? 2 : 1;
			}
			return nickLength >= 2 && nickLength <= 20;
		},
		$_phoneNumberFormat(value) {
			return value
				.replace(/[^0-9]/g, '')
				.replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/, '$1-$2-$3')
				.replace('--', '-');
		},
	},
};
export default commonMixin;

if (!Vue.__common_mixin__) {
	Vue.__common_mixin__ = true;
	Vue.mixin(commonMixin);
}
