const loadingMixin = {
	methods: {
		$_startLoading() {
			if (process.client) {
				this.$nextTick(() => {
					this.$nuxt.$loading.start();
				});
			}
		},
		$_finishLoading() {
			if (process.client) {
				this.$nextTick(() => {
					this.$nuxt.$loading.finish();
				});
			}
		},
	},
};

export default loadingMixin;
