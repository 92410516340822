
import {
	mapMutationsOfAnswer,
	SET_ANSWER_MISSION_INFO,
	mapGettersOfAnswer,
	GET_ANSWER_MISSION_INFO,
	GET_ANSWER_MENTOR_GRADE_INFO,
} from '@/store/answer';

import answerMixin from '@/mixins/answerMixin';

export default {
	name: 'AnswerSideBoxMissionExp',
	mixins: [answerMixin],
	data() {
		return {
			isOpen: true,
		};
	},
	computed: {
		...mapGettersOfAnswer([GET_ANSWER_MISSION_INFO, GET_ANSWER_MENTOR_GRADE_INFO]),
		missionInformation() {
			this.$_isCompleteMentorMission();

			if (!this[GET_ANSWER_MISSION_INFO]) {
				return false;
			}
			const missionComplete = this[GET_ANSWER_MISSION_INFO].mission_complete;
			const canMissionGrade = this[GET_ANSWER_MENTOR_GRADE_INFO].grade <= 2;
			const hasMission = this[GET_ANSWER_MISSION_INFO].mission_information.length > 0;
			const showMission = !missionComplete && canMissionGrade && hasMission;
			// 미션활성화 조건 = 미션조건이 있으면서, 코주임 이하일때 노출되어야 함
			if (showMission) {
				this.$_showMentorMission({ ...this.$_userInfoForGtm });
			}
			return showMission ? this[GET_ANSWER_MISSION_INFO].mission_information.slice(1, 3) : false;
		},
		userMissionInformation() {
			return this[GET_ANSWER_MISSION_INFO].user_information;
		},
		gradeInfo() {
			return this[GET_ANSWER_MENTOR_GRADE_INFO] ?? {};
		},
		nowMissionStep() {
			return this[GET_ANSWER_MISSION_INFO].mission_step;
		},
		nextTermNumber() {
			const gradeInfo = this.gradeInfo;
			return Math.round(
				((parseInt(gradeInfo.accu_cash) - parseInt(gradeInfo.grade_term)) /
					(parseInt(gradeInfo.next_term) - parseInt(gradeInfo.grade_term))) *
					100,
			);
		},
	},
	async created() {
		try {
			// 코사원 진급 미션 가져오기
			const { data } = await this.$api.answerList.getUserAnswerMission();
			this[SET_ANSWER_MISSION_INFO](data);
		} catch (error) {
			console.error(error);
		}
	},
	mounted() {
		setTimeout(() => {
			this.isOpen = false;
		}, 10000);
	},
	methods: {
		...mapMutationsOfAnswer([SET_ANSWER_MISSION_INFO]),
		contentFontColor(index, type) {
			const answerCondition = this.userMissionInformation.answer >= 1;
			const creditCondition = this.userMissionInformation.credit >= 3000;
			const step1 = answerCondition && index === 0;
			const step2 = creditCondition && index === 1;
			const totalCondition = answerCondition && creditCondition;

			/* 단계별 미션이 완료되었을때, 미션조건 모두 완료시 */
			if (step1 || step2 || totalCondition) {
				return type === 'icon' ? 'blue600' : 'blue400';
			}
			/* 단계별 미션이 진행중 */
			if (!step1 || !step2) {
				if (type === 'icon') {
					return 'gray300';
				}
				return type === 'sub' ? 'gray400' : 'gray850';
			}
			return 'gray400';
		},
	},
};
