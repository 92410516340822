
export default {
	name: 'LoadingSkeleton',
	props: {
		maxWidth: {
			default: 100,
			type: Number,
		},
		minWidth: {
			default: 80,
			type: Number,
		},
		height: {
			default: '1em',
			type: String,
		},
		width: {
			default: null,
			type: String,
		},
		circle: {
			default: false,
			type: Boolean,
		},
		borderRadius: {
			type: [Number, String],
			default: '4px',
		},
	},
	computed: {
		styles() {
			return {
				height: this.height,
				width: this.computedWidth,
				'--border-radius': this.computedBorderRadius,
			};
		},
		computedWidth() {
			return this.width || `${Math.floor(Math.random() * (this.maxWidth - this.minWidth))}%;`;
		},
		computedBorderRadius() {
			if (this.circle) {
				return '50px';
			}
			return this.borderRadius;
		},
	},
};
