import { createNamespacedHelpers } from 'vuex';
const NAMESPACE = 'loading';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export { mapGetters as mapGettersOfLoading, mapMutations as mapMutationsOfLoading, mapActions as mapActionsOfLoading };

export const ROUTE_NAME_FOR_COMMUNITY_CASE = 'job-questions-keyword';
export const ROUTE_NAME_FOR_COMMUNITY_CASE_DETAIL = 'job-questions-company-position-questionAndNo';
export const ROUTE_NAME_FOR_COMMUNITY_INSIGHT = 'community-insight';
export const ROUTE_NAME_FOR_COMMUNITY_LOUNGE = 'community-lounge';
export const ROUTE_NAME_FOR_COMMUNITY_ANSWER = 'answer';
export const ROUTE_NAMES_FOR_COMMUNITY_LIST = [
	ROUTE_NAME_FOR_COMMUNITY_CASE,
	ROUTE_NAME_FOR_COMMUNITY_INSIGHT,
	ROUTE_NAME_FOR_COMMUNITY_LOUNGE,
	ROUTE_NAME_FOR_COMMUNITY_ANSWER,
];
export const ROUTE_NAMES_FOR_COMMUNITY_PAGE = [...ROUTE_NAMES_FOR_COMMUNITY_LIST, ROUTE_NAME_FOR_COMMUNITY_CASE_DETAIL];

export const IS_COMMUNITY_PAGE = 'IS_COMMUNITY_PAGE';
export const IS_COMMUNITY_LIST_PAGE = 'IS_COMMUNITY_LIST_PAGE';
export const IS_COMMUNITY_TIMELINE_PAGE = 'IS_COMMUNITY_TIMELINE_PAGE';
export const ROUTE_NAME = 'ROUTE_NAME';

export const GET_IS_COMMUNITY_LIST_PAGE = 'GET_IS_COMMUNITY_LIST_PAGE';
export const GET_IS_COMMUNITY_PAGE = 'GET_IS_COMMUNITY_PAGE';
export const GET_IS_COMMUNITY_TIMELINE_PAGE = 'GET_IS_COMMUNITY_TIMELINE_PAGE';
export const GET_IS_COMMUNITY_INSIGHT_LIST_PAGE = 'GET_IS_COMMUNITY_INSIGHT_LIST_PAGE';
export const GET_IS_COMMUNITY_LOUNGE_LIST_PAGE = 'GET_IS_COMMUNITY_LOUNGE_LIST_PAGE';
export const GET_ROUTE_NAME = 'GET_ROUTE_NAME';

export const SET_IS_COMMUNITY_LIST_PAGE = 'SET_IS_COMMUNITY_LIST_PAGE';
export const SET_ROUTE_NAME = 'SET_ROUTE_NAME';
export const SET_IS_COMMUNITY_PAGE = 'SET_IS_COMMUNITY_PAGE';
export const SET_IS_COMMUNITY_TIMELINE_PAGE = 'SET_IS_COMMUNITY_TIMELINE_PAGE';

export const state = () => ({
	[ROUTE_NAME]: null,
	[IS_COMMUNITY_LIST_PAGE]: false,
	[IS_COMMUNITY_PAGE]: false,
	[IS_COMMUNITY_TIMELINE_PAGE]: true,
});

export const getters = {
	GET_IS_COMMUNITY_PAGE: state => {
		return state[IS_COMMUNITY_PAGE];
	},
	GET_IS_COMMUNITY_LIST_PAGE: state => {
		return state[IS_COMMUNITY_LIST_PAGE];
	},
	GET_IS_COMMUNITY_TIMELINE_PAGE: state => {
		return state[IS_COMMUNITY_TIMELINE_PAGE];
	},
	IS_COMMUNITY_TIMELINE_PAGE: state => {
		return state[IS_COMMUNITY_TIMELINE_PAGE];
	},
	GET_IS_COMMUNITY_INSIGHT_LIST_PAGE: state => {
		return state[ROUTE_NAME] === ROUTE_NAME_FOR_COMMUNITY_INSIGHT;
	},
	GET_IS_COMMUNITY_LOUNGE_LIST_PAGE: state => {
		return state[ROUTE_NAME] === ROUTE_NAME_FOR_COMMUNITY_LOUNGE;
	},
	GET_ROUTE_NAME: state => {
		return state[ROUTE_NAME];
	},
};

export const mutations = {
	SET_ROUTE_NAME: (state, payload) => {
		state[ROUTE_NAME] = payload;
	},
	SET_IS_COMMUNITY_PAGE: (state, payload) => {
		state[IS_COMMUNITY_PAGE] = payload;
	},
	SET_IS_COMMUNITY_LIST_PAGE: (state, payload) => {
		state[IS_COMMUNITY_LIST_PAGE] = payload;
	},
	SET_IS_COMMUNITY_TIMELINE_PAGE: (state, payload) => {
		state[IS_COMMUNITY_TIMELINE_PAGE] = payload;
	},
};
