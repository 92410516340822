
import { baseUrl } from '@/plugins/constants';

import { VOD_TITLE, VOD_ORIGIN_URL, mapGettersOfVodPlayer } from '@/store/vod/player';

import GridLayout from '@/components/common/atoms/GridLayout.vue';

import layoutMixin from '@/mixins/layoutMixin';
import routeMixin from '@/mixins/routeMixin';

export default {
	name: 'VodHeader',
	mixins: [layoutMixin, routeMixin],
	computed: {
		...mapGettersOfVodPlayer([VOD_TITLE, VOD_ORIGIN_URL]),
	},
	created() {
		this.setConstants();
	},
	methods: {
		setConstants() {
			this.baseUrl = baseUrl;
		},
		goBackToDetail() {
			this.$_routeMixin_href(this[VOD_ORIGIN_URL]);
		},
	},
	components: {
		GridLayout,
	},
};
