
import { cdnBaseUrl } from '@/plugins/constants';

import { moveToLaravelPage } from '@/utils/utils';

import GridLayout from '@/components/common/atoms/GridLayout.vue';
import CustomNuxtLink from '@/components/CustomNuxtLink.vue';

import layoutMixin from '@/mixins/layoutMixin';

export default {
	mixins: [layoutMixin],
	data() {
		return {
			cdnBaseUrl,
		};
	},
	computed: {
		kpcImage() {
			return `${this.cdnBaseUrl}/images/logo/logo-kpc.png`;
		},
	},
	methods: {
		moveToLaravelPage,
	},
	components: { CustomNuxtLink, GridLayout },
};
