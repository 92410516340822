import { SEARCH_PRODUCT_TYPES } from '@/constants/search/search-products';
const NEW_SEARCH_API_PREFIX = `/api/v2/search`;

export default axios => ({
	/**
	 * 커뮤니티 검색
	 * @param {Object} params
	 * @param {string} keyword
	 * @param {string} sort: recent, comments
	 * @param {Number} category_group_id
	 * @returns {*}
	 */
	getSearchListForCommunity(params) {
		return axios.get(`${NEW_SEARCH_API_PREFIX}/${SEARCH_PRODUCT_TYPES.COMMUNITY}`, { params });
	},
	/**
	 * 실무PT 검색
	 * @param {Object} params
	 * @param {string} keyword
	 * @param {string} sort: relevance, recent, popular
	 * @returns {*}
	 */
	getSearchListForClassPt(params) {
		return axios.get(`${NEW_SEARCH_API_PREFIX}/cbc`, { params });
	},
	/**
	 * 직부캠 검색
	 * @param {Object} params
	 * @param {string} keyword
	 * @param {string} sort: relevance, recent, popular
	 * @returns {*}
	 */
	getSearchListForEdu(params) {
		return axios.get(`${NEW_SEARCH_API_PREFIX}/${SEARCH_PRODUCT_TYPES.EDU}`, { params });
	},
	/**
	 * VOD 검색
	 * @param {Object} params
	 * @param {string} keyword
	 * @param {string} sort: relevance, popular
	 * @returns {*}
	 */
	getSearchListForVod(params) {
		return axios.get(`${NEW_SEARCH_API_PREFIX}/${SEARCH_PRODUCT_TYPES.VOD}`, { params });
	},
	/**
	 * 채용 검색
	 * @param {Object} params
	 * @param {string} keyword
	 * @param {string} sort: relevance, popular
	 * @returns {*}
	 */
	getSearchListForCareer(params) {
		return axios.get(`${NEW_SEARCH_API_PREFIX}/${SEARCH_PRODUCT_TYPES.CAREER}`, { params });
	},
	/**
	 * 검색하고 디테일페이지에 진입하면 인기검색어로 저장시키기(커뮤니티, 직부캠, 실무PT)
	 * @param {Object} params
	 * @param {string} keyword
	 * @param {keyword} type: community, edu, pt
	 * @returns {*}
	 */
	postPopularKeyword(params) {
		return axios.post(`/api/log/keyword`, params);
	},
});
