import { mapGetters } from 'vuex';

import {
	mapGettersOfUserMeta,
	GET_USER_META_CAREER_FOR_GTM,
	GET_USER_META_UNIV,
	GET_USER_META_MAJOR,
} from '@/store/userMeta';

const userInfoMixin = {
	computed: {
		...mapGetters(['userNo', 'userType', 'userId', 'userMobile', 'userNickname', 'userLevel']),
		...mapGettersOfUserMeta([GET_USER_META_CAREER_FOR_GTM, GET_USER_META_UNIV, GET_USER_META_MAJOR]),
		$_userInfoForGtm() {
			return {
				user_no: this.userNo,
				user_type: this.userType,
				user_email: this.userId,
				email: this.userId,
				user_level: this.userLevel,
				user_career: this[GET_USER_META_CAREER_FOR_GTM],
				user_university: this[GET_USER_META_UNIV],
				user_major: this[GET_USER_META_MAJOR],
			};
		},
	},
};

export default userInfoMixin;
