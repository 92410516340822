const middleware = {}

middleware['alumni-auth-check'] = require('../middleware/alumni-auth-check.js')
middleware['alumni-auth-check'] = middleware['alumni-auth-check'].default || middleware['alumni-auth-check']

middleware['app-meta'] = require('../middleware/app-meta.js')
middleware['app-meta'] = middleware['app-meta'].default || middleware['app-meta']

middleware['app'] = require('../middleware/app.js')
middleware['app'] = middleware['app'].default || middleware['app']

middleware['apply-page-meta'] = require('../middleware/apply-page-meta.js')
middleware['apply-page-meta'] = middleware['apply-page-meta'].default || middleware['apply-page-meta']

middleware['auth-logger'] = require('../middleware/auth-logger.js')
middleware['auth-logger'] = middleware['auth-logger'].default || middleware['auth-logger']

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['block'] = require('../middleware/block.js')
middleware['block'] = middleware['block'].default || middleware['block']

middleware['check-update-profile'] = require('../middleware/check-update-profile.js')
middleware['check-update-profile'] = middleware['check-update-profile'].default || middleware['check-update-profile']

middleware['class-pt-bookmark'] = require('../middleware/class-pt-bookmark.js')
middleware['class-pt-bookmark'] = middleware['class-pt-bookmark'].default || middleware['class-pt-bookmark']

middleware['edu-detail'] = require('../middleware/edu-detail.js')
middleware['edu-detail'] = middleware['edu-detail'].default || middleware['edu-detail']

middleware['loading'] = require('../middleware/loading.js')
middleware['loading'] = middleware['loading'].default || middleware['loading']

middleware['mentor'] = require('../middleware/mentor.js')
middleware['mentor'] = middleware['mentor'].default || middleware['mentor']

middleware['payment-page-meta'] = require('../middleware/payment-page-meta.js')
middleware['payment-page-meta'] = middleware['payment-page-meta'].default || middleware['payment-page-meta']

middleware['product-detail'] = require('../middleware/product-detail.js')
middleware['product-detail'] = middleware['product-detail'].default || middleware['product-detail']

middleware['read-case'] = require('../middleware/read-case.js')
middleware['read-case'] = middleware['read-case'].default || middleware['read-case']

middleware['route-meta'] = require('../middleware/route-meta.js')
middleware['route-meta'] = middleware['route-meta'].default || middleware['route-meta']

middleware['route-transparent-earlybird'] = require('../middleware/route-transparent-earlybird.js')
middleware['route-transparent-earlybird'] = middleware['route-transparent-earlybird'].default || middleware['route-transparent-earlybird']

middleware['trailing-slash'] = require('../middleware/trailing-slash.js')
middleware['trailing-slash'] = middleware['trailing-slash'].default || middleware['trailing-slash']

middleware['vod-detail-auth-check'] = require('../middleware/vod-detail-auth-check.js')
middleware['vod-detail-auth-check'] = middleware['vod-detail-auth-check'].default || middleware['vod-detail-auth-check']

export default middleware
