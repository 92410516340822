
import { moveToLaravelPage } from '@/utils/utils';

import { IS_MOBILE, mapGettersOfLayout } from '@/store/layout';

import GridLayout from '@/components/common/atoms/GridLayout.vue';

import layoutMixin from '@/mixins/layoutMixin';

import routeMixin from '../mixins/routeMixin';

export default {
	name: 'ErrorLayout',
	mixins: [routeMixin, layoutMixin],
	props: {
		error: {
			type: Object,
			default() {
				return {
					statusCode: 500,
				};
			},
		},
	},
	computed: {
		...mapGettersOfLayout([IS_MOBILE]),
		errorInfo() {
			const errorInfoArray = {
				400: {
					title: '페이지를 확인할 수 없습니다.',
					contents: `서비스 이용에 불편을 끼쳐드려 죄송합니다.<br />오류가 계속된다면, help@comento.kr 혹은 고객센터로 문의해주세요.`,
				},
				401: {
					title: `페이지를 확인할 수 없습니다.`,
					contents: `접근 권한이 없는 페이지입니다.<br/>입력한 주소를 다시 확인해주세요.`,
				},
				403: {
					title: `페이지를 확인할 수 없습니다.`,
					contents: `접근 권한이 없는 페이지입니다.<br/>입력한 주소를 다시 확인해주세요.`,
				},
				404: {
					title: `페이지를 찾을 수 없습니다.`,
					contents: `존재하지 않거나, 사용할 수 없는 페이지입니다.<br />입력한 주소가 정확한지 다시 확인해주세요.`,
				},
				500: {
					title: `페이지 오류가 발생했습니다.`,
					contents: `서비스 이용에 불편을 끼쳐드려 죄송합니다.<br />오류가 계속된다면, help@comento.kr 혹은 고객센터로 문의해주세요.`,
				},
			};
			return errorInfoArray[this.error.statusCode];
		},
		is500Error() {
			return this.error.statusCode === 500;
		},
	},
	methods: {
		goErrorAfterPage() {
			return this.is500Error ? moveToLaravelPage(`/faq`) : this.$_routeMixin_href();
		},
	},
	components: { GridLayout },
};
