import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'myPage/matching';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfMyPageMatching,
	mapMutations as mapMutationsOfMyPageMatching,
	mapActions as mapActionsOfMyPageMatching,
};

// state
const MATCHING_EDUCATION = 'MATCHING_EDUCATION';
const MATCHING_RESUME = 'MATCHING_RESUME';
const MATCHING_ASSIGNMENT = 'MATCHING_ASSIGNMENT';
const MATCHING_CAMPS = 'MATCHING_CAMPS';
const MODAL_VISIBILITY = 'MODAL_VISIBILITY';
const APPLIED = 'APPLIED';
const MATCHING_TOAST = 'MATCHING_TOAST';
const MATCHING_OPT_IN = 'MATCHING_OPT_IN';

// getters
export const GET_MATCHING_EDUCATION = 'GET_MATCHING_EDUCATION';
export const GET_MATCHING_RESUME = 'GET_MATCHING_RESUME';
export const GET_MATCHING_ASSIGNMENT = 'GET_MATCHING_ASSIGNMENT';
export const GET_MATCHING_INFO = 'GET_MATCHING_INFO';
export const GET_MATCHING_CAMPS = 'GET_MATCHING_CAMPS';

export const IS_EDUCATION_COMPLETE = 'IS_EDUCATION_COMPLETE';
export const IS_RESUME_COMPLETE = 'IS_RESUME_COMPLETE';
export const IS_ASSIGNMENT_COMPLETE = 'IS_ASSIGNMENT_COMPLETE';

export const IS_EDUCATION_FILLED = 'IS_EDUCATION_FILLED';
export const IS_RESUME_FILLED = 'IS_RESUME_FILLED';
export const IS_ASSIGNMENT_FILLED = 'IS_ASSIGNMENT_FILLED';

export const IS_START_MODAL_VISIBLE = 'IS_START_MODAL_VISIBLE';
export const IS_DESCRIPTION_MODAL_VISIBLE = 'IS_DESCRIPTION_MODAL_VISIBLE';
export const IS_AGREEMENT_MODAL_VISIBLE = 'IS_AGREEMENT_MODAL_VISIBLE';

export const IS_APPLIED = 'IS_APPLIED';
export const GET_MATCHING_TOAST = 'GET_MATCHING_TOAST';
export const GET_MATCHING_OPT_IN = 'GET_MATCHING_OPT_IN';

// mutations
export const SET_MATCHING_EDUCATION = 'SET_MATCHING_EDUCATION';
export const SET_MATCHING_RESUME = 'SET_MATCHING_RESUME';
export const SET_MATCHING_ASSIGNMENT = 'SET_MATCHING_ASSIGNMENT';
export const SET_MATCHING_CAMPS = 'SET_MATCHING_CAMPS';

export const SET_EDUCATION_FILLED = 'SET_EDUCATION_FILLED';
export const SET_RESUME_FILLED = 'SET_RESUME_FILLED';
export const SET_ASSIGNMENT_FILLED = 'SET_ASSIGNMENT_FILLED';

export const SET_START_MODAL_VISIBLE = 'SET_START_MODAL_VISIBLE';
export const SET_DESCRIPTION_MODAL_VISIBLE = 'SET_DESCRIPTION_MODAL_VISIBLE';
export const SET_AGREEMENT_MODAL_VISIBLE = 'SET_AGREEMENT_MODAL_VISIBLE';

export const SET_APPLIED = 'SET_APPLIED';
export const SET_MATCHING_TOAST = 'SET_MATCHING_TOAST';
export const SET_MATCHING_OPT_IN = 'SET_MATCHING_OPT_IN';

// actions
export const SHOW_MATCHING_TOAST = 'SHOW_MATCHING_TOAST';

export const state = () => ({
	[MATCHING_EDUCATION]: Object.freeze({
		academicLevel: null,
		from: null,
		major: null,
		isHighSchool: false,
	}),
	[MATCHING_RESUME]: Object.freeze({
		path: null,
		display: null,
		type: null,
	}),
	[MATCHING_ASSIGNMENT]: Object.freeze({
		campId: null,
		campTitle: null,
		assignmentId: null,
		assignmentTitle: null,
	}),
	[MATCHING_CAMPS]: Object.freeze([]),
	[IS_EDUCATION_FILLED]: true,
	[IS_RESUME_FILLED]: true,
	[IS_ASSIGNMENT_FILLED]: true,
	[MODAL_VISIBILITY]: {
		start: false,
		description: false,
		agreement: false,
	},
	[APPLIED]: false,
	[MATCHING_TOAST]: {
		message: '',
		type: 'basic',
		position: 'top',
	},
	[MATCHING_OPT_IN]: false,
});

export const getters = {
	[GET_MATCHING_EDUCATION]: state => state[MATCHING_EDUCATION],
	[GET_MATCHING_RESUME]: state => state[MATCHING_RESUME],
	[GET_MATCHING_ASSIGNMENT]: state => state[MATCHING_ASSIGNMENT],
	[GET_MATCHING_INFO]: state => {
		return {
			education: state[MATCHING_EDUCATION],
			resume: state[MATCHING_RESUME],
			assignment: state[MATCHING_ASSIGNMENT],
		};
	},
	[GET_MATCHING_CAMPS]: state => state[MATCHING_CAMPS],
	[IS_EDUCATION_COMPLETE]: state => {
		const { academicLevel, from, major } = state[MATCHING_EDUCATION];
		return Boolean(academicLevel && from && major);
	},
	[IS_RESUME_COMPLETE]: state => {
		const { display, type } = state[MATCHING_RESUME];
		return Boolean(display && type);
	},
	[IS_ASSIGNMENT_COMPLETE]: state => {
		const { campId, assignmentId } = state[MATCHING_ASSIGNMENT];
		return Boolean(campId && assignmentId);
	},
	[IS_EDUCATION_FILLED]: state => state[IS_EDUCATION_FILLED],
	[IS_RESUME_FILLED]: state => state[IS_RESUME_FILLED],
	[IS_ASSIGNMENT_FILLED]: state => state[IS_ASSIGNMENT_FILLED],
	[IS_START_MODAL_VISIBLE]: state => state[MODAL_VISIBILITY].start,
	[IS_DESCRIPTION_MODAL_VISIBLE]: state => state[MODAL_VISIBILITY].description,
	[IS_AGREEMENT_MODAL_VISIBLE]: state => state[MODAL_VISIBILITY].agreement,
	[IS_APPLIED]: state => state[APPLIED],
	[GET_MATCHING_TOAST]: state => state[MATCHING_TOAST],
	[GET_MATCHING_OPT_IN]: state => state[MATCHING_OPT_IN],
};

export const mutations = {
	[SET_MATCHING_EDUCATION]: (state, payload) => (state[MATCHING_EDUCATION] = Object.freeze(payload)),
	[SET_MATCHING_RESUME]: (state, payload) => (state[MATCHING_RESUME] = Object.freeze(payload)),
	[SET_MATCHING_ASSIGNMENT]: (state, payload) => (state[MATCHING_ASSIGNMENT] = Object.freeze(payload)),
	[SET_MATCHING_CAMPS]: (state, payload) => (state[MATCHING_CAMPS] = Object.freeze(payload)),
	[SET_EDUCATION_FILLED]: (state, payload) => (state[IS_EDUCATION_FILLED] = payload),
	[SET_RESUME_FILLED]: (state, payload) => (state[IS_RESUME_FILLED] = payload),
	[SET_ASSIGNMENT_FILLED]: (state, payload) => (state[IS_ASSIGNMENT_FILLED] = payload),
	[SET_START_MODAL_VISIBLE]: (state, payload) => (state[MODAL_VISIBILITY].start = payload),
	[SET_DESCRIPTION_MODAL_VISIBLE]: (state, payload) => (state[MODAL_VISIBILITY].description = payload),
	[SET_AGREEMENT_MODAL_VISIBLE]: (state, payload) => (state[MODAL_VISIBILITY].agreement = payload),
	[SET_APPLIED]: (state, payload) => (state[APPLIED] = payload),
	[SET_MATCHING_TOAST]: (state, payload) => (state[MATCHING_TOAST] = { ...state[MATCHING_TOAST], ...payload }),
	[SET_MATCHING_OPT_IN]: (state, payload) => (state[MATCHING_OPT_IN] = payload),
};

export const actions = {
	[SHOW_MATCHING_TOAST]: ({ state }) => {
		Toast.show(state[MATCHING_TOAST]);
	},
};
