import {
	CASE_CATEGORY_ONLINE_MENTOR,
	CASE_CATEGORY_ONLINE_MENTOR_NEW,
	CASE_CATEGORY_WORKING_LEVEL_SKILL,
	CASE_CATEGORY_WORKING_LEVEL_SKILL_NEW,
} from '@/plugins/constants';
import dayjs from '@/plugins/dayjs';

import { moveToLaravelPage } from '@/utils/utils';

export function getFormatCount(count = 0, max = 999, format = '+') {
	return count > max ? `${max}${format}` : count;
}

export function getBookmarkUpdatedData(originalData, isBookmarked) {
	const copiedData = { ...originalData };
	copiedData.bookmarkCount = isBookmarked ? copiedData.bookmarkCount + 1 : copiedData.bookmarkCount - 1;
	copiedData.isBookmarked = isBookmarked;
	return copiedData;
}

/**
 * 커뮤니티 카테고리 이름 보여주기
 * @param category: 카테고리명
 * @param removeWhitespace: true면 공백 제거
 */
export function getCommunityCategoryName(category, removeWhitespace) {
	if (category) {
		// 이름 바꾸기(실무스킬 -> 실무 고민, 랜선 사수 -> 직장인 고민)
		const changeCategoryName = category => {
			return (
				{
					[CASE_CATEGORY_WORKING_LEVEL_SKILL]: CASE_CATEGORY_WORKING_LEVEL_SKILL_NEW,
					[CASE_CATEGORY_ONLINE_MENTOR]: CASE_CATEGORY_ONLINE_MENTOR_NEW,
				}[category] ?? category
			);
		};
		if (removeWhitespace) {
			return changeCategoryName(category).toString().replace(/\s+/g, '');
		}
		return changeCategoryName(category);
	}
	return null;
}

export const isFirstIndex = index => index === 0;

// - 시작일 : 4/5(수) 00:00
// - 종료일 : 4/14(금) 23:59
// - 노출일 : 4/5(수) ~ 4/14(금)
export const isTodayBetweenSpartaAdDays = today => {
	return dayjs(today).isBetween(dayjs('2023-04-05'), dayjs('2023-04-14'), 'day', []);
};

export const showSpartaAd = (today, index) => {
	return isTodayBetweenSpartaAdDays(today) && isFirstIndex(index);
};

export const navigateToPriceList = (product, id) => {
	moveToLaravelPage(`/price/list/contents?product=${product}&id=${id}`);
};
