
import layoutMixin from '@/mixins/layoutMixin';

import Skeleton from '@/src/components/Other/Skeleton.vue';

export default {
	name: 'CaseDetailQuestionSkeleton',
	mixins: [layoutMixin],
	components: { Skeleton },
};
