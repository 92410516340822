import { TOP_BAR_ACTIONS } from '@comento/common-components';

import { IS_MOBILE, SET_IS_LOGO_TOP_BAR, SET_TOP_BAR_BUTTONS, SET_IS_SHOW_BOTTOM_NAV } from '@/store/layout';

const productDetailPages = [
	'edu-learn-top-camp',
	'edu-path-learn-top-camp',
	'career-externship-top-camp',
	'class-pt-content-title-classNo',
	'vod-lecture-top-title',
	'class-vod-detail-sample-no',
	'class-vod-detail-no',
];

export default function ({ store, route }) {
	const { name } = route;
	if (!productDetailPages.includes(name)) {
		return;
	}
	const isBackwardType = store.getters.isUser;
	const isMobile = store.getters[`layout/${IS_MOBILE}`];

	store.commit(`setMetaIsDetail`, isMobile ? isBackwardType : true);
	store.commit(`layout/${SET_IS_SHOW_BOTTOM_NAV}`, false);
	store.commit(`layout/${SET_IS_LOGO_TOP_BAR}`, !isBackwardType);
	store.commit(`layout/${SET_TOP_BAR_BUTTONS}`, [TOP_BAR_ACTIONS.SEARCH]);
}
