import appCommandMixin from '@/mixins/appCommandMixin';
import layoutMixin from '@/mixins/layoutMixin';

const routeMixin = {
	mixins: [layoutMixin, appCommandMixin],
	methods: {
		// 구글 폼은 단축링크 사용 시 앱에서 안 열리는 이슈가 있어서 지양해주세요.
		// ex) https://forms.gle/zVtUwvp2rW3ZfDkx9
		// TODO : url 검사해서 구글 폼 단축링크이면 예외 발생시키기
		$_routeMixin_open_page(url) {
			return this.IS_APP ? this.$_app_openExternalBrowser(url) : window.open(url, '_blank');
		},
		$_routeMixin_href(path = process.env.baseUrl) {
			location.href = path;
		},
		$_routeMixin_go_page(path) {
			this.$router.push(path);
		},
		$_routeMixin_go_back() {
			this.$router.go(-1);
		},
		$_routeMixin_replace(path) {
			this.$router.replace(path);
		},
	},
};
export default routeMixin;
