
import { ComentoFooter, BottomNav } from '@comento/common-components';
import { mapGetters } from 'vuex';

import { CLASS_VOD_DETAIL_PATH } from '@/constants/paths';

import {
	IS_SHOW_FOOTER,
	IS_SHOW_BOTTOM_NAV,
	IS_LOGO_TOP_BAR,
	IS_FOOTER_SLIM,
	mapGettersOfLayout,
	HAS_FOOTER_MARGIN,
} from '@/store/layout';

import layoutMixin from '@/mixins/layoutMixin';

import BackwardButton from '@/src/components/Navigation/BackwardButton.vue';

export default {
	name: 'FooterLayout',
	mixins: [layoutMixin],
	computed: {
		...mapGetters(['metaIsDetail']),
		...mapGettersOfLayout([IS_SHOW_FOOTER, IS_SHOW_BOTTOM_NAV, IS_LOGO_TOP_BAR, HAS_FOOTER_MARGIN, IS_FOOTER_SLIM]),
		urlPath() {
			if (process.server) {
				return this.$route.path;
			} else {
				return location.pathname;
			}
		},
		isLogoTopBarPage() {
			if (this.urlPath) {
				return;
			}
			const logoHeaderPages = ['job-questions/', 'edu/learn/', CLASS_VOD_DETAIL_PATH];
			let isLogoHeaderPage = false;
			// eslint-disable-next-line array-callback-return
			logoHeaderPages.find(pagePath => {
				if (this.urlPath.includes(pagePath)) {
					isLogoHeaderPage = this.urlPath.includes(pagePath);
				}
			});
			return this.metaIsDetail && isLogoHeaderPage;
		},
		/**
		 * 모바일웹에서는 서비스별 메인페이지에서만 푸터를 보여주기
		 * @returns {boolean}
		 */
		isShowFooterForWeb() {
			if (this.IS_PC) {
				return true;
			}
			const landingRouteNamesByProduct = [
				'index',
				'class',
				'edu',
				'class-pt',
				'search-product-keyword',
				'career-recruit-recruitIdWithCompanyName',
				'career-recruit',
				'kdigital',
				'product-b2b_gai_class',
			];
			return landingRouteNamesByProduct.includes(this.$route.name);
		},
		isShowFooter() {
			return !this.IS_APP && this.isShowFooterForWeb && this.IS_SHOW_FOOTER;
		},
		isShowBottomNav() {
			const { path, name, params, query } = this.$route;

			return (
				// EID CB101
				!path.startsWith('/class/pt') &&
				!(name === 'search-product-keyword' && params.product === 'pt' && query.origin === 'pt') &&
				!this.metaIsDetail &&
				!this.IS_APP &&
				this.IS_SHOW_BOTTOM_NAV
			);
		},
		shouldHaveMargin() {
			if (this.urlPath.includes('/survey')) {
				return false;
			}
			return !this.metaIsDetail && this[HAS_FOOTER_MARGIN];
		},
	},
	components: {
		BottomNav,
		BackwardButton,
		ComentoFooter,
	},
};
