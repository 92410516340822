export const state = () => ({
	campTitle: '',
	campUrl: '',
});

export const getters = {
	campTitle: state => {
		return state.campTitle;
	},
	campUrl: state => {
		return state.campUrl;
	},
};

export const mutations = {
	setCampTitle: (state, payload) => {
		state.campTitle = payload;
	},
	setCampUrl: (state, payload) => {
		state.campUrl = payload;
	},
};
