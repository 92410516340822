import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'classroom/path';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfClassroomEduPath,
	mapMutations as mapMutationsOfClassroomEduPath,
	mapActions as mapActionsOfClassroomEduPath,
};

export const PATH_CLASSROOM_USER_NAME = 'PATH_CLASSROOM_USER_NAME';
export const PATH_CLASSROOM_SELECTED_TAB = 'PATH_CLASSROOM_SELECTED_TAB';
export const PATH_CONTRACT_NO = 'PATH_CONTRACT_NO';
export const PATH_CONTRACT_INFO = 'PATH_CONTRACT_INFO';
export const PATH_CAMP_LIST = 'PATH_CAMP_LIST';
export const PATH_CAMP_APPLY_LIST = 'PATH_CAMP_APPLY_LIST';
export const PATH_CAMP_DETAIL_SELECTED_TAP = 'PATH_CAMP_DETAIL_SELECTED_TAP';
export const PATH_CAMP_DETAIL_SELECTED_CAMP_INFO = 'PATH_CAMP_DETAIL_SELECTED_CAMP_INFO';
export const PATH_CAMP_JOB_CONFIDENCE_MODAL = 'PATH_CAMP_JOB_CONFIDENCE_MODAL'; // 직무확신도
export const ACTIVE_PATH_JOB_CONFIDENCE_BOX = 'ACTIVE_PATH_JOB_CONFIDENCE_BOX';
export const COMPLETE_PATH_JOB_CONFIDENCE_BOX = 'COMPLETE_PATH_JOB_CONFIDENCE_BOX';
export const PATH_CAMP_CERTAINTY_MODAL = 'PATH_CAMP_CERTAINTY_MODAL'; // 진로확신도
export const PATH_CAMP_VOD_SURVEY_MODAL = 'PATH_CAMP_VOD_SURVEY_MODAL'; // 진로확신도

// mutations
export const SET_PATH_CLASSROOM_USER_NAME = 'SET_PATH_CLASSROOM_USER_NAME';
export const SET_PATH_CLASSROOM_SELECTED_TAB = 'SET_PATH_CLASSROOM_SELECTED_TAB';
export const SET_PATH_CONTRACT_NO = 'SET_PATH_CONTRACT_NO';
export const SET_PATH_CONTRACT_INFO = 'SET_PATH_CONTRACT_INFO';
export const SET_PATH_CAMP_LIST = 'SET_PATH_CAMP_LIST';
export const SET_PATH_CAMP_APPLY_LIST = 'SET_PATH_CAMP_APPLY_LIST';
export const SET_PATH_CAMP_DETAIL_SELECTED_TAP = 'SET_PATH_CAMP_DETAIL_SELECTED_TAP';
export const SET_PATH_CAMP_DETAIL_SELECTED_CAMP_INFO = 'SET_PATH_CAMP_DETAIL_SELECTED_CAMP_INFO';
export const SET_PATH_CAMP_JOB_CONFIDENCE_MODAL = 'SET_PATH_CAMP_JOB_CONFIDENCE_MODAL';
export const SET_ACTIVE_PATH_JOB_CONFIDENCE_BOX = 'SET_ACTIVE_PATH_JOB_CONFIDENCE_BOX';
export const SET_COMPLETE_PATH_JOB_CONFIDENCE_BOX = 'SET_COMPLETE_PATH_JOB_CONFIDENCE_BOX';
export const SET_PATH_CAMP_CERTAINTY_MODAL = 'SET_PATH_CAMP_CERTAINTY_MODAL';
export const SET_PATH_CAMP_VOD_SURVEY_MODAL = 'SET_PATH_CAMP_VOD_SURVEY_MODAL';

export const LOAD_PATH_CAMP_APPLY_LIST = 'LOAD_PATH_CAMP_APPLY_LIST';
export const LOAD_PATH_CAMP_LIST = 'LOAD_PATH_CAMP_LIST';
export const LOAD_SELECT_PATH_CAMP_INFO = 'LOAD_SELECT_PATH_CAMP_INFO';
export const LOAD_PATH_CONTRACT_NO = 'LOAD_PATH_CONTRACT_NO';
export const LOAD_PATH_CONTRACT_INFO = 'LOAD_PATH_CONTRACT_INFO';

export const state = () => ({
	pathClassroomUserName: null,
	pathClassroomSelectTab: null,
	pathContractNo: null,
	pathContractInfo: null,
	pathCampList: null,
	pathCampApplyList: null,
	pathCampLoaded: false,
	pathCampDetailSelectedTab: null,
	pathCampDetailSelectedCampInfo: null,
	pathCampJobConfidenceModal: false,
	activePathJobConfidenceBox: false,
	completePathJobConfidenceBox: false,
	pathCampCertaintyModal: false,
	pathCampVodSurveyModal: false,
});

export const getters = {
	[PATH_CLASSROOM_USER_NAME]: state => state.pathClassroomUserName,
	[PATH_CLASSROOM_SELECTED_TAB]: state => state.pathClassroomSelectTab,
	[PATH_CONTRACT_NO]: state => state.pathContractNo,
	[PATH_CONTRACT_INFO]: state => state.pathContractInfo,
	[PATH_CAMP_LIST]: state => state.pathCampList,
	[PATH_CAMP_APPLY_LIST]: state => state.pathCampApplyList,
	[PATH_CAMP_DETAIL_SELECTED_TAP]: state => state.pathCampDetailSelectedTab,
	[PATH_CAMP_DETAIL_SELECTED_CAMP_INFO]: state => state.pathCampDetailSelectedCampInfo,
	[PATH_CAMP_JOB_CONFIDENCE_MODAL]: state => state.pathCampJobConfidenceModal,
	[ACTIVE_PATH_JOB_CONFIDENCE_BOX]: state => state.activePathJobConfidenceBox,
	[COMPLETE_PATH_JOB_CONFIDENCE_BOX]: state => state.completePathJobConfidenceBox,
	[PATH_CAMP_CERTAINTY_MODAL]: state => state.pathCampCertaintyModal,
	[PATH_CAMP_VOD_SURVEY_MODAL]: state => state.pathCampVodSurveyModal,
};

export const mutations = {
	[SET_PATH_CLASSROOM_USER_NAME]: (state, payload) => {
		state.pathClassroomUserName = payload;
	},
	[SET_PATH_CLASSROOM_SELECTED_TAB]: (state, payload) => {
		state.pathClassroomSelectTab = payload;
	},
	[SET_PATH_CONTRACT_NO]: (state, payload) => {
		state.pathContractNo = payload;
	},
	[SET_PATH_CONTRACT_INFO]: (state, payload) => {
		state.pathContractInfo = payload;
	},
	[SET_PATH_CAMP_LIST]: (state, payload) => {
		state.pathCampList = payload;
	},
	[SET_PATH_CAMP_APPLY_LIST]: (state, payload) => {
		state.pathCampApplyList = payload;
	},
	[SET_PATH_CAMP_DETAIL_SELECTED_TAP]: (state, payload) => {
		state.pathCampDetailSelectedTab = payload;
	},
	[SET_PATH_CAMP_DETAIL_SELECTED_CAMP_INFO]: (state, payload) => {
		state.pathCampDetailSelectedCampInfo = payload;
	},
	[SET_PATH_CAMP_JOB_CONFIDENCE_MODAL]: (state, payload) => {
		state.pathCampJobConfidenceModal = payload;
	},
	[SET_ACTIVE_PATH_JOB_CONFIDENCE_BOX]: (state, payload) => {
		state.activePathJobConfidenceBox = payload;
	},
	[SET_COMPLETE_PATH_JOB_CONFIDENCE_BOX]: (state, payload) => {
		state.completePathJobConfidenceBox = payload;
	},
	[SET_PATH_CAMP_CERTAINTY_MODAL]: (state, payload) => {
		state.pathCampCertaintyModal = payload;
	},
	[SET_PATH_CAMP_VOD_SURVEY_MODAL]: (state, payload) => {
		state.pathCampVodSurveyModal = payload;
	},
};

export const actions = {
	[LOAD_PATH_CONTRACT_NO]({ commit }, fullPath) {
		const contractNo = fullPath.match(/\/path\/(\d+)/);
		commit(SET_PATH_CONTRACT_NO, contractNo ? contractNo[1] : null);
	},
	async [LOAD_PATH_CAMP_APPLY_LIST]({ getters, commit }) {
		const { data } = await this.$api.myPage.getEduPathApplicantList({ contract_no: getters.PATH_CONTRACT_NO });

		commit(SET_PATH_CLASSROOM_USER_NAME, data.name);
		commit(SET_PATH_CAMP_APPLY_LIST, data.data);
	},
	async [LOAD_PATH_CAMP_LIST]({ getters, commit }) {
		try {
			const {
				data: { data },
			} = await this.$api.myPage.getEduPathCartList({
				contract_no: getters.PATH_CONTRACT_NO,
			});

			commit(SET_PATH_CAMP_LIST, data);
		} catch (error) {
			console.log(error);
		}
	},
	async [LOAD_SELECT_PATH_CAMP_INFO]({ getters, commit, dispatch }, campNo) {
		try {
			const params = {
				camp_no: campNo,
				contract_no: getters.PATH_CONTRACT_NO,
			};

			const {
				data: { data },
			} = await this.$api.camp.getEduPathCampInfo(params);

			if (!getters.PATH_CAMP_APPLY_LIST) {
				dispatch(LOAD_PATH_CAMP_APPLY_LIST);
			}
			const targetIndex = getters.PATH_CAMP_APPLY_LIST.findIndex(obj => obj.camp_no === data.camp_no);
			commit(SET_PATH_CAMP_DETAIL_SELECTED_CAMP_INFO, { ...data, ...getters.PATH_CAMP_APPLY_LIST[targetIndex] });
		} catch (error) {
			console.log(error);
		}
	},
	async [LOAD_PATH_CONTRACT_INFO]({ commit, getters }, contractNo) {
		try {
			if (contractNo) {
				commit(SET_PATH_CONTRACT_NO, contractNo);
			}
			const {
				data: { data },
			} = await this.$api.myPage.totalEduPathInfo({
				contract_no: getters.PATH_CONTRACT_NO,
			});
			commit(SET_PATH_CONTRACT_INFO, data);
		} catch (error) {
			console.error(error);
		}
	},
};
