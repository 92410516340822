import _orderBy from 'lodash/orderBy';
import { createNamespacedHelpers } from 'vuex';

import { EDUCATION_ACTIVITY_LOGS_EVENT_TYPE } from '@/constants/community';

const NAMESPACE = 'community/timeline';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfCommunityTimeline,
	mapMutations as mapMutationsOfCommunityTimeline,
	mapActions as mapActionsOfCommunityTimeline,
};

// states
export const POSTS = 'POSTS';
export const INFINITE_ID = 'INFINITE_ID';
export const POSTS_PARAMS = 'POSTS_PARAMS';
export const EDU_APPLICANT_INFO = 'EDU_APPLICANT_INFO';
export const EDU_ASSIGNMENT_FEEDBACK_INFO = 'EDU_ASSIGNMENT_FEEDBACK_INFO';
export const EDUCATION_ACTIVITY_LOGS = 'EDUCATION_ACTIVITY_LOGS';
export const defaultActivityLogs = null;
// getters
export const GET_POSTS = 'GET_POSTS';
export const GET_INFINITE_ID = 'GET_INFINITE_ID';
export const GET_POSTS_PARAMS = 'GET_POSTS_PARAMS';
export const GET_EDU_APPLICANT_INFO = 'GET_EDU_APPLICANT_INFO';
export const GET_EDU_ASSIGNMENT_FEEDBACK_INFO = 'GET_EDU_ASSIGNMENT_FEEDBACK_INFO';

// mutations
export const SET_POSTS = 'SET_POSTS';
export const SET_INFINITE_ID = 'SET_INFINITE_ID';
export const SET_POSTS_PARAMS = 'SET_POSTS_PARAMS';
export const SET_EDU_APPLICANT_INFO = 'SET_EDU_APPLICANT_INFO';
export const SET_EDU_ASSIGNMENT_FEEDBACK_INFO = 'SET_EDU_ASSIGNMENT_FEEDBACK_INFO';
export const SET_EDUCATION_ACTIVITY_LOGS = 'SET_EDUCATION_ACTIVITY_LOGS';

// actions
export const FETCH_POSTS = 'FETCH_POSTS';
export const FETCH_EDU_APPLICANT_INFO = 'FETCH_EDU_APPLICANT_INFO';
export const FETCH_OPENED_LIVE_CLASS_PT_LOGS = 'FETCH_OPENED_LIVE_CLASS_PT_LOGS';
export const LOAD_EDUCATION_ACTIVITY_LOGS = 'LOAD_EDUCATION_ACTIVITY_LOGS';

export const state = () => ({
	[POSTS]: [],
	[INFINITE_ID]: +new Date(),
	[POSTS_PARAMS]: {
		page: 1,
		perPage: 16,
	},
	[EDU_APPLICANT_INFO]: null,
	[EDU_ASSIGNMENT_FEEDBACK_INFO]: null,
	/*
	 * [EDUCATION_ACTIVITY_LOGS]
	 * @type {Array}
	 *
	 * @typedef {Object} EDUCATION_ACTIVITY_LOG
	 * @property {string} title - 강의 제목
	 * @property {number} camp_no - 캠프 번호
	 * @property {number} group_no - 그룹 번호
	 * @property {string} top_category - 상위 카테고리
	 * @property {string} mid_category - 중위 카테고리
	 * @property {string} mentor_nickname - 멘토 닉네임
	 * @property {string} display_name - 멘토 경력
	 * @property {string} img_url - 썸네일 이미지 URL
	 * @property {number} applicant - 신청자 수
	 * @property {Array} user - 신청자 정보
	 * @property {string} user[].apply_at - 신청 시간
	 * @property {string} user[].profile - 프로필 이미지 URL
	 * @property {string} user[].name - 신청자 이름
	 * @property {string} url - 강의 URL
	 * @property {string} created_at - 생성 시간
	 * @property {string} event_type - 이벤트 타입
	 */
	[EDUCATION_ACTIVITY_LOGS]: defaultActivityLogs,
});

export const getters = {
	[GET_POSTS]: state => state[POSTS],
	[GET_INFINITE_ID]: state => state[INFINITE_ID],
	[GET_POSTS_PARAMS]: state => state[POSTS_PARAMS],
	[GET_EDU_APPLICANT_INFO]: state => state[EDU_APPLICANT_INFO],
	[GET_EDU_ASSIGNMENT_FEEDBACK_INFO]: state => state[EDU_ASSIGNMENT_FEEDBACK_INFO],
	[EDUCATION_ACTIVITY_LOGS]: state => state[EDUCATION_ACTIVITY_LOGS],
};

export const mutations = {
	[SET_POSTS]: (state, payload) => {
		state[POSTS] = payload;
	},
	[SET_INFINITE_ID]: (state, payload) => {
		state[INFINITE_ID] = payload;
	},
	[SET_POSTS_PARAMS]: (state, payload) => {
		state[POSTS_PARAMS] = payload;
	},
	[SET_EDU_APPLICANT_INFO]: (state, payload) => {
		state[EDU_APPLICANT_INFO] = payload;
	},
	[SET_EDU_ASSIGNMENT_FEEDBACK_INFO]: (state, payload) => {
		state[EDU_ASSIGNMENT_FEEDBACK_INFO] = payload;
	},
	[SET_EDUCATION_ACTIVITY_LOGS]: (state, payload) => {
		state[EDUCATION_ACTIVITY_LOGS] = payload;
	},
};

export const actions = {
	[FETCH_POSTS](_, params) {
		return new Promise((resolve, reject) => {
			const fetchPosts = this.$api.timeline.fetchTimeline(params);
			fetchPosts
				.then(response => {
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	async [FETCH_EDU_APPLICANT_INFO]() {
		try {
			const { data } = await this.$api.timeline.fetchTimelineEduApplicant();
			return data.list;
		} catch (error) {
			console.error('FETCH_EDU_APPLICANT_INFO error: ', error);
			return [];
		}
	},
	async [FETCH_OPENED_LIVE_CLASS_PT_LOGS]() {
		try {
			const { data } = await this.$api.timeline.fetchOpenedLiveClassPtLogs();
			return data;
		} catch (error) {
			console.error('FETCH_OPENED_LIVE_CLASS_PT_LOGS error: ', error);
			return [];
		}
	},
	async [LOAD_EDUCATION_ACTIVITY_LOGS]({ commit, dispatch }, hasCareer) {
		try {
			let promises = [];
			if (hasCareer) {
				// 경력이면 실무PT
				promises = [
					[...(await dispatch(FETCH_OPENED_LIVE_CLASS_PT_LOGS))].map(v => ({
						...v,
						event_type: EDUCATION_ACTIVITY_LOGS_EVENT_TYPE.OPENED_LIVE_CLASS_PT,
					})),
				];
			} else {
				promises = [
					[...(await dispatch(FETCH_EDU_APPLICANT_INFO))].map(v => ({
						...v,
						event_type: EDUCATION_ACTIVITY_LOGS_EVENT_TYPE.EDU_APPLICANT,
					})),
				];
			}

			const educationActivityLogs = await Promise.all(promises);
			commit(SET_EDUCATION_ACTIVITY_LOGS, _orderBy(...educationActivityLogs, 'created_at', 'desc'));
		} catch (error) {
			console.error('LOAD_EDUCATION_ACTIVITY_LOGS error: ', error);
		}
	},
};
