
import { moveToLaravelPage } from '@/utils/utils';

import { GET_PASS_INFO, mapGettersOfMyPage } from '@/store/myPage';

export default {
	name: 'MyPageToAffiliateBanner',
	computed: {
		...mapGettersOfMyPage([GET_PASS_INFO]),
		userUniv() {
			return this[GET_PASS_INFO].userInfo.univ;
		},
	},
	methods: {
		clickAffiliateButton() {
			moveToLaravelPage('/affiliated-university');
		},
	},
};
