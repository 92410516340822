export const DREAMVERSE_COMPANY = '드림버스컴퍼니';

export const DREAMVERSE_SITES_TITLE = {
	HOME: '홈페이지',
	YOUTUBE: '유튜브',
	BLOG: '블로그',
};

export const DREAMVERSE_PARTNER_TYPE = {
	HANSOL: 'hansol',
	YBM: 'YBM',
	INTERNZ: 'internz',
	HUMAX: 'humax',
	ISU: 'isu',
};

export const DREAMVERSE_PARTNER_NAME = {
	HANSOL: '한솔그룹',
	YBM: 'YBM',
	INTERNZ: '스타트업 마케팅 인턴즈',
	HUMAX: '휴맥스',
	ISU: '이수그룹',
};
