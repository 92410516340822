
import RegisterButtons from '@/components/common/atoms/RegisterButtons.vue';

export default {
	name: 'SocialLogin',
	props: {
		loginTypes: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		login(loginType) {
			this.$emit('login', loginType);
		},
	},
	components: {
		RegisterButtons,
	},
};
