import https from 'https';

import { parseAndSetCookies } from '@/utils/cookie';

import { isProduction } from './constants';

// for multiple requests
const processQueue = (error, token = null, $axios) => {
	$axios.failedQueue.forEach(prom => {
		if (error) {
			prom.reject(error);
		} else {
			prom.resolve(token);
		}
	});

	$axios.failedQueue = [];
};

// const excluded403Paths = [
// 	'/api/edu/classroom/assignment',
// 	'/api/classroom/assignment/',
// 	'/api/vod-lecture/assignment/upload',
// ];

export default function ({ store, $axios, redirect, $customLogger, $config, app }) {
	const NODE_ENV = process.env.NODE_ENV;
	const baseUrl = $config.baseUrl;
	if (!isProduction) {
		console.log('axios NODE_ENV : ', NODE_ENV);
		console.log('axios baseUrl : ', baseUrl);
	}
	if (NODE_ENV === 'develop' || NODE_ENV === 'stage' || NODE_ENV === 'qa') {
		// production이 아닐 때 server에서 보내는 request의 user-agent를 변경 (미들웨어 통과)
		if (process.server) {
			$axios.setHeader('user-agent', 'kube-probe/1.18+');
		}
	}

	$axios.onRequest(config => {
		if (!$axios.failedQueue) {
			$axios.failedQueue = [];
		}

		// local certificate issue 해결
		// const userAgent = config.headers.common['user-agent'].includes('Chrome') ? 'Chrome' : 'Not Chrome';
		if (!isProduction) {
			config.httpsAgent = new https.Agent({
				rejectUnauthorized: false,
			});
		}
		return config;
	});

	$axios.onResponseError(err => {
		const code = parseInt(err.response && err.response.status);
		const originalRequest = err.config;
		// const url = err.config.url;

		try {
			if (code === 400) {
				const message = err.response && err.response.data && err.response.data.message;
				const isAuthSessionIssue = message === 'This user dont have Auth Session';
				// 라라벨 세션이 없는 경우에는 debug
				$customLogger(
					'nuxt axios 400 error',
					{
						err: message,
						userNo: (store.state.userInfo && store.state.userInfo.no) || '없음',
						env: NODE_ENV,
					},
					isAuthSessionIssue ? 'debug' : 'error',
				);
				redirect(`${baseUrl}/logout`);
			}

			// jwt token이 없거나 만료되었을 때 401 && 리트라이 한적 없을 때
			if (code === 401 && !originalRequest._retry) {
				if ($axios._isRefreshing) {
					return new Promise(function (resolve, reject) {
						$axios.failedQueue.push({ resolve, reject });
					})
						.then(token => {
							originalRequest.headers.Authorization = 'Bearer ' + token;
							return $axios(originalRequest);
						})
						.catch(err => {
							return Promise.reject(err);
						});
				}

				originalRequest._retry = true;
				$axios._isRefreshing = true;

				return refreshToken($axios, store, originalRequest, app.$cookies);
			}

			// 클래스룸 권한없음
			// if (code === 403) {
			// 	$customLogger(
			// 		'nuxt axios 403 error',
			// 		{
			// 			err: `${err.response && err.response.data && JSON.stringify(err.response.data)}`,
			// 			userNo: store.state.userInfo && store.state.userInfo.no,
			// 			env: NODE_ENV,
			// 			token: err.config.headers.Authorization,
			// 			path: url,
			// 			params: err.config.params || null,
			// 			data: err.config.data || null,
			// 			server: err.response.headers.server,
			// 		},
			// 		'error',
			// 	);
			// 	// 다른 사람 과제 열람하는 경우에는 client에서 error handling
			// 	if (!excluded403Paths.some(path => url.startsWith(path))) {
			// 		store.commit('error/setErrorModalTitle', '권한 없음');
			// 		store.commit(
			// 			'error/setErrorModalContent',
			// 			'<div>권한이 없습니다. 재로그인 후 이용부탁드립니다.</div>',
			// 		);
			// 		store.commit('error/openErrorModal');
			// 		redirect(`${baseUrl}/login`);
			// 	}
			// }
		} catch (error) {
			console.error('onResponseError catch error : ', error);
		}
	});
}

async function refreshToken($axios, store, originalRequest, cookies) {
	try {
		store.commit('setJwt', 'fail');
		const { data, headers } = await $axios.get('/api/auth/jwt/refresh');
		store.commit('setJwt', data.token);
		const parsedSetHeader = parseAndSetCookies(headers['set-cookie']);
		parsedSetHeader.forEach(options => {
			cookies.set(options.name, options.value, options);
		});
		$axios.setToken(data.token, 'Bearer');
		originalRequest.headers.Authorization = 'Bearer ' + data.token;
		processQueue(null, data.token, $axios);
		return $axios(originalRequest);
	} catch (error) {
		processQueue(error, null, $axios);
		throw error;
	} finally {
		$axios._isRefreshing = false;
	}
}
