// import { MY_PAGE_CLASSROOM_PATH } from '~/constants/paths/my-page';
export async function handlePtClassroom({ route, params, $api, redirect, commit, dispatch }) {
	let ptNo = params.no;
	// assignment로 바로 들어가는 경우
	if (route.name && route.name.includes('assignment')) {
		const assignmentNo = route.params.no;
		try {
			const {
				data: { classId, check },
			} = await $api.classroomPt.checkPtMentorWithAssignmentNo(assignmentNo);
			ptNo = classId;
			commit('classroom/pt/SET_IS_PT_MENTOR', check);
			commit('classroom/pt/SET_IS_PT_MENTEE', !check);
			commit('classroom/pt/SET_PT_NO', { ptNo });
		} catch (error) {
			console.error(error);
			// $customLogger(
			// 	'/api/edu/classroom/assignment/check/mentor 실패',
			// 	{
			// 		error,
			// 	},
			// 	'debug',
			// );
		}
	} else {
		if (!ptNo) {
			redirect(`/mypage/classroom`);
		}
		commit('classroom/pt/SET_PT_NO', { ptNo });
		commit('classroom/pt/SET_IS_PT_CLASSROOM', !!ptNo);

		await dispatch('classroom/pt/FETCH_CHECK_PT_MENTOR', ptNo);
	}
}
