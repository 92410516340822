
import Lottie from 'vue-lottie/src/lottie.vue';

export default {
	name: 'LoadingWithOverlay',
	props: {
		spinner: {
			type: Object,
			default: null,
		},
	},
	computed: {
		options() {
			return {
				animationData: this.spinner,
			};
		},
	},
	components: {
		Lottie,
	},
};
