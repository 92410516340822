const CLASS_ON_AIR_API_PREFIX = `/api/class/onair`;

export default axios => ({
	/*
	 * 온에어 클래스 리스트 가져오기
	 * type : job, normal
	 * */
	getOnAirClassList(type) {
		const params = {
			limit: 9999,
		};
		return axios.get(`${CLASS_ON_AIR_API_PREFIX}/list/${type}`, { params });
	},
	/*
	 * 온에이 디테일 가져오기
	 * @route classId
	 * */
	getOnAirClassDetail(classId) {
		return axios.post(`${CLASS_ON_AIR_API_PREFIX}/detail/${classId}`);
	},
});
