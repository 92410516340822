
import { defineComponent } from 'vue';

import RemoteSvg from '@/components/common/atoms/RemoteSvg.vue';

export default defineComponent({
	name: 'CaseListServiceButton',
	props: {
		buttonInfo: {
			type: Object,
			default() {
				return {};
			},
		},
		compact: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		boxPaddings() {
			return this.compact ? [8, 8, 8, 8] : [12, 4, 12, 4];
		},
		graphicSize() {
			return this.compact ? 24 : 44;
		},
		titleType() {
			return this.compact ? 'body2' : 'body1';
		},
		imageSrc() {
			if (this.compact) {
				return this.buttonInfo.compactImageSrc;
			}
			return this.buttonInfo.imageSrc;
		},
	},
	components: { RemoteSvg },
});
