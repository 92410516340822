export const MY_PAGE_PREFIX_URL = `/api/mypage`;
export const MY_PAGE_PREFIX_URL_NEW = '/api/my-page';

export default axios => ({
	/**
	 * 이용권 정보
	 */
	getPassInfo() {
		return axios.get(`${MY_PAGE_PREFIX_URL}/passInfo`);
	},
	/**
	 * @params profile_img: 파일 이름과 확장자(ex. file.jpg)
	 */
	updateMyPageProfile(data) {
		return axios.post(`${MY_PAGE_PREFIX_URL}/updateProfile`, data);
	},
	/**
	 * 합격한 멘티 보기
	 * @apiParam select: ''(전체보기) || message(내가 받은 감사 인사) || who(우리 회사에 취업한 멘티 - deprecated)
	 * @apiParam page: 페이지 번호
	 */
	fetchThanks(data) {
		return axios.post(`${MY_PAGE_PREFIX_URL}/getMyMentee`, data);
	},
	/**
	 * 알림 설정 가져오기
	 */
	fetchNotiSetting() {
		return axios.get(`${MY_PAGE_PREFIX_URL}/notification/receive/information`);
	},
	/**
	 * 핸드폰번호 변경하기
	 * @param {Object} params
	 * @param {number} params.mobile_num: 핸드폰번호
	 */
	updatePhoneNumber(params) {
		return axios.post(`${MY_PAGE_PREFIX_URL}/postMobileNum`, params);
	},
	/**
	 * 알림 설정 변경하기
	 * @apiParam {String} email_type: notice(코멘토 활동관련 알림) || marketing(혜택 등 유용한 정보)
	 * @apiParam {Boolean} agree: 동의 여부
	 */
	updateNotiAgreement(data) {
		return axios.post(`${MY_PAGE_PREFIX_URL}/notification/receive/agree`, data);
	},
	/**
	 * AI 분석기 페이지 별 이용 내역
	 * @apiParam {Number} type: 0(all) || 1 (job)
	 * @apiParam {Number} page
	 * @apiParam {Number} limit
	 * @apiParam {Number} job_no: 0(all) || 1(직무 번호 - deprecated)
	 */
	getAIAnalyticsLog(params) {
		return axios.get(`${MY_PAGE_PREFIX_URL}/getAIAnalyticsLog`, { params });
	},
	/**
	 * AI 분석기 페이지 view 호출
	 * @apiParam {Number} no
	 */
	analyticsView(data) {
		return axios.post(`/api/analytics/view`, data);
	},
	/**
	 * AI 분석기에서 상담사 선생님 상품을 쓰는 고객인지 체크
	 */
	getIsOrgTeacher() {
		return axios.post(`/api/org/checkTeacher`);
	},
	/**
	 * 회원 탈퇴하기
	 * @apiParam { String } message: 탈퇴 사유
	 */
	deleteUser(data) {
		return axios.delete(`/api/user/delete`, data);
	},
	/**
	 * 코멘토샵 리스트
	 */
	fetchGiftishowList() {
		return axios.get(`/api/giftishow/list`);
	},
	/**
	 * 자기소개서 리스트 가져오기
	 */
	fetchEssayList() {
		return axios.get(`${MY_PAGE_PREFIX_URL}/getMyResume`);
	},
	/**
	 * 이력(이력서, 자소서, 추가 자료) 이름 변경
	 * @param {Object} params
	 * @param {number} params.resume_no
	 * @param {string} params.alias
	 */
	updateEssayAlias(params) {
		return axios.patch(`/api/resume/alias`, params);
	},
	/**
	 * 이력(이력서, 자소서, 추가 자료) 삭제
	 * @param {Object} params
	 * @param {number} params.resume_no
	 * @param {number} params.user_no
	 */
	deleteEssay(params) {
		return axios.delete(`/api/resume/delete`, { params });
	},
	/**
	 * 현금 환급 내역
	 * @apiParam {Number} page
	 * @apiParam {Number} per_page
	 */
	fetchMyCreditRefund(data) {
		return axios.post(`${MY_PAGE_PREFIX_URL}/getMyCreditRefund`, data);
	},
	/**
	 * 프로필 내 질문 정보 가져오기
	 * @apiParam {String} select: all(총) || waiting(미채택)
	 * @apiParam {Number} page: 1 (고정)
	 */
	getMyRequestInfo(params) {
		return axios.get(`${MY_PAGE_PREFIX_URL}/getRequest`, { params: { ...params, page: 1 } });
	},
	/**
	 * profile 대시보드 정보 가져오기
	 * @apiParam {Number} user_type
	 */
	getMyProfileInfo(params) {
		return axios.get(`${MY_PAGE_PREFIX_URL}/getDashBoardInfo`, { params });
	},
	/**
	 * 내 답변 정보 가져오기
	 */
	getMyAnswerInfo() {
		return axios.get('/api/answer/userInfo');
	},
	/**
	 * 내 커뮤니티 활동 내역 가져오기
	 * @param {Object} params
	 * @param {string} params.type all | article | comment | liked_article | liked_comment
	 * @param {number} params.page 페이지 번호
	 * @param {number} params.per_page 한 페이지당 출력 수
	 */
	getMyCommunityActivities(params) {
		return axios.get(`${MY_PAGE_PREFIX_URL_NEW}/community/activity`, { params });
	},
	/**
	 * 내가 한 멘토링 요청 갱신하기
	 * @param {Object} params
	 * @param {number} params.req_no request 번호
	 */
	renewMyRequest(params) {
		return axios.put('/api/request/renewal', params);
	},
	/**
	 * 내가 한 멘토링 요청 취소하기
	 * @param {Object} params
	 * @param {number} params.req_no request 번호
	 */
	cancelMyRequest(params) {
		return axios.delete('/api/request/delete', { params });
	},
	/**
	 * 크레딧 사용내역
	 * @param {Object} params
	 * @param {number} params.page
	 * @param {number} params.per_page
	 */
	fetchCreditUsage(params) {
		return axios.post(`${MY_PAGE_PREFIX_URL}/getMyCreditUsage`, params);
	},
	/**
	 * 결제, 환불 내역
	 * @param {Object} params
	 * @param {string} params.select payment | refund
	 * @param {number} params.page
	 * @param {number} params.per_page
	 */
	fetchCreditLog(params) {
		return axios.post(`${MY_PAGE_PREFIX_URL}/getMyCreditLog`, params);
	},
	/**
	 * 개설한 강의 리스트
	 * @param {Object} params
	 * @param {string} params.type all | edu | pts | vod
	 * @param {number} params.page
	 * @param {number} params.per_page
	 */
	getClassesOpenedByMe(params) {
		return axios.post(`${MY_PAGE_PREFIX_URL}/getOpenLecture`, params);
	},
	/**
	 * 신청한 강의 리스트
	 * @param {Object} params
	 * @param {string} params.type all | edu | pts | vod || externship
	 * @param {number} params.page
	 * @param {number} params.per_page
	 */
	getClassesAppliedByMe(params) {
		return axios.post(`${MY_PAGE_PREFIX_URL}/getMyLecture`, params);
	},
	/**
	 * 현직자 인증 - 1) 회사명, 직무
	 * @param {Object} params
	 * @param {string} params.belong
	 * @param {string} params.dep
	 */
	changeMentorAuthCompanyInfo(params) {
		return axios.post(`${MY_PAGE_PREFIX_URL}/userOfficialChangeStepOne`, params);
	},
	/**
	 * 현직자 인증 - 2) 이메일 인증
	 * @param {Object} params
	 * @param {number} params.type: 0(메일)
	 * @param {number} params.tmp_no: 인증 고유값
	 * @param {string} params.checkcfm: 이메일
	 */
	changeMentorAuthEmail(params) {
		return axios.post(`${MY_PAGE_PREFIX_URL}/userOfficialChangeStepTwo`, params);
	},
	/**
	 * 현직자 인증 - 3) 이메일 인증 - 핀 코드 입력
	 * @param {Object} params
	 * @param {number} params.tmp_no: 인증 고유값
	 * @param {number} params.pincode: 이메일로 발송된 핀 코드
	 */
	submitPinCode(params) {
		return axios.post(`${MY_PAGE_PREFIX_URL}/userOfficialChangeStepFinal`, params);
	},
	/**
	 * 현직자 인증 - 2) 파일 인증
	 * @param {FormData} formData
	 * @param {number} formData.type: 1(서류)
	 * @param {number} formData.tmp_no: 인증 고유값
	 * @param {array} formData.file
	 */
	changeMentorAuthFile(formData) {
		return axios.post(`${MY_PAGE_PREFIX_URL}/userOfficialChangeStepTwo`, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});
	},
	/**
	 * 현직자 인증 모달 인증정보 가져오기
	 */
	fetchMentorAuthInfo() {
		return axios.get(`${MY_PAGE_PREFIX_URL}/getUserOfficialChangeCurrentStatus`);
	},
	/**
	 * 마이페이지 매칭 설정 저장하기
	 * @param {Object} params
	 * @param {number} params.employment-time: 구직 유무(1: 현재 구직 중, 2: 관심 있음 3: 전혀 관심 없음)
	 * @param {number} params.career: 경력 유무(1: 신입, 2: 경력)
	 * @param {array} params.interest-dep: 관심 직무
	 * @param {array} params.interest-company: 관심 회사
	 * @param {array} params.interest-area: 관심 키워드(대기업, 중견기업...)
	 */
	setUserMeta(params) {
		return axios.post(`${MY_PAGE_PREFIX_URL}/setMyUserMeta`, params);
	},
	/**
	 * 학교, 재직 기업 저장하기
	 * @param {Object} params
	 * @param {array} params.info: 학교 정보 or 재직 기업
	 * @param {number} params.info[].type: 구분(1: 학교, 2: 직장)
	 * @param {string} params.info[].belong: 소속명(학교: 학교명, 직장: 직장명)
	 * @param {string} params.info[].dep: 부서명(학교: 전공계열, 직장: 직무)
	 * @param {string} params.info[].education: 학력구분(* 학교일 때만 필요해서 직장은 빈 값 보내기)
	 * @param {number} params.info[].is_marked: 대표 프로필 설정(1: 설정, 0: 설정하지 않음) -> user_list 테이블에 업데이트 됨
	 */
	updateSchoolOrCareer(params) {
		return axios.post(`${MY_PAGE_PREFIX_URL}/updateSchoolCareer`, params);
	},
	getOpenedClassList(data) {
		return axios.post(`/api/my-page/my-classroom/my-open-class`, data);
	},
	/**
	 * 진로부트캠프 통합 list 상단 정보
	 * contract_no
	 */
	totalEduPathInfo(params) {
		return axios.get(`${MY_PAGE_PREFIX_URL}/edu-path-info`, { params });
	},
	/**
	 * 멘티 진로부트캠프 통합 list
	 * contract_no
	 */
	getEduPathCartList(params) {
		return axios.get(`${MY_PAGE_PREFIX_URL}/edu-path-cart-list`, { params });
	},
	/**
	 * 멘티 진로부트캠프 신청 목록 (통합 리스트 중 신청)
	 * contract_no
	 */
	getEduPathApplicantList(params) {
		return axios.get(`${MY_PAGE_PREFIX_URL}/edu-path-applicant-list`, { params });
	},
	/**
	 * 멘토가 받은 멘토링 후기 개수
	 * @returns {Object} data 멘토링 후기 개수
	 * @returns {number} data.realistic 현실적인 조언
	 * @returns {number} data.quick 빠른 답변
	 * @returns {number} data.professional 전문성 높은 답변
	 * @returns {number} data.kind 친절하고 쉬운 설명
	 * @returns {number} data.latest 최신 정보
	 * @returns {number} data.cheer 격려와 응원
	 */
	fetchMentoringReviews() {
		return axios.get(`${MY_PAGE_PREFIX_URL_NEW}/community/mentoring-review/count`);
	},
});
