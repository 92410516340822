const SATISFACTION_PREFIX_URL = `/api/satisfaction`;

export default axios => ({
	/**
	 * 만족도 조사 step1 제출
	 * @param {String} data.type vodList | readCase | analyticsResult | readAnswer
	 * @param {Number} data.step 1 | 2 // 만족도 조사 step
	 * @param {Number} data.answer 1~5
	 * @param {Number} data.req_no
	 */
	createSatisfaction(data) {
		return axios.put(`${SATISFACTION_PREFIX_URL}/survey`, data);
	},
	/**
	 * 만족도 조사 step2 제출
	 * @param {String} data.description
	 */
	updateSatisfaction(data) {
		return axios.put(`${SATISFACTION_PREFIX_URL}/update`, data);
	},
	/**
	 * 만족도 조사 모달 show 체크(legacy)
	 * @type vodList | readCase | analyticsResult | readAnswer
	 */
	checkSatisfaction(params) {
		return axios.get(`${SATISFACTION_PREFIX_URL}/check`, { params });
	},
	/**
	 * 만족도 조사 모달 show 체크(legacy)
	 * @params.type positive | negative
	 */
	fetchSatisfactionCheckOptions(data) {
		return axios.post(`${SATISFACTION_PREFIX_URL}/questions`, data);
	},
});
