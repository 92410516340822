const USER_PREFIX_URL = `/api/user`;

export default axios => ({
	/**
	 * user 이용권을 가지고 있는지 판단 여부 확인
	 * count, date 정보
	 */
	getUserUsingSales() {
		return axios.get(`${USER_PREFIX_URL}/using/sales`);
	},
	/**
	 * 이메일 로그인
	 */
	submitEmailLogin(data, redirect_url) {
		if (redirect_url) {
			return axios.post(`${USER_PREFIX_URL}/emailLogin?redirect_url=${redirect_url}`, data);
		}
		return axios.post(`${USER_PREFIX_URL}/emailLogin`, data);
	},
	/**
	 * 토글 유저 차단
	 * @param {number} targetUserId: 차단, 차단취소할 userId
	 * @return {promise<boolean>} 차단일 경우 true, 차단 취소일 경우 false
	 */
	async toggleBlockUser(targetUserId) {
		const response = await axios.post('/api/block/manage', { target_user: targetUserId });
		return response.data.msg === 'save success';
	},
	/**
	 * 유저 판별해서 BLOCK 전용 페이지로 보내기
	 */
	checkBlockUserRedirect() {
		return axios.get(`/api/block/check`);
	},
	/**
	 * 북마크 개수 가져오기(커뮤니티, 클래스)
	 */
	getBookmarkCount() {
		return axios.get(`/api/bookmark/total-count`);
	},
	/**
	 * 휴대폰 번호 업데이트
	 * @param {string} mobile: 휴대폰 번호 ex) 010-1234-5678 or 01012345678
	 */
	updateMobile(mobile) {
		return axios.post(`/api/request/updateMobile`, { mobile: mobile.replace(/-/gi, '') });
	},
	logout() {
		return axios.get(`${USER_PREFIX_URL}/jwt/logout`);
	},
});
