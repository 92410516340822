import { checkIsApp, getAppOS, getAppVersion } from '@/utils/utils';

import { IS_MOBILE, IS_PC, USER_AGENT, mapGettersOfLayout } from '@/store/layout';

const layoutMixin = {
	computed: {
		...mapGettersOfLayout([IS_MOBILE, IS_PC, USER_AGENT]),
		IS_APP() {
			if (process.server) {
				return checkIsApp(this.USER_AGENT);
			}
			return checkIsApp(navigator.userAgent);
		},
		APP_VERSION() {
			if (process.server) {
				return getAppVersion(this.USER_AGENT);
			}
			return getAppVersion(navigator.userAgent);
		},
		IS_IOS_APP() {
			const os = process.server ? getAppOS(this.USER_AGENT) : getAppOS(navigator.userAgent);
			return os === 'ios' || os === 'ipados';
		},
		IS_ANDROID_APP() {
			if (process.server) {
				return getAppOS(this.USER_AGENT) === 'android';
			}
			return getAppOS(navigator.userAgent) === 'android';
		},
		IS_HIDE_HEADER() {
			return this.IS_APP && this.APP_VERSION >= '1.3.0';
		},
	},
	// App환경 Test용 코드. 상단 코드와 토글해서 사용할것.
	// computed: {
	// 	...mapGettersOfLayout([IS_MOBILE, IS_PC, USER_AGENT]),
	// 	IS_APP() {
	// 		return true;
	// 	},
	// 	APP_VERSION() {
	// 		return '1.5.2';
	// 	},
	// 	IS_IOS_APP() {
	// 		return true;
	// 	},
	// 	IS_ANDROID_APP() {
	// 		return false;
	// 	},
	// 	IS_HIDE_HEADER() {
	// 		return true;
	// 	},
	// },
};

export default layoutMixin;
