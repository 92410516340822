import { createNamespacedHelpers } from 'vuex';
const NAMESPACE = 'edu/purchase-survey';
const { mapGetters, mapMutations } = createNamespacedHelpers(NAMESPACE);
export { mapGetters as mapGettersOfEduPurchaseSurvey, mapMutations as mapMutationsOfEduPurchaseSurvey };

// states
export const EDU_SURVEY_STEP = 'EDU_SURVEY_STEP';
export const EDU_SURVEY_TOKEN = 'EDU_SURVEY_TOKEN';
export const EDU_SURVEY_STEP1_VALUES = 'EDU_SURVEY_STEP1_VALUES';
export const EDU_SURVEY_STEP2_VALUES = 'EDU_SURVEY_STEP2_VALUES';
export const EDU_SURVEY_STEP3_VALUES = 'EDU_SURVEY_STEP3_VALUES';

// mutations
export const SET_EDU_SURVEY_STEP = 'SET_EDU_SURVEY_STEP';
export const SET_EDU_SURVEY_TOKEN = 'SET_EDU_SURVEY_TOKEN';
export const SET_EDU_SURVEY_STEP1_VALUES = 'SET_EDU_SURVEY_STEP1_VALUES';
export const SET_EDU_SURVEY_STEP2_VALUES = 'SET_EDU_SURVEY_STEP2_VALUES';
export const SET_EDU_SURVEY_STEP3_VALUES = 'SET_EDU_SURVEY_STEP3_VALUES';

export const state = () => ({
	[EDU_SURVEY_STEP]: 0,
	[EDU_SURVEY_TOKEN]: null,
	[EDU_SURVEY_STEP1_VALUES]: null,
	[EDU_SURVEY_STEP2_VALUES]: null,
	[EDU_SURVEY_STEP3_VALUES]: null,
});

export const getters = {
	[EDU_SURVEY_STEP]: state => state[EDU_SURVEY_STEP],
	[EDU_SURVEY_TOKEN]: state => state[EDU_SURVEY_TOKEN],
	[EDU_SURVEY_STEP1_VALUES]: state => state[EDU_SURVEY_STEP1_VALUES],
	[EDU_SURVEY_STEP2_VALUES]: state => state[EDU_SURVEY_STEP2_VALUES],
	[EDU_SURVEY_STEP3_VALUES]: state => state[EDU_SURVEY_STEP3_VALUES],
};
export const mutations = {
	[SET_EDU_SURVEY_STEP]: (state, payload) => {
		state[EDU_SURVEY_STEP] = payload;
	},
	[SET_EDU_SURVEY_TOKEN]: (state, payload) => {
		state[EDU_SURVEY_TOKEN] = payload;
	},
	[SET_EDU_SURVEY_STEP1_VALUES]: (state, payload) => {
		state[EDU_SURVEY_STEP1_VALUES] = payload;
	},
	[SET_EDU_SURVEY_STEP2_VALUES]: (state, payload) => {
		state[EDU_SURVEY_STEP2_VALUES] = payload;
	},
	[SET_EDU_SURVEY_STEP3_VALUES]: (state, payload) => {
		state[EDU_SURVEY_STEP3_VALUES] = payload;
	},
};
