import { datadogLogs } from '@datadog/browser-logs';

import { isEmptyData } from '@/utils/utils';

export const commonErrorLogger = (error, internalData, datadogStatus = 'error') => {
	const hasResponse = Boolean(error.response) || !isEmptyData(error.response);
	const hasRequest = Boolean(error.request) || !isEmptyData(error.request);
	if (hasResponse) {
		const logTitle = internalData.title ? internalData.title : error.message;
		datadogLogs.logger.log(
			logTitle,
			{
				status: error.response.status,
				err: error,
				data: error.response.data,
				...internalData,
			},
			datadogStatus,
		);
	} else if (hasRequest) {
		datadogLogs.logger.log('Request Error', { err: error, request: error.request, ...internalData }, 'error');
	} else {
		datadogLogs.logger.log('Unknown Error', { err: error, ...internalData }, 'error');
	}
};
