import { createNamespacedHelpers } from 'vuex';

import { filterCamps } from '@/utils/eduUtil';

const NAMESPACE = 'edu/landing';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfEduLanding,
	mapMutations as mapMutationsOfEduLanding,
	mapActions as mapActionsOfEduLanding,
};

// states
export const SELECTED_EDU_LANDING_FILTER = 'SELECTED_EDU_LANDING_FILTER';
export const EDU_LANDING_FILTER_LIST = 'EDU_LANDING_FILTER_LIST';
export const EDU_POPULAR_CAMP_LIST = 'EDU_POPULAR_CAMP_LIST';

// mutations
export const SET_SELECTED_EDU_LANDING_FILTER = 'SET_SELECTED_EDU_LANDING_FILTER';
export const SET_EDU_LANDING_FILTER_LIST = 'SET_EDU_LANDING_FILTER_LIST';
export const SET_EDU_POPULAR_CAMP_LIST = 'SET_EDU_POPULAR_CAMP_LIST';

// actions
export const FETCH_EDU_LANDING_POPULAR = 'FETCH_EDU_LANDING_POPULAR';

export const state = () => ({
	[SELECTED_EDU_LANDING_FILTER]: '전체',
	[EDU_LANDING_FILTER_LIST]: [],
	[EDU_POPULAR_CAMP_LIST]: [],
});

export const getters = {
	[SELECTED_EDU_LANDING_FILTER]: state => state[SELECTED_EDU_LANDING_FILTER],
	[EDU_LANDING_FILTER_LIST]: state => state[EDU_LANDING_FILTER_LIST],
	[EDU_POPULAR_CAMP_LIST]: state => state[EDU_POPULAR_CAMP_LIST],
};

export const mutations = {
	[SET_SELECTED_EDU_LANDING_FILTER]: (state, payload) => {
		state[SELECTED_EDU_LANDING_FILTER] = payload;
	},
	[SET_EDU_LANDING_FILTER_LIST]: (state, payload) => {
		state[EDU_LANDING_FILTER_LIST] = payload;
	},
	[SET_EDU_POPULAR_CAMP_LIST]: (state, payload) => {
		state[EDU_POPULAR_CAMP_LIST] = payload;
	},
};

export const actions = {
	async [FETCH_EDU_LANDING_POPULAR]({ getters, commit }) {
		try {
			const categoryFilter =
				getters[SELECTED_EDU_LANDING_FILTER] === '전체' ? [] : [getters[SELECTED_EDU_LANDING_FILTER]];
			const {
				data: { list },
			} = await this.$axios.get(`/api/edu/eduListPopular`, {
				params: {
					category: categoryFilter,
					page: 1,
					limit: 10,
				},
			});
			commit(SET_EDU_POPULAR_CAMP_LIST, filterCamps(list));
		} catch (error) {
			console.error('FETCH_EDU_LANDING_POPULAR error: ', error);
		}
	},
};
