const CLASSROOM_PREFIX = '/api/classroom';
const CLASSROOM_PT_PREFIX = `${CLASSROOM_PREFIX}/pt`;
const CLASSROOM_PT_LIVE_CLASS_PREFIX = `${CLASSROOM_PT_PREFIX}/live-class`;

export default axios => ({
	/**
	 * 실무PT 클래스룸 정보 가져오기;
	 * @params class_no
	 */
	getPtClassroomInfo(ptNo) {
		const params = {
			class_no: ptNo,
		};
		return axios.post(`${CLASSROOM_PT_PREFIX}/class`, params);
	},
	/**
	 * 실무PT 커리큘럼 가져오기
	 * @params class_no
	 */
	getPtClassroomSchedule(ptNo) {
		const params = {
			class_no: ptNo,
		};
		return axios.post(`${CLASSROOM_PT_PREFIX}/class/schedule`, params);
	},
	/**
	 * 실무PT 커리큘럼 가져오기
	 * @params
	 *  class_no
	 *  per_page
	 *  list_type (all, mine, week)
	 */
	getPtClassroomAssignmentList(class_no, list_type = 'all', page = 1, per_page = 10) {
		const params = {
			class_no,
			page,
			per_page,
			list_type,
		};
		return axios.post(`${CLASSROOM_PREFIX}/assignment/list`, params);
	},
	/**
	 * 실무PT 클래스룸 과제 제출
	 * @params
	 *  formData
	 */
	submitClassroomAssignment(formData) {
		return axios.post(`${CLASSROOM_PREFIX}/assignment/store`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	},
	/**
	 * 실무PT 클래스룸 수료증 수료여부 체크
	 * @params classId
	 */
	fetchCertificationCheck(data) {
		return axios.post(`${CLASSROOM_PREFIX}/certification/check`, data);
	},
	/**
	 * 실무PT 클래스룸 수료증 다운로드
	 * @params applicant_no
	 */
	downloadPtCertification(params) {
		return axios.get('/api/class/pt/download/certificate', { responseType: 'blob', params });
	},
	/**
	 * 실무PT 리드멘토 확인
	 * @params class_no
	 */
	checkPtMentor(data) {
		return axios.post(`${CLASSROOM_PT_PREFIX}/check/mentor`, data);
	},
	/**
	 * 실무PT 과제 번호를 통한 리드멘토 확인
	 * @params assignment_no
	 */
	checkPtMentorWithAssignmentNo(assignmentNo) {
		const params = {
			assignment_no: assignmentNo,
		};
		return axios.post(`${CLASSROOM_PREFIX}/assignment/check/mentor`, params);
	},
	/**
	 * 실무PT 수강생 명단
	 * @params class - class_pts
	 * @params campNo - ptNo
	 * 클래스 시작 1시간전부터 30분 후까지 시작시간 확인
	 * 200 - 생성
	 * 202 - 당일인데 시간이 아님
	 * 204 - 당일이 아님
	 */
	checkIsLivePreparable(data) {
		return axios.post(`${CLASSROOM_PT_LIVE_CLASS_PREFIX}/is-preparable`, {
			class: 'class_pts',
			...data,
		});
	},
	/**
	 * 실무PT 수강생 명단
	 * @params class_no
	 */
	fetchPtApplicantList(params) {
		return axios.get(`${CLASSROOM_PT_PREFIX}/class/applicant/list`, { params });
	},
	/**
	 * zoom 계정 할당
	 * @params camp_no - ptNo
	 */
	fetchLiveClassPrepareUserInfo(data) {
		return axios.post(`${CLASSROOM_PT_LIVE_CLASS_PREFIX}/prepare-zoom`, data);
	},
	/**
	 * 라이브 클래스 zoom 생성하기
	 * 알림톡 나감
	 * @params camp_no
	 * @params email - 줌계정 id
	 */
	postLiveClassBookMeeting(data) {
		return axios.post(`${CLASSROOM_PT_LIVE_CLASS_PREFIX}/book-meeting`, data);
	},
	/**
	 * 현재 라이브(미팅이 생성된지 5시간 미만)인지 확인
	 * @params camp_no
	 */
	fetchClassIsLive(data) {
		return axios.post(`${CLASSROOM_PT_LIVE_CLASS_PREFIX}/is-live`, data);
	},
	/**
	 * 멘티에게 나가는 줌 접속페이지 링크
	 * @params camp_no
	 */
	fetchMenteeUrl(params) {
		return axios.get(`${CLASSROOM_PT_LIVE_CLASS_PREFIX}/url`, { params });
	},
	/**
	 * 실무PT 과제 보기
	 * @params assignment_no
	 */
	getClassroomAssignment(assignmentNo) {
		return axios.get(`${CLASSROOM_PREFIX}/assignment/${assignmentNo}`);
	},
	/*
	 * 출석체크할 클래스 정보 반환
	 * data.code === 200
	 * campInfo :{
	 *	 	"end_at",
	 *	 	"start_at",
	 * 		"title",
	 * 		"week"
	 * }
	 * 멘토가 출첵할 수 있는 멘티 리스트 (출석, 미출석, 줌 자동출석)
	 * list: {
	 * 		attended : [],
	 * 		not_attended : [],
	 * 		mentee_zoom : []
	 * }
	 ******************************
	 * data.code === 204
	 * 세션 시작으로부터 3일이 지나서 링크 만료
	 * */
	fetchAttendancePtInfo({ ptNo, week }) {
		return axios.get(`${CLASSROOM_PT_LIVE_CLASS_PREFIX}/attendance/allMenteeInfo/${ptNo}/${week}`);
	},
	/*
	 * 멘토가 userNo 배열을 보내서 출석체크를 함.
	 * data: {
	 * users: [userNo, userNo, ...]
	 * }
	 * */
	checkMenteeAttendance(data, { ptNo, week }) {
		return axios.post(`${CLASSROOM_PT_LIVE_CLASS_PREFIX}/attendance/check/${ptNo}/${week}`, data);
	},
	/*
	 * 멘티가 강의에 참여할 때, 현재 auth로 참여할 수 있는지 체크함
	 * data: {
	 *  meeting_id,
	 * }
	 * */
	checkAttendancePossibility(data) {
		return axios.post(`${CLASSROOM_PT_LIVE_CLASS_PREFIX}/can-attendance`, data);
	},
	/*
	 * 참여할 강의의 title, week 가져오기
	 * */
	fetchLiveClassMeetingInfo(params) {
		return axios.get(`${CLASSROOM_PT_LIVE_CLASS_PREFIX}/meeting`, { params });
	},
	/*
	 * 라이브 클래스 참여하기
	 * */
	postLiveClassAttendance(data) {
		return axios.post(`${CLASSROOM_PT_LIVE_CLASS_PREFIX}/attendance`, data);
	},
	/*
	 * 라이브 클래스 핸드폰 번호로 참여하기
	 * data: {
	 * 	meeting_id,
	 * 	mobile
	 * }
	 * */
	postLiveClassAttendanceByPhoneNumber(data) {
		return axios.post(`${CLASSROOM_PT_LIVE_CLASS_PREFIX}/attendance-mobile`, data);
	},
	/*
	 * 멘티 자신의 출석체크 정보 반환
	 * list: [attendance, ...]
	 * attendance: {
	 *   camp_no,
	 *   week,
	 *   start_at: 해당 세션 시작,
	 *   end_at: 해당 세션 끝,
	 *   checkable: 출석할 수 있는지,
	 *   via: 출석체크한 유저,
	 * }
	 * ******************************
	 * checkable
	 * - 출석체크 안되어있고, 세션 이후면서, 출석 체크 마감 날짜가 지나지 않았으면 true
	 * */
	fetchMyAttendances({ ptNo }) {
		return axios.get(`${CLASSROOM_PT_LIVE_CLASS_PREFIX}/attendance/mentee/${ptNo}`);
	},
	/*
	 * 멘티가 날짜 데이터를 보내서 자신의 출석을 체크함
	 * */
	postMyAttendance(data, { ptNo, week }) {
		return axios.post(`${CLASSROOM_PT_LIVE_CLASS_PREFIX}/attendance/mentee/${ptNo}/${week}`, data);
	},
	/**
	 * 멘티 신청자정보 불러오기
	 * @params class - class_pts
	 * @params classId
	 */
	fetchApplicantInfo(params) {
		return axios.get(`${CLASSROOM_PREFIX}/applicant`, { params: { class: 'class_pts', ...params } });
	},
	/**
	 * 새 댓글 목록 불러오기
	 * @params class_no (ptNo)
	 * @params per_page
	 */
	getPtClassroomReplyStream(classNo, perPage, page = 1) {
		const data = {
			class_no: classNo,
			per_page: perPage,
			page,
		};
		return axios.post(`${CLASSROOM_PREFIX}/assignment/comment/list`, data);
	},
	/**
	 * 새 댓글 count 불러오기
	 * @params class_no (ptNo)
	 */
	getPtClassroomReplyCount(classNo) {
		const data = {
			class_no: classNo,
		};
		return axios.post(`${CLASSROOM_PREFIX}/assignment/comment/count`, data);
	},
	/**
	 * 새 과제 count 불러오기
	 * @params class_no (ptNo)
	 */
	getPtClassroomUnreadAssignmentCount(classNo) {
		const data = {
			class_no: classNo,
		};
		return axios.post(`${CLASSROOM_PREFIX}/assignment/unread/count`, data);
	},
	/**
	 * 댓글 삭제
	 * @params comment_no
	 */
	deleteAssignmentComment(commentNo) {
		const data = {
			comment_no: commentNo,
		};
		return axios.post(`${CLASSROOM_PREFIX}/assignment/comment/destroy`, data);
	},
	/**
	 * private image 권한 설정
	 * @params assignment_no
	 */
	getPtSignedCookies(assignmentNo) {
		const params = {
			assignment_no: assignmentNo,
		};
		return axios.get(`${CLASSROOM_PREFIX}/assignment/file/getSignedCookie`, {
			params,
		});
	},
	/**
	 * 실무PT 클래스룸 복습 VOD 리스트
	 */
	fetchPtReviseVod(classId) {
		return axios.get(`${CLASSROOM_PREFIX}/revise-video/${classId}`);
	},
	/**
	 * 실무PT 복습 VOD 시작 및 종료 기록
	 * @param {Object} data
	 * @param {string} data.vod_session: ''
	 * @param {number} data.vod_no
	 * @param {string} data.vod_type: 'class_pt_revise'
	 * @param {string} data.event_name: 'ended'
	 * @param data.data
	 */
	postPtReviseVodLogStatus(data) {
		return axios.post(`${CLASSROOM_PREFIX}/revise-video/status`, data);
	},
	/**
	 * 실무PT 복습 VOD 시청 기록
	 * @param {Object} data
	 * @param {string} data.vod_session: ''
	 * @param {number} data.vod_no
	 * @param {string} data.vod_type: 'class_pt_revise'
	 * @param {number} data.vod_play_time
	 * @param {Array} data.vod_history
	 */
	postPtReviseVodLogHistory(data) {
		return axios.post(`${CLASSROOM_PREFIX}/revise-video/history`, data);
	},
	fetchIsMeetingLive(classPtId) {
		return axios.post(`${CLASSROOM_PT_PREFIX}/live-class/ready/${classPtId}`);
	},
	createLiveMeeting(classPtId) {
		return axios.post(`${CLASSROOM_PT_PREFIX}/live-class/create/${classPtId}`);
	},
});
