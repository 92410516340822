import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'appContext';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfAppContext,
	mapMutations as mapMutationsOfAppContext,
	mapActions as mapActionsOfAppContext,
};

// states
export const BOTTOM_NAVIGATION = 'BOTTOM_NAVIGATION';
export const SUB_HEADER = 'SUB_HEADER';
// getters
export const GET_BOTTOM_NAVIGATION = 'GET_BOTTOM_NAVIGATION';
export const GET_SUB_HEADER = 'GET_SUB_HEADER';
// mutations
export const SET_BOTTOM_NAVIGATION = 'SET_BOTTOM_NAVIGATION';
export const SET_SUB_HEADER = 'SET_SUB_HEADER';
// actions

export const state = () => ({
	[BOTTOM_NAVIGATION]: '',
	[SUB_HEADER]: '',
});

export const getters = {
	[GET_BOTTOM_NAVIGATION]: state => state[BOTTOM_NAVIGATION],
	[GET_SUB_HEADER]: state => state[SUB_HEADER],
};

export const mutations = {
	[SET_BOTTOM_NAVIGATION]: (state, payload) => {
		state[BOTTOM_NAVIGATION] = payload;
	},
	[SET_SUB_HEADER]: (state, payload) => {
		state[SUB_HEADER] = payload;
	},
};
