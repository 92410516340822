import Vue from 'vue';

import { purifyHtml } from '@/utils/sanitize';

export function purifyHtmlDirective(element, directive) {
	// Vue 소스 코드 참고
	// https://github.com/vuejs/vue/blob/49b6bd4264c25ea41408f066a1835f38bf6fe9f1/src/platforms/web/compiler/directives/html.ts
	if (directive.value) {
		element.innerHTML = purifyHtml(directive.value);
		element.classList && element.classList.add('purified-html');
	}
}

Vue.directive('purify-html', purifyHtmlDirective);
