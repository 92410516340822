export default axios => ({
	/**
	 * 카카오 플러스친구 api
	 * @data event_name : gtm event name과 동일
	 * @data add_info : 클라이언트에서 보내줘야 할 부가 정보
	 * 카카오플러스친구 사용자 정보 문서
	 * https://docs.google.com/spreadsheets/d/1sMA0Nxtyk80hA6qZcUhQoeK66XVXFbjsv4GKCMrEthg/edit#gid=0
	 **/
	updateUser(data) {
		return axios.post('/api/kakaoFriend/updateUser', data);
	},
});
