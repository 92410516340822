const API_PREFIX = '/api/recruitments';

// @ts-ignore
export default axios => ({
	/*
	 * 채용 공고 리스트
	 * @param {Object} params
	 * @param {number} params.page
	 * @param {number} params.perPage
	 * @example
	 * getRecruitments({page: 1, perPage: 10})
	 * @returns {Promise}
	 */
	getRecruitments(params) {
		return axios.get(`${API_PREFIX}`, { params, cache: true });
	},
	/*
	 * 채용 공고 디테일
	 * @param 일{Object} params
	 * @param {number} params.workNetId
	 * @example
	 * getRecruitments({workNetId: 1})
	 * @returns {Promise}
	 */
	getRecruitmentDetail(params) {
		return axios.get(`${API_PREFIX}/detail`, { params, cache: true });
	},
});
