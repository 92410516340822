const API_PREFIX = '/api/openchat';

// @ts-ignore
export default axios => ({
	/**
	 * 입장 기록 저장 & 오카방 주소 가져오기
	 * @param {Object} data
	 * @param {string} data.camp_no
	 * @param {string} data.token
	 * @returns {*}
	 */
	getOpenChatLink(data) {
		return axios.post(`${API_PREFIX}/link`, data);
	},
});
