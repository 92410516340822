import {
	SET_ROUTE_NAME,
	ROUTE_NAMES_FOR_COMMUNITY_LIST,
	ROUTE_NAMES_FOR_COMMUNITY_PAGE,
	SET_IS_COMMUNITY_PAGE,
	SET_IS_COMMUNITY_LIST_PAGE,
	SET_IS_COMMUNITY_TIMELINE_PAGE,
} from '@/store/loading';

export default function ({ route, store }) {
	store.commit(`loading/${SET_ROUTE_NAME}`, route.name);
	const isCommunityPage = ROUTE_NAMES_FOR_COMMUNITY_PAGE.includes(route.name);
	const isCommunityListPage = ROUTE_NAMES_FOR_COMMUNITY_LIST.includes(route.name);
	store.commit(`loading/${SET_IS_COMMUNITY_PAGE}`, isCommunityPage);

	if (isCommunityPage) {
		store.commit(`loading/${SET_IS_COMMUNITY_LIST_PAGE}`, isCommunityListPage);
		if (route.params.keyword || route.query.category) {
			store.commit(`loading/${SET_IS_COMMUNITY_TIMELINE_PAGE}`, false);
		}
	}
}
