
import { timelineCoreMenu } from '@/constants/community';

import { baseUrl, cdnImageBaseUrl } from '@/plugins/constants';

import { CASE_CATEGORY, mapGettersOfCase } from '@/store/case';

import CaseListServiceButton from '@/components/case/list/main/molecules/CaseListServiceButton.vue';
import FloatingActionButton from '@/components/common/FAB/FloatingActionButton.vue';
import CustomNuxtLink from '@/components/CustomNuxtLink.vue';

import communityGtmTriggerMixin from '@/mixins/gtm/communityGtmTriggerMixin';
import userInformationMixin from '@/mixins/userInformationMixin';

export default {
	name: 'CommunityBottomDrawerFAB',
	mixins: [communityGtmTriggerMixin, userInformationMixin],
	data() {
		return {
			timelineCoreMenu: Object.freeze(timelineCoreMenu),
			qaButtonInfo: Object.freeze({
				title: '무료로 무제한 질문하기',
				url: `${baseUrl}/request/question`,
				imageSrc: `${cdnImageBaseUrl}/community/request-qa-icon-case-list.svg`,
			}),
			showDrawer: false,
		};
	},
	computed: {
		...mapGettersOfCase([CASE_CATEGORY]),
	},
	methods: {
		toggle() {
			this.showDrawer = !this.showDrawer;
		},
		closeDrawer() {
			this.showDrawer = false;
		},
		clickWritingButton() {
			this.$emit('click-writing-button');
		},
		clickServiceButton(where) {
			// GTM - clickServiceButton
			this.$_clickServiceButton({
				...this.$_userInfoForGtm,
				where: this[CASE_CATEGORY] ? 'onboardCaseList' : 'timeline',
				click_to: where,
			});
		},
		// 클릭했을 때 drawer를 닫기 위함
		handleClickButton(callback) {
			this.closeDrawer();
			callback();
		},
	},
	components: { CaseListServiceButton, FloatingActionButton, CustomNuxtLink },
};
