import { baseUrl } from '@/plugins/constants';

export default async function ({ $api, redirect, store }) {
	const isUser = store.getters.isUser;
	if (!isUser) {
		return;
	}

	// API 체크
	const { data: isProfileFinished } = await $api.userMeta.checkUpdateProfile();
	if (isProfileFinished) {
		return;
	}

	redirect(`${baseUrl}/profile/signup`);
}
