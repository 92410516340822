import get from 'lodash/get';
import { createNamespacedHelpers } from 'vuex';

import { KDC_PROGRESS_DEFAULT, KDC_PROGRESS_ICON_DEFAULT } from '@/constants/kdc';

const NAMESPACE = 'kdc';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export { mapGetters as mapGettersOfKdc, mapMutations as mapMutationsOfKdc, mapActions as mapActionsOfKdc };

// States
export const KDC_VOD_DETAIL_INFO = 'KDC_VOD_DETAIL_INFO';
export const KDC_DEAD_LINE = 'KDC_DEAD_LINE';
export const HAVE_EDU_KDC_CARD = 'HAVE_EDU_KDC_CARD';
export const KDC_LECTURE_ID = 'KDC_LECTURE_ID';
export const KDC_CARD_TYPE = 'KDC_CARD_TYPE';
export const KDC_USER_INFO = 'KDC_USER_INFO';
export const KDC_PROGRESS_STATE = 'KDC_PROGRESS_STATE';
export const KDC_PROGRESS_GUIDE_STEP = 'KDC_PROGRESS_GUIDE_STEP';
export const KDC_PROGRESS_INFO = 'KDC_PROGRESS_INFO';
export const IS_KDC_PROGRESS_EXIST = 'IS_KDC_PROGRESS_EXIST';
export const KDC_ALERT_INFO = 'KDC_ALERT_INFO';

// Getters
export const GET_KDC_VOD_DETAIL_INFO = 'GET_KDC_VOD_DETAIL_INFO';
export const GET_KDC_DEAD_LINE = 'GET_KDC_DEAD_LINE';
export const GET_HAVE_KDC_CARD = 'GET_HAVE_KDC_CARD';
export const GET_KDC_LECTURE_ID = 'GET_KDC_LECTURE_ID';
export const GET_KDC_CARD_BRAND = 'GET_KDC_CARD_BRAND';
export const GET_KDC_USER_INFO = 'GET_KDC_USER_INFO';
export const GET_KDC_USER_NAME = 'GET_KDC_USER_NAME';
export const GET_KDC_USER_PHONE = 'GET_KDC_USER_PHONE';
export const GET_KDC_USER_EMAIL = 'GET_KDC_USER_EMAIL';
export const GET_KDC_PROGRESS_STATE = 'GET_KDC_PROGRESS_STATE';
export const GET_KDC_PROGRESS_GUIDE_STEP = 'GET_KDC_PROGRESS_GUIDE_STEP';
export const GET_KDC_PROGRESS_INFO = 'GET_KDC_PROGRESS_INFO';
export const GET_IS_KDC_PROGRESS_EXIST = 'GET_IS_KDC_PROGRESS_EXIST';
export const GET_KDC_ALERT_INFO = 'GET_KDC_ALERT_INFO';

// Mutations
export const SET_KDC_VOD_DETAIL_INFO = 'SET_KDC_VOD_DETAIL_INFO';
export const SET_KDC_DEAD_LINE = 'SET_KDC_DEAD_LINE';
export const SET_HAVE_EDU_KDC_CARD = 'SET_HAVE_EDU_KDC_CARD';
export const SET_KDC_LECTURE_ID = 'SET_KDC_LECTURE_ID';
export const SET_KDC_CARD_TYPE = 'SET_KDC_CARD_TYPE';
export const SET_KDC_USER_INFO = 'SET_KDC_USER_INFO';
export const SET_KDC_USER_NAME = 'SET_KDC_USER_NAME';
export const SET_KDC_USER_PHONE = 'SET_KDC_USER_PHONE';
export const SET_KDC_USER_EMAIL = 'SET_KDC_USER_EMAIL';
export const SET_KDC_PROGRESS_STATE = 'SET_KDC_PROGRESS_STATE';
export const SET_KDC_PROGRESS_GUIDE_STEP = 'SET_KDC_PROGRESS_GUIDE_STEP';
export const SET_IS_KDC_PROGRESS_EXIST = 'SET_IS_KDC_PROGRESS_EXIST';
export const SET_KDC_ALERT_INFO = 'SET_KDC_ALERT_INFO';

// Actions
export const FETCH_KDC_APPLY = 'FETCH_KDC_APPLY';
export const FETCH_KDC_STEP_COMPLETE = 'FETCH_KDC_STEP_COMPLETE';
export const CHECK_KDC_PROGRESSING_EXIST = 'CHECK_KDC_PROGRESSING_EXIST';

export const state = () => ({
	[KDC_VOD_DETAIL_INFO]: null,
	[HAVE_EDU_KDC_CARD]: null,
	[KDC_LECTURE_ID]: 0,
	[KDC_CARD_TYPE]: null,
	[KDC_USER_INFO]: null,
	// KDC Flow Progress icon / list state
	[KDC_PROGRESS_STATE]: KDC_PROGRESS_DEFAULT,
	[KDC_PROGRESS_GUIDE_STEP]: 1,
	[KDC_DEAD_LINE]: 'N월 N일',
	[KDC_PROGRESS_INFO]: false,
	[IS_KDC_PROGRESS_EXIST]: false,
	[KDC_ALERT_INFO]: {
		show: {
			alert: false,
			default: false,
		},
		content: '',
		url: '',
	},
});

export const getters = {
	[GET_KDC_VOD_DETAIL_INFO]: state => state[KDC_VOD_DETAIL_INFO],
	[GET_KDC_DEAD_LINE]: state => state[KDC_DEAD_LINE],
	[GET_HAVE_KDC_CARD]: state => state[HAVE_EDU_KDC_CARD],
	[GET_KDC_LECTURE_ID]: state => state[KDC_LECTURE_ID],
	[GET_KDC_CARD_BRAND]: state => state[KDC_CARD_TYPE],
	[GET_KDC_USER_INFO]: state => state[KDC_USER_INFO],
	[GET_KDC_USER_NAME]: state => get(state[KDC_USER_INFO], 'name', ''),
	[GET_KDC_USER_PHONE]: state => get(state[KDC_USER_INFO], 'mobile', ''),
	[GET_KDC_USER_EMAIL]: state => get(state[KDC_USER_INFO], 'id', ''),
	[GET_KDC_PROGRESS_STATE]: state => state[KDC_PROGRESS_STATE],
	[GET_KDC_PROGRESS_GUIDE_STEP]: state => state[KDC_PROGRESS_GUIDE_STEP],
	[GET_KDC_PROGRESS_INFO]: (_, getters) => {
		const iconObj = Object.values(get(getters[GET_KDC_PROGRESS_STATE], 'icon', KDC_PROGRESS_ICON_DEFAULT));
		iconObj.splice(-1, 1);
		const reducedObj = iconObj.reduce((acc, cur) => {
			if (cur === 2) {
				acc += 1;
			}
			return acc;
		}, 0);
		const percentage = Math.floor((reducedObj / iconObj.length) * 100);
		const isProgressComplete = percentage === 100;
		return { percentage, isProgressComplete };
	},
	[GET_IS_KDC_PROGRESS_EXIST]: state => state[IS_KDC_PROGRESS_EXIST],
	[GET_KDC_ALERT_INFO]: state => state[KDC_ALERT_INFO],
};

export const mutations = {
	[SET_KDC_VOD_DETAIL_INFO]: (state, payload) => {
		state[KDC_VOD_DETAIL_INFO] = payload;
	},
	[SET_KDC_DEAD_LINE]: (state, payload) => {
		state[KDC_DEAD_LINE] = payload;
	},
	[SET_HAVE_EDU_KDC_CARD]: (state, payload) => {
		state[HAVE_EDU_KDC_CARD] = payload;
	},
	[SET_KDC_LECTURE_ID]: (state, payload) => {
		state[KDC_LECTURE_ID] = payload;
	},
	[SET_KDC_CARD_TYPE]: (state, payload) => {
		state[KDC_CARD_TYPE] = payload;
	},
	[SET_KDC_USER_INFO]: (state, payload) => {
		state[KDC_USER_INFO] = payload;
	},
	[SET_KDC_USER_NAME]: (state, payload) => {
		state[KDC_USER_INFO].name = payload;
	},
	[SET_KDC_USER_PHONE]: (state, payload) => {
		state[KDC_USER_INFO].mobile = payload;
	},
	[SET_KDC_USER_EMAIL]: (state, payload) => {
		state[KDC_USER_INFO].id = payload;
	},
	[SET_KDC_PROGRESS_STATE]: (state, payload) => {
		state[KDC_PROGRESS_STATE] = payload;
	},
	[SET_KDC_PROGRESS_GUIDE_STEP]: (state, payload) => {
		state[KDC_PROGRESS_GUIDE_STEP] = payload;
	},
	[SET_IS_KDC_PROGRESS_EXIST]: (state, payload) => {
		state[IS_KDC_PROGRESS_EXIST] = payload;
	},
	[SET_KDC_ALERT_INFO]: (state, payload) => {
		state[KDC_ALERT_INFO] = payload;
	},
};

export const actions = {
	async [FETCH_KDC_APPLY]({ getters }, { classId }) {
		try {
			const data = {
				haveCard: getters[GET_HAVE_KDC_CARD],
				cardType: getters[GET_KDC_CARD_BRAND],
				name: getters[GET_KDC_USER_INFO].name,
				email: getters[GET_KDC_USER_INFO].id,
				mobile: getters[GET_KDC_USER_INFO].mobile,
				lectureId: getters[GET_KDC_LECTURE_ID],
				classId,
			};
			const {
				status,
				data: { classNum },
			} = await this.$api.kdc.postApplyUserInfo(data);
			return { isSuccess: true, classNum, statusCode: status };
		} catch (error) {
			console.error('FETCH_KDC_APPLY error: ', error);
			const errorStatus = error.response.status;
			return { isSuccess: false, statusCode: errorStatus };
		}
	},
	async [FETCH_KDC_STEP_COMPLETE]({ getters }) {
		const step = getters[GET_KDC_PROGRESS_GUIDE_STEP];
		let data = { step };
		try {
			if (step === 1) {
				data = {
					...data,
					haveCard: 1,
					cardType: getters[GET_KDC_CARD_BRAND],
				};
			}
			const { status } = await this.$api.kdc.postKdcStepComplete(data);
			return { isSuccess: true, statusCode: status };
		} catch (error) {
			console.error('FETCH_KDC_STEP_COMPLETE error: ', error);
			const errorCode = error.response.status;
			return { isSuccess: false, statusCode: errorCode };
		}
	},
	async [CHECK_KDC_PROGRESSING_EXIST]({ commit }) {
		try {
			const {
				data: { progress, complete },
			} = await this.$api.kdc.checkProgressingKdcExist();
			commit(SET_IS_KDC_PROGRESS_EXIST, Boolean(progress && !complete));
		} catch (error) {
			console.error('CHECK_KDC_PROGRESSING_EXIST error: ', error);
		}
	},
};
