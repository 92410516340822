const cookieMixin = {
	methods: {
		$_getCookie(name) {
			return this.$cookies.get(name);
		},
		$_setCookie(name, value, maxAge) {
			this.$cookies.set(name, value, {
				path: '/',
				maxAge: this.$_convertMaxAge(maxAge),
			});
		},
		$_convertMaxAge(maxAge) {
			const minutes = 60 * 60;
			if (typeof maxAge === 'object') {
				if (maxAge.hours !== undefined) {
					return minutes * maxAge.hours;
				} else if (maxAge.days !== undefined) {
					return minutes * 24 * maxAge.days;
				} else {
					console.warn('There is not key in maxAge object');
					return minutes;
				}
			} else {
				return maxAge;
			}
		},
	},
};

export default cookieMixin;
