
import EduListEtcFilter from '@/components/edu/list/molecules/EduListEtcFilter.vue';

import appCommandMixin from '@/mixins/appCommandMixin';
import datepickerMixin from '@/mixins/datepickerMixin';
import eduFilterMixin from '@/mixins/eduFilterMixin';
import layoutMixin from '@/mixins/layoutMixin';

export default {
	name: 'EduListFilterFullscreenModal',
	extends: EduListEtcFilter,
	mixins: [eduFilterMixin, datepickerMixin, layoutMixin, appCommandMixin],
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		sync_show: {
			get() {
				return this.show;
			},
			set(value) {
				this.$emit('update:show', value);
			},
		},
	},
	watch: {
		show() {
			this.$_app_setUI({ all: !this.show });
		},
	},
	created() {
		this.chipPaddings = [5, 8, 5, 8];
	},
	methods: {
		handleCalendar() {
			const $dataPickerCalendar = document.querySelector('.c-datepicker-popup');
			$dataPickerCalendar.style.zIndex = '9999';
		},
		applyAndClose() {
			this.handlClickApplyFilterButton();
			this.sync_show = false;
		},
	},
};
