import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'univ';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export { mapGetters as mapGettersOfUniv, mapMutations as mapMutationsOfUniv, mapActions as mapActionsOfUniv };

export const SOGANG_CONTRACT = 'SOGANG_CONTRACT';

export const SET_SOGANG_CONTRACT = 'SET_SOGANG_CONTRACT';

export const LOAD_SOGANG_CONTRACT = 'LOAD_SOGANG_CONTRACT';

export const state = () => ({
	sogang: {},
});

export const getters = {
	[SOGANG_CONTRACT]: state => state.sogang,
};

export const mutations = {
	[SET_SOGANG_CONTRACT]: (state, payload) => (state.sogang = payload),
};

export const actions = {
	async [LOAD_SOGANG_CONTRACT]({ commit }) {
		try {
			const { data } = await this.$api.univ.checkSogangContract();
			commit(SET_SOGANG_CONTRACT, data);
		} catch (error) {
			console.error('LOAD_SOGANG_CONTRACT error: ', error);
			commit(SET_SOGANG_CONTRACT, {});
		}
	},
};
