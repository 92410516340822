
import CustomSwiper from '@/components/common/atoms/swiper/CustomSwiper.vue';

import appCommandMixin from '@/mixins/appCommandMixin';

export default {
	name: 'DynamicSwiper',
	mixins: [appCommandMixin],
	props: {
		isCustomizable: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		componentName() {
			return this.isCustomizable ? CustomSwiper : 'CSwiper';
		},
	},
	methods: {
		touchStart() {
			this.$_app_setSwipe(false);
		},
		touchEnd() {
			this.$_app_setSwipe(true);
		},
	},
	components: {
		CustomSwiper,
	},
};
