const AFF_COUPON_PREFIX = '/api/aff/edu-coupon';

export default axios => ({
	/**
	 * 프리패스 쿠폰 관련 정보 가져오기
	 * @data id: encrypted string
	 */
	getEduCouponCodeInfo(data) {
		return axios.post(`${AFF_COUPON_PREFIX}/code/info`, data);
	},
	/**
	 *
	 * @param data
	 * @returns {msg}
	 */
	acceptEduCoupon(data) {
		return axios.post(`${AFF_COUPON_PREFIX}/coupon/accept`, data);
	},
	/**
	 *
	 * @param data
	 * @returns {msg}
	 */
	abandonEduCoupon(data) {
		return axios.post(`${AFF_COUPON_PREFIX}/coupon/reject`, data);
	},
});
