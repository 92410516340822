
import { mapGetters } from 'vuex';

import { LEAD_MENTOR_NOTION_PATH } from '@/constants/paths/edu';

import { getCdnPath } from '@/utils/cdn';

import communityGtmTriggerMixin from '@/mixins/gtm/communityGtmTriggerMixin';
import layoutMixin from '@/mixins/layoutMixin';
import myPageMixin from '@/mixins/myPageMixin';
import routeMixin from '@/mixins/routeMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

export default {
	name: 'MyPageToLeadMentorNotionBanner',
	mixins: [myPageMixin, communityGtmTriggerMixin, userInfoMixin, layoutMixin, routeMixin],
	data() {
		return {
			isEduMentor: false,
		};
	},
	computed: {
		...mapGetters(['isMentor']),
		leadMentorNotionBannerPath() {
			return getCdnPath(`/images/banner/banner-mypage-to-leadMentorNotion${this.IS_MOBILE ? '-mo' : ''}.jpg`);
		},
		isShowBanner() {
			return this.isCreditMenu && !this.isEduMentor;
		},
		isCreditMenu() {
			return this._myPageCreditMenuNavs.some(({ url }) => url === this.$route.path);
		},
	},
	async mounted() {
		if (this.isCreditMenu && this.isMentor) {
			await this.fetchOpenedEduClass();
		}
		if (this.isShowBanner) {
			this.triggerForBannerRendered();
		}
	},
	methods: {
		getCdnPath,
		triggerForBannerRendered() {
			this.$_impression({
				...this.$_userInfoForGtm,
				where: 'mypage',
				category: 'onboardCreditCash',
				order: 1,
				job_posting_id: '직무부트캠프',
				is_app: this.IS_APP,
			});
		},
		async fetchOpenedEduClass() {
			const params = {
				type: 'edu',
				page: 1,
				perPage: 9,
			};
			const {
				status,
				data: { total },
			} = await this.$api.myPage.getOpenedClassList(params);
			if (status === 200 && total > 0) {
				this.isEduMentor = true;
			}
		},
		clickLeadMentorNotionBanner() {
			this.$_clickAdBanner({
				...this.$_userInfoForGtm,
				type: 'basic',
				where: 'mypage',
				category: 'onboardCreditCash',
				order: 1,
				job_posting_id: '직무부트캠프',
				is_app: this.IS_APP,
			});
			this.$_routeMixin_open_page(LEAD_MENTOR_NOTION_PATH);
		},
	},
};
