import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'vod/player/checkPlus';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfCheckPlus,
	mapMutations as mapMutationsOfCheckPlus,
	mapActions as mapActionsOfCheckPlus,
};

// getters
export const CHECK_PLUS_STATUS = 'CHECK_PLUS_STATUS';
export const CHECK_PLUS_ENC_DATA = 'CHECK_PLUS_ENC_DATA';

// mutations
export const SET_CHECK_PLUS_STATUS = 'SET_CHECK_PLUS_STATUS';
export const SET_CHECK_PLUS_ENC_DATA = 'SET_CHECK_PLUS_ENC_DATA';

export const state = () => ({
	[CHECK_PLUS_STATUS]: null,
	[CHECK_PLUS_ENC_DATA]: null,
});

export const getters = {
	[CHECK_PLUS_STATUS]: state => state[CHECK_PLUS_STATUS],
	[CHECK_PLUS_ENC_DATA]: state => state[CHECK_PLUS_ENC_DATA],
};

export const mutations = {
	[SET_CHECK_PLUS_STATUS]: (state, payload) => {
		state[CHECK_PLUS_STATUS] = payload;
	},
	[SET_CHECK_PLUS_ENC_DATA]: (state, payload) => {
		state[CHECK_PLUS_ENC_DATA] = payload;
	},
};
