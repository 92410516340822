import { render, staticRenderFns } from "./LoadingWrapper.vue?vue&type=template&id=920012c6&scoped=true&"
import script from "./LoadingWrapper.vue?vue&type=script&lang=js&"
export * from "./LoadingWrapper.vue?vue&type=script&lang=js&"
import style0 from "./LoadingWrapper.vue?vue&type=style&index=0&id=920012c6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "920012c6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingWithOverlay: require('/app/components/loading/LoadingWithOverlay.vue').default})
