
import {
	MY_PAGE_THANKS_PATH,
	MY_PAGE_CREDIT_SHOP_PATH,
	MY_PAGE_CREDIT_LIST_PATH,
	MY_PAGE_CREDIT_CASH_PATH,
	MY_PAGE_ESSAY_PATH,
} from '@/constants/paths/my-page';

import { GET_THANKS_MENTEE_COUNT, mapGettersOfMyPage } from '@/store/myPage';

import MyPageCashInfoBoard from '@/components/my-page/_main/atom/MyPageCashInfoBoard.vue';
import MyPageCreditInfoBoard from '@/components/my-page/_main/atom/MyPageCreditInfoBoard.vue';
import MyPageEssayInfoBoard from '@/components/my-page/_main/atom/MyPageEssayInfoBoard.vue';
import MyPageThanksInfoBoard from '@/components/my-page/thanks/molecules/MyPageThanksInfoBoard.vue';

import myPageMixin from '@/mixins/myPageMixin';

export default {
	name: 'MyPageInfoBoard',
	mixins: [myPageMixin],
	data() {
		return {
			MY_PAGE_THANKS_PATH,
			MY_PAGE_CREDIT_SHOP_PATH,
			MY_PAGE_CREDIT_LIST_PATH,
			MY_PAGE_CREDIT_CASH_PATH,
			MY_PAGE_ESSAY_PATH,
		};
	},
	computed: {
		...mapGettersOfMyPage([GET_THANKS_MENTEE_COUNT]),
	},
	components: {
		MyPageEssayInfoBoard,
		MyPageCashInfoBoard,
		MyPageThanksInfoBoard,
		MyPageCreditInfoBoard,
	},
};
