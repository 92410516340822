import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'userVoice';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfUserVoice,
	mapMutations as mapMutationsOfUserVoice,
	mapActions as mapActionsOfUserVoice,
};

// state
export const SHOW_USER_VOICE_ALERT = 'SHOW_USER_VOICE_ALERT';

// getters
export const GET_SHOW_USER_VOICE_ALERT = 'GET_SHOW_USER_VOICE_ALERT';

// mutations
export const SET_SHOW_USER_VOICE_ALERT = 'SET_SHOW_USER_VOICE_ALERT';

export const state = () => ({
	[SHOW_USER_VOICE_ALERT]: false,
});

export const getters = {
	[GET_SHOW_USER_VOICE_ALERT]: state => state[SHOW_USER_VOICE_ALERT],
};

export const mutations = {
	[SET_SHOW_USER_VOICE_ALERT]: (state, payload) => {
		state[SHOW_USER_VOICE_ALERT] = payload;
	},
};
