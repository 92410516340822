const API_PREFIX = '/api/payment';

export default axios => ({
	applePayment(data) {
		return axios.post(`${API_PREFIX}/apple/request`, data);
	},

	googlePayment(data) {
		return axios.post(`${API_PREFIX}/google/request`, data);
	},

	inicisPayment(data) {
		return axios.post(`${API_PREFIX}/inicis/request`, data);
	},

	tossPayment(data) {
		return axios.post(`${API_PREFIX}/toss/request`, data);
	},

	getPayerInfo() {
		return axios.get('/api/user/me');
	},

	getMyCouponList(data) {
		return axios.post('/api/coupon/list/mine', data);
	},

	checkCouponCode(data) {
		return axios.post('/api/coupon/check/code', data);
	},

	downloadCouponCode(data) {
		return axios.post('/api/coupon/download/code', data);
	},
	// toss pg로 결제할 때
	tossPayments(data) {
		return axios.post(`${API_PREFIX}/tosspayments/request`, data);
	},
	// 크레딧으로 결제할 때
	creditPayments(data) {
		return axios.post(`${API_PREFIX}/credit/request`, data);
	},
});
