import affiliateApiFactory from './affiliate';
import afterClassApiFactory from './after-class';
import answerListApiFactory from './answer/list';
import autocompleteApiFactory from './autocomplete';
import baserowApiFactory from './baserow';
import campApiFactory from './camp';
import checkplusApiFactory from './checkplus';
import classPtOpenApiFactory from './class/pt/open';
import classPtApiFactory from './class-pt';
import classroomApiFactory from './classroom/classroom';
import classroomPtApiFactory from './classroom/classroom-pt';
import caseApiFactory from './community/case';
import insightApiFactory from './community/insight';
import loungeApiFactory from './community/lounge';
import timelineApiFactory from './community/timeline';
import eduEncoreApiFactory from './edu/encore';
import eventsReferralApiFactory from './events-referral';
import fileFactory from './file';
import inquiryApiFactory from './inquiry';
import kakaoPlusFriendApiFactory from './kakao-plus-friend';
import kdcApiFactory from './kdc';
import liveClassApiFactory from './live-class';
import myPageApiFactory from './my-page';
import notiApiFactory from './noti';
import noticeApiFactory from './notice';
import notionApiFactory from './notion';
import onAirClassApiFactory from './on-air';
import openChatApiFactory from './open-chat';
import paymentApiFactory from './payment';
import profileApiFactory from './profile';
import recruitApiFactory from './recruit';
import satisfactionFactory from './satisfaction';
import searchListApiFactory from './search/list';
import surveyApiFactory from './survey';
import univApiFactory from './univ';
import userApiFactory from './user';
import userMetaApiFactory from './user-meta';
import utilApiFactory from './util';
import vodApiFactory from './vod';
import vodLectureApiFactory from './vod-lecture';

const apiFactory = axios => ({
	autocomplete: autocompleteApiFactory(axios),
	answerList: answerListApiFactory(axios),
	case: caseApiFactory(axios),
	lounge: loungeApiFactory(axios),
	insight: insightApiFactory(axios),
	notice: noticeApiFactory(axios),
	timeline: timelineApiFactory(axios),
	camp: campApiFactory(axios),
	classroom: classroomApiFactory(axios),
	vod: vodApiFactory(axios),
	kdc: kdcApiFactory(axios),
	kakaoPlusFriend: kakaoPlusFriendApiFactory(axios),
	noti: notiApiFactory(axios),
	user: userApiFactory(axios),
	userMeta: userMetaApiFactory(axios),
	eventsReferral: eventsReferralApiFactory(axios),
	liveClass: liveClassApiFactory(axios),
	afterClass: afterClassApiFactory(axios),
	classPt: classPtApiFactory(axios),
	classPtOpen: classPtOpenApiFactory(axios),
	classroomPt: classroomPtApiFactory(axios),
	onAir: onAirClassApiFactory(axios),
	myPage: myPageApiFactory(axios),
	satisfaction: satisfactionFactory(axios),
	file: fileFactory(axios),
	inquiry: inquiryApiFactory(axios),
	vodLecture: vodLectureApiFactory(axios),
	payment: paymentApiFactory(axios),
	checkplus: checkplusApiFactory(axios),
	survey: surveyApiFactory(axios),
	univ: univApiFactory(axios),
	util: utilApiFactory(axios),
	searchList: searchListApiFactory(axios),
	affiliate: affiliateApiFactory(axios),
	notion: notionApiFactory(),
	profile: profileApiFactory(axios),
	openChat: openChatApiFactory(axios),
	recruit: recruitApiFactory(axios),
	eduEncore: eduEncoreApiFactory(axios),
	baserow: baserowApiFactory(axios),
});

export default ({ $axios }, inject) => {
	const api = apiFactory($axios);
	inject('api', api);
};
