import get from 'lodash/get';
import { createNamespacedHelpers } from 'vuex';

import { GUIDE_EXAMPLE_CATEGORIES } from '@/constants/class/pt/opening/guide';
import { CLASS_OPENING_STEPS } from '@/constants/class/pt/opening/opening-steps';
import {
	CLASS_OPENING_STATUS,
	CLASS_OPENING_STATUS_TO_NUMBER_MAP,
	CLASS_OPENING_STATUS_TO_STRING_MAP,
} from '@/constants/class/pt/opening/status';

import {
	checkStepStatusResult,
	convertAssignment,
	convertClassOpeningDataIntoPayload,
	convertPayloadIntoProfile,
	convertPayloadIntoStringArray,
	convertTheory,
} from '@/utils/class/pt/open';
import {
	defaultAssignment,
	defaultClassIntroduction,
	defaultCurricula,
	defaultCurriculum,
	defaultFAQ,
	defaultGoal,
	defaultGuideExampleActiveIndex,
	defaultHeaderTitle,
	defaultProfile,
	defaultStep,
	defaultStepStatusList,
	defaultTarget,
	defaultTheory,
	defaultWeek,
} from '@/utils/class/pt/open/default-values';

const NAMESPACE = 'class/pt/open';
export { NAMESPACE as NAMESPACE_CLASS_PT_OPEN };
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfClassPtOpen,
	mapMutations as mapMutationsOfClassPtOpen,
	mapActions as mapActionsOfClassPtOpen,
};

export const CLASS_OPENING_REQUIRED_DATA_BY_STEP = () => {
	return {
		[CLASS_OPENING_STEPS.PROFILE]: ['field', 'annual', 'nickname', 'profile'],
		[CLASS_OPENING_STEPS.CLASS_TARGET]: ['skillTool', 'job', 'level', 'target'],
		[CLASS_OPENING_STEPS.CLASS_OBJECTIVE]: ['title', 'difficulty', 'goal', 'mentorIntroduction'],
		[CLASS_OPENING_STEPS.CURRICULUM]: ['week', 'curricula', 'nickname'],
		[CLASS_OPENING_STEPS.CLASS_DESCRIPTION]: ['classIntroduction', 'faqs'],
	};
};

// states
export const HAS_REJECTION_NOTICE = 'HAS_REJECTION_NOTICE';
export const CLASS_OPENING_CURRENT_STEP = 'CLASS_OPENING_CURRENT_STEP';
export const SHOW_CREATE_CLASS_MODAL = 'SHOW_CREATE_CLASS_MODAL';
export const CLASS_OPENING_GUIDE_EXAMPLE_ACTIVE_INDEX = 'CLASS_OPENING_GUIDE_EXAMPLE_ACTIVE_INDEX';
export const CLASS_OPENING_GUIDE_EXAMPLE_CATEGORY = 'CLASS_OPENING_GUIDE_EXAMPLE_CATEGORY';
export const CLASS_OPENING_DATA = 'CLASS_OPENING_DATA';
export const CLASS_OPENING_ANNUAL_LIST = 'CLASS_OPENING_ANNUAL_LIST';
export const CLASS_OPENING_CLASS_DIFFICULTY_LIST = 'CLASS_OPENING_CLASS_DIFFICULTY_LIST';
export const CLASS_OPENING_LEVEL_LIST = 'CLASS_OPENING_LEVEL_LIST';
export const CLASS_OPENING_CLASS_STATUS_LIST = 'CLASS_OPENING_CLASS_STATUS_LIST';
export const CLASS_OPENING_STEP_STATUS_LIST = 'CLASS_OPENING_STEP_STATUS_LIST';
export const CLASS_OPENING_HEADER_TITLE = 'CLASS_OPENING_HEADER_TITLE';
export const IS_NICKNAME_AVAILABLE = 'IS_NICKNAME_AVAILABLE';
export const IS_READ_ONLY = 'IS_READ_ONLY';
export const SHOW_GUIDE_HIGHLIGHT = 'SHOW_GUIDE_HIGHLIGHT';

// getters
export const GET_HAS_REJECTION_NOTICE = 'GET_HAS_REJECTION_NOTICE';
export const GET_CLASS_OPENING_CURRENT_STEP = 'GET_CLASS_OPENING_CURRENT_STEP';
export const GET_SHOW_CREATE_CLASS_MODAL = 'GET_SHOW_CREATE_CLASS_MODAL';
export const GET_CLASS_OPENING_GUIDE_EXAMPLE_ACTIVE_INDEX = 'GET_CLASS_OPENING_GUIDE_EXAMPLE_ACTIVE_INDEX';
export const GET_CLASS_OPENING_GUIDE_EXAMPLE_CATEGORY = 'GET_CLASS_OPENING_GUIDE_EXAMPLE_CATEGORY';
export const GET_CLASS_OPENING_DATA = 'GET_CLASS_OPENING_DATA';
export const GET_CLASS_OPENING_STATUS = 'GET_CLASS_OPENING_STATUS';
export const GET_CLASS_OPENING_TARGET_JOB = 'GET_CLASS_OPENING_TARGET_JOB';
export const GET_CLASS_OPENING_ANNUAL = 'GET_CLASS_OPENING_ANNUAL';
export const GET_CLASS_OPENING_NICKNAME_DATA = 'GET_CLASS_OPENING_NICKNAME_DATA';
export const GET_CLASS_OPENING_PROFILE_DATA = 'GET_CLASS_OPENING_PROFILE_DATA';
export const GET_CLASS_OPENING_WEEK_DATA = 'GET_CLASS_OPENING_WEEK_DATA';
export const GET_CLASS_OPENING_CURRICULA_DATA = 'GET_CLASS_OPENING_CURRICULA_DATA';
export const GET_CLASS_OPENING_CURRICULUM_DATA = 'GET_CLASS_OPENING_CURRICULUM_DATA';
export const GET_CLASS_OPENING_CURRICULA_THEORY = 'GET_CLASS_OPENING_CURRICULA_THEORY';
export const GET_CLASS_OPENING_SKILL = 'GET_CLASS_OPENING_SKILL';
export const GET_CLASS_OPENING_LEVEL = 'GET_CLASS_OPENING_LEVEL';
export const GET_CLASS_OPENING_ANNUAL_LIST = 'GET_CLASS_OPENING_ANNUAL_LIST';
export const GET_CLASS_OPENING_CLASS_DIFFICULTY_LIST = 'GET_CLASS_OPENING_CLASS_DIFFICULTY_LIST';
export const GET_CLASS_OPENING_LEVEL_LIST = 'GET_CLASS_OPENING_LEVEL_LIST';
export const GET_CLASS_OPENING_CLASS_STATUS_LIST = 'GET_CLASS_OPENING_CLASS_STATUS_LIST';
export const GET_CLASS_OPENING_PAIN_POINTS = 'GET_CLASS_OPENING_PAIN_POINTS';
export const GET_CLASS_OPENING_PAIN_POINT = 'GET_CLASS_OPENING_PAIN_POINT';
export const GET_CLASS_OPENING_FIELD = 'GET_CLASS_OPENING_FIELD';
export const GET_CLASS_OPENING_TITLE = 'GET_CLASS_OPENING_TITLE';
export const GET_CLASS_OPENING_DIFFICULTY = 'GET_CLASS_OPENING_DIFFICULTY';
export const GET_CLASS_OPENING_RESULTS = 'GET_CLASS_OPENING_RESULTS';
export const GET_CLASS_OPENING_RESULT = 'GET_CLASS_OPENING_RESULT';
export const GET_CLASS_OPENING_MENTOR_INTRODUCTION = 'GET_CLASS_OPENING_MENTOR_INTRODUCTION';
export const GET_CLASS_OPENING_CLASS_INTRODUCTION = 'GET_CLASS_OPENING_CLASS_INTRODUCTION';
export const GET_CLASS_OPENING_FAQS = 'GET_CLASS_OPENING_FAQS';
export const GET_CLASS_OPENING_FAQ = 'GET_CLASS_OPENING_FAQ';
export const GET_CLASS_OPENING_FILES = 'GET_CLASS_OPENING_FILES';
export const GET_CLASS_OPENING_LINKS = 'GET_CLASS_OPENING_LINKS';
export const GET_CLASS_OPENING_FILES_AND_LINKS = 'GET_CLASS_OPENING_FILES_AND_LINKS';
export const GET_CLASS_OPENING_ID = 'GET_CLASS_OPENING_ID';
export const GET_HAS_CLASS_OPENING = 'GET_HAS_CLASS_OPENING';
export const GET_CLASS_OPENING_STEP_STATUS_LIST = 'GET_CLASS_OPENING_STEP_STATUS_LIST';
export const GET_CLASS_OPENING_STEP_STATUS = 'GET_CLASS_OPENING_STEP_STATUS';
export const GET_IS_CLASS_OPENING_STEP_STATUS_HALF = 'GET_IS_CLASS_OPENING_STEP_STATUS_HALF';
export const GET_CLASS_OPENING_HEADER_TITLE = 'GET_CLASS_OPENING_HEADER_TITLE';
export const GET_IS_IN_REVIEW = 'GET_IS_IN_REVIEW';
export const GET_IS_REJECTED = 'GET_IS_REJECTED';
export const GET_IS_EDIT = 'GET_IS_EDIT';
export const GET_IS_READ_ONLY = 'GET_IS_READ_ONLY';
export const GET_IS_NICKNAME_AVAILABLE = 'GET_IS_NICKNAME_AVAILABLE';
export const GET_SHOW_GUIDE_HIGHLIGHT = 'GET_SHOW_GUIDE_HIGHLIGHT';
export const GET_CLASS_OPENING_PROGRESS = 'GET_CLASS_OPENING_PROGRESS';

// mutations
export const SET_HAS_REJECTION_NOTICE = 'SET_HAS_REJECTION_NOTICE';
export const SET_CLASS_OPENING_CURRENT_STEP = 'SET_CLASS_OPENING_CURRENT_STEP';
export const SET_SHOW_CREATE_CLASS_MODAL = 'SET_SHOW_CREATE_CLASS_MODAL';
export const SET_CLASS_OPENING_GUIDE_EXAMPLE_ACTIVE_INDEX = 'SET_CLASS_OPENING_GUIDE_EXAMPLE_ACTIVE_INDEX';
export const SET_CLASS_OPENING_GUIDE_EXAMPLE_CATEGORY = 'SET_CLASS_OPENING_GUIDE_EXAMPLE_CATEGORY';
export const SET_CLASS_OPENING_DATA = 'SET_CLASS_OPENING_DATA';
export const SET_CLASS_OPENING_TARGET_JOB = 'SET_CLASS_OPENING_TARGET_JOB';
export const SET_CLASS_OPENING_ANNUAL = 'SET_CLASS_OPENING_ANNUAL';
export const SET_CLASS_OPENING_NICKNAME_DATA = 'SET_CLASS_OPENING_NICKNAME_DATA';
export const SET_CLASS_OPENING_PROFILE_DATA = 'SET_CLASS_OPENING_PROFILE_DATA';
export const SET_CLASS_OPENING_WEEK_DATA = 'SET_CLASS_OPENING_WEEK_DATA';
export const SET_CLASS_OPENING_CURRICULA_DATA = 'SET_CLASS_OPENING_CURRICULA_DATA';
export const SET_CLASS_OPENING_CURRICULUM_DATA = 'SET_CLASS_OPENING_CURRICULUM_DATA';
export const SET_CLASS_OPENING_CURRICULA_SUBJECT_DATA = 'SET_CLASS_OPENING_CURRICULA_SUBJECT_DATA';
export const SET_CLASS_OPENING_CURRICULA_THEORY_DATA = 'SET_CLASS_OPENING_CURRICULA_THEORY_DATA';
export const SET_CLASS_OPENING_CURRICULA_ASSIGNMENT_DATA = 'SET_CLASS_OPENING_CURRICULA_ASSIGNMENT_DATA';
export const SET_CLASS_OPENING_CURRICULA_CURRICULUM_DATA = 'SET_CLASS_OPENING_CURRICULA_CURRICULUM_DATA';
export const SET_CLASS_OPENING_SKILL = 'SET_CLASS_OPENING_SKILL';
export const SET_CLASS_OPENING_LEVEL = 'SET_CLASS_OPENING_LEVEL';
export const SET_CLASS_OPENING_ANNUAL_LIST = 'SET_CLASS_OPENING_ANNUAL_LIST';
export const SET_CLASS_OPENING_CLASS_DIFFICULTY_LIST = 'SET_CLASS_OPENING_CLASS_DIFFICULTY_LIST';
export const SET_CLASS_OPENING_LEVEL_LIST = 'SET_CLASS_OPENING_LEVEL_LIST';
export const SET_CLASS_OPENING_CLASS_STATUS_LIST = 'SET_CLASS_OPENING_CLASS_STATUS_LIST';
export const SET_CLASS_OPENING_PAIN_POINT = 'SET_CLASS_OPENING_PAIN_POINT';
export const SET_CLASS_OPENING_FIELD = 'SET_CLASS_OPENING_FIELD';
export const SET_CLASS_OPENING_TITLE = 'SET_CLASS_OPENING_TITLE';
export const SET_CLASS_OPENING_DIFFICULTY = 'SET_CLASS_OPENING_DIFFICULTY';
export const SET_CLASS_OPENING_RESULT = 'SET_CLASS_OPENING_RESULT';
export const SET_CLASS_OPENING_MENTOR_INTRODUCTION = 'SET_CLASS_OPENING_MENTOR_INTRODUCTION';
export const SET_CLASS_OPENING_CLASS_INTRODUCTION = 'SET_CLASS_OPENING_CLASS_INTRODUCTION';
export const SET_WEEK_AND_CURRICULA = 'SET_WEEK_AND_CURRICULA';
export const SET_CLASS_OPENING_FAQS = 'SET_CLASS_OPENING_FAQS';
export const SET_CLASS_OPENING_FAQ = 'SET_CLASS_OPENING_FAQ';
export const SET_CLASS_OPENING_FILES = 'SET_CLASS_OPENING_FILES';
export const SET_CLASS_OPENING_LINKS = 'SET_CLASS_OPENING_LINKS';
export const SET_CLASS_OPENING_STEP_STATUS_LIST = 'SET_CLASS_OPENING_STEP_STATUS_LIST';
export const SET_CLASS_OPENING_STEP_STATUS = 'SET_CLASS_OPENING_STEP_STATUS';
export const SET_CLASS_OPENING_HEADER_TITLE = 'SET_CLASS_OPENING_HEADER_TITLE';
export const RESET_STORE_DATA = 'RESET_STORE_DATA';
export const SET_IS_NICKNAME_AVAILABLE = 'SET_IS_NICKNAME_AVAILABLE';
export const SET_SHOW_GUIDE_HIGHLIGHT = 'SET_SHOW_GUIDE_HIGHLIGHT';

// actions
export const RESET_CLASS_OPENING_GUIDE_EXAMPLE_ACTIVE_INDEX = 'RESET_CLASS_OPENING_GUIDE_EXAMPLE_ACTIVE_INDEX';
export const FETCH_CLASS_OPENING = 'FETCH_CLASS_OPENING';
export const TEMPORARILY_SAVE_CLASS_OPENING = 'TEMPORARILY_SAVE_CLASS_OPENING';
export const HANDLE_TEMPORARILY_SAVE_CLASS_OPENING = 'HANDLE_TEMPORARILY_SAVE_CLASS_OPENING';
export const ADD_CLASS_OPENING_THEORY = 'ADD_CLASS_OPENING_THEORY';
export const REMOVE_CLASS_OPENING_THEORY = 'REMOVE_CLASS_OPENING_THEORY';
export const FETCH_CLASS_OPENING_STATUSES = 'FETCH_CLASS_OPENING_STATUSES';
export const UPDATE_WEEK_AND_CURRICULA = 'UPDATE_WEEK_AND_CURRICULA';
export const ADD_FAQ = 'ADD_FAQ';
export const REMOVE_FAQ = 'REMOVE_FAQ';
export const ADD_CLASS_OPENING_FILE = 'ADD_CLASS_OPENING_FILE';
export const ADD_CLASS_OPENING_LINK = 'ADD_CLASS_OPENING_LINK';
export const FETCH_CLASS_OPENING_INITIAL_INFO = 'FETCH_CLASS_OPENING_INITIAL_INFO';
export const UPDATE_CLASS_OPENING_STEP_STATUS_LIST = 'UPDATE_CLASS_OPENING_STEP_STATUS_LIST';
export const UPDATE_CLASS_OPENING_STEP_STATUS = 'UPDATE_CLASS_OPENING_STEP_STATUS';
export const UPDATE_NEW_CLASS_OPENING_STEP_STATUS = 'UPDATE_NEW_CLASS_OPENING_STEP_STATUS';
export const SUBMIT_CLASS_OPENING = 'SUBMIT_CLASS_OPENING';
export const HANDLE_SUBMIT_CLASS_OPENING = 'HANDLE_SUBMIT_CLASS_OPENING';
export const CHECK_CLASS_OPEN_REJECTION = 'CHECK_CLASS_OPEN_REJECTION';

const defaultOpeningData = () => {
	return {
		// 분야
		field: null,
		// 연차
		annual: null,
		// 닉네임
		nickname: null,
		// 약력
		profile: defaultProfile,
		// 스킬/툴
		skillTool: null,
		// 직무
		job: null,
		// 대상자 난이도
		level: null,
		// 대상
		target: defaultTarget(),
		// 클래스 제목
		title: null,
		// 클래스 난이도
		difficulty: null,
		// 목표
		goal: defaultGoal(),
		// 멘토 소개
		mentorIntroduction: null,
		// 주차
		week: defaultWeek,
		// 커리큘럼
		curricula: defaultCurricula(defaultWeek),
		// 클래스 소개
		classIntroduction: defaultClassIntroduction(),
		// FAQ
		faqs: [defaultFAQ()],
		// 참고자료 파일
		files: [],
		// 참고자료 링크
		links: [],
	};
};

const defaultState = () => {
	return {
		[CLASS_OPENING_CURRENT_STEP]: defaultStep(),
		[CLASS_OPENING_GUIDE_EXAMPLE_ACTIVE_INDEX]: defaultGuideExampleActiveIndex(),
		[CLASS_OPENING_GUIDE_EXAMPLE_CATEGORY]: Object.keys(GUIDE_EXAMPLE_CATEGORIES)[0],
		[CLASS_OPENING_DATA]: defaultOpeningData(),
		CLASS_OPENING_ANNUAL_LIST: [],
		CLASS_OPENING_CLASS_DIFFICULTY_LIST: [],
		CLASS_OPENING_LEVEL_LIST: [],
		CLASS_OPENING_CLASS_STATUS_LIST: [],
		CLASS_OPENING_STEP_STATUS_LIST: defaultStepStatusList(),
		CLASS_OPENING_HEADER_TITLE: defaultHeaderTitle(),
		[SHOW_CREATE_CLASS_MODAL]: false,
		[HAS_REJECTION_NOTICE]: false,
		[IS_NICKNAME_AVAILABLE]: null,
		[IS_READ_ONLY]: false,
		[SHOW_GUIDE_HIGHLIGHT]: false,
	};
};
export const state = () => defaultState();

export const getters = {
	[GET_HAS_REJECTION_NOTICE]: state => state[HAS_REJECTION_NOTICE],
	[GET_CLASS_OPENING_CURRENT_STEP]: state => state[CLASS_OPENING_CURRENT_STEP],
	[GET_CLASS_OPENING_GUIDE_EXAMPLE_ACTIVE_INDEX]: state => state[CLASS_OPENING_GUIDE_EXAMPLE_ACTIVE_INDEX],
	[GET_CLASS_OPENING_GUIDE_EXAMPLE_CATEGORY]: state => state[CLASS_OPENING_GUIDE_EXAMPLE_CATEGORY],
	[GET_SHOW_CREATE_CLASS_MODAL]: state => state[SHOW_CREATE_CLASS_MODAL],
	[GET_SHOW_GUIDE_HIGHLIGHT]: state => state[SHOW_GUIDE_HIGHLIGHT],
	[GET_CLASS_OPENING_DATA]: state => state[CLASS_OPENING_DATA],
	[GET_CLASS_OPENING_STATUS]: state =>
		CLASS_OPENING_STATUS_TO_STRING_MAP[get(state[CLASS_OPENING_DATA], 'status', 12)],
	[GET_IS_READ_ONLY]: (_, getters) =>
		[CLASS_OPENING_STATUS.IN_REVIEW, CLASS_OPENING_STATUS.REVIEW_COMPLETE].includes(
			getters[GET_CLASS_OPENING_STATUS],
		),
	[GET_CLASS_OPENING_TARGET_JOB]: state => get(state[CLASS_OPENING_DATA], 'job', null),
	[GET_CLASS_OPENING_ANNUAL]: state => get(state[CLASS_OPENING_DATA], 'annual', null),
	[GET_CLASS_OPENING_NICKNAME_DATA]: state => get(state[CLASS_OPENING_DATA], 'nickname', null),
	[GET_CLASS_OPENING_PROFILE_DATA]: state => get(state[CLASS_OPENING_DATA], 'profile', null),
	[GET_CLASS_OPENING_WEEK_DATA]: state => get(state[CLASS_OPENING_DATA], 'week', 1),
	[GET_CLASS_OPENING_CURRICULA_DATA]: state => get(state[CLASS_OPENING_DATA], 'curricula', null),
	[GET_CLASS_OPENING_CURRICULUM_DATA]: state => week => {
		return get(state[CLASS_OPENING_DATA], ['curricula', week - 1], null);
	},
	[GET_CLASS_OPENING_CURRICULA_THEORY]: state => week => {
		return get(state[CLASS_OPENING_DATA], ['curricula', week - 1, 'curriculum'], null);
	},
	[GET_CLASS_OPENING_SKILL]: state => get(state[CLASS_OPENING_DATA], 'skillTool', null),
	[GET_CLASS_OPENING_TARGET_JOB]: state => get(state[CLASS_OPENING_DATA], 'job', null),
	[GET_CLASS_OPENING_LEVEL]: state => get(state[CLASS_OPENING_DATA], 'level', null),
	[GET_CLASS_OPENING_ANNUAL_LIST]: state => state[CLASS_OPENING_ANNUAL_LIST],
	[GET_CLASS_OPENING_CLASS_DIFFICULTY_LIST]: state => state[CLASS_OPENING_CLASS_DIFFICULTY_LIST],
	[GET_CLASS_OPENING_LEVEL_LIST]: state => state[CLASS_OPENING_LEVEL_LIST],
	[GET_CLASS_OPENING_CLASS_STATUS_LIST]: state => state[CLASS_OPENING_CLASS_STATUS_LIST],
	[GET_CLASS_OPENING_PAIN_POINTS]: state => get(state[CLASS_OPENING_DATA], ['target'], null),
	[GET_CLASS_OPENING_PAIN_POINT]: state => index => get(state[CLASS_OPENING_DATA], ['target', index], null),
	[GET_CLASS_OPENING_FIELD]: state => get(state[CLASS_OPENING_DATA], 'field', null),
	[GET_CLASS_OPENING_TITLE]: state => get(state[CLASS_OPENING_DATA], 'title', null),
	[GET_CLASS_OPENING_DIFFICULTY]: state => get(state[CLASS_OPENING_DATA], 'difficulty', null),
	[GET_CLASS_OPENING_RESULTS]: state => get(state[CLASS_OPENING_DATA], 'goal', []),
	[GET_CLASS_OPENING_RESULT]: state => index => get(state[CLASS_OPENING_DATA], ['goal', index], null),
	[GET_CLASS_OPENING_MENTOR_INTRODUCTION]: state => get(state[CLASS_OPENING_DATA], 'mentorIntroduction', null),
	[GET_CLASS_OPENING_CLASS_INTRODUCTION]: state => get(state[CLASS_OPENING_DATA], 'classIntroduction', null),
	[GET_CLASS_OPENING_FAQS]: state => get(state[CLASS_OPENING_DATA], 'faqs', null),
	[GET_CLASS_OPENING_FAQ]: state => index => get(state[CLASS_OPENING_DATA], ['faqs', index], null),
	[GET_CLASS_OPENING_FILES]: state => get(state[CLASS_OPENING_DATA], 'files', []),
	[GET_CLASS_OPENING_LINKS]: state => get(state[CLASS_OPENING_DATA], 'links', []),
	[GET_CLASS_OPENING_FILES_AND_LINKS]: state => {
		const files = get(state[CLASS_OPENING_DATA], 'files', []);
		const links = get(state[CLASS_OPENING_DATA], 'links', []);
		return [...files, ...links];
	},
	[GET_CLASS_OPENING_ID]: state => get(state[CLASS_OPENING_DATA], 'id', null),
	[GET_HAS_CLASS_OPENING]: (_, getters) => !!getters[GET_CLASS_OPENING_ID],
	[GET_CLASS_OPENING_STEP_STATUS_LIST]: state => state[CLASS_OPENING_STEP_STATUS_LIST],
	[GET_CLASS_OPENING_STEP_STATUS]: (_, getters) => step => get(getters[GET_CLASS_OPENING_STEP_STATUS_LIST], step),
	[GET_IS_CLASS_OPENING_STEP_STATUS_HALF]: (_, getters) => step => {
		return getters[GET_CLASS_OPENING_STEP_STATUS](step) === 'half';
	},
	[GET_CLASS_OPENING_HEADER_TITLE]: state => state[CLASS_OPENING_HEADER_TITLE],
	[GET_IS_IN_REVIEW]: (_, getters) => getters[GET_CLASS_OPENING_STATUS] === CLASS_OPENING_STATUS.IN_REVIEW,
	[GET_IS_REJECTED]: (_, getters) => getters[GET_CLASS_OPENING_STATUS] === CLASS_OPENING_STATUS.REJECTED,
	[GET_IS_EDIT]: (_, getters) => getters[GET_IS_REJECTED],
	[GET_IS_NICKNAME_AVAILABLE]: state => state[IS_NICKNAME_AVAILABLE],
	[GET_CLASS_OPENING_PROGRESS]: (_, getters) => {
		const stepStatusValues = Object.values(getters[GET_CLASS_OPENING_STEP_STATUS_LIST]);
		const fullStatusCount = stepStatusValues.reduce((accumulator, status) => {
			if (status === 'full') {
				accumulator += 1;
			}
			return accumulator;
		}, 0);
		return (fullStatusCount / stepStatusValues.length) * 100;
	},
};

export const mutations = {
	[SET_HAS_REJECTION_NOTICE]: (state, payload) => {
		state[HAS_REJECTION_NOTICE] = payload;
	},
	[SET_CLASS_OPENING_CURRENT_STEP]: (state, payload) => {
		state[CLASS_OPENING_CURRENT_STEP] = payload;
	},
	[SET_CLASS_OPENING_GUIDE_EXAMPLE_ACTIVE_INDEX]: (state, payload) => {
		state[CLASS_OPENING_GUIDE_EXAMPLE_ACTIVE_INDEX] = payload;
	},
	[SET_CLASS_OPENING_GUIDE_EXAMPLE_CATEGORY]: (state, payload) => {
		state[CLASS_OPENING_GUIDE_EXAMPLE_CATEGORY] = payload;
	},
	[SET_SHOW_GUIDE_HIGHLIGHT]: (state, payload) => {
		state[SHOW_GUIDE_HIGHLIGHT] = payload;
	},
	[SET_SHOW_CREATE_CLASS_MODAL]: (state, payload) => {
		state[SHOW_CREATE_CLASS_MODAL] = payload;
	},
	[SET_CLASS_OPENING_DATA]: (state, payload) => {
		state[CLASS_OPENING_DATA] = payload;
	},
	[SET_CLASS_OPENING_TARGET_JOB]: (state, payload) => {
		state[CLASS_OPENING_DATA].job = payload;
	},
	[SET_CLASS_OPENING_ANNUAL]: (state, payload) => {
		state[CLASS_OPENING_DATA].annual = payload;
	},
	[SET_CLASS_OPENING_NICKNAME_DATA]: (state, payload) => {
		state[CLASS_OPENING_DATA].nickname = payload;
	},
	[SET_CLASS_OPENING_PROFILE_DATA]: (state, payload) => {
		state[CLASS_OPENING_DATA].profile = payload;
	},
	[SET_CLASS_OPENING_WEEK_DATA]: (state, payload) => {
		state[CLASS_OPENING_DATA].week = payload;
	},
	[SET_CLASS_OPENING_CURRICULA_DATA]: (state, payload) => {
		state[CLASS_OPENING_DATA].curriculula = payload;
	},
	[SET_CLASS_OPENING_CURRICULUM_DATA]: (state, { value, week }) => {
		state[CLASS_OPENING_DATA].curriculula[week - 1] = value;
	},
	[SET_CLASS_OPENING_CURRICULA_SUBJECT_DATA]: (state, { value, week }) => {
		state[CLASS_OPENING_DATA].curricula[week - 1].subject = value;
	},
	// payload: value, week, index
	// curriculum이 array라서 state[CLASS_OPENING_DATA].curricula[week - 1].curriculum[index] = value;
	// 을 사용하면 Editor에서 변화를 감지하지 못함 -> ValidationProvider에도 값이 안 들어감
	[SET_CLASS_OPENING_CURRICULA_THEORY_DATA]: (state, { value, week, index }) => {
		const copiedData = Object.assign({}, state[CLASS_OPENING_DATA]);
		copiedData.curricula[week - 1].curriculum[index] = value;
		state[CLASS_OPENING_DATA] = copiedData;
	},
	// payload: value, week
	[SET_CLASS_OPENING_CURRICULA_ASSIGNMENT_DATA]: (state, { value, week }) => {
		state[CLASS_OPENING_DATA].curricula[week - 1].assignment = value;
	},
	// payload: value, week
	[SET_CLASS_OPENING_CURRICULA_CURRICULUM_DATA]: (state, { value, week }) => {
		state[CLASS_OPENING_DATA].curricula[week - 1].curriculum = value;
	},
	[SET_CLASS_OPENING_SKILL]: (state, payload) => {
		state[CLASS_OPENING_DATA].skillTool = payload;
	},
	[SET_CLASS_OPENING_ANNUAL_LIST]: (state, payload) => {
		state[CLASS_OPENING_ANNUAL_LIST] = payload;
	},
	[SET_CLASS_OPENING_LEVEL_LIST]: (state, payload) => {
		state[CLASS_OPENING_LEVEL_LIST] = payload;
	},
	[SET_CLASS_OPENING_CLASS_DIFFICULTY_LIST]: (state, payload) => {
		state[CLASS_OPENING_CLASS_DIFFICULTY_LIST] = payload;
	},
	[SET_CLASS_OPENING_CLASS_STATUS_LIST]: (state, payload) => {
		state[CLASS_OPENING_CLASS_STATUS_LIST] = payload;
	},
	[SET_CLASS_OPENING_LEVEL]: (state, payload) => {
		state[CLASS_OPENING_DATA].level = payload;
	},
	[SET_CLASS_OPENING_PAIN_POINT]: (state, { value, index }) => {
		state[CLASS_OPENING_DATA].target[index] = value;
	},
	[SET_CLASS_OPENING_FIELD]: (state, payload) => {
		state[CLASS_OPENING_DATA].field = payload;
	},
	[SET_CLASS_OPENING_TITLE]: (state, payload) => {
		state[CLASS_OPENING_DATA].title = payload;
	},
	[SET_CLASS_OPENING_DIFFICULTY]: (state, payload) => {
		state[CLASS_OPENING_DATA].difficulty = payload;
	},
	[SET_CLASS_OPENING_RESULT]: (state, { value, index }) => {
		state[CLASS_OPENING_DATA].goal[index] = value;
	},
	[SET_CLASS_OPENING_MENTOR_INTRODUCTION]: (state, payload) => {
		state[CLASS_OPENING_DATA].mentorIntroduction = payload;
	},
	[SET_CLASS_OPENING_CLASS_INTRODUCTION]: (state, { value, index }) => {
		state[CLASS_OPENING_DATA].classIntroduction[index] = value;
	},
	[SET_WEEK_AND_CURRICULA]: (state, { week, curricula }) => {
		state[CLASS_OPENING_DATA].week = week;
		state[CLASS_OPENING_DATA].curricula = curricula;
	},
	[SET_CLASS_OPENING_FAQS]: (state, payload) => {
		state[CLASS_OPENING_DATA].faqs = payload;
	},
	// type: question, content
	[SET_CLASS_OPENING_FAQ]: (state, { value, index, type }) => {
		state[CLASS_OPENING_DATA].faqs[index][type] = value;
	},
	[SET_CLASS_OPENING_FILES]: (state, payload) => {
		state[CLASS_OPENING_DATA].files = payload;
	},
	[SET_CLASS_OPENING_LINKS]: (state, payload) => {
		state[CLASS_OPENING_DATA].links = payload;
	},
	[SET_CLASS_OPENING_STEP_STATUS_LIST]: (state, payload) => {
		state[CLASS_OPENING_STEP_STATUS_LIST] = payload;
	},
	[SET_CLASS_OPENING_STEP_STATUS]: (state, { step, status }) => {
		state[CLASS_OPENING_STEP_STATUS_LIST][step] = status;
	},
	[SET_CLASS_OPENING_HEADER_TITLE]: (state, payload) => {
		state[CLASS_OPENING_HEADER_TITLE] = payload;
	},
	[RESET_STORE_DATA]: state => {
		Object.assign(state, defaultState());
	},
	[SET_IS_NICKNAME_AVAILABLE]: (state, payload) => {
		state[IS_NICKNAME_AVAILABLE] = payload;
	},
};

export const actions = {
	[RESET_CLASS_OPENING_GUIDE_EXAMPLE_ACTIVE_INDEX]({ commit }) {
		commit(SET_CLASS_OPENING_GUIDE_EXAMPLE_ACTIVE_INDEX, -1);
	},

	async [FETCH_CLASS_OPENING]({ commit }, params) {
		try {
			const { data } = await this.$api.classPtOpen.getClassOpeningInfo(params);
			// 수정요청시에는 id가 필요
			// 수정하면 어드민도 수정해야 함
			const convertCurriculaData = data.curricula.map(curriculumItem => {
				const theories = curriculumItem.processes.filter(process => process.homework === 'N');
				const assignment = curriculumItem.processes.find(process => process.homework === 'Y');
				return {
					subject: curriculumItem.subject,
					curriculum: theories.map((theory, index) => convertTheory(theory, index)),
					// 마지막 주차 과제 없음
					...(assignment && {
						assignment: convertAssignment(assignment),
					}),
				};
			});
			commit(SET_CLASS_OPENING_DATA, {
				...data,
				curricula: convertCurriculaData,
				target: convertPayloadIntoStringArray(data.target, defaultTarget()),
				goal: convertPayloadIntoStringArray(data.goal, defaultGoal()),
				classIntroduction: convertPayloadIntoStringArray(data.classIntroduction, defaultClassIntroduction()),
				profile: convertPayloadIntoProfile(data.profile),
			});
			if (data.title) {
				commit(SET_CLASS_OPENING_HEADER_TITLE, data.title);
			} else {
				commit(SET_CLASS_OPENING_HEADER_TITLE, defaultHeaderTitle());
			}
			// 작성중이 아닌 경우에는 닉네임 체크
			if (data.status !== CLASS_OPENING_STATUS_TO_NUMBER_MAP[CLASS_OPENING_STATUS.IN_PROGRESS]) {
				commit(SET_IS_NICKNAME_AVAILABLE, true);
			}
		} catch (error) {
			console.error('FETCH_CLASS_OPENING error: ', error);
		}
	},
	async [TEMPORARILY_SAVE_CLASS_OPENING]({ getters }) {
		try {
			const response = await this.$api.classPtOpen.temporarilySaveClassOpening(
				convertClassOpeningDataIntoPayload(getters),
			);
			return {
				classId: response.data,
				originalData: getters[GET_CLASS_OPENING_DATA],
				convertedData: convertClassOpeningDataIntoPayload(getters),
			};
		} catch (error) {
			console.error(error);
		}
	},
	async [SUBMIT_CLASS_OPENING]({ getters }) {
		try {
			const response = await this.$api.classPtOpen.submitClassOpening(
				convertClassOpeningDataIntoPayload(getters),
			);
			return {
				classId: response.data,
				originalData: getters[GET_CLASS_OPENING_DATA],
				convertedData: convertClassOpeningDataIntoPayload(getters),
			};
		} catch (error) {
			console.error(error);
		}
	},
	async [HANDLE_TEMPORARILY_SAVE_CLASS_OPENING]({ dispatch }) {
		const { classId, originalData, convertedData } = await dispatch(TEMPORARILY_SAVE_CLASS_OPENING);
		await dispatch(FETCH_CLASS_OPENING_INITIAL_INFO, { classId });
		return { classId, originalData, convertedData };
	},
	async [HANDLE_SUBMIT_CLASS_OPENING]({ dispatch }) {
		const result = await dispatch(SUBMIT_CLASS_OPENING);
		return result;
	},
	[ADD_CLASS_OPENING_THEORY]({ commit, getters }, params) {
		commit(SET_CLASS_OPENING_CURRICULA_CURRICULUM_DATA, {
			value: [...getters[GET_CLASS_OPENING_CURRICULA_THEORY](params.week), defaultTheory(params.index)],
			week: params.week,
		});
	},
	[REMOVE_CLASS_OPENING_THEORY]({ commit, getters }, params) {
		const theoryLength = getters[GET_CLASS_OPENING_CURRICULA_THEORY](params.week).length;
		commit(SET_CLASS_OPENING_CURRICULA_CURRICULUM_DATA, {
			value: [...getters[GET_CLASS_OPENING_CURRICULA_THEORY](params.week)].slice(0, theoryLength - 1),
			week: params.week,
		});
	},
	async [FETCH_CLASS_OPENING_STATUSES]({ commit }) {
		try {
			const {
				data: { annual, level, classDifficulty, classStatus },
			} = await this.$api.classPtOpen.getClassOpeningStatuses();
			commit(SET_CLASS_OPENING_ANNUAL_LIST, annual);
			commit(SET_CLASS_OPENING_LEVEL_LIST, level);
			commit(SET_CLASS_OPENING_CLASS_DIFFICULTY_LIST, classDifficulty);
			commit(SET_CLASS_OPENING_CLASS_STATUS_LIST, classStatus);
		} catch (error) {
			console.error('FETCH_CLASS_OPENING_STATUSES error: ', error);
		}
	},
	[UPDATE_WEEK_AND_CURRICULA]({ commit, getters }, params) {
		const currentWeek = getters[GET_CLASS_OPENING_CURRICULA_DATA].length;
		const targetWeek = params.week;
		if (targetWeek === currentWeek) {
			return;
		}
		if (targetWeek > currentWeek) {
			// 현재 마지막 주차 과제를 채워 넣음(원래는 마지막 주차여서 비어있었으므로)
			commit(SET_CLASS_OPENING_CURRICULA_ASSIGNMENT_DATA, {
				value: defaultAssignment,
				week: getters[GET_CLASS_OPENING_WEEK_DATA],
			});
			commit(SET_WEEK_AND_CURRICULA, {
				week: targetWeek,
				curricula: [
					...getters[GET_CLASS_OPENING_CURRICULA_DATA],
					...Array.from({ length: targetWeek - currentWeek }, () => defaultCurriculum(true)),
				],
			});
		}
		if (targetWeek < currentWeek) {
			// 마지막 주차는 과제가 없어야 한다.
			const lastWeekCurriculum = { ...getters[GET_CLASS_OPENING_CURRICULUM_DATA](targetWeek) };
			delete lastWeekCurriculum.assignment;
			commit(SET_WEEK_AND_CURRICULA, {
				week: targetWeek,
				curricula: [
					...[...getters[GET_CLASS_OPENING_CURRICULA_DATA]].slice(0, targetWeek - 1),
					lastWeekCurriculum,
				],
			});
		}
	},
	[ADD_FAQ]({ commit, getters }) {
		commit(SET_CLASS_OPENING_FAQS, [...getters[GET_CLASS_OPENING_FAQS], defaultFAQ()]);
	},
	[REMOVE_FAQ]({ commit, getters }, index) {
		commit(
			SET_CLASS_OPENING_FAQS,
			[...getters[GET_CLASS_OPENING_FAQS]].filter((_, faqIndex) => {
				return faqIndex !== index;
			}),
		);
	},
	[ADD_CLASS_OPENING_FILE]({ commit, getters }, files) {
		commit(SET_CLASS_OPENING_FILES, [...getters[GET_CLASS_OPENING_FILES], ...files]);
	},
	[ADD_CLASS_OPENING_LINK]({ commit, getters }, link) {
		commit(SET_CLASS_OPENING_LINKS, [...getters[GET_CLASS_OPENING_LINKS], { id: null, url: link }]);
	},
	// params : classId
	async [FETCH_CLASS_OPENING_INITIAL_INFO]({ dispatch }, params) {
		await dispatch(FETCH_CLASS_OPENING, params);
	},
	[UPDATE_CLASS_OPENING_STEP_STATUS_LIST]({ commit, getters }) {
		commit(SET_CLASS_OPENING_STEP_STATUS_LIST, {
			...checkStepStatusResult(getters, [
				CLASS_OPENING_STEPS.PROFILE,
				CLASS_OPENING_STEPS.CLASS_TARGET,
				CLASS_OPENING_STEPS.CLASS_OBJECTIVE,
				CLASS_OPENING_STEPS.CURRICULUM,
				CLASS_OPENING_STEPS.CLASS_DESCRIPTION,
			]),
		});
	},
	// @step : 체크할 단계
	[UPDATE_CLASS_OPENING_STEP_STATUS]({ commit, getters }, step) {
		commit(SET_CLASS_OPENING_STEP_STATUS_LIST, {
			...getters[GET_CLASS_OPENING_STEP_STATUS_LIST],
			...checkStepStatusResult(getters, [step]),
		});
	},
	[UPDATE_NEW_CLASS_OPENING_STEP_STATUS]({ commit }, payload) {
		commit(SET_CLASS_OPENING_STEP_STATUS, payload);
	},
	async [CHECK_CLASS_OPEN_REJECTION]({ commit }) {
		try {
			const { data } = await this.$api.classPtOpen.getCheckRejection();
			commit(SET_HAS_REJECTION_NOTICE, data);
		} catch (error) {
			console.error('CHECK_CLASS_OPEN_REJECTION error: ', error);
		}
	},
};
