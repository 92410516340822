import { mapGetters } from 'vuex';

import { getSessionStorage, setSessionStorage, removeSessionStorage } from '@/utils/utils';

import {
	mapMutationsOfCampPath,
	SET_IS_APPLY_CAREER_CAMP,
	SET_IS_CHECK_CAREER_AUTH,
	SET_APPLY_CAREER_CAMP_LIST,
	SET_PATH_CONTRACT_NO,
} from '@/store/camp/path';

import gtmTriggerMixin from '@/mixins/gtmTriggerMixin';
import loadingMixin from '@/mixins/loadingMixin';
import routeMixin from '@/mixins/routeMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

const eduCampMixin = {
	mixins: [routeMixin, userInfoMixin, gtmTriggerMixin, loadingMixin],
	props: {
		page: {
			type: Number,
		},
		name: {
			type: String,
		},
		storeKeyword: {
			type: String,
		},
	},
	computed: {
		...mapGetters(['campInfo', 'menteeName']),
	},
	data() {
		return {
			matchingOption: true,
			resultMatchingOption: false,
		};
	},
	methods: {
		...mapMutationsOfCampPath([
			SET_APPLY_CAREER_CAMP_LIST,
			SET_PATH_CONTRACT_NO,
			SET_IS_APPLY_CAREER_CAMP,
			SET_IS_CHECK_CAREER_AUTH,
		]),
		$_detail(url, no, _blank) {
			if (typeof this.page !== 'undefined') {
				const isRecommendCamp = this.name === 'recommend-camp';
				if (isRecommendCamp) {
					removeSessionStorage('state_edu_camp_list');
				} else {
					const eduCampList = getSessionStorage('state_edu_camp_list') || {};
					eduCampList.campList = this.campList;
					eduCampList.page = this.page;
					eduCampList.count = this.count;
					eduCampList.no = no;
					eduCampList.keyword = this.storeKeyword;
					setSessionStorage('state_edu_camp_list', eduCampList);
				}
			}
			if (_blank) {
				this.$_routeMixin_open_page(url);
			} else {
				this.$_routeMixin_go_page(url);
			}
		},
		$_campLocation(channel, location) {
			let result = '온라인';

			if (channel !== 'online') {
				const locationArr = location.split(',');
				const locationLastItem = locationArr.slice(-1).pop();
				result = locationLastItem.trim();
			}

			return result;
		},
		$_isEarlyCamp(campPrice) {
			// 가격이 24만원 미만일 때
			return campPrice < 240000;
		},
		$_printInternOrCamp(eduCurrent) {
			let text = '인턴';
			if (eduCurrent && parseInt(eduCurrent) === 1) {
				text = '캠프';
			}
			return text;
		},
		$_printEncoreCampTitle(title, round = 0, partner) {
			const isPartner = partner && partner.name !== 'comento';
			if (isPartner) {
				return title;
			} else if (round > 1) {
				return `[${round}차 앵콜] ${title}`;
			} else {
				return title;
			}
		},
		$_isEndCamp(running, partnerName) {
			return running === 0 && partnerName === 'comento';
		},
		$_isNineWeekCampTitle(title) {
			if (title) {
				return title.includes('[9주 심화반]');
			}
			return false;
		},
	},
};

export default eduCampMixin;
