import { createNamespacedHelpers } from 'vuex';

import dayjs from '@/plugins/dayjs';

const NAMESPACE = 'edu/schedule';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfEduSchedule,
	mapMutations as mapMutationsOfEduSchedule,
	mapActions as mapActionsOfEduSchedule,
};

// state / getters
export const EDU_SCHEDULE_CALENDAR_PICKED = 'EDU_SCHEDULE_CALENDAR_PICKED';
export const EDU_SCHEDULE_CALENDAR_SELECTED = 'EDU_SCHEDULE_CALENDAR_SELECTED';
export const EDU_SCHEDULE_SELECTED_TIME = 'EDU_SCHEDULE_SELECTED_TIME';
export const EDU_SCHEDULE_WEEK_LIST = 'EDU_SCHEDULE_WEEK_LIST';
export const EDU_SCHEDULE_SELECTED_WEEK = 'EDU_SCHEDULE_SELECTED_WEEK';
export const EDU_SCHEDULE_REASON_OF_CHANGE = 'EDU_SCHEDULE_REASON_OF_CHANGE';
export const EDU_MENTOR_OTHER_CAMP_SCHEDULE = 'EDU_MENTOR_OTHER_CAMP_SCHEDULE';

// mutations
export const SET_EDU_SCHEDULE_CALENDAR_PICKED = 'SET_EDU_SCHEDULE_CALENDAR_PICKED';
export const SET_EDU_SCHEDULE_CALENDAR_SELECTED = 'SET_EDU_SCHEDULE_CALENDAR_SELECTED';
export const SET_EDU_SCHEDULE_SELECTED_TIME = 'SET_EDU_SCHEDULE_SELECTED_TIME';
export const SET_EDU_SCHEDULE_WEEK_LIST = 'SET_EDU_SCHEDULE_WEEK_LIST';
export const SET_EDU_SCHEDULE_SELECTED_WEEK = 'SET_EDU_SCHEDULE_SELECTED_WEEK';
export const SET_EDU_SCHEDULE_REASON_OF_CHANGE = 'SET_EDU_SCHEDULE_REASON_OF_CHANGE';
export const SET_EDU_MENTOR_OTHER_CAMP_SCHEDULE = 'SET_EDU_MENTOR_OTHER_CAMP_SCHEDULE';

// actions
export const FETCH_EDU_SCHEDULE_REQUESTS = 'FETCH_EDU_SCHEDULE_REQUESTS';
export const SUBMIT_EDU_SCHEDULE_REQUEST = 'SUBMIT_EDU_SCHEDULE_REQUEST';

export const state = () => ({
	[EDU_SCHEDULE_CALENDAR_SELECTED]: '',
	[EDU_SCHEDULE_SELECTED_TIME]: '',
	[EDU_SCHEDULE_SELECTED_WEEK]: {},
	[EDU_SCHEDULE_REASON_OF_CHANGE]: '',
	[EDU_SCHEDULE_WEEK_LIST]: [],
});

export const getters = {
	[EDU_SCHEDULE_CALENDAR_PICKED]: state => {
		return state[EDU_SCHEDULE_SELECTED_WEEK].date;
	},
	[EDU_SCHEDULE_CALENDAR_SELECTED]: state => {
		return state[EDU_SCHEDULE_CALENDAR_SELECTED];
	},
	[EDU_SCHEDULE_SELECTED_TIME]: state => {
		return state[EDU_SCHEDULE_SELECTED_TIME];
	},
	[EDU_SCHEDULE_WEEK_LIST]: state => {
		return state[EDU_SCHEDULE_WEEK_LIST];
	},
	[EDU_SCHEDULE_REASON_OF_CHANGE]: state => {
		return state[EDU_SCHEDULE_REASON_OF_CHANGE];
	},
	[EDU_SCHEDULE_SELECTED_WEEK]: state => {
		return state[EDU_SCHEDULE_SELECTED_WEEK];
	},
	[EDU_MENTOR_OTHER_CAMP_SCHEDULE]: state => {
		return state[EDU_MENTOR_OTHER_CAMP_SCHEDULE];
	},
};

export const mutations = {
	[SET_EDU_SCHEDULE_CALENDAR_PICKED]: (state, payload) => {
		state[EDU_SCHEDULE_CALENDAR_PICKED] = payload;
	},
	[SET_EDU_SCHEDULE_CALENDAR_SELECTED]: (state, payload) => {
		state[EDU_SCHEDULE_CALENDAR_SELECTED] = payload;
	},
	[SET_EDU_SCHEDULE_SELECTED_TIME]: (state, payload) => {
		state[EDU_SCHEDULE_SELECTED_TIME] = payload;
	},
	[SET_EDU_SCHEDULE_WEEK_LIST]: (state, payload) => {
		state[EDU_SCHEDULE_WEEK_LIST] = payload;
	},
	[SET_EDU_SCHEDULE_REASON_OF_CHANGE]: (state, payload) => {
		state[EDU_SCHEDULE_REASON_OF_CHANGE] = payload;
	},
	[SET_EDU_SCHEDULE_SELECTED_WEEK]: (state, payload) => {
		state[EDU_SCHEDULE_SELECTED_WEEK] = payload;
	},
	[SET_EDU_MENTOR_OTHER_CAMP_SCHEDULE]: (state, payload) => {
		const scheduleList = payload.map(schedule => {
			return schedule.weekList.map(week => week.date);
		});
		const deleteRedundant = new Set(scheduleList.flat());
		state[EDU_MENTOR_OTHER_CAMP_SCHEDULE] = [...deleteRedundant].sort((a, b) =>
			dayjs(a).isAfter(dayjs(b)) ? 1 : -1,
		);
	},
};

export const actions = {
	async [FETCH_EDU_SCHEDULE_REQUESTS]({ commit }, campNo) {
		try {
			const {
				data: {
					data: { weekList, duplicatedCamp },
				},
			} = await this.$api.camp.getEduScheduleRequests(campNo);
			commit(SET_EDU_SCHEDULE_WEEK_LIST, weekList);
			commit(SET_EDU_MENTOR_OTHER_CAMP_SCHEDULE, duplicatedCamp);
			return true;
		} catch (error) {
			console.error('FETCH_EDU_SCHEDULE_REQUESTS error: ', error);
			return false;
		}
	},
	async [SUBMIT_EDU_SCHEDULE_REQUEST]({ getters }, { campNo, isMenteeAgreed }) {
		const data = {
			week: getters[EDU_SCHEDULE_SELECTED_WEEK].week,
			reasonForDelay: getters[EDU_SCHEDULE_REASON_OF_CHANGE],
			referenceDate: getters[EDU_SCHEDULE_SELECTED_WEEK].date,
			requestDate: `${getters[EDU_SCHEDULE_CALENDAR_SELECTED]} ${getters[EDU_SCHEDULE_SELECTED_TIME]}`,
			isMenteeAgreed,
		};
		try {
			await this.$api.camp.postEduScheduleRequest(campNo, data);
			return true;
		} catch (error) {
			console.error('SUBMIT_EDU_SCHEDULE_REQUEST error: ', error);
			return false;
		}
	},
};
