
import layoutMixin from '@/mixins/layoutMixin';
import routeMixin from '@/mixins/routeMixin';

export default {
	name: 'CommunityUserVoiceAlert',
	mixins: [layoutMixin, routeMixin],
	methods: {
		openTargetPage() {
			this.$_routeMixin_open_page(
				'https://docs.google.com/forms/d/e/1FAIpQLScgdHQlr5lHEW8_nMCFolb5z1PHsGTtEvIxC9_1dHiPqbOugA/viewform?usp=sf_link',
			);
		},
	},
};
