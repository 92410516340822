
import GridLayout from '@/components/common/atoms/GridLayout.vue';

import layoutMixin from '@/mixins/layoutMixin';

import Skeleton from '@/src/components/Other/Skeleton.vue';

export default {
	name: 'CommunityCardSkeleton',
	mixins: [layoutMixin],
	props: {
		limit: {
			type: Number,
			default: 3,
		},
		showEvent: {
			type: Boolean,
			default: false,
		},
		showCategory: {
			type: Boolean,
			default: false,
		},
		showThumbnail: {
			type: Boolean,
			default: false,
		},
		showDate: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		GridLayout,
		Skeleton,
	},
};
