export const state = () => ({
	errorModalTitle: '권한 없음',
	errorModalContent: '<div>권한이 없습니다.</div>',
	showErrorModal: false,
	errorModalType: 'redirect',
});

export const getters = {
	errorModalTitle: state => {
		return state.errorModalTitle;
	},
	errorModalContent: state => {
		return state.errorModalContent;
	},
	errorModalType: state => {
		return state.errorModalType;
	},
	showErrorModal: state => {
		return state.showErrorModal;
	},
};

export const mutations = {
	setErrorModalType(state, payload) {
		state.errorModalType = payload;
	},
	setErrorModalTitle(state, payload) {
		state.errorModalTitle = payload;
	},
	setErrorModalContent(state, payload) {
		state.errorModalContent = payload;
	},
	openErrorModal(state) {
		state.showErrorModal = true;
	},
	closeErrorModal(state) {
		state.showErrorModal = false;
	},
};

export const actions = {};
