import { CDN_EMOJI_PATH } from '@/constants/paths';

import { cdnBaseUrl } from '@/plugins/constants';

export function getCdnPath(path) {
	return `${cdnBaseUrl}${path}`;
}

export function getCdnImagePath(path) {
	return `${cdnBaseUrl}/images${path}`;
}

export function getEmojiCdnUrl(name) {
	return `${cdnBaseUrl}${CDN_EMOJI_PATH}/emoji-${name}.svg`;
}

export function getCustomEmojiUrl(name) {
	return `${cdnBaseUrl}${CDN_EMOJI_PATH}/custom/${name}.svg`;
}
