import { APP_STATE, APP_USER_INFO, APP_USER_PASS_INFO } from '@/constants/app';

import { baseUrl } from '@/plugins/constants';

import { isEmptyData, checkIsApp, decodeBase64 } from '@/utils/utils';

import { NAMESPACE_MY_PAGE, LOAD_PASS_INFO, SET_IS_LOADED_PASS_INFO, SET_PASS_INFO } from '@/store/myPage';

export default function ({ redirect, req }) {
	const userAgent = req.headers['user-agent'];
	if (!checkIsApp(userAgent)) {
		return redirect(`${baseUrl}`);
	}
}

/**
 * appState를 set할 수 있다면 true
 * 앱이거나 앱에서 외부 브라우저를 열었을 때, 인증 정보가 끊기지 않게 한다.
 */
export function canSetAppState(req) {
	return !isEmptyData(req.headers[APP_STATE]);
}

/**
 * 앱에서는 웹뷰를 여러 번 띄웠다 내림
 * 그때마다 호출되는 nuxtServerInit 내부 api load를 줄이기 위해
 * api call이 아닌 app의 커스텀 헤더에 state를 저장
 */
export async function setAppState(req, commit, dispatch, getters) {
	commit('setJwt', req.headers.authorization?.split(' ')[1]);
	const appState = JSON.parse(decodeBase64(req.headers[APP_STATE]));
	if (!isEmptyData(getters.jwt) && getters.jwt !== 'fail') {
		await Promise.all([setUserInfo(appState, commit, dispatch), setUserPassInfo(appState, commit, dispatch)]);
	}
}
// 앱에서 보낸 정보가 없다면, 기존대로 api call
async function setUserInfo(appState, commit, dispatch) {
	if (isEmptyData(appState[APP_USER_INFO])) {
		return await dispatch('getUserInfo');
	}
	commit('setUserInfo', appState[APP_USER_INFO]);
}

async function setUserPassInfo(appState, commit, dispatch) {
	if (isEmptyData(appState[APP_USER_PASS_INFO])) {
		return await dispatch(`${NAMESPACE_MY_PAGE}/${LOAD_PASS_INFO}`);
	}
	commit(`${NAMESPACE_MY_PAGE}/${SET_PASS_INFO}`, appState[APP_USER_PASS_INFO]);
	commit(`${NAMESPACE_MY_PAGE}/${SET_IS_LOADED_PASS_INFO}`, true);
}
