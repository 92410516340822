import { isProduction } from '@/plugins/constants';

import { validateAppUrl } from '@/utils/app/url';
import { compareVersion } from '@/utils/utils';

import layoutMixin from '@/mixins/layoutMixin';

export const APP_COMMAND = {
	OPEN_EXTERNAL_BROWSER: 'openExternalBrowser',
	SET_META: 'setMeta',
	SHOW_TOAST: 'showToast',
	SET_UI: 'setUI',
	SET_WEB_VIEW_SIZE: 'setWebViewSize',
	OPEN_MODAL: 'openModal',
	CLOSE_MODAL: 'closeModal',
	SET_SWIPE: 'setSwipe',
	OPEN_IN_APP_PURCHASE: 'openInAppPurchase',
	OPEN_PG: 'openPG',
	OPEN_NATIVE_PAGE: 'openNativePage',
};

export const APP_NATIVE_PAGE = {
	READ_CASE: 'readCase',
	VIEW_REQUEST: 'viewRequest',
	MENTOR_KEYWORD: 'mentorKeyword',
};

const appCommandMixin = {
	mixins: [layoutMixin],
	computed: {
		$_app_canOpenModal() {
			return this.IS_APP && compareVersion('1.2.3', this.APP_VERSION);
		},
		$_app_canShowFAB() {
			return this.IS_APP && compareVersion('1.2.6', this.APP_VERSION);
		},
		$_app_canShowToast() {
			return this.IS_APP && compareVersion('1.2.9', this.APP_VERSION);
		},
		$_app_canOpenInAppPurchase() {
			return this.IS_APP && compareVersion('1.5.0', this.APP_VERSION);
		},
		$_app_canOpenNativePage() {
			return this.IS_APP && compareVersion('1.6.0', this.APP_VERSION);
		},
		$_app_canOpenTossPG() {
			return this.IS_APP && compareVersion('1.10.0', this.APP_VERSION);
		},
		$_app_canOpenViewRequestPage() {
			return this.IS_APP && compareVersion('1.9.0', this.APP_VERSION);
		},
		$_app_canOpenMentorKeywordPage() {
			return this.IS_APP && compareVersion('1.9.1', this.APP_VERSION);
		},
	},
	methods: {
		/**
		 * 앱에서 브라우저 열기
		 * @param {string} url: 링크
		 * @param {Object} options: 옵션
		 * @param {boolean} options.isExternalForced: 코멘토 url이어도 외부브라우저로 띄울 것인지 플래그
		 * @return {boolean} 성공 여부
		 */
		$_app_openExternalBrowser(url, options = { isExternalForced: false }) {
			try {
				if (!validateAppUrl(url)) {
					throw new Error(`Invalid URL: ${url} is not valid in $_app_openExternalBrowser`);
				}

				if (options && typeof options !== 'object') {
					throw new Error(
						`Invalid options parameter: ${options} is not an object in $_app_openExternalBrowser`,
					);
				}
				commandApp(APP_COMMAND.OPEN_EXTERNAL_BROWSER, { url, options });
			} catch (error) {
				console.error(error);
			}
		},
		/**
		 * 앱에 meta set
		 * @param {Object} meta
		 * @param {string} meta.path
		 * @param {boolean} meta.isDetail
		 * @param {array} meta.bgColor
		 * @param {string} meta.title
		 * @param {string} meta.backwardButtonMarginBottom
		 * @param {boolean} meta.isLogoTopBar
		 * @param {boolean} meta.isShowSubHeader
		 * @param {boolean} meta.isShowFooter
		 * @param {boolean} meta.isTransparentType
		 * @param {boolean} meta.isShowHeader
		 * @return {boolean} 성공 여부
		 */
		$_app_setMeta(meta) {
			return commandApp(APP_COMMAND.SET_META, { meta });
		},
		/**
		 * 앱 native ui 요소 set
		 * @param {Object} uiMap
		 * @param {boolean?} uiMap.all: 모든 ui 요소 출력 여부, 값이 없으면 아래 값들을 참조합니다.
		 * @param {boolean?} uiMap.bottomNavigationBar: 바텀 네비게이션바 출력 여부
		 * @param {boolean?} uiMap.header: 헤더 출력 여부
		 * @param {boolean?} uiMap.subHeader: 서브 헤더 출력 여부
		 * @return {boolean} 성공 여부
		 */
		$_app_setUI(uiMap) {
			return commandApp(APP_COMMAND.SET_UI, { uiMap });
		},
		/**
		 * 앱의 webview 사이즈 조절, 값이 없는 것은 최대 크기로 설정
		 * @param {Object} size
		 * @param {number?} size.width
		 * @param {number?} size.height
		 * @return {boolean} 성공 여부
		 */
		$_app_setWebViewSize(size) {
			return commandApp(APP_COMMAND.SET_WEB_VIEW_SIZE, { size });
		},
		/**
		 * 앱에서 modal 열기
		 * @param {Object} options
		 * @param {string} options.component: modal 안에 출력할 component
		 * @param {boolean?} options.isDismissible: 다른 곳을 클릭해서 모달 닫기 가능 여부
		 * @param {Object?} options.args: component에 전달되는 값들, props에서 args로 받음
		 * @return {boolean} 성공 여부
		 */
		$_app_openModal(options) {
			return commandApp(APP_COMMAND.OPEN_MODAL, { options });
		},
		/**
		 * 앱에서 modal 닫기
		 * @param {Object} options
		 * @param {boolean?} options.isReloadRequired: 모달을 닫고 reload 여부
		 * @return {boolean} 성공 여부
		 */
		$_app_closeModal(options) {
			return commandApp(APP_COMMAND.CLOSE_MODAL, { options });
		},
		/**
		 * 앱에서 toast show
		 * @param {Object} options
		 * @param {string} options.message: 메시지 내용
		 * @param {string?} options.type: 색상(basic, error, success)
		 * @param {string?} options.position: 위치(top, bottom)
		 * @param {number?} options.timeout: 노출 시간
		 * @param {string?} options.icon: 아이콘 이름
		 * @return {boolean} 성공 여부
		 */
		$_app_showToast(options) {
			return commandApp(APP_COMMAND.SHOW_TOAST, { options });
		},
		/**
		 * 앱 native swipe 중지 (웹 swipe가 될 수 있도록 사용)
		 * @param {boolean} isEnabled
		 * @return {boolean} 성공 여부
		 */
		$_app_setSwipe(isEnabled) {
			return commandApp(APP_COMMAND.SET_SWIPE, { isEnabled });
		},
		/**
		 * 앱에서 인앱 구매 창 열기
		 * @param {Object} options
		 * @param {string} options.productId: 앱 스토어에 등록된 상품 id
		 * @param {string} options.orderNo: 코멘토에서 사용할 mid
		 * @return {boolean} 성공 여부
		 */
		$_app_openInAppPurchase(options) {
			return commandApp(APP_COMMAND.OPEN_IN_APP_PURCHASE, { options });
		},
		/**
		 * 앱에서 pg 구매 창 열기
		 * @param {Object} options
		 * @param {Object} options.paymentInfo: iamport 결제 요청 데이터
		 * @return {boolean} 성공 여부
		 */
		$_app_openPG(options) {
			return commandApp(APP_COMMAND.OPEN_PG, { options });
		},
		/**
		 * 앱에서 네이티브 페이지 열기
		 * @param {Object} options
		 * @param {string & APP_NATIVE_PAGE} options.page: 페이지 타입
		 * @param {string} options.path: fullPath
		 * @param {?Object} options.args: 페이지에 전달할 data, 각 타입마다 다름
		 * @return {boolean} 성공 여부
		 */
		$_app_openNativePage(options) {
			return commandApp(APP_COMMAND.OPEN_NATIVE_PAGE, { options });
		},
	},
};
/**
 * app의 webview에서 appCommand라는 javascriptChannel을 열어놓고,
 * 프론트에서 global로 접근할 수 있다. app의 webview가 아니라면 error가 발생
 */
export function commandApp(command, options) {
	try {
		if (process.server) {
			return false;
		}
		const message = {
			command,
			options,
		};
		appCommand.postMessage(JSON.stringify(message));
		return true;
	} catch (error) {
		// info인 이유는 appCommand를 받으려면 app에서 처리가 되어야 하는데,
		// 처리되지 않은 버전의 앱을 계속 사용하고 있는 유저들에겐 업데이트 하지 않으면 계속 나올 메시지이기 때문입니다.
		// 그래서 에러 로깅의 의미가 별로 없을 거 같다는 판단입니다.
		if (!isProduction) {
			console.info(`appCommand not exist: ${command}`);
		}
		return false;
	}
}

export default appCommandMixin;
