
import { baseUrl, NAVER, KAKAO, GOOGLE, FACEBOOK, EMAIL } from '@/plugins/constants';

import { moveToLaravelPage } from '@/utils/utils';

import RegisterButtons from '@/components/common/atoms/RegisterButtons.vue';
import EmailLogin from '@/components/common/molecules/login/EmailLogin.vue';
import SocialLogin from '@/components/common/molecules/login/SocialLogin.vue';
import CustomNuxtLink from '@/components/CustomNuxtLink.vue';

import loginMixin from '@/mixins/auth/loginMixin';
import gtmTriggerMixin from '@/mixins/gtmTriggerMixin';

export default {
	name: 'CommonLogin',
	mixins: [gtmTriggerMixin, loginMixin],
	props: {
		redirectUrl: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			baseUrl,
			isEmailLogin: false,
			showSocialLoginButtons: false,
			recentLoginType: null,
			loginTypes: null,
			isLoaded: false,
		};
	},
	async created() {
		await this.handleSetLoginTypes();
	},
	methods: {
		hideEmailLogin() {
			this.isEmailLogin = false;
		},
		async handleSetLoginTypes() {
			this.recentLoginType = this.$_getLoginSocialTypeInCookie();
			await this.setLoginTypes();
			this.isLoaded = true;
		},
		setLoginTypes() {
			let loginTypes = [KAKAO, NAVER, GOOGLE, FACEBOOK, EMAIL];
			if (this.recentLoginType) {
				loginTypes = this.$_moveIndex(
					loginTypes,
					loginTypes.findIndex(type => type === this.recentLoginType),
					0,
				);
			}
			this.loginTypes = loginTypes;
		},
		handlePrimaryLogin(socialType) {
			this.setGtm(socialType);
			const path = `${socialType.substring(0, 1).toLowerCase()}${socialType.substring(1)}`;
			if (path === EMAIL) {
				this.showSocialLoginButtons = false;
				this.isEmailLogin = true;
			} else {
				this.loginWithSocial(path);
			}
		},
		setGtm(signupType) {
			this.$_storeLoginSocialTypeInCookie(signupType);
			// GTM - registerButtonClick
			this.$_registerButtonClick({
				signup_type: signupType,
				where: 'Register',
				local_storage_id: this.comentoLocalStorageId,
			});
		},
		loginWithSocial(path) {
			if (this.redirectUrl) {
				return moveToLaravelPage(`/login/${path}?redirect_url=${this.redirectUrl}`);
			}
			moveToLaravelPage(`/login/${path}`);
		},
		handleStartWithOtherLogin() {
			this.showSocialLoginButtons = true;
			this.isEmailLogin = false;
		},
	},
	components: {
		SocialLogin,
		EmailLogin,
		RegisterButtons,
		CustomNuxtLink,
	},
};
