import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'vod/kdc';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export { mapGetters as mapGettersOfVodKdc, mapMutations as mapMutationsOfVodKdc, mapActions as mapActionsOfVodKdc };

export const IS_KDC_USER = 'IS_KDC_USER';
export const IS_KDC_LECTURE = 'IS_KDC_LECTURE';
export const KDC_USER = 'KDC_USER';
export const IS_KDC_WAITING_FOR_OPEN = 'IS_KDC_WAITING_FOR_OPEN';
export const KDC_SCHEDULES = 'KDC_SCHEDULES';
export const CURRENT_SCHEDULE = 'CURRENT_SCHEDULE';

export const SET_KDC_USER = 'SET_KDC_USER';
export const SET_IS_KDC_LECTURE = 'SET_IS_KDC_LECTURE';
export const SET_IS_KDC_WAITING_FOR_OPEN = 'SET_IS_KDC_WAITING_FOR_OPEN';
export const SET_KDC_SCHEDULES = 'SET_KDC_SCHEDULES';
export const SET_CURRENT_SCHEDULE_ID = 'SET_CURRENT_SCHEDULE_ID';

export const FETCH_KDC_INFO = 'FETCH_KDC_INFO';
export const CHANGE_SCHEDULE = 'CHANGE_SCHEDULE';

export const state = () => ({
	user: null,
	isKdcLecture: false,
	isKdcWaitingForOpen: false,
	schedules: [],
	currentScheduleId: null,
});

export const getters = {
	[KDC_USER]: state => state.user,
	[IS_KDC_USER]: state => !!state.user?.user,
	[IS_KDC_LECTURE]: state => state.isKdcLecture,
	[IS_KDC_WAITING_FOR_OPEN]: state => state.isKdcWaitingForOpen,
	[KDC_SCHEDULES]: state => state.schedules,
	[CURRENT_SCHEDULE]: state => state.schedules.find(schedule => schedule.classId === state.currentScheduleId),
};

export const mutations = {
	[SET_KDC_USER]: (state, payload) => (state.user = payload),
	[SET_IS_KDC_LECTURE]: (state, payload) => (state.isKdcLecture = payload),
	[SET_IS_KDC_WAITING_FOR_OPEN]: (state, payload) => (state.isKdcWaitingForOpen = payload),
	[SET_KDC_SCHEDULES]: (state, payload) => (state.schedules = payload),
	[SET_CURRENT_SCHEDULE_ID]: (state, payload) => (state.currentScheduleId = payload),
};

export const actions = {
	async [FETCH_KDC_INFO]({ commit }, lectureId) {
		try {
			const [{ data: user }, { data: info }] = await Promise.all([
				this.$api.vodLecture.getUserInfo({ lectureId }),
				this.$api.vodLecture.fetchVodSimpleInfo({ lectureId }),
			]);

			commit(SET_KDC_USER, user.kdc);
			commit(SET_IS_KDC_LECTURE, info.type === 'kdc');
			commit(SET_IS_KDC_WAITING_FOR_OPEN, info.isSaleKdc === false);
		} catch (error) {
			console.error(error);
		}
	},
	[CHANGE_SCHEDULE]({ commit }, scheduleId) {
		commit(SET_CURRENT_SCHEDULE_ID, scheduleId);
	},
};
