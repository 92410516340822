
import loadingMixin from '@/mixins/loadingMixin';

export default {
	name: 'ImageUploadWrapper',
	mixins: [loadingMixin],
	props: {
		id: {
			type: String,
			default: 'imageUploadWrapper',
		},
		path: {
			type: String,
			default: '',
		},
		imageFilePrefix: {
			type: String,
			default: 'prefix',
		},
		isChangeProfile: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		async handleFilesUpload() {
			try {
				this.$emit('upload-start');
				const file = this.$refs[this.id].files[0];
				const {
					data: { file_trans_name, cdn_url },
				} = await this.uploadImageToPublicS3(file);

				if (this.isChangeProfile) {
					await this.handleUpdateProfileImage(file_trans_name);
				} else {
					this.$emit('upload-success', cdn_url);
				}
			} catch (error) {
				console.error(error);
			}
		},
		async uploadImageToPublicS3(file) {
			try {
				const formData = new FormData();
				formData.append('file', file);
				formData.append('folder', this.path);
				formData.append('prefix', this.imageFilePrefix);

				return await this.$api.file.uploadFile(formData);
			} catch (error) {
				Toast.show({
					message: '오류가 발생했습니다. 다시 시도해주세요.',
					type: 'error',
					position: this.isMobile ? 'bottom' : 'top',
				});
			}
		},
		async handleUpdateProfileImage(fileName) {
			try {
				const params = { profile_img: fileName };
				const {
					data: { profile_url },
				} = await this.$api.myPage.updateMyPageProfile(params);
				this.$emit('upload-success', profile_url);
			} catch (error) {
				Toast.show({
					message: '오류가 발생했습니다. 다시 시도해주세요.',
					type: 'error',
					position: this.isMobile ? 'bottom' : 'top',
				});
			}
		},
	},
};
