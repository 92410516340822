
import { isEmptyData } from '@/utils/utils';

export default {
	name: 'GlobalNoticeAlert',
	props: {
		globalNoticeInfo: {
			type: Object,
			default: () => {},
		},
	},
	computed: {
		hasUrl() {
			return !isEmptyData(this.globalNoticeInfo.url);
		},
	},
	methods: {
		isEmptyData,
		emitClose() {
			this.$emit('close');
		},
		openTargetPage() {
			if (!this.hasUrl) {
				return false;
			}

			window.open(this.globalNoticeInfo.url);
		},
	},
};
