import { render, staticRenderFns } from "./LoadingWithOverlay.vue?vue&type=template&id=80fa605c&scoped=true&"
import script from "./LoadingWithOverlay.vue?vue&type=script&lang=js&"
export * from "./LoadingWithOverlay.vue?vue&type=script&lang=js&"
import style0 from "./LoadingWithOverlay.vue?vue&type=style&index=0&id=80fa605c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80fa605c",
  null
  
)

export default component.exports