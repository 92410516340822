import isMatch from 'lodash/isMatch';

import { CASE_PATH } from '@/constants/paths';

import { checkOwnProperty } from '@/utils/utils';

export const redirectToCaseListConditionally = ({ route, redirect, isLoggedIn }) => {
	const isLanding = route.name === 'index';
	if (isLanding) {
		// 네이버 브랜드 검색 시 index로
		if (isNaverBrandSearch(route.query)) {
			// 로그인 되어 있으면 타임라인으로
			if (isLoggedIn) {
				return redirectToTimeLine(route, redirect);
			}
			// 로그인 되어 있지 않으면 그대로 인덱스로
			// 이렇게 하면 정책도 우회하고, 성과도 높일 수 있음
			return;
		}
		// comento.kr?index
		if (!checkOwnProperty(route.query, 'index')) {
			return redirectToTimeLine(route, redirect);
		}
	}
};

// http://localhost.comento.kr:8000/?utm_source=ppc-naver-search&utm_medium=ad_link&utm_campaign=NSN-COMENTO-Mentee-S1-BRAND&utm_term=main-text&utm_content=home-link
export const isNaverBrandSearch = query => {
	return isMatch(query, {
		utm_source: 'ppc-naver-search',
		utm_campaign: 'NSN-COMENTO-Mentee-S1-BRAND',
	});
};

export const redirectWithQueries = (path, route, redirect) => {
	return redirect({
		path,
		query: route.query,
		params: route.params,
	});
};

export const redirectToTimeLine = (route, redirect) => {
	return redirectWithQueries(CASE_PATH, route, redirect);
};
