import { isEmptyData } from '@/utils/utils';

const userInformationMixin = {
	props: {
		// simple, normal, full
		type: {
			type: String,
			default: 'normal',
		},
		nickname: {
			type: String,
			default: '',
		},
		isQuestioner: {
			type: Boolean,
			default: false,
		},
		isDormancy: {
			type: Boolean,
			default: false,
		},
		avatarUserNo: {
			type: Number,
			default: 0,
		},
		avatarUserType: {
			type: Number,
			default: 0,
		},
		userProfileImage: {
			type: String,
			default: '',
		},
		userList: {
			type: Object,
			default: null,
		},
		company: {
			type: String,
			default: '',
		},
	},
	computed: {
		defaultNickname() {
			return `${this.avatarUserType === 1 ? '멘티' : '멘토'}${this.avatarUserNo}`;
		},
		computedNickname() {
			return this.nickname || this.defaultNickname;
		},
		avatarNickname() {
			if (this.isDormancy) {
				return '휴면계정';
			}
			return this.computedNickname;
		},
		avatarSize() {
			const sizes = {
				full: 'medium',
				normal: 'small',
				simple: 'xsmall',
			};
			return sizes[this.type];
		},
		isMentorAndNotQuestioner() {
			return !this.isQuestioner && this.isMentor;
		},
		computedCompany() {
			return this.company || this.$_getUserListItem(this.userList, 'belong');
		},
		isMentor() {
			return this.avatarUserType === 2;
		},
	},
	methods: {
		$_getUserListItem(userList, property) {
			if (!userList) {
				return null;
			}
			return userList[property];
		},
		$_getUserProfileImg(userList) {
			if (isEmptyData(userList?.user_profile?.profile_img)) {
				return null;
			}
			return userList.user_profile.profile_img;
		},
	},
};

export default userInformationMixin;
