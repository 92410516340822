import { datadogLogs } from '@datadog/browser-logs';

import { isProduction } from '@/plugins/constants';

export default (context, inject) => {
	// 형식 안맞추면 에러내기
	function customLogger(message, messageContext, level) {
		const isServer = process.server;
		if (!level || typeof level !== 'string') {
			// eslint-disable-next-line no-throw-literal
			throw 'customLogger requires level parameter';
		} else if (!message || typeof message !== 'string') {
			// eslint-disable-next-line no-throw-literal
			throw 'customLogger requires message parameter';
		} else if (!messageContext || typeof messageContext !== 'object') {
			// eslint-disable-next-line no-throw-literal
			throw 'customLogger requires messageContext parameter';
		}
		if (isServer) {
			context.$logger.log({
				level,
				message,
				...messageContext,
			});
		} else if (isProduction) {
			datadogLogs.logger.log(message, messageContext, level);
		} else {
			console.group('datadogLogs');
			console.info('message : ', message);
			console.info('messageContext : ', messageContext);
			console.info('level : ', level);
			console.groupEnd();
		}
	}
	inject('customLogger', customLogger);
};
