const LOGIN_SOCIAL_TYPE_KEY = 'LOGIN_SOCIAL_TYPE_KEY';

const loginMixin = {
	methods: {
		$_getLoginSocialTypeInCookie() {
			return this.$cookies.get(LOGIN_SOCIAL_TYPE_KEY);
		},
		$_storeLoginSocialTypeInCookie(social) {
			this.$cookies.set(LOGIN_SOCIAL_TYPE_KEY, social.toLowerCase(), 30 * 24 * 3600, '/', '.comento.kr');
		},
	},
};

export default loginMixin;
