import { isSelectorValid } from '@/utils/utils';

export default function (to, _from, savedPosition) {
	let timeout = 50;
	if (savedPosition || (to.hash && isSelectorValid(to.hash))) {
		timeout = 250;
	}

	return new Promise(resolve => {
		setTimeout(() => {
			if (savedPosition) {
				return resolve(savedPosition);
			}

			if (to.hash && isSelectorValid(to.hash)) {
				return resolve({
					selector: to.hash,
					// 헤더 + 서브헤더 = 69 + 44px이므로 넉넉하게 120
					offset: { x: 0, y: 120 },
				});
			}

			return resolve({ x: 0, y: 0 });
		}, timeout);
	});
}
