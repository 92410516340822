export const KDC_PROGRESS_ICON_DEFAULT = {
	1: 0,
	2: 0,
	3: 0,
	4: 0,
};

export const KDC_PROGRESS_LIST_DEFAULT = {
	1: 0,
	2: 0,
	3: 0,
};

export const DEFAULT_KDC_USER_INFO = {
	name: '',
	mobile: '',
	id: '',
};

export const KDC_CARD_BRAND_NAME = {
	shinHan: 'shinHan',
	nongHyup: 'nongHyup',
};

export const KDC_CARD_TYPE = {
	debit: 'debit',
	credit: 'credit',
};

export const KDC_PROGRESS_DEFAULT = {
	icon: KDC_PROGRESS_ICON_DEFAULT,
	text: KDC_PROGRESS_LIST_DEFAULT,
};

export const HRD_NET_URL = 'https://www.hrd.go.kr/hrdp/ma/pmmao/indexNew.do';

export const KDC_DETAIL_CTA_TEXT = {
	PAID: '이미 구매한 클래스입니다',
	CLOSED: '모집 마감',
	WAITING: '오픈 시 알림 받기',
	OPEN: '선착순 무료 수강하기',
};
