import flatMapDeep from 'lodash/flatMapDeep';
import flatten from 'lodash/flatten';

import { CLASS_OPENING_GUIDE_CATEGORY_KEY } from '@/constants/class/pt/opening/guide';
import { CLASS_OPENING_STEPS } from '@/constants/class/pt/opening/opening-steps';

import { cdnImageBaseUrl } from '@/plugins/constants';

import {
	defaultAssignment,
	defaultCurricula,
	defaultProfile,
	defaultTheory,
} from '@/utils/class/pt/open/default-values';
import { getSessionStorage, isEmptyData, setSessionStorage } from '@/utils/utils';

import {
	GET_CLASS_OPENING_CLASS_INTRODUCTION,
	GET_CLASS_OPENING_CURRICULA_DATA,
	GET_CLASS_OPENING_DATA,
	GET_CLASS_OPENING_FILES,
	GET_CLASS_OPENING_NICKNAME_DATA,
	GET_CLASS_OPENING_PAIN_POINTS,
	GET_CLASS_OPENING_PROFILE_DATA,
	GET_CLASS_OPENING_RESULTS,
	GET_IS_NICKNAME_AVAILABLE,
} from '@/store/class/pt/open';

// 수정하면 백엔드도 수정해야 함
const separator = '<p>----</p>';

export const getGuideCategoryFromSessionStorage = () => {
	return getSessionStorage(CLASS_OPENING_GUIDE_CATEGORY_KEY);
};

export const setGuideCategoryInSessionStorage = category => {
	return setSessionStorage(CLASS_OPENING_GUIDE_CATEGORY_KEY, category);
};

export const getGuideImageSrc = (page, category, index) => {
	return `${cdnImageBaseUrl}/class/pt/open/class-opening-guide-${page}-example-${category}-${index}.png`;
};

export const getDefaultTheory = index => {
	return `<p>[0${index}] 이곳에 이론 주제를 작성해주세요.</p><p>-이론 주제에 대한 세부 내용을 작성해주세요.</p><p>-이론 주제에 대한 세부 내용을 작성해주세요.</p>`;
};

// 수정하면 어드민도 수정해야 함.
export const convertCurriculaIntoPayload = curricula => {
	const copiedCurricula = [...curricula];
	const titleRegexp = /\<p>(.*?)\[(([0-9]{2})|과제)\](.*?)<\/p>/g;
	const titleToken = /\[(([0-9]{2})|과제)\]/g;
	const homeworkRegexp = /\<p>(.*?)\[과제\](.*?)<\/p>/g;
	const homeworkTitleToken = /\[(과제)\]/g;

	return copiedCurricula.map((item, index) => {
		const { subject, curriculum, assignment } = item;
		const content = [
			...curriculum.map((theory, theoryIndex) => {
				if (theory === defaultTheory(theoryIndex + 1)) {
					return {
						title: '',
						content: '',
						homework: 'N',
					};
				}
				return {
					title: theory.match(titleRegexp) ? theory.match(titleRegexp)[0].replace(titleToken, '') : '',
					content: theory.replace(titleRegexp, ''),
					homework: 'N',
				};
			}),
		];
		// 마지막 주차는 과제가 없으므로
		if (assignment) {
			if (assignment === defaultAssignment) {
				content.push({
					title: '',
					content: '',
					homework: 'Y',
				});
			} else {
				content.push({
					title: assignment.match(homeworkRegexp)
						? assignment.match(homeworkRegexp)[0].replace(homeworkTitleToken, '')
						: '',
					content: assignment.replace(homeworkRegexp, ''),
					homework: 'Y',
				});
			}
		}

		return {
			week: index + 1,
			subject,
			content,
		};
	});
};

export const isArrayWithNull = data => {
	return data.every(item => item === null);
};

export const joinWithSeparator = data => {
	if (isEmptyData(data)) {
		return null;
	}
	return data.join(separator);
};

export const splitBySeparator = data => {
	if (isEmptyData(data)) {
		return null;
	}
	return data.split(separator);
};

export const convertStringArrayIntoPayload = array => {
	if (isArrayWithNull(array)) {
		return null;
	}
	return joinWithSeparator(array);
};

export const convertPayloadIntoStringArray = (array, defaultArray) => {
	if (array === null) {
		return defaultArray;
	}
	return splitBySeparator(array);
};

export const convertPayloadIntoProfile = profile => {
	if (!profile) {
		return defaultProfile;
	}
	return profile;
};

export const convertNicknameIntoPayload = (nickname, isNicknameAvailable) => {
	if (isNicknameAvailable) {
		return nickname;
	}
	return null;
};

export const convertProfileIntoPayload = profile => {
	if (profile === defaultProfile) {
		return null;
	}
	return profile;
};

const checkStepStatus = values => {
	// 채워졌는지 체크
	const checkValuesAreTheyFilled = values.map(value => value !== null && value !== '');
	const numberOfTrue = checkValuesAreTheyFilled.filter(value => value === true).length;
	return calculateStepStatus(numberOfTrue, values.length);
};

const calculateStepStatus = (numberOfTrue, valuesLength) => {
	if (numberOfTrue === 0) {
		return 'empty';
	}
	if (numberOfTrue === valuesLength) {
		return 'full';
	}
	return 'half';
};

const flattenObjectArray = objectArray => {
	return flatten(objectArray.map(item => flatMapDeep(item)));
};

// profile이 기본값이랑 같으면 빈 값으로 처리
const checkProfileCustomStepStatus = ({ profile }) => {
	return [profile === defaultProfile ? null : profile];
};

const checkProfileStepStatus = ({
	GET_CLASS_OPENING_FIELD,
	GET_CLASS_OPENING_ANNUAL,
	GET_CLASS_OPENING_NICKNAME_DATA,
	GET_IS_NICKNAME_AVAILABLE,
	GET_CLASS_OPENING_PROFILE_DATA,
}) => {
	const values = [
		GET_CLASS_OPENING_FIELD,
		GET_CLASS_OPENING_ANNUAL,
		GET_CLASS_OPENING_NICKNAME_DATA,
		GET_IS_NICKNAME_AVAILABLE,
		// 기본 values에 profile이 기본값이랑 같은지 판별
		...checkProfileCustomStepStatus({ profile: GET_CLASS_OPENING_PROFILE_DATA }),
	];
	return checkStepStatus(values);
};

const checkClassTargetStepStatus = ({
	GET_CLASS_OPENING_SKILL,
	GET_CLASS_OPENING_TARGET_JOB,
	GET_CLASS_OPENING_LEVEL,
	GET_CLASS_OPENING_PAIN_POINTS,
}) => {
	const values = [
		GET_CLASS_OPENING_SKILL,
		GET_CLASS_OPENING_TARGET_JOB,
		GET_CLASS_OPENING_LEVEL,
		...GET_CLASS_OPENING_PAIN_POINTS,
	];
	return checkStepStatus(values);
};

const checkClassObjectiveStepStatus = ({
	GET_CLASS_OPENING_TITLE,
	GET_CLASS_OPENING_DIFFICULTY,
	GET_CLASS_OPENING_RESULTS,
	GET_CLASS_OPENING_MENTOR_INTRODUCTION,
}) => {
	const values = [
		GET_CLASS_OPENING_TITLE,
		GET_CLASS_OPENING_DIFFICULTY,
		...GET_CLASS_OPENING_RESULTS,
		GET_CLASS_OPENING_MENTOR_INTRODUCTION,
	];
	return checkStepStatus(values);
};

// 커리큘럼 인풋값들이 기본값과 일치하는지 체크
// 기본값이면 비어있는 것으로 처리
const checkCurriculumCustomStepStatus = ({ curricula, week }) => {
	const defaultCurriculaData = flattenObjectArray(defaultCurricula(week));
	const currentCurriculaData = flattenObjectArray(curricula);
	const dataLength = currentCurriculaData.length;
	const result = [];
	try {
		for (let i = 0; i < dataLength; i++) {
			if (defaultCurriculaData[i] === currentCurriculaData[i]) {
				result.push(null);
			} else {
				result.push(currentCurriculaData[i]);
			}
		}
		return result;
	} catch (error) {
		console.error(error);
		return currentCurriculaData;
	}
};

const checkCurriculumStepStatus = ({ GET_CLASS_OPENING_WEEK_DATA, GET_CLASS_OPENING_CURRICULA_DATA }) => {
	const values = [
		...checkCurriculumCustomStepStatus({
			curricula: GET_CLASS_OPENING_CURRICULA_DATA,
			week: GET_CLASS_OPENING_WEEK_DATA,
		}),
	];
	return checkStepStatus(values);
};

const checkClassDescriptionStepStatus = ({ GET_CLASS_OPENING_CLASS_INTRODUCTION, GET_CLASS_OPENING_FAQS }) => {
	// faq를 서버에 저장하면 id값이 같이 넘어오는데 이는 validation 대상이 아니므로 제외시킴
	const values = [
		...GET_CLASS_OPENING_CLASS_INTRODUCTION,
		...flattenObjectArray(
			GET_CLASS_OPENING_FAQS.map(faq => {
				return [faq.question, faq.content];
			}),
		),
	];
	return checkStepStatus(values);
};

export const checkStepStatusResult = (getters, steps) => {
	const resultMap = {
		[CLASS_OPENING_STEPS.PROFILE]: checkProfileStepStatus,
		[CLASS_OPENING_STEPS.CLASS_TARGET]: checkClassTargetStepStatus,
		[CLASS_OPENING_STEPS.CLASS_OBJECTIVE]: checkClassObjectiveStepStatus,
		[CLASS_OPENING_STEPS.CURRICULUM]: checkCurriculumStepStatus,
		[CLASS_OPENING_STEPS.CLASS_DESCRIPTION]: checkClassDescriptionStepStatus,
	};
	const result = {};
	steps.forEach(step => {
		result[step] = resultMap[step](getters);
	});
	return result;
};

export const convertClassOpeningDataIntoPayload = getters => {
	return {
		...getters[GET_CLASS_OPENING_DATA],
		profile: convertProfileIntoPayload(getters[GET_CLASS_OPENING_PROFILE_DATA]),
		nickname: convertNicknameIntoPayload(
			getters[GET_CLASS_OPENING_NICKNAME_DATA],
			getters[GET_IS_NICKNAME_AVAILABLE],
		),
		curricula: convertCurriculaIntoPayload([...getters[GET_CLASS_OPENING_CURRICULA_DATA]]),
		classIntroduction: convertStringArrayIntoPayload(getters[GET_CLASS_OPENING_CLASS_INTRODUCTION]),
		target: convertStringArrayIntoPayload(getters[GET_CLASS_OPENING_PAIN_POINTS]),
		goal: convertStringArrayIntoPayload(getters[GET_CLASS_OPENING_RESULTS]),
		// 파일 보낼때는 새로 추가된 것만 보낼 것.
		files: getters[GET_CLASS_OPENING_FILES].filter(fileInfo => fileInfo.id === undefined),
	};
};

export const convertTheory = (theory, index) => {
	if (!theory.title && !theory.content) {
		return defaultTheory(index + 1);
	}
	const title = theory.title ? theory.title.replace('<p>', `<p>[0${index + 1}]`) : '';
	const content = theory.content || '';
	return title + content;
};

export const convertAssignment = assignment => {
	if (!assignment.title && !assignment.content) {
		return defaultAssignment;
	}
	return `${assignment.title ? assignment.title : ''}${assignment.content ? assignment.content : ''}`.replace(
		'<p>',
		`<p>[과제]`,
	);
};
