import dayjs from 'dayjs';
import 'dayjs/locale/ko';
// eslint-disable-next-line import/no-named-as-default-member
dayjs.locale('ko');

export const sideBoxDateTemplate = 'M월 D일(ddd) HH:mm';
export const monthAndDateTemplate = 'M월 D일';
export const dayOfTheWeekTemplate = '(dd)';
export const YMDWithDots = 'YYYY.MM.DD';
export const ShortYMDWithDots = 'YY.MM.DD';
export const FullDateKoreaTemplate = 'YYYY년 MM월 DD일';

export const YYMMDDHMDateTemplate = 'YY.MM.DD(dd) HH:mm';
export const YMDHMS = 'YYYY-MM-DD HH:mm:ss';

export const onlyHHmm = 'HH:mm';

export const formatDate = (date, template) => (dayjs(date).isValid() ? dayjs(date).format(template) : date);
