const EDU_ENCORE_PREFIX = '/api/edu/encore';

export default axios => ({
	/**
	 * 앵콜 요청서 정보 가져오기
	 * @param params
	 * @returns {*}
	 */
	getEncoreInfo(params) {
		return axios.get(`${EDU_ENCORE_PREFIX}/info`, { params });
	},
	/**
	 * 앵콜 요청 수락
	 * @param data
	 * @returns {*}
	 */
	createEduEncore(data) {
		return axios.post(`${EDU_ENCORE_PREFIX}/create`, data);
	},
	/**
	 * 다른 날짜 개설 제안 제출
	 * @param data
	 * @returns {*}
	 */
	submitEncoreSuggest(data) {
		return axios.post(`${EDU_ENCORE_PREFIX}/suggest`, data);
	},
});
