const KDC_PREFIX = '/api/kdc';
const USER_STEP_PREFIX = '/user/step';

export default axios => ({
	/**
	 * 진행중인 KDC 클래스 유무 체크
	 */
	checkProgressingKdcExist() {
		return axios.get(`${KDC_PREFIX}/check/user/step`);
	},
	/**
	 * KDC 신청 정보 제출
	 * @params haveCard : 카드 보유 여부
	 * @params cardType : 보유 카드 종류
	 * @params name: 신청 유저 이름
	 * @params email : 신청 유저 email
	 * @params mobile : 신청 유저 전화번호
	 * @params lectureId : 신청 강의 ID
	 */
	postApplyUserInfo(data) {
		return axios.post(`${KDC_PREFIX}/apply`, data);
	},
	/**
	 * KDC FLOW 각 항목별 상태 가져오기
	 */
	getKdcProgressState() {
		return axios.get(`${KDC_PREFIX}${USER_STEP_PREFIX}`);
	},
	/**
	 * KDC 정보 입력하기 (Guide Button Click)
	 * @params step : 현재 Flow의 step
	 * @params haveCard : 내일배움카드 보유 여부
	 * @params cardType : 내일배움카드 종류
	 */
	postKdcStepComplete(data) {
		return axios.post(`${KDC_PREFIX}${USER_STEP_PREFIX}/complete`, data);
	},
	/**
	 * KDC 수료증 다운로드
	 * @params id : download를 위한 유저별 번호
	 */
	downloadKdcCertification(id) {
		return axios.get(`${KDC_PREFIX}/certificate/download/${id}`, { responseType: 'blob' });
	},
	/**
	 * KDC Hrd-net 링크 가져오기
	 * @params {number} lectureId : 강의 Id
	 */
	getKdcHrdNedLink(data) {
		return axios.post(`${KDC_PREFIX}/apply/success`, data);
	},
	/**
	 * KDC 강의 목록 가져오기
	 */
	getClassList() {
		return axios.get(`${KDC_PREFIX}/list`);
	},
});
