import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { isProduction } from './constants';

if (isProduction) {
	// datadog RUM
	datadogRum.init({
		applicationId: '93a33540-8b1f-4981-bd1a-a5a516279aeb',
		clientToken: 'pub44aa64a4ae847bbaff27b73374114ed6',
		service: 'comento-nuxt',
		env: process.env.NODE_ENV,
		version: '1.0.0',
		sessionSampleRate: 10,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		beforeSend: event => {
			if (shouldDiscardLog(event)) {
				return false;
			}
		},
	});
	// datadog Logs
	datadogLogs.init({
		clientToken: 'pubd5e450c5c759938eefb0b2e2529a9d06',
		site: 'datadoghq.com',
		service: 'comento-nuxt',
		env: process.env.NODE_ENV,
		version: '1.0.0',
		forwardErrorsToLogs: true,
		sampleRate: 100,
		beforeSend: (event, context) => {
			if (shouldDiscardLog(event, context)) {
				return false;
			}
		},
	});
}

function shouldDiscardLog(event, context) {
	const fromChromeExtension = event.error?.stack?.includes('chrome-extension://');
	const isNotProduction = !isProduction;
	const isAbortedXHRError = context?.isAborted || event.http?.status_code === 0;
	if (fromChromeExtension || isNotProduction || isAbortedXHRError) {
		return true;
	}
	return false;
}
