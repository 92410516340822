import { CASE_PATH } from '@/constants/paths';

const CASE_PREFIX_URL = `/api/case`;
const CASE_DETAIL_PREFIX_URL = `${CASE_PREFIX_URL}/detail`;
const READ_CASE_PREFIX_URL = `/api/readCase`;
const JOB_QUESTIONS_PREFIX_URL = `/api${CASE_PATH}`;

export default axios => ({
	// 서브 헤더에 들어가는 리스트
	getCaseSubHeader() {
		return axios.get(`${CASE_PREFIX_URL}/header`);
	},
	/**
	 * 상담사례 최신순
	 * @apiParam {Integer} page 페이지 번호
	 * @apiParam {Integer} limit 페이지당 아이템 개수
	 * @apiParam {String} keyword 검색어
	 * @apiParam {Integer} category_group_id 서브 헤더 카테고리 그룹 고유번호
	 * @apiParam {Integer} category_id 카테고리 고유번호 [nullable]
	 */
	getCaseList(params) {
		return axios.get(`${CASE_PREFIX_URL}/list`, { params, cache: true });
	},
	/**
	 * 상담사례 추천순
	 * @apiParam {Integer} page 페이지 번호
	 * @apiParam {Integer} limit 페이지당 아이템 개수
	 * @apiParam {Integer} category_group_id 서브 헤더 카테고리 그룹 고유번호
	 */
	getCaseRelationList(params) {
		return axios.get(`${CASE_PREFIX_URL}/relationList`, { params });
	},
	/**
	 * 질문유형
	 * @apiParam {Integer} category_group_id 서브 헤더 카테고리 그룹 고유번호
	 */
	getCaseCategories(params) {
		return axios.get(`${CASE_PREFIX_URL}/categories`, { params });
	},
	/**
	 * 오늘의 추천 사례
	 * @apiParam {{Integer}} page 페이지 번호
	 * @apiParam {{Integer}} per_page 페이지당 개수
	 */
	getCaseTodayRecommend(params) {
		return axios.get(`${CASE_PREFIX_URL}/todayRecommend`, { params });
	},
	/**
	 * 제휴대학인지
	 * @apiParam {{String}} univ 학교 이름
	 */
	checkPartnerApplyAble(params) {
		return axios.get(`${JOB_QUESTIONS_PREFIX_URL}/checkPartnerApplyAble`, { params });
	},
	/**
	 * 멘토링 제휴 대학인지
	 * @apiParam {{String}} univ 학교 이름
	 */
	checkMentoringPartnerApplyAble(params) {
		return axios.get(`${JOB_QUESTIONS_PREFIX_URL}/checkMentoringPartnerApplyAble`, { params });
	},
	/**
	 * readCase meta_description 가져오기
	 * @param data
	 * @param data.request_no: 요청글번호
	 */
	getCaseDetailMetaDescription(data) {
		return axios.post(`${READ_CASE_PREFIX_URL}/getMetaDescription`, data);
	},
	/**
	 * readCase 질문 가져오기
	 * @apiParam {Integer} request_no 요청 번호
	 */
	getCaseDetailQuestion(params) {
		return axios.get(`${CASE_DETAIL_PREFIX_URL}/question`, { params });
	},
	/**
	 * readCase 답변 가져오기
	 * @apiParam {Integer} request_no 요청 번호
	 */
	getCaseDetailAnswer(data) {
		return axios.post(`${CASE_DETAIL_PREFIX_URL}/answer`, data);
	},
	/**
	 * readCase 답변 가져오기 페이지네이션
	 * @param data
	 * @param data.request_no: 요청 번호
	 * @param data.page
	 * @param data.per_page
	 * @param data.is_bot
	 * @param data.mentoring: getBuyMentoring 리턴값
	 * @returns {*}
	 */
	getCaseDetailAnswerPagination(data) {
		return axios.post(`api/v2/case/detail/answer`, data);
	},
	/**
	 * 멘토링 열람권 있는지 확인
	 * @typedef {Object} ItemInfo
	 * @property {number} no
	 * @property {string} tier
	 * @property {number} period
	 * @property {string} status
	 *
	 * @returns {Promise<{buy_mentoring: boolean, itemInfo: ItemInfo}>}
	 */
	getBuyMentoring() {
		return axios.get(`/api/check/buyMentoring`);
	},
	/**
	 * 신규 멘토 튜토리얼 대상자인지 체크
	 * 신규 멘토인지 체크 (최초 인증이 일주일 이내) && 튜토리얼 완료 여부가 없는지
	 * 대상자 { msg: 'not complete', code: 204 }
	 * 대상자가 아님 { msg: 'success', code: 200 }
	 * @returns {Promise<{msg: string, code: number}>}
	 */
	checkNewChangeUser() {
		return axios.get(`/api/check/newChangeUser`);
	},
	/**
	 * 신규 멘토 튜토리얼 완료 처리
	 * @param {Object=} data
	 * @param data.type: 1: 튜토리얼 완료, 2: 튜토리얼 스킵
	 *
	 * @returns {Promise<{msg: string, code: number}>}
	 */
	completeMentorTutorial(data) {
		return axios.post(`/api/tutorial/setRemark`, data);
	},
	// 인증 멘토 무료 열람권 받기
	checkCaseDetailMentorFree() {
		return axios.post(`${CASE_DETAIL_PREFIX_URL}/mentorFree`);
	},
	/**
	 * 질문 좋아요
	 * @apiParam {Integer} request_no: 질문 번호
	 */
	postCaseDetailQuestionLike(data) {
		return axios.post(`${CASE_DETAIL_PREFIX_URL}/questionLike`, data);
	},
	/**
	 * 답변 좋아요
	 * @apiParam {Integer} feedback_no: 답변 번호
	 */
	postCaseDetailAnswerLike(data) {
		return axios.post(`${CASE_DETAIL_PREFIX_URL}/answerLike`, data);
	},
	/**
	 * 댓글 좋아요
	 * @apiParam {Integer} feedback_no: 댓글 번호
	 */
	postCaseDetailCommentLike(data) {
		return this.postCaseDetailAnswerLike(data);
	},
	/**
	 * 추가 답변 달기
	 * @apiParam {Integer} request_no 요청 번호
	 * @apiParam {String} comment 추가 답변
	 */
	postCaseAdditionalComment(data) {
		return axios.post(`${CASE_PREFIX_URL}/additionalComment`, data);
	},
	/**
	 * 댓글 달기
	 * @apiParam {Integer} request_no 요청 번호
	 * @apiParam {Integer} feedback_no 답변 번호
	 * @apiParam {String} comment 댓글 내용
	 */
	postCaseCreateComment(data) {
		return axios.post(`${CASE_PREFIX_URL}/additionalReply`, { ...data, target_no: null });
	},
	/**
	 * 대댓글 달기
	 * @apiParam {Integer} parent_no 댓글 번호
	 * @apiParam {Integer} request_no 요청 번호
	 * @apiParam {Integer} feedback_no 답변 번호
	 * @apiParam {String} comment 댓글 내용
	 */
	postCaseCreateReply(data) {
		return axios.post(`${CASE_PREFIX_URL}/additionalReply`, data);
	},
	/**
	 * readCase gtm 정보 가져오기
	 * @apiParam {Number} request_no 질문 고유 번호
	 * @apiParam {String} search_keyword 검색 키워드 nullable
	 * @apiParam {Number} search_count 검색 된 갯수 nullable
	 */
	getReadCase(data) {
		return axios.post(`${CASE_DETAIL_PREFIX_URL}/readCase`, data);
	},
	/**
	 * 질문 신고하기
	 * @apiParam {Number} question_no 신고 대상 답변 고유번호
	 * @apiParam {String} report_reason 신고 사유
	 */
	postReportQuestion(data) {
		return axios.post(`${CASE_DETAIL_PREFIX_URL}/questionReport`, data);
	},
	/**
	 * 답변 신고하기
	 * @apiParam {Number} feedback_no 신고 대상 답변 고유번호
	 * @apiParam {String} report_reason 신고 사유
	 */
	postReportAnswer(data) {
		return axios.post(`${CASE_DETAIL_PREFIX_URL}/answerReport`, data);
	},
	/**
	 * 댓글 신고하기
	 * @apiParam {Number} feedback_no 신고 대상 댓글 고유번호
	 * @apiParam {String} report_reason 신고 사유
	 */
	postReportComment(data) {
		return this.postReportAnswer(data);
	},
	/**
	 * 대댓글 신고하기
	 * @apiParam {Number} feedback_no 신고 대상 대댓글 고유번호
	 * @apiParam {String} report_reason 신고 사유
	 */
	postReportReply(data) {
		return this.postReportAnswer(data);
	},
	/**
	 * 추천 사례
	 * @apiParam {Number} request_no 요청 번호
	 */
	getRelationList(params) {
		return axios.get(`${CASE_DETAIL_PREFIX_URL}/relationCase`, { params });
	},
	checkAffiliate() {
		return axios.get(`${JOB_QUESTIONS_PREFIX_URL}/checkAffiliate`);
	},
	/**
	 * 첫 질문 보상 키워드 캐싱
	 * @apiParam {Number} request_no 요청 번호
	 */
	postFirstKeyword(data) {
		return axios.post(`${CASE_PREFIX_URL}/firstKeyword`, data);
	},
	/**
	 * 답변 채택하기
	 * @param data
	 * @param data.feedback_no: 답변 번호
	 * @param data.type: 멘토링 구분(qa, in, ce 등)
	 * @param data.request_no: 요청글 번호,
	 * @param data.length: 답변 글자수
	 */
	postAdopt(data) {
		return axios.post(`/api/request/adopt`, data);
	},
	/**
	 * 북마크 개수 가져오기
	 * @returns { cnt: Number }
	 */
	getCommunityBookmarkCount() {
		return axios.get(`${JOB_QUESTIONS_PREFIX_URL}/getBookMarkCnt`);
	},
	/**
	 * 북마크 리스트 가져오기
	 * @param params
	 * @param params.page
	 * @returns {*}
	 */
	getCommunityBookmarkListWithPagination(params) {
		return axios.get(`${JOB_QUESTIONS_PREFIX_URL}/getBookMarkList`, { params });
	},
	/**
	 * 북마크된 글인지 판별하기
	 * @param data
	 * @returns { status: Number(0 | 1) }
	 */
	getIsBookmarked(data) {
		return axios.post(`${JOB_QUESTIONS_PREFIX_URL}/isBookMarked`, data);
	},
	/**
	 * 북마크 추가/삭제
	 * @param data
	 * @param data.feed_type: case, mentoring, bookmarkAnswer...
	 * @param data.request_no
	 * @returns {*}
	 */
	toggleCommunityBookmark(data) {
		return axios.post(`${JOB_QUESTIONS_PREFIX_URL}/addBookMark`, data);
	},
	/**
	 * 답변 북마크 리스트 가져오기
	 * @param params
	 * @param params.page
	 * @returns {*}
	 */
	getCommunityBookmarkAnswerListWithPagination(params) {
		return axios.get(`${JOB_QUESTIONS_PREFIX_URL}/getBookMarkAnswerList`, { params });
	},
	/**
	 * 답변 북마크 개수 가져오기
	 * @returns { cnt: Number }
	 */
	getCommunityBookmarkAnswerCount() {
		return axios.get(`${JOB_QUESTIONS_PREFIX_URL}/getBookMarkAnswerCnt`);
	},
	/**
	 * 북마크된 글인지 판별하기
	 * @param content_no : 컨텐츠 번호
	 * @param type: 컨텐츠 타입('bookmarkAnswer', 'comment', 'reply)
	 * @returns { status: Number(0 | 1) }
	 */
	getIsAnswerBookmarked(data) {
		return axios.post(`${JOB_QUESTIONS_PREFIX_URL}/isAnswerBookmarked`, data);
	},
	/**
	 * 답변 북마크 전체 리스트 가져오기
	 * @param params
	 * @param params.page
	 * @returns {*}
	 */
	getBookMarkTotalList(params) {
		return axios.get(`${JOB_QUESTIONS_PREFIX_URL}/getBookMarkTotalList`, { params });
	},
	getBookmarkList(params) {
		return axios.get(`/api/bookmark/list`, {
			params,
		});
	},
	/**
	 * 답변 북마크 전체 개수 가져오기
	 * @returns { cnt: Number }
	 */
	getBookMarkTotalCnt() {
		return axios.get(`/api/bookmark/total-count`);
	},
	/**
	 * @param data
	 * @param params.request_no
	 * @param params.userNo
	 */
	checkBookmark(data) {
		return axios.post(`${JOB_QUESTIONS_PREFIX_URL}/checkBookMark`, data);
	},
	/**
	 */
	getAuthorInfo() {
		return axios.get(`/api/profile/checkInfo`);
	},
	/**
	 * 유저가 휴대폰 번호를 가지고 있는지 체크
	 *
	 * 가지고 있지 않은 경우
	 * returns {code} 200
	 * returns {msg} 'ok'
	 *
	 * 가지고 있는 경우
	 * returns {code} 210
	 * returns {msg} 'already have mobile'
	 */
	checkUserHasMobile() {
		return axios.post('/api/request/checkMobile');
	},
	/**
	 * AI Bot 답변을 이미 평가했는지
	 * @param request_no: number
	 * @return cnt: number
	 * @return feedback_type: number // 1 or 0
	 */
	checkEvaluation(data) {
		return axios.post('/api/comment/checkReputation', data);
	},
	/**
	 * AI Bot 답변 추천 / 비추천
	 *
	 * @param request_no: number
	 * @param feedback_no: number
	 * @param feedback_type: number
	 * @param feedback_detail: string
	 */
	evaluateFeedback(data) {
		return axios.post('/api/comment/setReputation', data);
	},
	/**
	 * 멘토가 남긴 답변 & 질문 제목 리스트
	 *
	 * @param data
	 * @param data.user_no: number
	 * @param data.page: number
	 * @param data.per_page: number
	 *
	 * TODO: response type 추가
	 */
	getMentorAnswerList(data) {
		return axios.post('/api/check/mentorFeedback', data);
	},
	/**
	 * Z.Ai 모델 리스트 가져오기(readCase에서)
	 * @param {number} params.page
	 * @param {number} params.limit
	 * @param {number} params.requestNo
	 * @returns {*}
	 */
	getZaiRecommendationListAtReadCase(params) {
		return axios.get(`/api/pull/zai/recommendation/read-case`, { params });
	},
	postMentoringReviews(data) {
		return axios.post('/api/community/read-case/mentoring-review/create', data);
	},
});
