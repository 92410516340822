
import { ESSAY_TUTORIAL_PATH } from '@/constants/paths';

import { moveToLaravelPage } from '@/utils/utils';

import layoutMixin from '@/mixins/layoutMixin';
import routeMixin from '@/mixins/routeMixin';

export default {
	name: 'MyPageEssayInfoBoard',
	mixins: [layoutMixin, routeMixin],
	computed: {
		myPageEduLeadButtonContainer() {
			return this.IS_MOBILE ? [24, 16, 24, 16] : [0, 14, 12, 20];
		},
	},
	methods: {
		goEssayTutorial() {
			moveToLaravelPage(ESSAY_TUTORIAL_PATH);
		},
	},
};
