import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'user/bookmark';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfUserBookmark,
	mapMutations as mapMutationsOfUserBookmark,
	mapActions as mapActionsOfUserBookmark,
};

const isReloadBookmarkCount = 'isReloadBookmarkCount';

export const IS_RELOAD_BOOKMARK_COUNT = 'IS_RELOAD_BOOKMARK_COUNT';

export const SET_RELOAD_BOOKMARK_FLAG = 'SET_RELOAD_BOOKMARK_FLAG';

export const LOAD_BOOKMARK_COUNT = 'LOAD_BOOKMARK_COUNT';
export const RELOAD_BOOKMARK_COUNT = 'RELOAD_BOOKMARK_COUNT';

export const state = () => ({
	[isReloadBookmarkCount]: false,
});

export const getters = {
	[IS_RELOAD_BOOKMARK_COUNT]: state => state[isReloadBookmarkCount],
};

export const mutations = {
	[SET_RELOAD_BOOKMARK_FLAG]: (state, payload) => (state[isReloadBookmarkCount] = payload),
};

export const actions = {
	async [LOAD_BOOKMARK_COUNT]({ commit }) {
		try {
			commit(SET_RELOAD_BOOKMARK_FLAG, false);
			const { data } = await this.$api.user.getBookmarkCount();
			return data;
		} catch (error) {
			console.error('LOAD_BOOKMARK_COUNT error: ', error);
			return 0;
		}
	},
	[RELOAD_BOOKMARK_COUNT]({ commit }) {
		commit(SET_RELOAD_BOOKMARK_FLAG, true);
	},
};
