export const eduFilterTopCategoryOrderArray = [
	0, 5, 8, 4, 1, 6, 7, 3, 2, 9, 13, 11, 18, 16, 20, 10, 12, 14, 15, 19, 21,
];

export const updateProfileTopCategoryOrderArray = [
	0, 5, 8, 4, 1, 6, 7, 3, 2, 9, 21, 13, 18, 16, 20, 10, 12, 11, 15, 14, 19,
];

export const updateProfileCompanyGroupsPcOrderArray = [0, 1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 5, 12];

export const updateProfileCompanyGroupsMobileOrderArray = [0, 1, 2, 7, 8, 6, 3, 4, 5, 9, 10, 11, 12];
