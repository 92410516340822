
import '@/plugins/vue-awesome-swiper';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
	name: 'CustomSwiper',
	props: {
		options: {
			type: Object,
			default: () => {},
		},
	},
	components: {
		Swiper,
		SwiperSlide,
	},
};
