import { checkDoesNotHaveTrailingSlash } from '@/utils/trailing-slash';

export default function ({ route, redirect }) {
	const { path, params, query, hash } = route;
	if (checkDoesNotHaveTrailingSlash(path)) {
		return;
	}

	const nextPath = path.replace(/\/+$/, '') || '/';
	const nextRoute = { path: nextPath, params, query, hash };

	// 308 Permanent Redirect
	return redirect(308, nextRoute);
}
