export default () => {
	// if (process.env.NODE_ENV !== 'production') {
	// 	return;
	// }

	window.dataLayer = window.dataLayer || [];
	function gtag() {
		dataLayer.push(arguments);
	}
	gtag('js', new Date());
	gtag('config', process.env.GA_ID);
};
