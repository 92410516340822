import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'affiliate';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfAffiliateCoupon,
	mapMutations as mapMutationsOfAffiliateCoupon,
	mapActions as mapActionsOfAffiliateCoupon,
};

// State
export const AFFILIATE_COUPON_INFO = 'AFFILIATE_COUPON_INFO';

// Getters
export const GET_AFFILIATE_COUPON_INFO = 'GET_AFFILIATE_COUPON_INFO';

// Mutations
export const SET_AFFILIATE_COUPON_INFO = 'SET_AFFILIATE_COUPON_INFO';

export const state = () => ({
	[AFFILIATE_COUPON_INFO]: null,
});

export const getters = {
	[GET_AFFILIATE_COUPON_INFO]: state => state[AFFILIATE_COUPON_INFO],
};

export const mutations = {
	[SET_AFFILIATE_COUPON_INFO]: (state, payload) => {
		state[AFFILIATE_COUPON_INFO] = payload;
	},
};
