// 현직자 인증 모달
import { createNamespacedHelpers } from 'vuex';
const NAMESPACE = 'mentorAuth';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfMentorAuth,
	mapMutations as mapMutationsOfMentorAuth,
	mapActions as mapActionsOfMentorAuth,
};

// state
export const STEP = 'STEP';
export const COMPANY = 'COMPANY';
export const JOB = 'JOB';
export const EMAIL = 'EMAIL';
export const AUTH_TYPE = 'AUTH_TYPE';
export const FILES = 'FILES';
export const TMP_NO = 'TMP_NO';
export const EXPIRATION_TIME = 'EXPIRATION_TIME';
export const PIN_CODE = 'PIN_CODE';

// getters
export const GET_STEP = 'GET_STEP';
export const GET_COMPANY = 'GET_COMPANY';
export const GET_JOB = 'GET_JOB';
export const GET_EMAIL = 'GET_EMAIL';
export const GET_AUTH_TYPE = 'GET_AUTH_TYPE';
export const IS_AUTH_TYPE_EMAIL = 'IS_AUTH_TYPE_EMAIL';
export const IS_AUTH_TYPE_FILE = 'IS_AUTH_TYPE_FILE';
export const GET_FILES = 'GET_FILES';
export const GET_TMP_NO = 'GET_TMP_NO';
export const GET_EXPIRATION_TIME = 'GET_EXPIRATION_TIME';
export const GET_PIN_CODE = 'GET_PIN_CODE';

// mutations
export const SET_STEP = 'SET_STEP';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_JOB = 'SET_JOB';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_AUTH_TYPE = 'SET_AUTH_TYPE';
export const SET_FILES = 'SET_FILES';
export const SET_TMP_NO = 'SET_TMP_NO';
export const SET_EXPIRATION_TIME = 'SET_EXPIRATION_TIME';
export const SET_PIN_CODE = 'SET_PIN_CODE';

// actions
export const INIT_MENTOR_INFO = 'INIT_MENTOR_INFO';

export const MentorAuthStep = {
	ENTRY: 'entry',
	COMPANY: 'company',
	JOB: 'job',
	AUTH: 'auth',
	PINCODE: 'pincode',
	FINISH_EMAIL: 'finish-email',
	FINISH_FILE: 'finish-file',
};

export const state = () => ({
	[STEP]: MentorAuthStep.ENTRY, // 'entry' | 'company' | 'job' | 'auth' | 'pincode' | 'finish-email' | 'finish-file'
	[AUTH_TYPE]: 'email', // email | file
	[COMPANY]: '',
	[JOB]: '',
	[EMAIL]: '',
	[FILES]: [],
	[TMP_NO]: null,
	[EXPIRATION_TIME]: null,
	[PIN_CODE]: null, // 이메일 인증 핀 코드
});

export const getters = {
	[GET_STEP]: state => {
		return state[STEP];
	},
	[GET_AUTH_TYPE]: state => {
		return state[AUTH_TYPE];
	},
	[IS_AUTH_TYPE_EMAIL]: state => {
		return state[AUTH_TYPE] === 'email';
	},
	[IS_AUTH_TYPE_FILE]: state => {
		return state[AUTH_TYPE] === 'file';
	},
	[GET_COMPANY]: state => {
		return state[COMPANY];
	},
	[GET_JOB]: state => {
		return state[JOB];
	},
	[GET_EMAIL]: state => {
		return state[EMAIL];
	},
	[GET_FILES]: state => {
		return state[FILES];
	},
	[GET_TMP_NO]: state => {
		return state[TMP_NO];
	},
	[GET_EXPIRATION_TIME]: state => {
		return state[EXPIRATION_TIME];
	},
	[GET_PIN_CODE]: state => {
		return state[PIN_CODE];
	},
};

export const mutations = {
	[SET_STEP]: (state, payload) => {
		state[STEP] = payload;
	},
	[SET_AUTH_TYPE]: (state, payload) => {
		state[AUTH_TYPE] = payload;
	},
	[SET_COMPANY]: (state, payload) => {
		state[COMPANY] = payload;
	},
	[SET_JOB]: (state, payload) => {
		state[JOB] = payload;
	},
	[SET_EMAIL]: (state, payload) => {
		state[EMAIL] = payload;
	},
	[SET_FILES]: (state, payload) => {
		state[FILES] = payload;
	},
	[SET_TMP_NO]: (state, payload) => {
		state[TMP_NO] = payload;
	},
	[SET_EXPIRATION_TIME]: (state, payload) => {
		state[EXPIRATION_TIME] = payload;
	},
	[SET_PIN_CODE]: (state, payload) => {
		state[PIN_CODE] = payload;
	},
};

export const actions = {
	async [INIT_MENTOR_INFO]({ getters, commit, rootState }) {
		const { data } = await this.$api.myPage.fetchMentorAuthInfo();
		const { code, status, user } = data;

		if (code === 8023200) {
			// 인증 시도가 있을 때
			const { no, check_type, belong, dep, check_email, updated_at, step } = status;

			const userInfo = rootState.userInfo;
			// 이미 현재 직장으로 인증을 완료함
			const isAlreadyMentor = userInfo.belong === belong && userInfo.dep === dep;
			if (isAlreadyMentor) {
				if (getters[GET_STEP] === 1) {
					this.openAlertModalWithMessage(
						`[인증 오류] 변경하려는 정보가 현재와 동일합니다. 내용을 다시 확인해 주세요.`,
					);
					commit(SET_STEP, 0);
				}
				return false;
			}

			const { email } = user;
			commit(SET_EMAIL, email);
			commit(SET_TMP_NO, no);
			commit(SET_AUTH_TYPE, check_type === 1 ? 'file' : 'email');
			commit(SET_COMPANY, belong);
			commit(SET_JOB, dep);
			if (check_email) {
				commit(SET_EMAIL, check_email);
			}
			if (updated_at) {
				commit(SET_EXPIRATION_TIME, updated_at);
			}
			// 이메일 인증 완료일 때는 step 0부터 시작
			commit(SET_STEP, step === 3 ? getStep(0, check_type) : getStep(step, check_type));
		}
	},
};

/**
 * 백엔드의 step과 check_type을 기준으로 프론트에서 사용하는 step값을 반환
 * 기존 step 구조를 프론트에서 변경했기 때문에 백엔드의 step값과 맞추기 위해서 별도 함수로 분리
 * @param {number} stepNo
 * @param {number} check_type
 * @returns 현재 인증 단계
 */
function getStep(stepNo, check_type) {
	const authType = check_type === 0 ? 'email' : 'file';
	switch (stepNo) {
		case 0:
			return MentorAuthStep.ENTRY;
		case 1:
			return MentorAuthStep.AUTH;
		case 2:
			return authType === 'email' ? MentorAuthStep.PINCODE : MentorAuthStep.FINISH_FILE;
		case 3:
			return MentorAuthStep.FINISH_EMAIL;
		default:
			return '';
	}
}
