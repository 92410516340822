import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'vod/player/lecture/file';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfVodPlayerLectureFile,
	mapMutations as mapMutationsOfVodPlayerLectureFile,
	mapActions as mapActionsOfVodPlayerLectureFile,
};

// getters
export const LECTURE_FILES = 'LECTURE_FILES';
export const FILES_FETCH_PARAMS = 'FILES_FETCH_PARAMS';

// setters
export const SET_LECTURE_FILES = 'SET_LECTURE_FILES';
export const SET_FILES_FETCH_PARAMS = 'SET_FILES_FETCH_PARAMS';

// actions
export const LOAD_LECTURE_FILES = 'LOAD_LECTURE_FILES';

export const state = () => ({
	[LECTURE_FILES]: [],
	[FILES_FETCH_PARAMS]: {
		currentPage: 0,
		lastPage: Infinity,
	},
});

export const getters = {
	[LECTURE_FILES]: state => state[LECTURE_FILES],
	[FILES_FETCH_PARAMS]: state => state[FILES_FETCH_PARAMS],
};

export const mutations = {
	[SET_LECTURE_FILES]: (state, payload) => (state[LECTURE_FILES] = payload),
	[SET_FILES_FETCH_PARAMS]: (state, payload) =>
		(state[FILES_FETCH_PARAMS] = { ...state[FILES_FETCH_PARAMS], ...payload }),
};

export const actions = {
	async [LOAD_LECTURE_FILES]({ state, commit, rootState }) {
		try {
			const currentPage = state[FILES_FETCH_PARAMS].currentPage;
			const {
				data: { current_page, last_page, data },
			} = await this.$api.vodLecture.getFiles({
				page: currentPage + 1,
				lectureId: rootState.vod.player.lecture.CURRENT_LESSON.lectureId,
			});
			commit(SET_LECTURE_FILES, data);
			commit(SET_FILES_FETCH_PARAMS, { currentPage: current_page, lastPage: last_page });
		} catch (error) {
			console.error('LOAD_LECTURE_FILES error: ', error);
		}
	},
};
