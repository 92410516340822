
import { cdnBaseUrl } from '@/plugins/constants';

export default {
	name: 'ProfileAvatar',
	props: {
		userNo: {
			type: Number,
			default: -1,
		},
		width: {
			type: Number,
			default: 0,
		},
		height: {
			type: Number,
			default: 0,
		},
		backgroundSize: {
			type: Number,
			default: 0,
		},
		imagePath: {
			type: String,
			default: '',
		},
		avatarSize: {
			type: String,
			default: 'large',
		},
	},
	data() {
		return {
			cdnBaseUrl,
		};
	},
	computed: {
		isCustomSize() {
			return this.width && this.height;
		},
		avatarImage() {
			if (this.imagePath) {
				const size = this.isCustomSize ? `${this.width * 1.5}x${this.height * 1.5}` : '150x150';
				return `${this.imagePath}?s=${size}`;
			}
			return `${cdnBaseUrl}/images/illust/illust-photo-upload.svg`;
		},
	},
	mounted() {
		this.handleAvatarSize();
	},
	methods: {
		handleAvatarSize() {
			const $avatarContainer = this.$refs.profileAvatar.$el;
			const $avatar = this.$refs.profileAvatar.$el.firstChild;
			if (this.isCustomSize) {
				$avatarContainer.style.width = `${this.width}px`;
				$avatarContainer.style.height = `${this.height}px`;
				$avatar.style.width = `${this.width}px`;
				$avatar.style.height = `${this.height}px`;
			}
			if (this.backgroundSize) {
				$avatar.style.backgroundSize = `${this.backgroundSize}%`;
				$avatar.style.backgroundPosition = 'center';
			}
		},
	},
};
