
import { mapGettersOfMyPage, GET_CREDIT_REFUND_THIS_MONTH, GET_CREDIT_REFUND_AMOUNT } from '@/store/myPage';

import layoutMixin from '@/mixins/layoutMixin';

import Skeleton from '@/src/components/Other/Skeleton.vue';

export default {
	name: 'MyPageCashInfoBoard',
	mixins: [layoutMixin],
	computed: {
		...mapGettersOfMyPage([GET_CREDIT_REFUND_THIS_MONTH, GET_CREDIT_REFUND_AMOUNT]),
		myPageCashInfoBoardContainer() {
			return this.IS_MOBILE ? [24, 16, 24, 16] : [0, 0, 32, 0];
		},
		titleTypography() {
			return this.IS_MOBILE ? 'body2' : 'body1';
		},
		priceTypography() {
			return this.IS_MOBILE ? 'headline7' : 'headline1';
		},
		priceFontWeight() {
			return this.IS_MOBILE ? 'semi-bold' : 'regular';
		},
		wonTypography() {
			return this.IS_MOBILE ? 'headline7' : 'headline5';
		},
		skeletonHeight() {
			return this.IS_MOBILE ? '27px' : '47px';
		},
		hasCashInfo() {
			return this[GET_CREDIT_REFUND_THIS_MONTH] !== null && this[GET_CREDIT_REFUND_AMOUNT] !== null;
		},
	},
	components: { Skeleton },
};
