const CLASS_PT_API_PREFIX = `/api/class/pt`;

export default axios => ({
	/**
	 * 실무PT 랜딩 list 가져오기;
	 * @params order_type
	 */
	// TODO : perPage가 추가되었기 때문에 기존 api 수정할 것.
	getPtLandingList(params) {
		return axios.get(`${CLASS_PT_API_PREFIX}/v2/list`, { params });
	},
	/**
	 * 실무PT detail info 가져오기;
	 * @params title
	 * @params camp_round: /contents일 때 (구버전)
	 * @params reference_id: /content일 때 (신버전)
	 */
	postClassPtDetailInfo(data) {
		return axios.post(`${CLASS_PT_API_PREFIX}/v2/detail/floating-box`, data);
	},
	/**
	 * 디테일 contents 가져오기
	 * @params title
	 * @params camp_round: /contents일 때 (구버전)
	 * @params reference_id: /content일 때 (신버전)
	 */
	postClassPtDetailContent(data) {
		return axios.post(`${CLASS_PT_API_PREFIX}/v2/detail/content`, data);
	},
	/**
	 * 마감 된 클래스 - 연관된 리스트 가져오기
	 * @params classId
	 */
	getRelationClassOfClosed(params) {
		return axios.get(`${CLASS_PT_API_PREFIX}/list/relation`, { params });
	},
	/**
	 * 데이터라는 키워드를 가진 readCase에 노출될 수 있도록 list 가져오는 api
	 */
	getPtList(params) {
		return axios.get(`${CLASS_PT_API_PREFIX}/list/category`, { params });
	},
	/**
	 * 실무PT를 구매, 환 여부를 판단하기 위한 api
	 * 현재 선택한 클래스의 구매 정보를 가져옴
	 *
	 * @param {Object} data 클래스 정보
	 * @param {string} data.title 클래스 제목
	 * @param {number} data.class_id 클래스 ID
	 */
	postDetailUserInfo(data) {
		return axios.post(`${CLASS_PT_API_PREFIX}/detail/user/info`, data);
	},
	/**
	 * 실무PT 추천 list 가져오기
	 * @params classId
	 */
	getPtRelationList(params) {
		return axios.get(`${CLASS_PT_API_PREFIX}/list/relation/category`, { params });
	},
	/**
	 * 실무PT list 대분류 가져오기
	 */
	getClassPtTopCategory() {
		return axios.get(`${CLASS_PT_API_PREFIX}/list/top-category`);
	},
	/**
	 * readCase 하단 네이티브 광고 정보 가져오기
	 * @param params
	 * @param params.keyword: 마케팅, 데이터분석, 프로그래밍, 업무생산성, 디자인, 연구개발, 반도체
	 */
	getReadCaseAdForClassPt(params) {
		return axios.get(`${CLASS_PT_API_PREFIX}/read-case/advertisement`, { params });
	},
	/**
	 * 실무PT 리뷰 가져오기
	 * @param {Number} referenceId
	 * @param {Object} params
	 * @param {Number} params.page
	 * @param {Number} params.perPage
	 */
	getClassPtReviews(referenceId, params) {
		return axios.get(`${CLASS_PT_API_PREFIX}/reviews/${referenceId}`, { params });
	},

	/**
	 * 유저 구매 정보 조회
	 * @param {number} class_id 현재 클래스 ID
	 * @returns 응답
	 */
	getUserPurchaseInfo(class_id) {
		return axios.post(`${CLASS_PT_API_PREFIX}/detail/purchase`, { class_id });
	},
});
