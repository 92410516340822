
import { cdnBaseUrl } from '@/plugins/constants';

import {
	mapGettersOfAnswer,
	GET_ANSWER_USER_MENTEE_COUNT,
	GET_ANSWER_USER_MENTORING_COUNT,
	GET_ANSWER_USER_CREDIT,
} from '@/store/answer';

import CustomNuxtLink from '@/components/CustomNuxtLink.vue';

import gtmTriggerMixin from '@/mixins/gtmTriggerMixin';
import layoutMixin from '@/mixins/layoutMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

export default {
	name: 'AnswerSideBoxCountInfo',
	mixins: [gtmTriggerMixin, userInfoMixin, layoutMixin],
	data() {
		return { userRank: 0, cdnBaseUrl };
	},
	computed: {
		...mapGettersOfAnswer([GET_ANSWER_USER_MENTEE_COUNT, GET_ANSWER_USER_MENTORING_COUNT, GET_ANSWER_USER_CREDIT]),
		userAnswerMentoringCount() {
			return [
				{ title: '• 내 답변 수', value: this[GET_ANSWER_USER_MENTORING_COUNT] },
				{ title: '• 도와준 멘티', value: this[GET_ANSWER_USER_MENTEE_COUNT] },
			];
		},
		userAnswerEtcCount() {
			return [
				{
					title: '실시간 랭킹',
					value: this.userRank >= 193 ? '순위 밖' : `${this.userRank}위`,
					url: '/rank',
					image: 'answer-rank.svg',
					color: 'gray500',
				},
				{
					title: '크레딧 관리',
					value: this[GET_ANSWER_USER_CREDIT],
					url: '/mypage/shop/list',
					image: 'answer-credit.svg',
					unit: '원',
					color: 'yellow600',
				},
			];
		},
		creditShopType() {
			const userCredit = this[GET_ANSWER_USER_CREDIT];

			if (userCredit <= 3000) {
				return {
					credit: 3000,
					product: '베스킨라빈스 아이스크림',
					koreaPostpositions: '을 받을 수 있어요!',
				};
			} else if (userCredit <= 6500) {
				return {
					credit: 6500,
					product: '투썸 레드벨벳 케이크',
					koreaPostpositions: '를 받을 수 있어요!',
				};
			} else if (userCredit <= 19000) {
				return {
					credit: 19000,
					product: 'BHC 후라이드치킨+콜라',
					koreaPostpositions: '를 받을 수 있어요!',
				};
			} else {
				return false;
			}
		},
	},
	mounted() {
		this.getAnswerRank();
	},
	methods: {
		async getAnswerRank() {
			try {
				const {
					data: { my_rank },
				} = await this.$api.answerList.getUserAnswerRank();

				this.userRank = my_rank;
			} catch (error) {
				console.error(error);
			}
		},
		remainText() {
			const userCredit = this[GET_ANSWER_USER_CREDIT];
			const sameText = `크레딧만 더 모으면`;
			setTimeout(() => {
				const eidCm074 = document.querySelector('.eid-cm074')?.clientWidth;
				if (eidCm074 > 0) {
					this.$_showCreditTip({ ...this.$_userInfoForGtm, is_app: this.IS_APP });
				}
			}, 1000);

			if (this.creditShopType.credit) {
				return `${Intl.NumberFormat().format(this.creditShopType.credit - userCredit)} ${sameText}</br><span>${
					this.creditShopType.product
				}</span>${this.creditShopType.koreaPostpositions}`;
			} else {
				return `조금만 더 모아서 현금으로 교환해가세요!`;
			}
		},
	},
	components: {
		CustomNuxtLink,
	},
};
