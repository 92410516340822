import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'advertisement';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfAdvertisement,
	mapMutations as mapMutationsOfAdvertisement,
	mapActions as mapActionsOfAdvertisement,
};

// State
export const AD_TYPE = 'AD_TYPE';
export const AD_SORT = 'AD_SORT';
export const AD_LIMIT = 'AD_LIMIT';
export const AD_LIST = 'AD_LIST';
export const TIMELINE_AD_INDEX_LIST = 'TIMELINE_AD_INDEX_LIST';
export const STACK_TIMELINE_AD_LIST = 'STACK_TIMELINE_AD_LIST';

// Getters
export const GET_AD_TYPE = 'GET_AD_TYPE';
export const GET_AD_SORT = 'GET_AD_SORT';
export const GET_AD_LIMIT = 'GET_AD_LIMIT';
export const GET_AD_LIST = 'GET_AD_LIST';
export const GET_TIMELINE_AD_INDEX_LIST = 'GET_TIMELINE_AD_INDEX_LIST';
export const GET_STACK_TIMELINE_AD_LIST = 'GET_STACK_TIMELINE_AD_LIST';

// Mutations
export const SET_AD_TYPE = 'SET_AD_TYPE';
export const SET_AD_SORT = 'SET_AD_SORT';
export const SET_AD_LIMIT = 'SET_AD_LIMIT';
export const SET_AD_LIST = 'SET_AD_LIST';
export const SET_TIMELINE_AD_INDEX_LIST = 'SET_TIMELINE_AD_INDEX_LIST';
export const SET_STACK_TIMELINE_AD_LIST = 'SET_STACK_TIMELINE_AD_LIST';

// Action
export const LOAD_AD_LIST = 'LOAD_AD_LIST';

export const state = () => ({
	/* [AD_TYPE]
	home_native_image(타임라인 네이티브),
	readCase_native_image(상담사례 네이티브),
	onboardJobDescriptionList_main_swiper(채용 전용관 상단 롤링),
	onboardJobDescriptionList_jobDescriptionCard(채용 전용관 LIST 상단 추천채용) */
	[AD_TYPE]: null,
	[AD_SORT]: 'random', // 정렬 타입 order:재고번호 순서, random:무작위
	[AD_LIMIT]: 4, // 광고 개수
	[AD_LIST]: [],
	[TIMELINE_AD_INDEX_LIST]: [], // 타임라인 광고 노출 index
	[STACK_TIMELINE_AD_LIST]: [],
});

export const getters = {
	[GET_AD_TYPE]: state => state[AD_TYPE],
	[GET_AD_SORT]: state => state[AD_SORT],
	[GET_AD_LIMIT]: state => state[AD_LIMIT],
	[GET_AD_LIST]: state => state[AD_LIST],
	[GET_TIMELINE_AD_INDEX_LIST]: state => state[TIMELINE_AD_INDEX_LIST],
	[GET_STACK_TIMELINE_AD_LIST]: state => state[STACK_TIMELINE_AD_LIST],
};

export const mutations = {
	[SET_AD_TYPE]: (state, payload) => {
		state[AD_TYPE] = payload;
	},
	[SET_AD_SORT]: (state, payload) => {
		state[AD_SORT] = payload;
	},
	[SET_AD_LIMIT]: (state, payload) => {
		state[AD_LIMIT] = payload;
	},
	[SET_AD_LIST]: (state, payload) => {
		state[AD_LIST] = payload;
	},
	[SET_TIMELINE_AD_INDEX_LIST]: (state, payload) => {
		state[TIMELINE_AD_INDEX_LIST] = payload;
	},
	[SET_STACK_TIMELINE_AD_LIST]: (state, payload) => {
		state[STACK_TIMELINE_AD_LIST] = payload;
	},
};

export const actions = {
	async [LOAD_AD_LIST]({ getters, commit }, info) {
		const { data } = await this.$axios.get(`/api/advertisements`, {
			params: info || { type: getters.GET_AD_TYPE, sort: getters.GET_AD_SORT, limit: getters.GET_AD_LIMIT },
		});

		return info ? data : commit(SET_AD_LIST, data);
	},
};
