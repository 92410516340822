
import { mapGetters } from 'vuex';

import { NOTICE_PLATFORM } from '@/constants/notice';

import { GLOBAL_NOTICE_ALERT, PT_FIRST_ANNIVERSARY_ALERT_KEY } from '@/plugins/constants';
import dayjs, { getTimeInAsia } from '@/plugins/dayjs';

import { isEmptyData } from '@/utils/utils';

import {
	mapGettersOfLayout,
	IS_SHOW_ALERT_LAYOUT,
	mapMutationsOfLayout,
	SET_IS_SHOW_GLOBAL_NOTICE_ALERT,
	SET_GLOBAL_NOTICE_INFO,
	SET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT,
	GLOBAL_NOTICE_INFO,
} from '@/store/layout';

import AlertLayout from '@/components/layouts/AlertLayout.vue';
import HeaderLayout from '@/components/layouts/HeaderLayout.vue';
import IEAlert from '@/components/navigation/molecules/alert/IEAlert.vue';

import cookieMixin from '@/mixins/cookieMixin';
import layoutMixin from '@/mixins/layoutMixin';

export default {
	name: 'HeaderWrapper',
	mixins: [cookieMixin, layoutMixin],
	data() {
		return {
			isShowIEAlert: false,
		};
	},
	computed: {
		...mapGetters(['isIE']),
		...mapGettersOfLayout([IS_SHOW_ALERT_LAYOUT, GLOBAL_NOTICE_INFO]),
		isNovember() {
			return getTimeInAsia().isBefore(dayjs('2022-12-01'));
		},
	},
	mounted() {
		this.isShowIEAlert = this.isIE;
		this.$nextTick(() => {
			this.fetchNotice();
			this.getPtFirstAnniversaryStatus();
		});
	},
	methods: {
		...mapMutationsOfLayout([
			SET_IS_SHOW_GLOBAL_NOTICE_ALERT,
			SET_GLOBAL_NOTICE_INFO,
			SET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT,
		]),
		async fetchNotice() {
			try {
				const platformText = this.IS_MOBILE ? NOTICE_PLATFORM.MOBILE : NOTICE_PLATFORM.PC;
				const { data } = await this.$api.notice.fetchNoticeAlert({ filter: platformText });

				if (!isEmptyData(data) && data.code !== 500) {
					if (!this.$_getCookie(`${GLOBAL_NOTICE_ALERT}${data.id}`)) {
						this[SET_GLOBAL_NOTICE_INFO](data);
						this[SET_IS_SHOW_GLOBAL_NOTICE_ALERT](true);
					}
				}
			} catch (error) {
				console.error(error);
			}
		},
		getPtFirstAnniversaryStatus() {
			if (this.isNovember && !this.$_getCookie(PT_FIRST_ANNIVERSARY_ALERT_KEY)) {
				this[SET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT](true);
			}
		},
	},
	components: {
		AlertLayout,
		IEAlert,
		HeaderLayout,
	},
};
