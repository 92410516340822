import { createNamespacedHelpers } from 'vuex';
const NAMESPACE = 'vod/landing';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfVodLanding,
	mapMutations as mapMutationsOfVodLanding,
	mapActions as mapActionsOfVodLanding,
};

export const EDU_VODS = 'EDU_VODS';
export const EDU_VODS_PAGINATION = 'EDU_VODS_PAGINATION';
export const VOD_LIST_TOP_CATEGORY = 'VOD_LIST_TOP_CATEGORY';
export const SELECTED_TOP_CATEGORY = 'SELECTED_TOP_CATEGORY';

export const SET_EDU_VODS = 'SET_EDU_VODS';
export const SET_EDU_VODS_PAGINATION = 'SET_EDU_VODS_PAGINATION';
export const SET_VOD_LIST_TOP_CATEGORY = 'SET_VOD_LIST_TOP_CATEGORY';
export const SET_SELECTED_TOP_CATEGORY = 'SET_SELECTED_TOP_CATEGORY';

export const state = () => ({
	[EDU_VODS]: [],
	[EDU_VODS_PAGINATION]: {
		page: 1,
		limit: 12,
		lastPage: Infinity,
		category: [],
	},
	vodListTopCategory: [],
	selectedTopCategory: [],
});

export const getters = {
	[EDU_VODS]: state => state[EDU_VODS],
	[EDU_VODS_PAGINATION]: state => {
		return {
			...state[EDU_VODS_PAGINATION],
			category: state.selectedTopCategory,
		};
	},
	[VOD_LIST_TOP_CATEGORY]: state => state.vodListTopCategory,
	[SELECTED_TOP_CATEGORY]: state => state.selectedTopCategory,
};

export const mutations = {
	[SET_EDU_VODS]: (state, payload) => (state[EDU_VODS] = payload),
	[SET_EDU_VODS_PAGINATION]: (state, payload) => (state[EDU_VODS_PAGINATION] = payload),
	[SET_VOD_LIST_TOP_CATEGORY]: (state, payload) => {
		state.vodListTopCategory = payload;
	},
	[SET_SELECTED_TOP_CATEGORY]: (state, payload) => {
		state.selectedTopCategory = payload;
	},
};
