import get from 'lodash/get';
import { createNamespacedHelpers } from 'vuex';

// TODO : edu directory 아래로 옮길 것
const NAMESPACE = 'alumni';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export { mapGetters as mapGettersOfAlumni, mapMutations as mapMutationsOfAlumni, mapActions as mapActionsOfAlumni };

// states
// 직부캠 수료 완료 여부
export const IS_APPLICANT_COMPLETED = 'IS_APPLICANT_COMPLETED';
export const SHOW_ALUMNI_MODAL = 'SHOW_ALUMNI_MODAL';
export const POST_ID = 'POST_ID';
export const POSTS = 'POSTS';
export const POSTS_TOTAL_COUNT = 'POSTS_TOTAL_COUNT';
export const POSTS_PARAMS = 'POSTS_PARAMS';
export const POSTS_LAST_PAGE = 'POSTS_LAST_PAGE';
export const HEADERS_INFO = 'HEADERS_INFO';
// 댓글 대댓글 공용
export const CREATED_COMMENT_ID = 'CREATED_COMMENT_ID';

// getters
export const GET_IS_APPLICANT_COMPLETED = 'GET_IS_APPLICANT_COMPLETED';
export const GET_SHOW_ALUMNI_MODAL = 'GET_SHOW_ALUMNI_MODAL';
export const GET_POST_ID = 'GET_POST_ID';
export const GET_POSTS = 'GET_POSTS';
export const GET_POSTS_TOTAL_COUNT = 'GET_POSTS_TOTAL_COUNT';
export const GET_POSTS_PARAMS = 'GET_POSTS_PARAMS';
export const GET_IS_LAST_PAGE = 'GET_IS_LAST_PAGE';
export const GET_POSTS_PAGE = 'GET_POSTS_PAGE';
export const GET_POSTS_LAST_PAGE = 'GET_POSTS_LAST_PAGE';
export const GET_HEADERS_INFO = 'GET_HEADERS_INFO';
export const GET_CREATED_COMMENT_ID = 'GET_CREATED_COMMENT_ID';

// setters
export const SET_IS_APPLICANT_COMPLETED = 'SET_IS_APPLICANT_COMPLETED';
export const SET_SHOW_ALUMNI_MODAL = 'SET_SHOW_ALUMNI_MODAL';
export const SET_POST_ID = 'SET_POST_ID';
export const SET_POSTS = 'SET_POSTS';
export const ADD_POSTS = 'ADD_POSTS';
export const SET_POSTS_TOTAL_COUNT = 'SET_POSTS_TOTAL_COUNT';
export const SET_POSTS_PARAMS = 'SET_POSTS_PARAMS';
export const SET_POSTS_LAST_PAGE = 'SET_POSTS_LAST_PAGE';
export const SET_HEADERS_INFO = 'SET_HEADERS_INFO';
export const SET_CREATED_COMMENT_ID = 'SET_CREATED_COMMENT_ID';

// actions
export const FETCH_POSTS = 'FETCH_POSTS';
export const FETCH_HEADERS_INFO = 'FETCH_HEADERS_INFO';

export const state = () => ({
	[IS_APPLICANT_COMPLETED]: false,
	[SHOW_ALUMNI_MODAL]: false,
	[POST_ID]: null,
	[POSTS]: null,
	[POSTS_TOTAL_COUNT]: 0,
	[POSTS_PARAMS]: {
		page: 1,
		limit: 10,
	},
	[POSTS_LAST_PAGE]: 1,
	[HEADERS_INFO]: null,
	[CREATED_COMMENT_ID]: null,
});

export const getters = {
	[GET_IS_APPLICANT_COMPLETED]: state => state[IS_APPLICANT_COMPLETED],
	[GET_SHOW_ALUMNI_MODAL]: state => state[SHOW_ALUMNI_MODAL],
	[GET_POST_ID]: state => state[POST_ID],
	[GET_POSTS]: state => state[POSTS],
	[GET_POSTS_TOTAL_COUNT]: state => state[POSTS_TOTAL_COUNT],
	[GET_POSTS_PARAMS]: state => state[POSTS_PARAMS],
	[GET_POSTS_PAGE]: (_state, getters) => get(getters, [GET_POSTS_PARAMS, 'page'], '1'),
	[GET_POSTS_LAST_PAGE]: state => state[POSTS_LAST_PAGE],
	[GET_IS_LAST_PAGE]: (_state, getters) => getters[GET_POSTS_LAST_PAGE] <= getters[GET_POSTS_PAGE],
	[GET_HEADERS_INFO]: state => state[HEADERS_INFO],
	[GET_CREATED_COMMENT_ID]: state => state[CREATED_COMMENT_ID],
};

export const mutations = {
	[SET_IS_APPLICANT_COMPLETED]: (state, payload) => {
		state[IS_APPLICANT_COMPLETED] = payload;
	},
	[SET_SHOW_ALUMNI_MODAL]: (state, payload) => {
		state[SHOW_ALUMNI_MODAL] = payload;
	},
	[SET_POST_ID]: (state, payload) => {
		state[POST_ID] = payload;
	},
	[SET_POSTS]: (state, payload) => {
		state[POSTS] = payload;
	},
	[SET_POSTS_TOTAL_COUNT]: (state, payload) => {
		state[POSTS_TOTAL_COUNT] = payload;
	},
	[SET_POSTS_PARAMS]: (state, payload) => {
		state[POSTS_PARAMS] = { ...state[POSTS_PARAMS], ...payload };
	},
	[SET_POSTS_LAST_PAGE]: (state, payload) => {
		state[POSTS_LAST_PAGE] = payload;
	},
	[SET_HEADERS_INFO]: (state, payload) => {
		state[HEADERS_INFO] = payload;
	},
	[SET_CREATED_COMMENT_ID]: (state, payload) => {
		state[CREATED_COMMENT_ID] = payload;
	},
};

export const actions = {
	async [FETCH_POSTS]({ getters, commit }, { type = 'replace' }) {
		try {
			const { data } = await this.$api.afterClass.getAfterClassPosts(getters[GET_POSTS_PARAMS]);
			commit(SET_POSTS_TOTAL_COUNT, data.total);
			commit(SET_POSTS_LAST_PAGE, data.last_page);
			const posts = getters[GET_POSTS];
			switch (type) {
				case 'replace':
					commit(SET_POSTS, data.data);
					break;
				case 'add':
					if (Array.isArray(posts)) {
						commit(SET_POSTS, [...posts, ...data.data]);
					} else {
						commit(SET_POSTS, data.data);
					}
					break;
			}
		} catch (error) {
			console.error('FETCH_POSTS error: ', error);
		}
	},
	async [FETCH_HEADERS_INFO]({ getters, commit }) {
		try {
			if (getters[GET_HEADERS_INFO]) {
				return;
			}
			const response = await this.$api.afterClass.getAfterClassPostCategories();
			commit(SET_HEADERS_INFO, response.data);
		} catch (error) {
			console.error('FETCH_HEADERS_INFO error: ', error);
		}
	},
};
