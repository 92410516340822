import { checkIsLoggedIn } from '@/utils/auth';

import { SET_IS_LOGO_TOP_BAR } from '@/store/layout';

function showLogoTopBarWhenUserIsNotLoggedIn(app) {
	const isLoggedIn = checkIsLoggedIn(app.store);
	if (!isLoggedIn) {
		app.store.commit('setMetaIsDetail', true);
		app.store.commit(`layout/${SET_IS_LOGO_TOP_BAR}`, true);
	}
}
export default function ({ app }) {
	showLogoTopBarWhenUserIsNotLoggedIn(app);
}
