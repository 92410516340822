import myPageMixin from '@/mixins/myPageMixin';

const myPageNavMixin = {
	mixins: [myPageMixin],
	data() {
		return {
			nav: null,
		};
	},
	watch: {
		$route() {
			this.$_setNav();
		},
	},
	computed: {
		$_currentMenuNavs() {
			return this.$_currentMenu.navs;
		},
		$_currentNavIndex() {
			let index = 0;
			let utmRemovedQuery = '';

			// query에서 utm_으로 시작하는건 제거하기
			for (const [key, value] of Object.entries(this.$route.query)) {
				if (!key.startsWith('utm_')) {
					utmRemovedQuery += utmRemovedQuery === '' ? '?' : '&';
					utmRemovedQuery += `${key}=${value}`;
				}
			}
			const convertedFullPath = `${this.$route.path}${utmRemovedQuery}`;

			index = this.$_currentMenuNavs.findIndex(nav => {
				return nav.url === convertedFullPath;
			});
			return index;
		},
		$_currentNav() {
			return this.$_currentMenuNavs[this.$_currentNavIndex];
		},
	},
	created() {
		this.$_setNav();
	},
	methods: {
		$_setNav() {
			this.nav = this.$route.query.nav ?? '';
		},
	},
};

export default myPageNavMixin;
