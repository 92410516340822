import { mapActions, mapGetters, mapMutations } from 'vuex';

import { CLICKED_PROFILE_IMAGE_POPOVER_CLOSE_BUTTON } from '@/plugins/constants';

import { getLocalStorage, isEmptyData, setLocalStorage } from '@/utils/utils';

import myPageGtmTriggerMixin from '@/mixins/gtm/myPageGtmTriggerMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

export const profileImageMixin = {
	mixins: [myPageGtmTriggerMixin, userInfoMixin],
	data() {
		return {
			isShowProfileImagePopover: false,
		};
	},
	computed: {
		...mapGetters(['userProfileImage', 'isUser']),
	},
	async created() {
		if (this.isUser) {
			await this.getUserProfileImage();
			this.$_fetchProfileImagePopover();
		}
	},
	methods: {
		...mapActions(['getUserProfileImage']),
		...mapMutations(['setUserProfileImage']),
		$_fetchProfileImagePopover() {
			// popover가 뜨는 조건: 프로필 이미지가 없거나, 닫기 버튼을 누르지 않았을 때
			const _isEmptyProfileImage = isEmptyData(this.userProfileImage);
			if (_isEmptyProfileImage) {
				const _clickedProfileImagePopoverCloseButton =
					getLocalStorage(CLICKED_PROFILE_IMAGE_POPOVER_CLOSE_BUTTON) || false;
				this.isShowProfileImagePopover = _isEmptyProfileImage && !_clickedProfileImagePopoverCloseButton;
			}
		},
		$_hideProfileImagePopover() {
			setLocalStorage(CLICKED_PROFILE_IMAGE_POPOVER_CLOSE_BUTTON, true);
			this.isShowProfileImagePopover = false;
		},
		$_profileImageUpdated(imagePath) {
			if (!imagePath) {
				return;
			}
			this.setUserProfileImage(imagePath);
			this.$_hideProfileImagePopover();
			this.$_registerProfilePic(this.$_userInfoForGtm);
		},
	},
};
