
import { mapGetters } from 'vuex';

import { cdnBaseUrl } from '@/plugins/constants';
import { YMDWithDots } from '@/plugins/filters/formatDate';

import { EDU_USER_MATCH_INFO, EDU_USER_COMPLETION, mapGettersOfCamp } from '@/store/camp';
import { SEARCH_LIST_PARAMS, mapGettersOfSearchList } from '@/store/search/list';

import routeMixin from '@/mixins/routeMixin';

export default {
	name: 'NotiContent',
	mixins: [routeMixin],
	props: {
		notiList: {
			type: Array,
			default: () => [],
		},
		infiniteId: {
			type: Number,
			default: 0,
		},
		isErrorInfiniteLoading: {
			type: Boolean,
			default: false,
		},
		infiniteErrorMessage: {
			type: String,
			default: '오류가 발생했습니다.',
		},
	},
	data() {
		return {
			baseUrl: process.env.baseUrl,
			cdnBaseUrl,
		};
	},
	computed: {
		...mapGetters(['userNickname']),
		...mapGettersOfCamp([EDU_USER_MATCH_INFO, EDU_USER_COMPLETION]),
		...mapGettersOfSearchList([SEARCH_LIST_PARAMS]),
	},
	created() {
		this.YMDWithDots = YMDWithDots;
	},
	methods: {
		goLoginPage() {
			location.href = `${this.baseUrl}/logout`;
		},
		emitGoNotiDetail(args) {
			this.$emit('go-noti-detail', args);
		},
		infiniteHandler($state) {
			this.$emit('infinite-handler', $state);
		},
		dateFormat(dateTime) {
			return dateTime && dateTime.split(' ')[0];
		},
		getConfirmStyle(confirm_style) {
			return confirm_style !== '' ? 'alarm-unread' : '';
		},
	},
};
