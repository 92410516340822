
import { isEmptyData } from '@/utils/utils';

import appCommandMixin from '@/mixins/appCommandMixin';
import layoutMixin from '@/mixins/layoutMixin';

export default {
	mixins: [appCommandMixin, layoutMixin],
	data() {
		return {
			showToast: false,
			message: '',
			type: 'basic',
			position: 'bottom',
			timeout: 3000,
			icon: null,
			showIcon: false,
			clickEvent: null,
			actionName: '',
		};
	},
	computed: {
		hasClickEvent() {
			return !isEmptyData(this.clickEvent);
		},
	},
	methods: {
		/**
		 * @param message: Toast 내용
		 * @param type: Toast type (basic, error, success)
		 * @param position: 위치 (top, bottom)
		 * @param timeout: 시간
		 * @param icon: 아이콘 이름
		 * @param clickEvent: 클릭 이벤트
		 **/
		show(message, type, position, timeout, icon, clickEvent) {
			this.message = message;
			this.type = type;
			this.position = position;
			this.timeout = timeout;
			if (icon !== null) {
				this.icon = icon;
				this.showIcon = true;
			}
			if (clickEvent !== null) {
				this.clickEvent = clickEvent;
			}
			this.showToast = true;
		},
		toastClickEvent() {
			if (this.clickEvent !== null) {
				this.clickEvent();
			}
		},
	},
};
