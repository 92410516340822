export default axios => ({
	/**
	 * params
	 * page
	 **/
	getNotiList(params) {
		return axios.get('/api/noti/list', { params });
	},
	patchNotiReadAll() {
		return axios.patch('/api/noti/read/all');
	},
	patchNotiItem(noti_no) {
		return axios.patch(`/api/noti/${noti_no}`);
	},
});
