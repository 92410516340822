export const CLASSROOM_TAB_ITEMS_ENUM = Object.freeze({
	CLASSROOM_CAMP_START: '시작하기',
	CLASSROOM_CAMP_ASSIGNMENT: '실무 업무',
	CLASSROOM_CAMP_SCHEDULE: '캠프 일정',
	CLASSROOM_CAMP_CERTIFICATION: '수료증',
	CLASSROOM_CAMP_ATTENDANCE: '출석부',
	CLASSROOM_CAMP_NEW_REPLY: '새 댓글',
	CLASSROOM_CAMP_LIVE_CLASS: '라이브 강의',
	CLASSROOM_CAMP_ALL_ASSIGNMENT: '수강생 업무',
	CLASSROOM_CAMP_MY_ASSIGNMENT: '내 업무',
	CLASSROOM_PT_SCHEDULE: '강의 일정',
	CLASSROOM_MY_CLASSROOM: '내 강의실',
	CLASSROOM_MY_CLASSROOM_LIVE: '대시보드',
	CLASSROOM_ALL_ASSIGNMENT_LIVE: '모든 업무',
	CLASSROOM_ALL_ASSIGNMENT: '수강생 과제',
	CLASSROOM_PATH_CAMP_START: '수강하기',
	CLASSROOM_PATH_ASSIGNMENT: '과제',
	CLASSROOM_PATH_DETAIL_VOD: '1. VOD',
	CLASSROOM_PATH_DETAIL_ASSIGNMENT: '2. 실무과제',
	CLASSROOM_PT_REVISE_VOD: '복습 VOD',
	CLASSROOM_PT_REVISE_VOD_FOR_HAS_VOD: '실무 VOD',
});

export const CLASSROOM_REVIEW_EVENT_URL = 'https://comentoedureview.oopy.io/';
