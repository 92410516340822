import get from 'lodash/get';
import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'review';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export { mapGetters as mapGettersOfReview, mapMutations as mapMutationsOfReview, mapActions as mapActionsOfReview };

// state
export const CAMP_REVIEWS_LOAD_OPTIONS = 'CAMP_REVIEWS_LOAD_OPTIONS';

// getters
export const CAMP_REVIEWS = 'CAMP_REVIEWS';
export const CAMP_REVIEWS_COUNT = 'CAMP_REVIEWS_COUNT';
export const CAMP_REVIEWS_PARAMS = 'CAMP_REVIEWS_PARAMS';
export const CAMP_REVIEWS_PAGE = 'CAMP_REVIEWS_PAGE';
export const GET_CAMP_REVIEWS_LOAD_OPTIONS = 'GET_CAMP_REVIEWS_LOAD_OPTIONS';

// mutations
export const SET_CAMP_REVIEWS = 'SET_CAMP_REVIEWS';
export const SET_CAMP_REVIEWS_COUNT = 'SET_CAMP_REVIEWS_COUNT';
export const SET_CAMP_REVIEWS_PARAMS = 'SET_CAMP_REVIEWS_PARAMS';
export const SET_CAMP_REVIEWS_LOAD_OPTIONS = 'SET_CAMP_REVIEWS_LOAD_OPTIONS';
// actions
export const LOAD_CAMP_REVIEWS = 'LOAD_CAMP_REVIEWS';
export const HANDLE_SET_CAMP_REVIEWS_PARAMS = 'HANDLE_SET_CAMP_REVIEWS_PARAMS';

export const state = () => ({
	[CAMP_REVIEWS]: null,
	[CAMP_REVIEWS_COUNT]: 0,
	[CAMP_REVIEWS_PARAMS]: {
		page: 1,
		per_page: 6,
	},
	[CAMP_REVIEWS_LOAD_OPTIONS]: {
		type: 'replace',
	},
});
export const getters = {
	[CAMP_REVIEWS]: state => state[CAMP_REVIEWS],
	[CAMP_REVIEWS_COUNT]: state => state[CAMP_REVIEWS_COUNT],
	[CAMP_REVIEWS_PARAMS]: state => state[CAMP_REVIEWS_PARAMS],
	[CAMP_REVIEWS_PAGE]: (_state, getters) => get(getters, [CAMP_REVIEWS_PARAMS, 'page'], '1'),
	[GET_CAMP_REVIEWS_LOAD_OPTIONS]: state => state[CAMP_REVIEWS_LOAD_OPTIONS],
};
export const mutations = {
	[SET_CAMP_REVIEWS]: (state, payload) => {
		state[CAMP_REVIEWS] = payload;
	},
	[SET_CAMP_REVIEWS_COUNT]: (state, payload) => {
		state[CAMP_REVIEWS_COUNT] = payload;
	},
	[SET_CAMP_REVIEWS_LOAD_OPTIONS]: (state, payload) => {
		state[CAMP_REVIEWS_LOAD_OPTIONS] = payload;
	},
	[SET_CAMP_REVIEWS_PARAMS]: (state, payload) => {
		state[CAMP_REVIEWS_PARAMS] = { ...state[CAMP_REVIEWS_PARAMS], ...payload };
	},
};
export const actions = {
	async [LOAD_CAMP_REVIEWS]({ getters, commit }, { params, options }) {
		try {
			let type = 'replace';
			const response = await this.$api.camp.getEduSurvey(params);
			commit(SET_CAMP_REVIEWS_COUNT, response.data.total);
			if (options) {
				type = options.type;
			}
			switch (type) {
				case 'replace':
					commit(SET_CAMP_REVIEWS, response.data.data);
					break;
				case 'add':
					commit(SET_CAMP_REVIEWS, [...getters[CAMP_REVIEWS], ...response.data.data]);
					break;
			}
		} catch (error) {
			console.error('LOAD_CAMP_REVIEWS error: ', error);
		}
	},
	[HANDLE_SET_CAMP_REVIEWS_PARAMS]: ({ commit }, { params, options }) => {
		const defaultOptions = {
			type: 'replace',
		};
		if (options) {
			commit(SET_CAMP_REVIEWS_LOAD_OPTIONS, options);
		} else {
			commit(SET_CAMP_REVIEWS_LOAD_OPTIONS, defaultOptions);
		}
		commit(SET_CAMP_REVIEWS_PARAMS, params);
	},
};
