
import LoadingWithOverlay from '@/components/loading/LoadingWithOverlay.vue';
import MobileLoading from '@/components/loading/MobileLoading.vue';

import layoutMixin from '@/mixins/layoutMixin';

export default {
	name: 'LoadingWrapper',
	mixins: [layoutMixin],
	data() {
		return {
			loading: false,
			isRouteChanging: false,
			spinnerObject: this.$loadingSpinner,
		};
	},
	methods: {
		// Called when a route changes, this is where you display your component.
		start() {
			this.loading = true;
		},
		// Called during loading the route component, num is an Integer < 100.
		increase() {
			this.isRouteChanging = true;
		},
		// Called when a route is loaded (and data fetched), this is where you hide your component.
		finish() {
			this.loading = false;
			this.isRouteChanging = false;
		},
	},
	components: {
		LoadingWithOverlay,
		MobileLoading,
	},
};
