import { baseUrl } from '@/plugins/constants';

export default function ({ $api, redirect, store }) {
	// SSR 때는 렌더링을 막지 않고 리디렉트할 방법이 없어서 @/mixins/blockMixin으로 대체
	if (process.server || !store.getters.isUser) {
		return;
	}
	checkBlockedUser();

	async function checkBlockedUser() {
		try {
			const { data } = await $api.user.checkBlockUserRedirect();
			const { code, msg } = data;

			if (code === 306) {
				redirect(`${baseUrl}${msg}`);
			}
		} catch (error) {
			if (error.response?.status === 403) {
				return;
			}
			console.error(error);
		}
	}
}
