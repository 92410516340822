import get from 'lodash/get';
import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'modal/alert';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfAlertModal,
	mapMutations as mapMutationsOfAlertModal,
	mapActions as mapActionsOfAlertModal,
};

export const ALERT_SHOW_MODAL = 'ALERT_SHOW_MODAL';
export const ALERT_OPTIONS = 'ALERT_OPTIONS';
export const ALERT_BUTTON_COLOR = 'ALERT_BUTTON_COLOR';
export const ALERT_SUCCESS_MESSAGE = 'ALERT_SUCCESS_MESSAGE';
export const ALERT_PERSISTENT = 'ALERT_PERSISTENT';
export const ALERT_CONTENT = 'ALERT_CONTENT';

export const ALERT_SET_OPTIONS = 'ALERT_SET_OPTIONS';
export const ALERT_OPEN_MODAL = 'ALERT_OPEN_MODAL';
export const ALERT_CLOSE_MODAL = 'ALERT_CLOSE_MODAL';
export const ALERT_OPEN_MODAL_WITH_OPTIONS = 'ALERT_OPEN_MODAL_WITH_OPTIONS';

export const state = () => ({
	ALERT_SHOW_MODAL: false,
	ALERT_OPTIONS: {
		ALERT_BUTTON_COLOR: 'gray500',
		ALERT_SUCCESS_MESSAGE: '확인',
		ALERT_PERSISTENT: false,
		ALERT_CONTENT: '',
	},
});

export const getters = {
	ALERT_SHOW_MODAL: state => state[ALERT_SHOW_MODAL],
	ALERT_BUTTON_COLOR: state => get(state, [ALERT_OPTIONS, ALERT_BUTTON_COLOR]),
	ALERT_SUCCESS_MESSAGE: state => get(state, [ALERT_OPTIONS, ALERT_SUCCESS_MESSAGE]),
	ALERT_PERSISTENT: state => get(state, [ALERT_OPTIONS, ALERT_PERSISTENT]),
	ALERT_CONTENT: state => get(state, [ALERT_OPTIONS, ALERT_CONTENT]),
};

export const mutations = {
	ALERT_OPEN_MODAL: state => {
		state[ALERT_SHOW_MODAL] = true;
	},
	ALERT_CLOSE_MODAL: state => {
		state[ALERT_SHOW_MODAL] = false;
	},
	ALERT_SET_OPTIONS: (state, payload) => {
		const defaultOptions = {
			ALERT_BUTTON_COLOR: 'gray500',
			ALERT_SUCCESS_MESSAGE: '확인',
			ALERT_PERSISTENT: false,
			ALERT_CONTENT: '',
		};
		state[ALERT_OPTIONS] = { ...defaultOptions, ...payload };
	},
};

export const actions = {
	ALERT_OPEN_MODAL_WITH_OPTIONS({ commit }, payload) {
		commit(ALERT_SET_OPTIONS, payload);
		commit(ALERT_OPEN_MODAL);
	},
};
