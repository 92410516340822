
import { CASE_PATH } from '@/constants/paths';

import { BACKWARD_BUTTON_MARGIN_BOTTOM, mapGettersOfLayout } from '@/store/layout';

export default {
	name: 'BackwardButton',
	computed: {
		...mapGettersOfLayout([BACKWARD_BUTTON_MARGIN_BOTTOM]),
		styles() {
			return {
				'margin-bottom': this[BACKWARD_BUTTON_MARGIN_BOTTOM],
			};
		},
	},
	methods: {
		goPage() {
			const historyBackUrl = {
				'/job-questions/': CASE_PATH,
				'/edu/learn/': '/edu/list',
			};

			let hrefUrl = null;
			// eslint-disable-next-line array-callback-return
			Object.keys(historyBackUrl).some(key => {
				if (location.pathname.includes(key)) {
					hrefUrl = historyBackUrl[key];
				}
			});
			if (hrefUrl) {
				window.location.href = hrefUrl;
			}
			window.history.back();
		},
	},
};
