
import layoutMixin from '@/mixins/layoutMixin';

import Skeleton from '@/src/components/Other/Skeleton.vue';

export default {
	name: 'CaseDetailAnswerSkeleton',
	mixins: [layoutMixin],
	computed: {
		cardCount() {
			return 1;
		},
	},
	components: { Skeleton },
};
