export const CAREER = 'career';
export const INTEREST_DEPARTMENT = 'interest-dep';
export const TOP_CATEGORY = 'topCategory';
export const MID_CATEGORY = 'midCategory';
export const NICKNAME = 'nickname';
export const UNIV = 'univ';
export const MAJOR = 'major';
export const SCHOOL = 'school';
export const MARKETING = 'marketing';
export const COMPANY = 'company';
export const USER_META_COMPANY_GROUPS = [
	'대기업',
	'중견기업',
	'중소기업',
	'외국계',
	'스타트업',
	'프리랜서',
	'공기업',
	'공무원',
	'교직원',
	'비영리',
	'창업',
	'전문직',
	'기타',
];
export const HIGH_SCHOOL_ACADEMIC = '고등학교 졸업';
export const UNIV_ACADEMIC = '대학교 졸업 (4년)';
export const SCHOOL_ACADEMIC_OPTIONS = [HIGH_SCHOOL_ACADEMIC, '대학교 재학', '대학교 졸업 (2, 3년)', UNIV_ACADEMIC];
export const HIGH_SCHOOL_MAJOR_OPTIONS = [
	'문과계열',
	'이과계열',
	'전문(실업)계',
	'예체능계',
	'특성화/ 마이스터고',
	'특수목적고',
];
export const SCHOOL_DEFAULT_PROPERTY = { academic: '', name: '', major: '', isNewbiePartner: false };
export const COMPANY_INFO = { company: '', job: '' };
