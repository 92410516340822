export const ROUTE_NAMES = {
	JOB_QUESTIONS: 'job-questions',
	CASE_LIST: 'job-questions-keyword',
	READ_CASE: 'job-questions-company-position-questionAndNo',
	ANSWER: 'answer',
	COMMUNITY_LOUNGE: 'community-lounge',
	EDU: 'edu',
	EDU_LIST: 'edu-list',
	EDU_DETAIL: 'edu-learn-top-camp',
	PATH_DETAIL: 'edu-path-learn-top-camp',
	VOD: 'vod',
	VOD_DETAIL: 'vod-lecture-top-title',
	CLASS: 'class',
	CLASS_VOD: 'class-vod',
	CLASS_VOD_DETAIL: 'class-vod-detail-no',
	CLASS_VOD_DETAIL_SAMPLE: 'class-vod-detail-sample-no',
	CLASS_PT: 'class-pt',
	CLASS_PT_LIST: 'class-pt-list-keyword',
	CLASS_PT_DETAIL: 'class-pt-content-title-classNo',
	CLASS_PT_DETAIL_OLD: 'class-pt-contents-top-class',
	SEARCH_LIST: 'search-product-keyword',
	CAREER_EXTERNSHIP: 'career-externship',
	EXTERNSHIP_DETAIL: 'career-externship-top-camp',
	BOOKMARK: 'bookmark',
	CAREER_RECRUIT: 'career-recruit',
	CAREER_RECRUIT_DETAIL: 'career-recruit-recruitIdWithCompanyName',
} as const;
