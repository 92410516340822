
import { mapGetters } from 'vuex';

import { MY_PAGE_SETTING_PATH } from '@/constants/paths/my-page';

import { isEmptyData } from '@/utils/utils';

import { IS_UNIV_AFFILIATE_AVAILABLE, mapGettersOfCase } from '@/store/case';
import { GET_IS_LOADED_PASS_INFO, GET_PASS_INFO, mapGettersOfMyPage } from '@/store/myPage';

import ImageUploadWrapper from '@/components/common/atoms/ImageUploadWrapper.vue';
import ProfileAvatar from '@/components/common/atoms/ProfileAvatar.vue';
import SocialIcon from '@/components/common/atoms/SocialIcon.vue';
import MyPageAsideMenuButton from '@/components/my-page/layout/MyPageAsideMenuButton.vue';
import MyPageToAffiliateBanner from '@/components/my-page/layout/MyPageToAffiliateBanner.vue';

import layoutMixin from '@/mixins/layoutMixin';
import passInfoMixin from '@/mixins/passInfoMixin';
import { profileImageMixin } from '@/mixins/profileImageMixin';
import routeMixin from '@/mixins/routeMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

import Skeleton from '@/src/components/Other/Skeleton.vue';

export default {
	name: 'MyPageAsideMenu',
	mixins: [userInfoMixin, routeMixin, profileImageMixin, passInfoMixin, layoutMixin],
	props: {
		myPageMenus: {
			type: Object,
			default: () => {},
		},
		currentMenuIndex: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			showDrawer: false,
			boxHeight: 0,
		};
	},
	computed: {
		...mapGetters(['isUser', 'userInfo', 'userProfileImage', 'isMentee']),
		...mapGettersOfMyPage([GET_PASS_INFO, GET_IS_LOADED_PASS_INFO]),
		...mapGettersOfCase([IS_UNIV_AFFILIATE_AVAILABLE]),
		showExtendableMenu() {
			return this.userType === 1;
		},
		passUserInfo() {
			return this[GET_PASS_INFO]?.userInfo;
		},
		nickname() {
			return this.passUserInfo?.nickname ? this.passUserInfo.nickname : this.defaultNickname;
		},
		defaultNickname() {
			return `${this.userType === 1 ? '멘티' : '멘토'}${this.userNo}`;
		},
		isUserLoaded() {
			return !this.isUser || this[GET_IS_LOADED_PASS_INFO];
		},
		userSocialType() {
			return this.userInfo?.social_type;
		},
		hasProfileImage() {
			return !isEmptyData(this.userProfileImage);
		},
		showMyPageToAffiliateBanner() {
			return this.isMentee && this[IS_UNIV_AFFILIATE_AVAILABLE];
		},
	},
	watch: {
		showDrawer() {
			const iconRefs = this.$refs.myPageDrawerArrowIcon;
			if (!this.showDrawer) {
				this.boxHeight = 0;
				iconRefs.$el.classList.add('click-passive');
				iconRefs.$el.classList.remove('click-active');
			} else {
				const refs = this.$refs.myPageDrawerItem;
				this.boxHeight = refs.clientHeight;
				iconRefs.$el.classList.add('click-active');
				iconRefs.$el.classList.remove('click-passive');
			}
		},
	},
	methods: {
		goProfileDetail() {
			this.$_routeMixin_go_page(MY_PAGE_SETTING_PATH);
		},
		toggleDrawer() {
			return (this.showDrawer = !this.showDrawer);
		},
		getCurrentMenuIndex(menu, index) {
			if (!this.IS_MOBILE && menu.hasSubMenu && this.myPageMenus.menus[this.currentMenuIndex]?.isSubMenu) {
				// 서브 메뉴가 선택되어있으면 상위 메뉴 bold 시키기
				return index < this.currentMenuIndex ? index : this.currentMenuIndex;
			}
			return this.currentMenuIndex;
		},
	},
	components: {
		MyPageToAffiliateBanner,
		SocialIcon,
		MyPageAsideMenuButton,
		Skeleton,
		ProfileAvatar,
		ImageUploadWrapper,
	},
};
