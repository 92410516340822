
import { mapGetters } from 'vuex';

import { mapMutationsOfAnswer, SET_IS_SHOW_MENTOR_AUTH_MODAL } from '@/store/answer';

import AnswerSideBoxCountInfo from '@/components/answer/molecules/AnswerSideBoxCountInfo.vue';
import AnswerSideBoxMissionExp from '@/components/answer/molecules/AnswerSideBoxMissionExp.vue';
import AnswerSideBoxTrophy from '@/components/answer/molecules/AnswerSideBoxTrophy.vue';
import UserProfileSection from '@/components/case/list/main/molecules/UserProfileSection.vue';
import ProfileImagePopover from '@/components/common/molecules/ProfileImagePopover.vue';

import routeMixin from '@/mixins/routeMixin';

export default {
	name: 'AnswerSideBox',
	mixins: [routeMixin],
	computed: {
		...mapGetters(['userType', 'isUser']),
	},
	methods: {
		...mapMutationsOfAnswer([SET_IS_SHOW_MENTOR_AUTH_MODAL]),
		showMentorAuthModal() {
			if (this.isUser) {
				this[SET_IS_SHOW_MENTOR_AUTH_MODAL](true);
			} else {
				this.$_routeMixin_href(`/logout`);
			}
		},
	},
	components: {
		AnswerSideBoxMissionExp,

		AnswerSideBoxCountInfo,
		AnswerSideBoxTrophy,
		UserProfileSection,
		ProfileImagePopover,
	},
};
