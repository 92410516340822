
import NotiReadAllButton from '@/components/common/molecules/noti/NotiReadAllButton.vue';

export default {
	name: 'NotiSideDrawer',
	methods: {
		emitReadAll() {
			this.$emit('read-all');
		},
	},
	components: { NotiReadAllButton },
};
