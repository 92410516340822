import _cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex';

import { SEARCH_PRODUCT_TYPES } from '@/constants/search/search-products';

import { baseUrl } from '@/plugins/constants';
import dayjs from '@/plugins/dayjs';

import {
	GET_ANSWER_LIST,
	GET_ANSWER_LIST_INFO,
	GET_ANSWER_REMOVE_CARD_INFO,
	GET_ANSWER_REMOVE_INDEX,
	GET_ANSWER_LIST_TOTAL_COUNT,
	GET_ANSWER_MISSION_INFO,
	mapGettersOfAnswer,
	SET_ANSWER_LIST,
	SET_ANSWER_KEYWORD,
	SET_IS_FETCHING_ANSWER_LIST,
	SET_FIRST_ANSWER_LIST,
	SET_ANSWER_LIST_INFO,
	SET_ANSWER_PAGE,
	SET_ANSWER_REMOVE_CARD_INFO,
	mapMutationsOfAnswer,
	LOAD_ANSWER_USER_INFO,
	LOAD_ANSWER_LIST,
	mapActionsOfAnswer,
	GET_ANSWER_KEYWORD,
	GET_ANSWER_PAGE,
} from '@/store/answer';
import {
	mapGettersOfSearchList,
	SEARCH_LIST_PARAMS,
	mapMutationsOfSearchList,
	SET_SEARCH_COUNT,
} from '@/store/search/list';

import communityGtmTriggerMixin from '@/mixins/gtm/communityGtmTriggerMixin';
import gtmSearchMixin from '@/mixins/gtm/gtmSearchMixin';
import routeMixin from '@/mixins/routeMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

const answerMixin = {
	mixins: [routeMixin, communityGtmTriggerMixin, userInfoMixin, gtmSearchMixin],
	data() {
		return {
			baseUrl,
			relativityList: {
				com: { value: '회사', chipColor: 'secondary', chipType: 'outline' },
				industry: { value: '산업', chipColor: 'primary', chipType: 'outline' },
				job: { value: '직무', chipColor: 'primary', chipType: 'outline' },
				interest: { value: '관심사', chipColor: 'primary', chipType: 'fill' },
				univ: { value: '학교', chipColor: 'info', chipType: 'fill' },
			},
		};
	},
	computed: {
		...mapGetters(['isUser']),
		...mapGettersOfAnswer([
			GET_ANSWER_LIST_TOTAL_COUNT,
			GET_ANSWER_LIST,
			GET_ANSWER_MISSION_INFO,
			GET_ANSWER_LIST_INFO,
			GET_ANSWER_REMOVE_CARD_INFO,
			GET_ANSWER_REMOVE_INDEX,
			GET_ANSWER_KEYWORD,
			GET_ANSWER_PAGE,
		]),
		...mapGettersOfSearchList([SEARCH_LIST_PARAMS]),
	},
	methods: {
		...mapMutationsOfAnswer([
			SET_ANSWER_LIST,
			SET_IS_FETCHING_ANSWER_LIST,
			SET_ANSWER_LIST_INFO,
			SET_ANSWER_PAGE,
			SET_FIRST_ANSWER_LIST,
			SET_ANSWER_REMOVE_CARD_INFO,
			SET_ANSWER_KEYWORD,
		]),
		...mapMutationsOfSearchList([SET_SEARCH_COUNT]),
		...mapActionsOfAnswer([LOAD_ANSWER_USER_INFO, LOAD_ANSWER_LIST]),
		$_isUserCheck() {
			if (!this.isUser) {
				this.$_routeMixin_href('/logout');
				return false;
			}
			return true;
		},
		$_initAnswer() {
			this[SET_ANSWER_PAGE](1);
			this[SET_ANSWER_LIST]([]);
			this[SET_FIRST_ANSWER_LIST]([]);
			this[SET_IS_FETCHING_ANSWER_LIST](false);
			this[SET_ANSWER_LIST_INFO]({
				page: 1,
				filter_request_council: 1,
				filter_request_essay: 1,
				filter_request_interview: 1,
				keyword: '',
			});
		},
		$_relativityLevel(value) {
			if (!value) {
				return false;
			}
			switch (value) {
				case '#회사':
					return 'com';
				case '#산업':
					return 'industry';
				case '#직무':
					return 'job';
				case '#관심사':
					return 'interest';
				case '#학교':
					return 'univ';
			}
		},
		$_removeUpdateAnswerList(removeIndex) {
			const answerList = _cloneDeep(this[GET_ANSWER_LIST]);
			answerList.splice(removeIndex, 1);
			this[SET_ANSWER_LIST](answerList);
		},
		async $_removeAnswer() {
			const payload = {
				request_no: this[GET_ANSWER_REMOVE_CARD_INFO].no,
				type: this[GET_ANSWER_REMOVE_CARD_INFO].type,
			};
			const {
				data: { code },
			} = await this.$api.answerList.postRemoveAnswer(payload);

			if (code === 200) {
				this.$_removeUpdateAnswerList(this[GET_ANSWER_REMOVE_INDEX]);
				this[SET_ANSWER_REMOVE_CARD_INFO](null);
			} else {
				Toast.show({
					message: '오류가 발생했습니다. 다시 시도해주세요.',
					type: 'error',
					position: this.isMobile ? 'bottom' : 'top',
				});
			}
		},
		async $_searchAnswerInfoSetting(isSearch = false) {
			this.$_initAnswer();
			this[SET_ANSWER_KEYWORD](this[SEARCH_LIST_PARAMS]?.keyword);
			await this[LOAD_ANSWER_USER_INFO]();
			// LOAD_ANSWER_LIST에서 page+1를 해버리므로, 부르기 전에 해야 함.
			this.$_answerMixin_triggerViewSearchProduct();
			await this[LOAD_ANSWER_LIST]({
				sort: this[SEARCH_LIST_PARAMS]?.sort,
				isSearch,
			});
			this[SET_SEARCH_COUNT](this[GET_ANSWER_LIST_TOTAL_COUNT]);
		},
		$_isCompleteMentorMission() {
			// 미션완료 날짜와, 당일 일자가 같은 경우 미션을 완료한 것으로 한다
			const today = dayjs();
			const missionInfo = this[GET_ANSWER_MISSION_INFO];

			if (missionInfo && missionInfo.mission_complete) {
				const missionCompleteDate = missionInfo.mission_complete_date;

				const recentFinishedDate = missionCompleteDate.filter(
					day => dayjs(day.finished_date).format('YYYY-MM-DD') === dayjs(today).format('YYYY-MM-DD'),
				);
				if (recentFinishedDate) {
					// GTM - completeMentorMission
					this.$_completeMentorMission({ ...this.$_userInfoForGtm });
				}
			}
		},
		$_answerMixin_triggerViewSearchProduct() {
			this.$_gtmSearchMixin_viewSearchProduct({
				search_keyword: this[GET_ANSWER_KEYWORD],
				product: SEARCH_PRODUCT_TYPES.ANSWER,
				page_depth: this[GET_ANSWER_PAGE],
			});
		},
	},
};

export default answerMixin;
