
import { mapGetters } from 'vuex';

import { MY_PAGE_MENUS } from '@/constants/my-page';

import { isEmptyData } from '@/utils/utils';

import { mapMutationsOfClassPtOpen, SET_SHOW_CREATE_CLASS_MODAL } from '@/store/class/pt/open';

import MyPageToLeadMentorNotionBanner from '@/components/my-page/_main/atom/MyPageToLeadMentorNotionBanner.vue';
import MyPageNav from '@/components/my-page/layout/MyPageNav.vue';

import gtmTriggerMixin from '@/mixins/gtmTriggerMixin';
import layoutMixin from '@/mixins/layoutMixin';
import myPageMixin from '@/mixins/myPageMixin';
import routeMixin from '@/mixins/routeMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

export default {
	name: 'MyPageNavSection',
	mixins: [layoutMixin, myPageMixin, routeMixin, userInfoMixin, gtmTriggerMixin],
	props: {
		currentMenu: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			inquiryUrl: 'http://pf.kakao.com/_jYxfDj/chat',
		};
	},
	computed: {
		...mapGetters(['isUser']),
		isShowTitle() {
			return !(this.IS_MOBILE || this.currentMenu.isTitleDisabled);
		},
		menuButtonInfo() {
			if (!this.currentMenu.buttonInfo) {
				return { hasButton: false, iconName: '', buttonText: '' };
			}
			const { hasButton, iconName, buttonText, action } = this.currentMenu.buttonInfo;
			return { hasButton, iconName, buttonText, action };
		},
	},
	methods: {
		...mapMutationsOfClassPtOpen([SET_SHOW_CREATE_CLASS_MODAL]),
		isEmptyData,
		openCreateClassModal() {
			this.setClickCTAButtonOnMyPage();
			this[SET_SHOW_CREATE_CLASS_MODAL](true);
		},
		setClickCTAButtonOnMyPage() {
			this.$_clickCTAButtonOnMyPage({
				...this.$_userInfoForGtm,
				where: '클래스만들기_상단',
				click_to: '과정개발시스템',
			});
		},
		clickNavButton() {
			if (this.menuButtonInfo.action === MY_PAGE_MENUS.CREATE_CLASS) {
				return this.openCreateClassModal();
			}
			return false;
		},
		moveToInquiry() {
			this.$_routeMixin_open_page(this.inquiryUrl);
		},
	},
	components: {
		MyPageToLeadMentorNotionBanner,
		MyPageNav,
	},
};
