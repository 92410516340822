import Toast from './ToastTemplate.vue';

const toast = {
	install(Vue) {
		const ToastConstructor = Vue.extend(Toast);
		let toastInstance = null;

		window.Toast = () => {};

		window.Toast.show = ({ message, type, position, timeout, icon = null, clickEvent = null, actionName }) => {
			toastInstance = new ToastConstructor();
			if (toastInstance.$_app_canShowToast) {
				return icon == null
					? toastInstance.$_app_showToast({
							message,
							type,
							position,
							timeout,
					  })
					: toastInstance.$_app_showToast({
							message,
							type,
							position,
							timeout,
							icon,
					  });
			}

			toastInstance = new ToastConstructor({
				el: document.createElement('div'),
				data() {
					return {
						message,
						type,
						position,
						timeout,
						icon,
						clickEvent,
						actionName,
					};
				},
			});

			if (icon !== null) {
				toastInstance.showIcon = true;
			}
			if (clickEvent !== null) {
				toastInstance.clickEvent = clickEvent;
			}
			toastInstance.showToast = true;
			document.body.appendChild(toastInstance.$el);
		};
	},
};

export default toast;
