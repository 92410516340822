import { isEmptyData } from '@/utils/utils';

import { mapGettersOfCase, USER_PARTNER_INFO } from '@/store/case';
import { GET_PASS_INFO, mapGettersOfMyPage } from '@/store/myPage';

const passInfoMixin = {
	computed: {
		...mapGettersOfMyPage([GET_PASS_INFO]),
		...mapGettersOfCase([USER_PARTNER_INFO]),
		$_hasPassInfo() {
			return !this.isEmptyData(this[GET_PASS_INFO]?.pass_name);
		},
		$_organizationTypeText() {
			return this[USER_PARTNER_INFO].organizationType === 'university' ? '대학' : '기관';
		},
		$_passExpireDate() {
			return this[GET_PASS_INFO].pass_expire;
		},
		$_passNameAtMyPage() {
			if (this[USER_PARTNER_INFO].isUserUsingAffiliate) {
				return `제휴 ${this.$_organizationTypeText} 혜택`;
			}
			return this[GET_PASS_INFO].pass_name;
		},
	},
	methods: {
		isEmptyData,
	},
};

export default passInfoMixin;
