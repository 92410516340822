
import Lottie from 'vue-lottie/src/lottie.vue';

export default {
	name: 'LoadingSpinner',
	data() {
		return {
			loadingSpinner: this.$loadingSpinner,
			options: {
				animationData: this.$loadingSpinner,
			},
		};
	},
	components: {
		Lottie,
	},
};
