import { COMMUNITY_FEED_TYPE } from '@/constants/community';

const COMENTO = '코멘토';

export const GTM_COMMUNITY_WHERE = {
	CASE: 'Case',
	ANSWER: 'Answer',
	NOTICE: 'Notice',
	MENTOR_FEEDBACK_LIST: 'mentorFeedbackList',
	ON_BOARD_CASE_LIST: 'onboardCaseList',
	READ_CASE: 'readCase',
};

export const GTM_COMMUNITY_TARGET_TYPE = {
	QUESTION: '질문',
	ANSWER: '답변',
	COMMENT: '댓글',
};

export const GTM_COMMUNITY_EVENT_NAMES = {
	ADD_BOOKMARKS_COMMUNITY: 'addBookMarks_community',
};

export const COMMUNITY_GTM_WHERE_MAP = {
	[COMMUNITY_FEED_TYPE.CASE]: '질문',
	[COMMUNITY_FEED_TYPE.ANSWER]: '답변',
	[COMMUNITY_FEED_TYPE.COMMENT]: '댓글',
	[COMMUNITY_FEED_TYPE.REPLY]: '댓글',
};

export const GTM_COMMUNITY_AD_PARAMETERS = {
	JOB_POSTING_ID: {
		EDU: '직무부트캠프',
		PT: '실무PT',
		EXTERNSHIP: '익스턴십-230911',
		BRAZE: 'brazeCRM',
		PACER: '용산2기',
		DADE: '프론트엔드개발자취업캠프',
		NHR: 'NHR-230818',
		HANHWAMOMENTUM: '한화모멘텀-230821',
		HANHWA_INVESTMENT: '한화투자증권-230828',
		HANHWA_OCEAN: '한화오션-230905',
		HWAVE: 'LG디스플레이-230905',
		NCSOFT: '엔씨소프트-230906',
		COMENTO_SESAC: '코멘토새싹-230918',
		COMENTO_GOVERNMENT_SUPPORT: '코멘토-KDC회로설계-231006',
		ODF: 'ODF-231010',
		SHINHAN: '신한카드 "나의 지원금 찾기"-231016',
	},
	PARTNER_NAME: {
		EDU: '코멘토_직무부트캠프',
		PT: '코멘토_실무PT',
		EXTERNSHIP: '코멘토_익스턴십',
		VOD: '코멘토_VOD',
		PACER: '페이서',
		DADE: '데이드',
		NHR: 'NHR',
		HANHWAMOMENTUM: '한화모멘텀',
		HANHWA_INVESTMENT: '한화투자증권',
		HANHWA_OCEAN: '한화오션',
		HWAVE: 'LG디스플레이',
		NCSOFT: '엔씨소프트',
		COMENTO: '코멘토',
		ODF: 'Girls_in_Tech_Korea',
	},
	CLIENT_NAME: {
		COMENTO: `${COMENTO}-ALL`,
		COMENTO_CBC: `${COMENTO}-CBC`,
		COMENTO_EDU: `${COMENTO}-EDU`,
		COMENTO_KDC: `${COMENTO}-KDC`,
		COMENTO_COMMUNITY: `${COMENTO}-COMMUNITY`,
		SKT: 'SKT',
	},
	WHERE: {
		ON_BOARD_CASE_LIST: 'onboardCaseList',
		VIEW_REQUEST_LIST: 'viewRequestList',
		TIMELINE: 'timeline',
		INDEX: 'index',
		VIEW_SEARCH_PRODUCT: 'viewSearchProduct',
		NOTI_LIST: 'notiList',
		READ_CASE: 'readCase',
		HOME: 'home',
	},
	CATEGORY: {
		COMMUNITY: 'community',
	},
	PRODUCT_NAME: {
		IMAGE: 'image',
		MAIN_SWIPER: 'main_swiper',
		CALLOUT: 'callout',
		NATIVE_TEXT: 'native_text',
	},
};
