
import { EDU_FILTER_DAYS } from '@/constants/edu';

import datepickerMixin from '@/mixins/datepickerMixin';
import eduFilterMixin from '@/mixins/eduFilterMixin';

export default {
	name: 'EduListEtcFilter',
	mixins: [eduFilterMixin, datepickerMixin],
	data() {
		return {
			days: EDU_FILTER_DAYS,
			filterDefaultText: '전체',
			tempDays: [],
			tempEndDate: null,
		};
	},
	watch: {
		$_eduFilterMixin_currentDays: {
			handler(newVal) {
				this.tempDays = newVal;
			},
			immediate: true,
		},
		'$route.query.endDate': {
			handler(newVal) {
				this.tempEndDate = newVal;
			},
			immediate: true,
		},
		tempEndDate(newValue) {
			if (!newValue) {
				return;
			}

			this.$_eduFilterMixin_triggerSelectFilterOptionGtm('종료일');
		},
	},
	methods: {
		handleClickEtcFilterButton() {
			this.$emit('clickEtcFilterButton');
		},
		handlClickApplyFilterButton() {
			const newQuery = { ...this.$route.query };
			if (this.tempDays.length === 0) {
				delete newQuery.day;
			} else {
				newQuery.day = this.tempDays.join('+');
			}

			if (this.tempEndDate === null) {
				delete newQuery.endDate;
			} else {
				newQuery.endDate = this.tempEndDate;
			}

			this.$emit('clickApplyFilterButton');
			const categoryMap = {
				요일: this.tempDays.length > 0,
				종료일: !!this.tempEndDate,
			};

			const categories = Object.keys(categoryMap).filter(key => categoryMap[key]);
			this.$_eduFilterMixin_triggerClickApplyFilterButtonGtm(categories);

			// Abundant Navigation 방지
			if (JSON.stringify(newQuery) === JSON.stringify(this.$route.query)) {
				return;
			}

			this.$router.replace({ query: newQuery });
		},
		toggleDay(day) {
			this.$_eduFilterMixin_triggerSelectFilterOptionGtm('요일');
			const newDays = [...this.tempDays];
			if (newDays.includes(day)) {
				newDays.splice(newDays.indexOf(day), 1);
			} else {
				newDays.push(day);
			}
			// 요일 순서대로 정렬
			newDays.sort(
				(a, b) =>
					EDU_FILTER_DAYS.findIndex(item => item.text === a) -
					EDU_FILTER_DAYS.findIndex(item => item.text === b),
			);
			this.tempDays = newDays;
		},
		resetEtcFilter() {
			this.tempDays = [];
			this.tempEndDate = null;
		},
	},
};
