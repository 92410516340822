export function parseAndSetCookies(setCookieHeader) {
	if (!setCookieHeader) {
		return [];
	}
	return setCookieHeader.map(parseCookieString);
}

function parseCookieString(cookieString) {
	const parts = cookieString.split(';');
	const [nameValue, ...attributes] = parts;
	const [name, value] = nameValue.split('=');

	const cookieAttributes = attributes.reduce((acc, attr) => {
		const [key, val] = attr.trim().split('=');
		acc[key.toLowerCase()] = val || true;
		return acc;
	}, {});

	return {
		name,
		value,
		path: cookieAttributes.path,
		domain: cookieAttributes.domain,
		maxAge: cookieAttributes['max-age'] ? parseInt(cookieAttributes['max-age'], 10) : undefined,
		expires: cookieAttributes.expires ? new Date(cookieAttributes.expires) : undefined,
		secure: !!cookieAttributes.secure,
		httpOnly: !!cookieAttributes.httponly,
		sameSite: cookieAttributes.samesite,
	};
}
