
import { CASE_PATH } from '@/constants/paths';

import { baseUrl, EMAIL } from '@/plugins/constants';

import { isEmptyData, moveToLaravelPage } from '@/utils/utils';

import routeMixin from '@/mixins/routeMixin';

export default {
	name: 'EmailLogin',
	mixins: [routeMixin],
	props: {
		redirectUrl: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			email: null,
			password: null,
			emailError: false,
			passwordError: false,
			errorText: null,
			isLoading: false,
			baseUrl,
		};
	},
	mounted() {
		this.setEmailFocus();
	},
	methods: {
		isEmptyData,
		emitSetGtm(signupType) {
			this.$emit('setGtm', signupType);
		},
		setEmailFocus(isError) {
			this.$nextTick(() => {
				this.$refs.email.$refs.email.focus();
				this.emailError = isError;
			});
		},
		setPasswordFocus(isError) {
			this.$nextTick(() => {
				this.$refs.password.$refs.password.focus();
				this.passwordError = isError;
			});
		},
		toggleLoginType() {
			this.$emit('hide');
		},
		resetStatusText() {
			this.hintText = null;
			this.errorText = null;
			this.emailError = false;
			this.passwordError = false;
		},
		async validCheck() {
			await this.$refs.validObserver.validate().then(isValid => {
				if (isValid) {
					this.submitEmailLogin();
				}
			});
		},
		async submitEmailLogin() {
			try {
				this.isLoading = true;
				this.resetStatusText();
				this.emitSetGtm(EMAIL);
				const params = {
					email: this.email,
					pw: this.password,
					csrf: '',
				};

				const {
					data: { code, url, link, type },
				} = await this.$api.user.submitEmailLogin(params, this.redirectUrl);

				switch (parseInt(code)) {
					case 200: // 성공
						if (!isEmptyData(url)) {
							moveToLaravelPage(url);
						} else {
							const isMentor = parseInt(type) === 2;
							// 로그인 후 유저 정보를 새롭게 가져오기 위해서 router 이동이 아닌 window.location.href로 이동
							if (isMentor) {
								// 현직자는 답변하기로 이동
								window.location.href = `/answer`;
							} else {
								window.location.href = CASE_PATH;
							}
						}
						break;
					case 201:
						moveToLaravelPage(link);
						break;
					case 302:
						this.setEmailFocus(true);
						this.errorText = `유저 정보가 없습니다.`;
						break;
					case 303:
						this.setEmailFocus(true);
						this.errorText = `소셜 가입자입니다. 소셜 버튼으로 로그인해주세요.`;
						break;
					case 304:
						this.setPasswordFocus(true);
						this.errorText = `비밀번호가 틀렸습니다.`;
						break;
					case 305:
						this.setEmailFocus(true);
						this.errorText = `탈퇴한 사용자입니다. 코멘토 이용을 위해서는 새로운 계정 생성이 필요합니다.`;
						break;
					case 306:
						this.setEmailFocus(true);
						this.errorText = `신고 누적으로 이용이 정지된 계정입니다.<br />기타 문의사항이 있으시면 고객센터로 문의해 주세요.`;
						break;
				}
				this.isLoading = false;
			} catch (error) {
				console.error(error);
				this.errorText = `오류가 발생했습니다. 고객센터로 문의해 주세요.`;
				this.isLoading = false;
			}
		},
		clickStartWithOtherLoginButton() {
			this.$emit('startWithOtherLogin');
		},
	},
};
