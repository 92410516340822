export const PROFILE_API_PREFIX = `/api/profile`;

export default axios => ({
	/**
	 * validInfo
	 * @typedef {Object} ValidInfo
	 * @property {boolean} b2b_user
	 * 현재 유효한 제휴 계약번호를 지닌 유저인지 아닌지 (Y/N)
	 * ㄴ 이용중인 제휴 계약번호가 없다면 N
	 * ㄴ 과거에 이용한적 있으나 이미 종료된 계약번호라면 N
	 * ㄴ 현재 시점에 만료일이 도래하지 않은 계약번호를 지닌 경우라면 Y
	 * @property {string} contract_no: 현재 이용중인 제휴 계약번호
	 * @return {promise<ValidInfo>}
	 */
	getValidInfo() {
		return axios.get(`${PROFILE_API_PREFIX}/validInfo`, {
			cache: true,
		});
	},
});
