import { baseUrl } from '@/plugins/constants';

import extend from '@/utils/extendVueApp';

export default function ({ app, store }) {
	extend(app, {
		mounted() {
			this.$_blockMixin_checkBlockedUser();
		},
		methods: {
			async $_blockMixin_checkBlockedUser() {
				if (!store.getters.isUser) {
					return;
				}

				try {
					const {
						data: { code, msg },
					} = await this.$api.user.checkBlockUserRedirect();

					if (code === 306) {
						window.location.replace(`${baseUrl}${msg}`);
					}
				} catch (error) {
					if (error.response?.status === 403) {
						return;
					}
					console.error(error);
				}
			},
		},
	});
}
