import { MY_PAGE_PREFIX_URL } from '@/api/my-page';
const USER_META_PREFIX_URL = `/api/user/meta`;

export default axios => ({
	/**
	 * updateProfile 메타 정보 가져오기
	 * @param {Object} params
	 * @param {string} params.info_parameter_name ['career', 'employment-time', 'interest-depart', 'interest-company', 'interest-area', 'univ']
	 *
	 **/
	getUserMetaInfo(params) {
		return axios.get(`${USER_META_PREFIX_URL}/getInfo`, { params });
	},
	/**
	 * updateProfile 유저 메타 생성 및 업데이트
	 * @param {Object} data
	 * @param {number} data.career 신입/경력
	 * @param {array} data.interest-area 관심 영역 [대기업, 공기업 ...]
	 * @param {array} data.interest-company 관심 회사
	 * @param {array} data.interest-depart 관심 직무, 직책
	 * @param {number} data.employment-time 경력(연차)
	 * @param {string} data.univ 학교 * 전공과 학교는 동시에 보내져야 합니다
	 * @param {string} data.major 전공 * 전공과 학교는 동시에 보내져야 합니다
	 * @param {string} data.nickname 닉네임
	 */
	postUserMetaUpdateOrCreate(data) {
		return axios.post(`${USER_META_PREFIX_URL}/updateOrCreate`, data);
	},
	/**
	 * 업데이트 프로파일 완료 여부 확인(학교, 전공은 제외)
	 */
	checkUpdateProfile() {
		return axios.post(`${USER_META_PREFIX_URL}/checkUpdateProfile`);
	},
	/**
	 * 닉네임 중복검사
	 * @param {Object} data
	 * @param {string} data.nickname 입력한 닉네임
	 */
	checkNicknameDuplicate(data) {
		return axios.post(`${USER_META_PREFIX_URL}/nicknameDuplicate`, data);
	},
	fetchUserMetaAll() {
		return axios.post(`${MY_PAGE_PREFIX_URL}/getMyRecord`);
	},
	/**
	 * 유저가 오래된 관심 직무를 가지고 있어서 새롭게 관심 직무를 선택해야 하는지 확인
	 */
	checkShouldChangeInterestJob() {
		return axios.get(`${USER_META_PREFIX_URL}/check/interest-job`);
	},
});
