import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
const NAMESPACE = 'pay';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export { mapGetters as mapGettersOfPay, mapMutations as mapMutationsOfPay, mapActions as mapActionsOfPay };

export const PG_TYPE = Object.freeze({
	INICIS: 'INICIS',
	KAKAO_PAY: 'KAKAO_PAY',
	TOSS: 'TOSS',
	EDU_VOUCHER: 'EDU_VOUCHER',
});

export const PAYMENT_CATEGORY = Object.freeze({
	VOD: {
		GENERAL: 'vod',
		LECTURE: 'vod-lecture',
	},
});

export const PAYMENT_CATEGORY_NO = Object.freeze({
	ALL: 1,
	EDU: {
		GENERAL: 2,
	},
	CONTENTS: {
		GENERAL: 3,
	},
	VOD: {
		GENERAL: 4,
		LECTURE: 8,
	},
	ONAIR: {
		JOB: 5,
		NORMAL: 6,
	},
	CBC: {
		PT: 7,
	},
});

/**
 * pgType {String} inicis, kakaopay, toss
 * finalPrice {Number}
 * couponInfo {Object}
 * category {String} edu, vod, request,contents
 * itemInfo {Object}
 */
// getters
export const PAY_INFORMATION = 'PAY_INFORMATION';
export const PAY_PG_TYPE = 'PAY_PG_TYPE';
export const PAY_CATEGORY = 'PAY_CATEGORY';
export const PAY_CATEGORY_NO = 'PAY_CATEGORY_NO';

export const PAYER = 'PAYER';
export const PAYER_NAME = 'PAYER_NAME';
export const PAYER_MOBILE_NUMBER = 'PAYER_MOBILE_NUMBER';
export const PAYER_EMAIL = 'PAYER_EMAIL';

export const ITEM_TO_PAY = 'ITEM_TO_PAY';

export const SELECTED_COUPON = 'SELECTED_COUPON';
export const ORIGINAL_COUPON = 'ORIGINAL_COUPON';
export const BEST_COUPON = 'BEST_COUPON';
export const IS_COUPON_SELECTOR_VISIBLE = 'IS_COUPON_SELECTOR_VISIBLE';
export const MY_COUPONS = 'MY_COUPONS';

export const SELLER = 'SELLER';

// mutations
export const SET_PAY_INFORMATION = 'SET_PAY_INFORMATION';
export const SET_PAY_PG_TYPE = 'SET_PAY_PG_TYPE';
const SET_PAY_CATEGORY = 'SET_PAY_CATEGORY';
const SET_PAY_CATEGORY_NO = 'SET_PAY_CATEGORY_NO';

export const SET_PAYER = 'SET_PAYER';
export const SET_PAYER_NAME = 'SET_PAYER_NAME';
export const SET_PAYER_MOBILE_NUMBER = 'SET_PAYER_MOBILE_NUMBER';
export const SET_PAYER_EMAIL = 'SET_PAYER_EMAIL';

export const SET_ITEM_TO_PAY = 'SET_ITEM_TO_PAY';

export const SET_ORIGINAL_COUPON = 'SET_ORIGINAL_COUPON';
export const SET_SELECTED_COUPON = 'SET_SELECTED_COUPON';
export const SET_BEST_COUPON = 'SET_BEST_COUPON';
export const SET_COUPON_SELECTOR_VISIBLE = 'SET_COUPON_SELECTOR_VISIBLE';
export const SET_MY_COUPONS = 'SET_MY_COUPONS';

export const SET_SELLER = 'SET_SELLER';

// actions
export const FIND_PAY_CATEGORY = 'FIND_PAY_CATEGORY';
export const EMPTY_COUPON = 'EMPTY_COUPON';

export const state = () => ({
	[PAY_INFORMATION]: {
		pgType: PG_TYPE.INICIS,
		category: '',
		categoryNo: -1,
	},
	[PAYER]: {},
	[ITEM_TO_PAY]: {},
	[SELECTED_COUPON]: null,
	[ORIGINAL_COUPON]: null,
	[BEST_COUPON]: null,
	[IS_COUPON_SELECTOR_VISIBLE]: false,
	[MY_COUPONS]: [],
	[SELLER]: {},
});

export const getters = {
	[PAY_INFORMATION]: state => state[PAY_INFORMATION],
	[PAY_PG_TYPE]: state => state[PAY_INFORMATION].pgType,
	[PAY_CATEGORY]: state => state[PAY_INFORMATION].category,
	[PAY_CATEGORY_NO]: state => state[PAY_INFORMATION].categoryNo,
	[PAYER]: state => state[PAYER],
	[PAYER_NAME]: state => state[PAYER].name,
	[PAYER_MOBILE_NUMBER]: state => state[PAYER].mobileNumber,
	[PAYER_EMAIL]: state => state[PAYER].email,
	[ITEM_TO_PAY]: state => state[ITEM_TO_PAY],
	[SELECTED_COUPON]: state => state[SELECTED_COUPON],
	[ORIGINAL_COUPON]: state => state[ORIGINAL_COUPON],
	[BEST_COUPON]: state => state[BEST_COUPON],
	[IS_COUPON_SELECTOR_VISIBLE]: state => state[IS_COUPON_SELECTOR_VISIBLE],
	[MY_COUPONS]: state => state[MY_COUPONS],
	[SELLER]: state => state[SELLER],
};

export const mutations = {
	[SET_PAY_INFORMATION]: (state, payload) => (state[PAY_INFORMATION] = payload),
	[SET_PAY_PG_TYPE]: (state, payload) => (state[PAY_INFORMATION] = { ...state[PAY_INFORMATION], pgType: payload }),
	[SET_PAY_CATEGORY]: (state, payload) => (state[PAY_INFORMATION] = { ...state[PAY_INFORMATION], category: payload }),
	[SET_PAY_CATEGORY_NO]: (state, payload) =>
		(state[PAY_INFORMATION] = { ...state[PAY_INFORMATION], categoryNo: payload }),
	[SET_PAYER]: (state, payload) => (state[PAYER] = payload),
	[SET_PAYER_NAME]: (state, payload) => (state[PAYER] = { ...state[PAYER], name: payload }),
	[SET_PAYER_MOBILE_NUMBER]: (state, payload) => (state[PAYER] = { ...state[PAYER], mobileNumber: payload }),
	[SET_PAYER_EMAIL]: (state, payload) => (state[PAYER] = { ...state[PAYER], email: payload }),
	[SET_ITEM_TO_PAY]: (state, payload) => (state[ITEM_TO_PAY] = payload),
	[SET_SELECTED_COUPON]: (state, payload) => Vue.set(state, SELECTED_COUPON, payload),
	[SET_ORIGINAL_COUPON]: (state, payload) => (state[ORIGINAL_COUPON] = payload),
	[SET_BEST_COUPON]: (state, payload) => (state[BEST_COUPON] = payload),
	[SET_COUPON_SELECTOR_VISIBLE]: (state, payload) => (state[IS_COUPON_SELECTOR_VISIBLE] = payload),
	[SET_MY_COUPONS]: (state, payload) => (state[MY_COUPONS] = payload),
	[SET_SELLER]: (state, payload) => Vue.set(state, SELLER, payload),
};

export const actions = {
	[FIND_PAY_CATEGORY]: ({ commit }, { domain, subDomain = 'GENERAL' }) => {
		const category = PAYMENT_CATEGORY[domain.toUpperCase()][subDomain.toUpperCase()];
		const categoryNo = PAYMENT_CATEGORY_NO[domain.toUpperCase()][subDomain.toUpperCase()];
		commit(SET_PAY_CATEGORY, category);
		commit(SET_PAY_CATEGORY_NO, categoryNo);
	},
	[EMPTY_COUPON]({ commit }) {
		commit(SET_SELECTED_COUPON, null);
	},
};
