
import { cdnBaseUrl } from '@/plugins/constants';

import CustomNuxtLink from '@/components/CustomNuxtLink.vue';

export default {
	name: 'IEAlert',
	data() {
		return {
			cdnBaseUrl,
		};
	},
	computed: {
		browserList() {
			return [
				{
					name: 'Chrome',
					img: 'chrome',
					url: 'https://www.google.com/intl/ko/chrome/',
				},
				{
					name: 'Edge',
					img: 'edge',
					url: 'https://www.microsoft.com/ko-kr/edge',
				},
				{
					name: 'Firefox',
					img: 'firefox',
					url: 'https://www.mozilla.org/ko/firefox/new/',
				},
			];
		},
	},
	methods: {
		handleClose() {
			this.$emit('close');
		},
	},
	components: { CustomNuxtLink },
};
