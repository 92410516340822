import get from 'lodash/get';

export const baseUrl = process.env.baseUrl;

export const isProduction = process.env.NODE_ENV === 'production';
export const isLocal = process.env.NODE_ENV === 'local';

export const cdnBaseUrl = 'https://cdn.comento.kr';
export const cdnImageBaseUrl = 'https://cdn.comento.kr/images';

export const channelMap = {
	online: '온라인',
	offline: '오프라인',
};

export const eduDetailTabs = [
	{ id: 'campSummary', ko: '캠프 요약' },
	{ id: 'campMentoIntro', ko: '멘토 소개' },
	{ id: 'campAssignmentIntro', ko: '업무 소개' },
	{ id: 'campSchedule', ko: '상세 일정' },
	{ id: 'campSpecial', ko: '캠프 특징' },
	{ id: 'campPrice', ko: '가격/후기' },
	{ id: 'campFaq', ko: 'FAQ/환불' },
];

export const eduExternshipTabs = [
	{ id: 'campSummary', ko: '과정 요약' },
	{ id: 'campMentoIntro', ko: '멘토 소개' },
	{ id: 'campAssignmentIntro', ko: '과제 소개' },
	{ id: 'campSchedule', ko: '상세 일정' },
	{ id: 'campGoodPoint', ko: '특징' },
	{ id: 'campFaq', ko: 'FAQ' },
];

export const vodDetailTabs = [
	{ id: 'vodDetailInfo', ko: '강의 정보' },
	{ id: 'vodDetailMentoIntro', ko: '멘토 소개' },
	{ id: 'vodDetailPrice', ko: '후기/가격' },
	{ id: 'vodDetailRefund', ko: '환불 규정' },
];

export const onAirDetailTabs = [
	{ id: 'onAirDetailContent', ko: '강의 정보' },
	{ id: 'onAirDetailRefund', ko: '환불 규정' },
];

export const headerHeight = (process.client && get(document.getElementById('header'), 'offsetHeight')) || 48;
export const tabHeight = (process.client && get(document.getElementById('edu-detail-tab'), 'offsetHeight')) || 36;

export const freeScheduleMap = {
	free_schedule: '자율일정',
};
export const liveScheduleMap = {
	default: '라이브',
};
export const isFreeSchedule = type => !!freeScheduleMap[type];
export const isLiveSchedule = type => !!liveScheduleMap[type];

export const defaultPartner = {
	no: 0,
	name: 'comento',
	url: 'comento',
	file: '',
	start_date: '',
	end_date: '',
};

export const channelIOPluginKey = 'cca5e229-8621-4669-9a4c-c8f58cff15b1';

export const META_ALUMNI_URL = `${cdnBaseUrl}/images/meta/meta-alumni.jpg`;

export const CASE_CATEGORY_JOB_SEEKING = '취업 고민';
export const CASE_CATEGORY_JOB_SEEKING_GTM = 'JobSeeking';
export const CASE_CATEGORY_CAREER_CHANGE = '이직 고민';
export const CASE_CATEGORY_CAREER_CHANGE_GTM = 'CareerChange';
export const CASE_CATEGORY_ONLINE_MENTOR = '랜선 사수';
export const CASE_CATEGORY_ONLINE_MENTOR_NEW = '직장인 고민';
export const CASE_CATEGORY_UNIVERSITY_STUDENT_WORRY = '대학생 고민';
export const CASE_CATEGORY_WORKING_LEVEL_SKILL = '실무스킬';
export const CASE_CATEGORY_WORKING_LEVEL_SKILL_NEW = '실무 고민';
export const CASE_CATEGORY_ZAI = '추천피드';
export const CASE_CATEGORY_ZAI_GTM = 'Recommend';

export const CASE_CATEGORY_GROUP_ID_MAP = new Map(
	Object.entries({
		[CASE_CATEGORY_JOB_SEEKING]: 1,
		[CASE_CATEGORY_CAREER_CHANGE]: 2,
		[CASE_CATEGORY_ONLINE_MENTOR]: 3,
		[CASE_CATEGORY_UNIVERSITY_STUDENT_WORRY]: 4,
		[CASE_CATEGORY_WORKING_LEVEL_SKILL]: 5,
		[CASE_CATEGORY_ZAI]: 6,
	}),
);
export const CASE_CATEGORY_GTM_MAP = {
	0: null,
	1: CASE_CATEGORY_JOB_SEEKING_GTM,
	2: CASE_CATEGORY_CAREER_CHANGE_GTM,
	3: CASE_CATEGORY_ONLINE_MENTOR,
	4: CASE_CATEGORY_UNIVERSITY_STUDENT_WORRY,
	5: CASE_CATEGORY_WORKING_LEVEL_SKILL,
};

// map category_id -> category
export const CASE_CATEGORY_MAP = {
	0: null,
	1: CASE_CATEGORY_JOB_SEEKING,
	2: CASE_CATEGORY_CAREER_CHANGE,
	3: CASE_CATEGORY_ONLINE_MENTOR,
	4: CASE_CATEGORY_UNIVERSITY_STUDENT_WORRY,
	5: CASE_CATEGORY_WORKING_LEVEL_SKILL,
};

// map category_id -> category
export const CASE_CATEGORY_DISPLAY_MAP = {
	0: null,
	1: CASE_CATEGORY_JOB_SEEKING,
	2: CASE_CATEGORY_CAREER_CHANGE,
	3: CASE_CATEGORY_ONLINE_MENTOR_NEW,
	4: CASE_CATEGORY_UNIVERSITY_STUDENT_WORRY,
	5: CASE_CATEGORY_WORKING_LEVEL_SKILL_NEW,
};

export const SORT_LATEST = '최신순';
export const SORT_RECOMMENDED = '추천순';
export const ALL_CONTENT_VIEW = '전체 보기';

export const COUPON_BOOK_PATH = '/coupon/book';

export const TIMELINE = '타임라인';
export const LOUNGE = '자유게시판';
export const NOTICE = '공지사항';
export const INSIGHT = '인사이트';

export const CLICKED_PROFILE_IMAGE_POPOVER_CLOSE_BUTTON = 'clickedProfileImagePopoverCloseButton';

export const NAVER = 'naver';
export const KAKAO = 'kakao';
export const GOOGLE = 'google';
export const FACEBOOK = 'facebook';
export const EMAIL = 'email';

export const SOCIAL_LOGIN_MAP = {
	[KAKAO]: '카카오',
	[NAVER]: '네이버',
	[FACEBOOK]: '페이스북',
	[GOOGLE]: '구글',
	[EMAIL]: '이메일',
};

export const LOCAL_STORAGE_TOOLTIP_VISIBILITY = 'tooltipVisibility';

export const API_EDU_PREFIX = '/api/edu/';
export const API_EDU_B2B_PREFIX = '/api/b2b/edu/';

export const PipedriveUuid = Object.freeze({
	PARTNER: 'fd4a2de0-ca22-4c94-a589-28997b65b371',
	RECRUIT: '977524a9-fdb6-4d48-b04d-5853b1d558fd',
});

export const ALL_IN_ONE_LINK = 'https://comento.oopy.io/';

export const DEEP_LINK_ROOT_URL = 'https://comento.page.link/app';

export const bannerLocation = {
	top: 'top',
	sideBox: 'sideBox',
};

export const LOGIN_TOAST_KEY = 'COMENTO_LOGIN_TOAST';

export const IAMPORT_MERCHANT_CODE = 'imp09388674';

export const TEXT_GUIDE_DATE_FORMAT = 'YYYY.MM.DD';

export const DiscountText = {
	SUPER_EARLY_BIRD: '슈퍼 얼리버드 할인',
	FIRST_EARLY_BIRD: '1차 얼리버드 할인',
	SECOND_EARLY_BIRD: '2차 얼리버드 할인',
};

export const EDU_DETAIL_SPARTA_NOTIFICATION_ACTION_TOAST_KEY = 'EDU_DETAIL_SPARTA_NOTIFICATION_ACTION_TOAST_KEY';
export const MATCHING_MODAL_STORAGE_KEY = 'MATCHING_MODAL_STORAGE_KEY';

export const EDU_CARD_IMAGE_SIZE = {
	width: 518,
	height: 272,
	quality: 75,
};

export const GLOBAL_NOTICE_ALERT = `global-notice-alert`;
export const PT_FIRST_ANNIVERSARY_ALERT_KEY = 'pt-first-anniversary-alert';
export const TWITTER_SHARE_URL = 'https://twitter.com/intent/tweet?text=';
export const FACEBOOK_SHARE_URL = 'http://www.facebook.com/sharer/sharer.php?u=';
export const NAVER_SHARE_URL = 'https://share.naver.com/web/shareView?url=';

export const IS_VOD = {
	LIVE_ONLY: 0,
	LIVE_PLUS_VOD: 1,
	VOD_ONLY: 2,
};
