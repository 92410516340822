// plugins/fetchLoadingSpinner.js
import axios from 'axios';

export default async (_, inject) => {
	try {
		const { data } = await axios.get('/cdn', {
			params: { resource: 'https://cdn.comento.kr/images/loading-spinner.json' },
		});
		inject('loadingSpinner', data);
	} catch (error) {
		console.error('Error when fetching loading spinner', error);
	}
};
