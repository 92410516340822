import { createNamespacedHelpers } from 'vuex';
const NAMESPACE = 'whitelist';

const { mapGetters, mapActions } = createNamespacedHelpers(NAMESPACE);
export { mapGetters as mapGettersOfWhitelist, mapActions as mapActionsOfWhitelist };

export const WHITELIST = 'WHITELIST';
export const WHITELIST_FOR_DOCUMENTS = 'WHITELIST_FOR_DOCUMENTS';
export const WHITELIST_FOR_IMAGES = 'WHITELIST_FOR_IMAGES';

export const SET_WHITELIST_FOR_DOCUMENTS = 'SET_WHITELIST_FOR_DOCUMENTS';
export const SET_WHITELIST_FOR_IMAGES = 'SET_WHITELIST_FOR_IMAGES';

export const state = () => ({
	whitelistForDocuments: [],
	whitelistForImages: [],
});

export const getters = {
	[WHITELIST_FOR_DOCUMENTS]: state => state.whitelistForDocuments,
	[WHITELIST_FOR_IMAGES]: state => state.whitelistForImages,
	[WHITELIST]: state => state.whitelistForDocuments.concat(state.whitelistForImages),
};

export const mutations = {
	[SET_WHITELIST_FOR_DOCUMENTS]: (state, payload) => (state.whitelistForDocuments = payload),
	[SET_WHITELIST_FOR_IMAGES]: (state, payload) => (state.whitelistForImages = payload),
};
