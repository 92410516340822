
import appCommandMixin from '@/mixins/appCommandMixin';
import layoutMixin from '@/mixins/layoutMixin';

export default {
	name: 'CustomNuxtLink',
	mixins: [layoutMixin, appCommandMixin],
	props: {
		to: {
			type: String,
			default: '/',
		},
		nuxt: {
			type: Boolean,
			default: true,
		},
		replace: {
			type: Boolean,
			default: false,
		},
		target: {
			type: String,
			default: '_self',
		},
	},
	computed: {
		isExternalLinkInApp() {
			return this.IS_APP && this.target === '_blank';
		},
	},
	methods: {
		openAppBrowser() {
			this.$_app_openExternalBrowser(this.to);
		},
	},
};
