import {
	SET_BACKWARD_BUTTON_MARGIN_BOTTOM,
	SET_IS_LOGO_TOP_BAR,
	HANDLE_SET_IS_TRANSPARENT,
	SET_IS_SHOW_SUB_HEADER,
	SET_IS_SHOW_FOOTER,
	SET_IS_SHOW_HEADER,
	SET_IS_SHOW_BOTTOM_NAV,
	SET_STICKY_ENABLED,
	SET_HAS_FOOTER_MARGIN,
	SET_FOOTER_SLIM,
	SET_TOP_BAR_BUTTONS,
} from '@/store/layout';

export default ({ route, app }) => {
	const fluid = route.meta.reduce((fluid, meta) => meta.fluid || fluid, false);
	const bgColor = route.meta.reduce((bgColor, meta) => meta.bgColor || bgColor, false);
	const title = route.meta.reduce((title, meta) => meta.title || title, false);
	const isDetail = route.meta.reduce((isDetail, meta) => meta.isDetail || isDetail, false);
	const backwardButtonMarginBottom = route.meta.reduce((_, meta) => meta.backwardButtonMarginBottom || '16px', false);
	const isLogoTopBar = route.meta.reduce((isLogoTopBar, meta) => meta.isLogoTopBar || isLogoTopBar, false);
	const isShowSubHeader = route.meta.reduce(
		(isShowSubHeader, meta) => meta.isShowSubHeader || isShowSubHeader,
		false,
	);
	const isTransparentType = route.meta.reduce(
		(isTransparentHeader, meta) => meta.isTransparentHeader || isTransparentHeader,
		false,
	);

	const isShowFooter = route.meta.reduce((isShowFooter, meta) => meta.isShowFooter ?? isShowFooter, true);
	const hasFooterMargin = route.meta.reduce((hasFooterMargin, meta) => meta.hasFooterMargin ?? hasFooterMargin, true);
	const isShowHeader = route.meta.reduce((isShowHeader, meta) => meta.isShowHeader ?? isShowHeader, true);
	const isShowBottomNav = route.meta.reduce((isShowBottomNav, meta) => meta.isShowBottomNav ?? isShowBottomNav, true);
	const isStickyEnabled = route.meta.reduce(
		(isStickyEnabled, meta) => meta.isStickyEnabled ?? isStickyEnabled,
		false,
	);
	const isFooterSlim = route.meta.reduce((isFooterSlim, meta) => meta.isFooterSlim ?? isFooterSlim, false);
	const topBarButtons = route.meta.reduce((topBarButtons, meta) => meta.topBarButtons ?? topBarButtons, null);

	app.store.commit('setMetaFluid', fluid);
	app.store.commit('setMetaIsDetail', isDetail);
	app.store.commit('setMetaBgColor', bgColor);
	app.store.commit('setMetaTitle', title);
	app.store.commit(`layout/${SET_BACKWARD_BUTTON_MARGIN_BOTTOM}`, backwardButtonMarginBottom);
	app.store.commit(`layout/${SET_IS_LOGO_TOP_BAR}`, isLogoTopBar);
	app.store.commit(`layout/${SET_IS_SHOW_SUB_HEADER}`, isShowSubHeader);
	app.store.commit(`layout/${SET_IS_SHOW_FOOTER}`, isShowFooter);
	app.store.commit(`layout/${SET_HAS_FOOTER_MARGIN}`, hasFooterMargin);
	app.store.commit(`layout/${SET_IS_SHOW_FOOTER}`, isShowFooter);
	app.store.commit(`layout/${SET_IS_SHOW_HEADER}`, isShowHeader);
	app.store.commit(`layout/${SET_IS_SHOW_BOTTOM_NAV}`, isShowBottomNav);
	app.store.dispatch(`layout/${HANDLE_SET_IS_TRANSPARENT}`, isTransparentType);
	app.store.commit(`layout/${SET_STICKY_ENABLED}`, isStickyEnabled);
	app.store.commit(`layout/${SET_FOOTER_SLIM}`, isFooterSlim);
	app.store.commit(`layout/${SET_TOP_BAR_BUTTONS}`, topBarButtons);
};
