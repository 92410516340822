
import { ROUTE_NAMES } from '@/constants/route-name';

import { cdnImageBaseUrl } from '@/plugins/constants';

import { setLocalStorage, convertBooleanToYorN } from '@/utils/utils';

import { GET_IS_AUTHORITY, mapGettersOfCase } from '@/store/case';

import communityGtmTriggerMixin from '@/mixins/gtm/communityGtmTriggerMixin';
import scrollMixin from '@/mixins/scrollMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

export default {
	name: 'AppLinkModal',
	mixins: [scrollMixin, communityGtmTriggerMixin, userInfoMixin],
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		storageKey: {
			type: String,
			required: true,
		},
	},
	computed: {
		...mapGettersOfCase([GET_IS_AUTHORITY]),
		showModal: {
			get() {
				return this.show;
			},
			set(value) {
				this.$emit('update:show', value);
			},
		},
		buttonText() {
			return '앱으로 보기';
		},
		modalImageSrc() {
			return `${cdnImageBaseUrl}/app/app-link-c.jpg`;
		},
		modalImage() {
			return {
				width: 271,
				height: 187,
			};
		},
		appLink() {
			const linkUrl = new URL('https://comento-f2f9f.web.app/');
			linkUrl.searchParams.append('utm_source', 'internal');
			linkUrl.searchParams.append('utm_medium', 'banner');
			linkUrl.searchParams.append('utm_campaign', 'app_conversion');
			linkUrl.searchParams.append('utm_term', '전용콘텐츠');
			linkUrl.searchParams.append('utm_content', '240814');

			return linkUrl;
		},
		gtmValue() {
			const { name } = this.$route;
			const where = {
				[ROUTE_NAMES.CASE_LIST]: 'onboardCaseList',
				[ROUTE_NAMES.READ_CASE]: 'readCase',
				[ROUTE_NAMES.ANSWER]: '답변하기',
				[ROUTE_NAMES.VOD]: 'vod',
				[ROUTE_NAMES.CAREER_RECRUIT]: 'onboardJobDescriptionList',
				[ROUTE_NAMES.CAREER_RECRUIT_DETAIL]: 'viewJobDescriptionDetail',
			}[name];

			return {
				...this.$_userInfoForGtm,
				where,
				authority: convertBooleanToYorN(this[GET_IS_AUTHORITY]),
			};
		},
	},
	watch: {
		showModal: {
			immediate: true,
			handler(value) {
				this.$_handleNotScroll(value);
				if (value) {
					this.triggerShowAppBannerGtm();
				}
			},
		},
	},
	methods: {
		closeForToday() {
			this.showModal = false;
			setLocalStorage(this.storageKey, Date.now());
		},
		triggerShowAppBannerGtm() {
			this.$_showAppBanner(this.gtmValue);
		},
		triggerClickAppBannerGtm() {
			this.$_clickAppBanner(this.gtmValue);
		},
	},
};
