import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'vod/player/lecture/community';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfVodPlayerLectureCommunity,
	mapMutations as mapMutationsOfVodPlayerLectureCommunity,
	mapActions as mapActionsOfVodPlayerLectureCommunity,
};

// getters
export const QUESTION_LIST = 'QUESTION_LIST';
export const IS_QUESTION_EMPTY = 'IS_QUESTION_EMPTY';
export const QUESTION_FETCH_PARAMS = 'QUESTION_FETCH_PARAMS';
export const CURRENT_SUB_SECTION = 'CURRENT_SUB_SECTION';
export const CURRENT_QUESTION_NO = 'CURRENT_QUESTION_NO';
export const CURRENT_POST = 'CURRENT_POST';
export const CURRENT_WRITER = 'CURRENT_WRITER';

// mutations
export const SET_QUESTION_LIST = 'SET_QUESTION_LIST';
export const SET_QUESTION_EMPTY = 'SET_QUESTION_EMPTY';
export const SET_QUESTION_FETCH_PARAMS = 'SET_QUESTION_FETCH_PARAMS';
export const SET_CURRENT_SUB_SECTION = 'SET_CURRENT_SUB_SECTION';
export const SET_CURRENT_QUESTION_NO = 'SET_CURRENT_QUESTION_NO';
export const SET_CURRENT_POST = 'SET_CURRENT_POST';
export const SET_CURRENT_WRITER = 'SET_CURRENT_WRITER';

// actions
export const LOAD_QUESTION_LIST = 'LOAD_QUESTION_LIST';
export const GO_BACK_TO_LIST = 'GO_BACK_TO_LIST';
export const WRITE_POST = 'WRITE_POST';
export const MOVE_TO_DETAIL = 'MOVE_TO_DETAIL';
export const LOAD_QUESTION = 'LOAD_QUESTION';

export const SUB_SECTION = Object.freeze({
	LIST_READ: 'LIST_READ',
	WRITE: 'WRITE',
	READ: 'READ',
});

export const state = () => ({
	[QUESTION_LIST]: [],
	[QUESTION_FETCH_PARAMS]: {
		currentPage: 0,
		lastPage: Infinity,
		type: 'all',
		needUpdate: true,
		total: -1,
	},
	[CURRENT_SUB_SECTION]: SUB_SECTION.LIST_READ,
	[CURRENT_QUESTION_NO]: -1,
	[CURRENT_POST]: {
		question: {
			no: -1,
			title: '',
			content: '',
			time: '',
			user: {},
			isLikedByMe: false,
			like: 0,
		},
		comments: [],
		feedbacks: [],
	},
	[CURRENT_WRITER]: {
		title: '',
		content: '',
		images: [],
	},
});

export const getters = {
	[QUESTION_LIST]: state => state[QUESTION_LIST],
	[IS_QUESTION_EMPTY]: state => state[QUESTION_FETCH_PARAMS].total === 0,
	[QUESTION_FETCH_PARAMS]: state => state[QUESTION_FETCH_PARAMS],
	[CURRENT_SUB_SECTION]: state => state[CURRENT_SUB_SECTION],
	[CURRENT_QUESTION_NO]: state => state[CURRENT_QUESTION_NO],
	[CURRENT_POST]: state => state[CURRENT_POST],
	[CURRENT_WRITER]: state => state[CURRENT_WRITER],
};

export const mutations = {
	[SET_QUESTION_LIST]: (state, payload) => (state[QUESTION_LIST] = payload),
	[SET_QUESTION_FETCH_PARAMS]: (state, payload) => (state[QUESTION_FETCH_PARAMS] = payload),
	[SET_CURRENT_SUB_SECTION]: (state, payload) => (state[CURRENT_SUB_SECTION] = payload),
	[SET_CURRENT_QUESTION_NO]: (state, payload) => (state[CURRENT_QUESTION_NO] = payload),
	[SET_CURRENT_POST]: (
		state,
		{
			question = state[CURRENT_POST].question,
			comments = state[CURRENT_POST].comments,
			feedbacks = state[CURRENT_POST].feedbacks,
		},
	) => {
		Vue.set(state[CURRENT_POST], 'question', question);
		Vue.set(state[CURRENT_POST], 'comments', comments);
		Vue.set(state[CURRENT_POST], 'feedbacks', feedbacks);
	},
	[SET_CURRENT_WRITER]: (state, payload) => (state[CURRENT_WRITER] = { ...state[CURRENT_WRITER], ...payload }),
};

export const actions = {
	async [LOAD_QUESTION_LIST]({ state, commit }, lectureId) {
		if (state[QUESTION_FETCH_PARAMS].needUpdate) {
			commit(SET_QUESTION_FETCH_PARAMS, {
				...state[QUESTION_FETCH_PARAMS],
				currentPage: 0,
			});
		}

		const currentPage = state[QUESTION_FETCH_PARAMS].currentPage;
		if (currentPage >= state[QUESTION_FETCH_PARAMS].lastPage) {
			return;
		}

		const isFirstLoad = currentPage === 0;

		const {
			data: { data },
		} = await this.$api.vodLecture.getCommunityList({
			page: currentPage + 1,
			type: state[QUESTION_FETCH_PARAMS].type,
			lectureId,
		});
		if (isFirstLoad) {
			commit(SET_QUESTION_LIST, data.data);
		} else {
			commit(SET_QUESTION_LIST, [...state[QUESTION_LIST], ...data.data]);
		}
		commit(SET_QUESTION_FETCH_PARAMS, {
			...state[QUESTION_FETCH_PARAMS],
			currentPage: data.current_page,
			lastPage: data.last_page,
			needUpdate: false,
			total: data.total,
		});
	},
	[GO_BACK_TO_LIST]() {},
	[WRITE_POST]() {},
	[MOVE_TO_DETAIL]({ commit }, requestNo) {
		commit(SET_CURRENT_QUESTION_NO, requestNo);
		commit(SET_CURRENT_SUB_SECTION, SUB_SECTION.READ);
	},
	async [LOAD_QUESTION]({ state, commit, rootState }) {
		const requestNo = state[CURRENT_QUESTION_NO];
		const questionPromise = async function ($api) {
			const { data: question } = await $api.vodLecture.getCommunityDetailQuestion(requestNo);
			const newQuestion = {
				no: question.no,
				title: question.other_inf,
				content: question.question,
				time: question.time,
				user: question.user_list,
				isLikedByMe: question.isLike,
				like: Number(question.likenum),
			};
			commit(SET_CURRENT_POST, { ...state[CURRENT_POST], question: newQuestion });
		};

		const commentPromise = async function ($api) {
			const { data: comment } = await $api.vodLecture.getCommunityDetailAnswer(
				requestNo,
				rootState.vod.player.lecture.CURRENT_LESSON.lectureId,
			);
			const newComments = [...comment.data];
			const feedbacks = newComments.reduce((feedbacks, comment) => {
				feedbacks.push(comment);
				const replies = comment.reply_feedback.map(reply => {
					reply.isReply = true;
					reply.comment_no = comment.no;
					return reply;
				});
				feedbacks.push(...replies);
				return feedbacks;
			}, []);
			commit(SET_CURRENT_POST, { ...state[CURRENT_POST], comments: newComments, feedbacks });
		};

		await Promise.all([questionPromise(this.$api), commentPromise(this.$api)]);
	},
};
