
import { GLOBAL_NOTICE_ALERT, PT_FIRST_ANNIVERSARY_ALERT_KEY } from '@/plugins/constants';

import {
	IS_SHOW_KPC_HEADER,
	IS_SHOW_GLOBAL_NOTICE_ALERT,
	GET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT,
	SET_IS_SHOW_GLOBAL_NOTICE_ALERT,
	SET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT,
	mapGettersOfLayout,
	mapMutationsOfLayout,
} from '@/store/layout';

import GlobalNoticeAlert from '@/components/navigation/molecules/alert/GlobalNoticeAlert.vue';
import KPCHeader from '@/components/navigation/molecules/alert/KPCHeader.vue';
import PtFirstAnniversaryAlert from '@/components/navigation/molecules/alert/PtFirstAnniversaryAlert.vue';

import cookieMixin from '@/mixins/cookieMixin';
import layoutMixin from '@/mixins/layoutMixin';

export default {
	name: 'AlertLayout',
	mixins: [cookieMixin, layoutMixin],
	props: {
		globalNoticeInfo: {
			type: Object,
			default: () => {},
		},
	},
	computed: {
		...mapGettersOfLayout([
			IS_SHOW_KPC_HEADER,
			IS_SHOW_GLOBAL_NOTICE_ALERT,
			GET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT,
		]),
		isClosedGlobalNoticeBanner() {
			return this.$_getCookie(`${GLOBAL_NOTICE_ALERT}${this.globalNoticeInfo.id}`);
		},
		isClosedPtFirstAnniversaryAlert() {
			return this.$_getCookie(PT_FIRST_ANNIVERSARY_ALERT_KEY);
		},
	},
	methods: {
		...mapMutationsOfLayout([SET_IS_SHOW_GLOBAL_NOTICE_ALERT, SET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT]),
		closeGlobalNoticeAlert() {
			this[SET_IS_SHOW_GLOBAL_NOTICE_ALERT](false);
			if (!this.isClosedGlobalNoticeBanner) {
				const max = 60 * 60 * 24;
				this.$_setCookie(`${GLOBAL_NOTICE_ALERT}${this.globalNoticeInfo.id}`, true, max);
			}
		},
		closePtFirstAnniversaryAlert() {
			this[SET_IS_SHOW_PT_FIRST_ANNIVERSARY_ALERT](false);
			if (!this.isClosedPtFirstAnniversaryAlert) {
				const max = 60 * 60 * 24;
				this.$_setCookie(PT_FIRST_ANNIVERSARY_ALERT_KEY, true, max);
			}
		},
	},
	components: {
		PtFirstAnniversaryAlert,
		GlobalNoticeAlert,
		KPCHeader,
	},
};
