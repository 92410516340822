
import { CASE_PATH } from '@/constants/paths';

import {
	mapGettersOfLoading,
	GET_IS_COMMUNITY_PAGE,
	GET_IS_COMMUNITY_LIST_PAGE,
	GET_IS_COMMUNITY_INSIGHT_LIST_PAGE,
	GET_IS_COMMUNITY_LOUNGE_LIST_PAGE,
	GET_IS_COMMUNITY_TIMELINE_PAGE,
} from '@/store/loading';

import GridLayout from '@/components/common/atoms/GridLayout.vue';
import LoadingWithOverlay from '@/components/loading/LoadingWithOverlay.vue';
import CommunityCardSkeleton from '@/components/skeleton/CommunityCardSkeleton.vue';
import CommunityDetailSkeleton from '@/components/skeleton/CommunityDetailSkeleton.vue';

import appCommandMixin from '@/mixins/appCommandMixin';
import layoutMixin from '@/mixins/layoutMixin';

export default {
	name: 'MobileLoading',
	mixins: [layoutMixin, appCommandMixin],
	props: {
		isRouteChanging: {
			type: Boolean,
			default: false,
		},
		spinner: {
			type: Object,
			default: null,
		},
	},
	computed: {
		...mapGettersOfLoading([
			GET_IS_COMMUNITY_PAGE,
			GET_IS_COMMUNITY_LIST_PAGE,
			GET_IS_COMMUNITY_INSIGHT_LIST_PAGE,
			GET_IS_COMMUNITY_LOUNGE_LIST_PAGE,
			GET_IS_COMMUNITY_TIMELINE_PAGE,
		]),
		styles() {
			let top = '53px'; // 로고강조 TopBar
			if (this[GET_IS_COMMUNITY_LIST_PAGE]) {
				top = '97px'; // TopBar + SubHeader
			} else if (!this.$route.path.startsWith(CASE_PATH)) {
				top = '53px'; // 디테일 TopBar
			}

			return { '--top': top };
		},
	},
	components: {
		CommunityCardSkeleton,
		LoadingWithOverlay,
		CommunityDetailSkeleton,
		GridLayout,
	},
};
