import { createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'vod/player/lecture/note';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE);
export {
	mapGetters as mapGettersOfVodPlayerLectureNote,
	mapMutations as mapMutationsOfVodPlayerLectureNote,
	mapActions as mapActionsOfVodPlayerLectureNote,
};

export const WRITER_MODE = {
	WRITE: 'WRITE',
	UPDATE: 'UPDATE',
	DELETE: 'DELETE',
};

// getters
export const NOTES = 'NOTES';
export const NOTES_FETCH_PARAMS = 'NOTES_FETCH_PARAMS';
export const CURRENT_NOTE = 'CURRENT_NOTE';
export const CURRENT_WRITER_MODE = 'CURRENT_WRITER_MODE';
export const IS_NOTES_EMPTY = 'IS_NOTES_EMPTY';

// mutations
export const SET_NOTES = 'SET_NOTES';
export const SET_NOTES_FETCH_PARAMS = 'SET_NOTES_FETCH_PARAMS';
export const SET_CURRENT_NOTE = 'SET_CURRENT_NOTE';
export const SET_CURRENT_WRITER_MODE = 'SET_CURRENT_WRITER_MODE';

// actions
export const OPEN_NOTE_WRITER = 'OPEN_NOTE_WRITER';
export const RELOAD_NOTE_LIST = 'RELOAD_NOTE_LIST';
export const SEEK_PLAYER_TO_TIME = 'SEEK_PLAYER_TO_TIME';
export const WRITE_NOTE = 'WRITE_NOTE';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const LOAD_NOTES = 'LOAD_NOTES';
export const SCROLL_TOP = 'SCROLL_TOP';

export const state = () => ({
	[NOTES]: [],
	[NOTES_FETCH_PARAMS]: {
		lessonId: -1,
		currentPage: 0,
		lastPage: Infinity,
		total: -1,
		needUpdate: true,
	},
	[CURRENT_NOTE]: {
		id: -1,
		content: '',
		images: [],
		time: '00:00',
	},
	[CURRENT_WRITER_MODE]: WRITER_MODE.WRITE,
});

export const getters = {
	[NOTES]: state => state[NOTES],
	[NOTES_FETCH_PARAMS]: state => state[NOTES_FETCH_PARAMS],
	[IS_NOTES_EMPTY]: state => state[NOTES_FETCH_PARAMS].total === 0,
	[CURRENT_NOTE]: state => state[CURRENT_NOTE],
	[CURRENT_WRITER_MODE]: state => state[CURRENT_WRITER_MODE],
};

export const mutations = {
	[SET_NOTES]: (state, payload) => (state[NOTES] = payload),
	[SET_NOTES_FETCH_PARAMS]: (state, payload) =>
		(state[NOTES_FETCH_PARAMS] = { ...state[NOTES_FETCH_PARAMS], ...payload }),
	[SET_CURRENT_NOTE]: (state, payload) => (state[CURRENT_NOTE] = { ...state[CURRENT_NOTE], ...payload }),
	[SET_CURRENT_WRITER_MODE]: (state, payload) => (state[CURRENT_WRITER_MODE] = payload),
};

export const actions = {
	[OPEN_NOTE_WRITER]() {},
	async [RELOAD_NOTE_LIST]({ commit, dispatch }, { lectureId, lessonId }) {
		commit(SET_NOTES_FETCH_PARAMS, { needUpdate: true });
		await dispatch(LOAD_NOTES, { lectureId, lessonId });
	},
	[SEEK_PLAYER_TO_TIME]() {},
	[WRITE_NOTE]() {},
	async [UPDATE_NOTE]({ state, dispatch }, { content, images, lectureId, lessonId }) {
		const postData = {
			noteId: state[CURRENT_NOTE].id,
			content,
			images,
		};
		await this.$api.vodLecture.updateNote(postData);
		dispatch(RELOAD_NOTE_LIST, { lectureId, lessonId });
	},
	async [LOAD_NOTES]({ state, commit }, { lectureId, lessonId }) {
		if (state[NOTES_FETCH_PARAMS].needUpdate) {
			commit(SET_NOTES_FETCH_PARAMS, { currentPage: 0 });
		}

		const currentPage = state[NOTES_FETCH_PARAMS].currentPage;

		const isFirstLoad = currentPage === 0;

		const {
			data: { data },
		} = await this.$api.vodLecture.getNoteList({
			lessonId,
			lectureId,
		});

		if (isFirstLoad) {
			commit(SET_NOTES, data.data);
		} else {
			commit(SET_NOTES, [...state[NOTES], ...data.data]);
		}
		commit(SET_NOTES_FETCH_PARAMS, {
			currentPage: data.current_page,
			lastPage: data.last_page,
			needUpdate: false,
			total: data.total,
			lessonId,
		});
	},
	[SCROLL_TOP]() {},
};
