import isEmpty from 'lodash/isEmpty';

import { isEarlybird, isEarlybirdVariation } from '@/utils/eduUtil';

import { HANDLE_SET_IS_TRANSPARENT } from '@/store/layout';

export default ({ route, app, query }) => {
	const isEduLandingEarlybird = route.name === 'edu' && query.earlybird !== undefined;
	// /edu/list일때만 (검색때 X)
	const isEduListEarlybird = route.name === 'edu-list' && isEarlybird() && isEmpty(query);

	if (!isEarlybirdVariation() && (isEduLandingEarlybird || isEduListEarlybird)) {
		app.store.dispatch(`layout/${HANDLE_SET_IS_TRANSPARENT}`, true);
	}
};
