export default function ({ $customLogger, store, route }) {
	$customLogger(
		'세션 정보 로그',
		{
			jwt: store.state.jwt,
			userNo: (store.state.userInfo && store.state.userInfo.no) || '없음',
			userInfo: store.state.userInfo,
			localStorageId: store.state.comentoLocalStorageId,
			route,
		},
		'debug',
	);
}
