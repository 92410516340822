/* eslint-disable */

// Gray
export const black = '#000000';
export const gray900 = '#040505';
export const gray850 = '#202325';
export const gray800 = '#3C4144';
export const gray700 = '#595F63';
export const gray600 = '#767D82';
export const gray500 = '#949BA0';
export const gray400 = '#ADB3B8';
export const gray300 = '#CFD4D7';
export const gray250 = '#E1E4E6';
export const gray200 = '#EAECEE';
export const gray100 = '#F3F4F5';
export const gray050 = '#F7F8F8';
export const gray000 = '#FCFCFC';
export const white = '#FFFFFF';

// Primary
// Blue
export const blue800 = '#1B5192';
export const blue600 = '#2A7DE1';
export const blue400 = '#6EA7EB';
export const blue100 = '#D4E5F9';
export const blue000 = '#F7FAFE';

// Success
// Green
export const green800 = '#038542';
export const green600 = '#04CD66';
export const green400 = '#54DD97';
export const green100 = '#CDF5E0';
export const green000 = '#F5FDF9';

// Error
// Red
export const red800 = '#971818';
export const red600 = '#E92525';
export const red400 = '#F06B6B';
export const red100 = '#FBD3D3';
export const red000 = '#FEF6F6';

// Secondary
// Orange
export const orange800 = '#CF5310';
export const orange600 = '#EE7E41';
export const orange400 = '#F3A77E';
export const orange100 = '#FCE5D9';
export const orange000 = '#FEFAF7';

// Secondary
// Yellow
export const yellow600 = '#FFD700';
export const yellow400 = '#FFE452';
export const yellow100 = '#FFF7CC';
export const yellow000 = '#FFFDF5';

export const primary = blue600;
export const success = green600;
export const error = red600;
export const secondary = orange600;
export const link = '#006BEB';

export const colors = {
	black: black,
	gray900: gray900,
	gray850: gray850,
	gray800: gray800,
	gray700: gray700,
	gray600: gray600,
	gray500: gray500,
	gray400: gray400,
	gray300: gray300,
	gray250: gray250,
	gray200: gray200,
	gray100: gray100,
	gray050: gray050,
	gray000: gray000,
	white: white,
	blue800: blue800,
	blue600: blue600,
	blue400: blue400,
	blue100: blue100,
	blue000: blue000,
	green800: green800,
	green600: green600,
	green400: green400,
	green100: green100,
	green000: green000,
	red800: red800,
	red600: red600,
	red400: red400,
	red100: red100,
	red000: red000,
	orange800: orange800,
	orange600: orange600,
	orange400: orange400,
	orange100: orange100,
	orange000: orange000,
	yellow600: yellow600,
	yellow400: yellow400,
	yellow100: yellow100,
	yellow000: yellow000,
	primary: primary,
	success: success,
	error: error,
	secondary: secondary,
	link,
};

export const colorKeys = [...Object.keys(colors)];
